import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as propertiesActionCreators from "../../redux/modules/properties";
import * as appActionCreators from "../../redux/modules/app";
import * as invitesActionCreators from "../../redux/modules/invites";
import WizardContainer from "components/wizard/WizardContainer";
import PropertiesStep from "./PropertiesStep";
import MessageStep from "./MessageStep";
import GuestsStep from "./GuestsStep";
import ConfirmStep from "./ConfirmStep";

class InviteWizardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        emails: ["", "", "", "", ""],
        propertyId: this.props.match.params.propertyId
      }
    };
    this.profile = this.props.auth.getProfile();
  }
  async componentDidMount() {
    await this.props.fetchProperties();
  }
  change = (name, value) => {
    let data = { ...this.state.data, [name]: value };
    this.setState({ data });
  };
  render() {
    const { properties, isLoading, hasFetched } = this.props;
    const { data } = this.state;

    if (isLoading || !hasFetched) return <div>Loading</div>;

    const wizardData = [
      { name: "Properties", component: PropertiesStep },
      { name: "Guests", component: GuestsStep },
      { name: "Message", component: MessageStep },
      {
        name: "Confirm",
        component: ConfirmStep,
        actionTitle: "Send Invitations"
      }
    ];

    return (
      <WizardContainer
        data={wizardData}
        onClose={() => {
          this.props.history.push("/manage");
        }}
        onCancel={() => {
          this.props.history.goBack();
        }}
        title={"Inviting Guests"}
        extraProps={{
          properties: properties,
          data: data,
          change: this.change,
          snackbarMessage: this.props.appSnackbarMessage,
          profile: this.profile,
          sendInvites: this.props.sendInvites
        }}
      />
    );
  }
}

function mapStateToProps({ properties }, props) {
  const ativeOwnProperties = ((properties && properties.ids) || [])
    .map(id => {
      const p = properties[id];
      if (p && p.isHost && p.active) return p;
      return null;
    })
    .filter(el => el !== null);
  return {
    hasFetched: properties.hasFetched,
    isDeleting: properties.isDeleting,
    isFetching: properties.isFetching,
    properties: ativeOwnProperties
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...invitesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteWizardContainer);
