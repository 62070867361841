import {
  rpcRequest,
  apiRequest,
  createResource,
  updateResource
} from "apis/jsonApi";
import Resource from "./resource";
import { getErrorMessage } from "helpers/utils";

let resource = new Resource({
  singularName: "property",
  pluralName: "properties"
});

export function fetchProperties() {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    let ownPropertiesIds = [];
    let ids = [];
    let opps = [];
    apiRequest(`properties?includes=hosts`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        ids = response.data.map(p => p.id);
        ownPropertiesIds = response.data
          .map(p => (p.isHost && p.active ? p.id : false))
          .filter(Boolean);
        return apiRequest(`properties/opp`, "GET");
      })
      .then(response => {
        dispatch(resource.addingPlural(response));
        opps = response.data.map(p => p.id);
        return opps;
      })
      .then(opps => {
        dispatch(resource.successPlural(ids, { ownPropertiesIds, opps }));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function fetchProperty(id) {
  return function(dispatch) {
    dispatch(resource.fetchingSingular);
    apiRequest(`properties/${id}`, "GET")
      .then(response => {
        dispatch(resource.successSingular([response.data.id], response.data));
        return response.data;
      })
      .catch(error => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function fetchPropertyBySlug(slug) {
  return function(dispatch) {
    dispatch(resource.fetchingSingular);
    apiRequest(`properties/slug/${slug}`, "GET")
      .then(response => {
        dispatch(resource.successSingular([response.data.id], response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function addProperty(values) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    const ids = getState().properties.ids;
    try {
      const property = await createResource("property", values);
      if (property) {
        ids.unshift(property.id);
        dispatch(resource.successAddingSingular(ids, property));
      }
      return property;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function updateProperty(values) {
  return function(dispatch, getState) {
    dispatch(resource.updatingSingular());
    const property = updateResource("property", values)
      .then(response => {
        dispatch(resource.successUpdatingSingular(response));
        return response;
      })
      .catch(error => {
        dispatch(resource.errorUpdatingSingular(error));
        throw error;
      });
    return property;
  };
}

export function updatePropertyRequest(values) {
  return function(dispatch) {
    dispatch(resource.successUpdatingSingular(values));
  };
}

export function deleteProperty(id) {
  return async function(dispatch, getState) {
    dispatch(resource.deletingSingular());
    try {
      await apiRequest(`properties/${id}`, "DELETE").then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        } else {
          dispatch(removeProperty(id));
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function removeProperty(id) {
  return function(dispatch, getState) {
    const ids = getState().properties.ids;
    const i = ids.indexOf(id.toString());
    if (i !== -1) {
      ids.splice(i, 1);
    }
    dispatch(resource.successDeletingSingular(ids));
  };
}
export function sortImages(propertyId, userId) {
  return async function(dispatch, getState) {
    dispatch(resource.updatingPlural());
    try {
      await rpcRequest("property-add-friend", {
        property_id: propertyId,
        user_id: userId
      }).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorUpdatingPlural(error));
          throw error;
        }
      });
    } catch (error) {
      dispatch(resource.errorUpdatingPlural(error));
      throw error;
    }
  };
}
export default resource.reducer;
