import React, { Component } from "react";
import AppPageHeader from "components/App/AppPageHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Switch from "@material-ui/core/Switch";
import Loading from "components/Progress/Loading";

const settingsList = [
  {
    name: `NOTIFICATION_FRIEND_ADDED_YOU_AS_GUEST`,
    description: `Notification - Friend added you as a guest`
  },
  {
    name: `EMAIL_FRIEND_ADDED_YOU_AS_GUEST`,
    description: `Email - Friend added you as a guest`
  },
  {
    name: `NOTIFICATION_FOLLOW_REQUEST_RECEIVED`,
    description: `Notification - Follow Request to your properties`
  },
  {
    name: `EMAIL_FOLLOW_REQUEST_RECEIVED`,
    description: `Email - Follow Request to your properties`
  },
  {
    name: `NOTIFICATION_FOLLOW_REQUEST_ACCEPTED`,
    description: `Notification - Follow Request got accepted`
  },
  {
    name: `EMAIL_FOLLOW_REQUEST_ACCEPTED`,
    description: `Email - Follow Request got accepted`
  },
  {
    name: `NOTIFICATION_NEW_TRIP_REQUEST`,
    description: `Notification - New trip request to one of your properties`
  },
  {
    name: `EMAIL_NEW_TRIP_REQUEST`,
    description: `Email - New trip request to one of your properties`
  },
  {
    name: `NOTIFICATION_NEW_TRIP_MESSAGE`,
    description: `Notification - New message on a trip you are involved`
  },
  {
    name: `EMAIL_NEW_TRIP_MESSAGE`,
    description: `Email - New message on a trip you are involved`
  },
  {
    name: `NOTIFICATION_PROPERTY_ADDED_YOU_AS_HOST`,
    description: `Notification - Someone added you as an owner of their property`
  },
  {
    name: `EMAIL_PROPERTY_ADDED_YOU_AS_HOST`,
    description: `Email - Someone added you as an owner of their property`
  }
];

export default class Settings extends Component {
  handleToggle = value => () => {
    let settings = {};
    this.props.settings.forEach(s => {
      settings[s.name] = s;
    });
    if (settings[value].valueBool) {
      this.props.updateSetting(value, false);
    } else {
      this.props.updateSetting(value, true);
    }
  };

  render() {
    let settings = {};
    this.props.settings.forEach(s => {
      settings[s.name] = s;
    });

    return (
      <>
        <AppPageHeader subHeadline="My Settings" headline="Settings" />
        {!this.props.hasFetched && <Loading />}
        {this.props.hasFetched && (
          <div>
            <List subheader={<ListSubheader>Settings</ListSubheader>}>
              {settingsList.map((s, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText primary={s.description} />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        onChange={this.handleToggle(s.name)}
                        checked={settings[s.name].valueBool}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </List>
          </div>
        )}
      </>
    );
  }
}
