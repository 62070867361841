import React, { Component, Fragment } from "react";
import Avatar from "components/General/Avatar";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import Message from "../Message/Message";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  "@global": {
    body: {
      margin: 0,
      background: theme.palette.background.color,
      color: theme.palette.text.primary,
      lineHeight: "1.2",
      overflowX: "hidden",
      textAlign: "center",
      WebkitFontSmoothing: "antialiased", // Antialiasing.
      MozOsxFontSmoothing: "grayscale" // Antialiasing.
    }
  },

  contentContainer: {
    display: "flex",
    alignContent: "center",
    flexDirection: "column",
    alignItems: "center"
  },
  spacer: {
    margin: "2rem auto"
  }
});

class Invite extends Component {
  getInvite = () => {
    const { classes, invite } = this.props;
    if (!invite) {
      return (
        <Fragment>
          <div className="section">
            <div className="container">
              <h1 className="h2">No invite found!</h1>
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className="section">
          <div className="container">
            <h1 className="h2">You got a golden ticket!</h1>
            <div className="text-large">
              {invite && invite.fromUser && invite.fromUser.picture && (
                <Avatar
                  firstName={invite.fromUser.firstName}
                  lastName={invite.fromUser.lastName}
                  src={invite.fromUser.picture}
                  isBig
                />
              )}
              {invite && invite.fromUser && invite.fromUser.firstName} sent you
              an invite to Vrbff!
            </div>
            <div
              className={classes.spacer}
              dangerouslySetInnerHTML={{ __html: invite.message }}
            />
            <div className={classes.contentContainer}>
              <div
                className="fb-login-button"
                data-max-rows="1"
                width="200"
                data-size="large"
                data-button-type="login_with"
                data-show-faces="true"
                data-auto-logout-link="false"
                data-use-continue-as="true"
                data-scope="public_profile, email, user_friends"
                data-onlogin="checkLoginState();"
              />
              <div style={{ marginTop: 20, marginBottom: 20 }}> OR </div>
              <MainActionButton
                style={{ width: "fit-content" }}
                to={`/signup?email=${invite.email}`}
              >
                Create an account with your email address
              </MainActionButton>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    const { errorMessage } = this.props;
    return (
      <Fragment>
        <link rel="stylesheet" type="text/css" href={"/css/normalize.css"} />
        <link
          rel="stylesheet"
          type="text/css"
          href={"/css/private-porch.webflow.css"}
        />
        <link rel="stylesheet" type="text/css" href={"/css/webflow.css"} />
        <Message type="danger" message={errorMessage} />
        {this.getInvite()}
      </Fragment>
    );
  }
}

export default withRoot(withStyles(styles)(Invite));
