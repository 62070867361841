import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
const ExpansionPanel = withStyles(theme => ({
  root: {
    border: "1px solid " + theme.palette.secondary.border,
    boxShadow: "none",
    backgroundColor: "transparent",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: 40,
    paddingRight: 40,
    "&$expanded": {
      minHeight: 56
    }
  },
  content: {
    alignItems: "center",
    margin: "32px 0",
    "&$expanded": {
      margin: "32px 0"
    },
    "& span": {
      width: 8,
      height: 20,
      backgroundColor: "#BCDAB0",
      borderRadfius: 2,
      marginRight: 12
    }
  },
  expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    background: "transparent",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 32,
    lineHeight: "24px"
  }
}))(MuiExpansionPanelDetails);
const PropertyExtensionPanel = ({ name, children }) => {
  return (
    <ExpansionPanel defaultExpanded>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <span>&nbsp;</span>
        {name}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default PropertyExtensionPanel;
