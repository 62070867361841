import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as bookingsActionCreators from "../../redux/modules/bookings";
import * as propertiesActionCreators from "../../redux/modules/properties";
import Calendar from "../../components/Calendar/Calendar";

import * as messagesActionCreators from "../../redux/modules/messages";
import * as appActionCreators from "../../redux/modules/app";
import * as tripsActionCreators from "../../redux/modules/trips";

import dasherize from "dasherize";

import { getErrorMessage } from "../../helpers/utils";

class CalendarContainer extends Component {
  componentDidMount() {
    this.loadBookings();
  }

  loadBookings = () => {
    const id = this.props.match.params.id;
    const selectedProperty = id && id !== "all" ? id : false;
    this.props.fetchBookings(selectedProperty);
    this.props.fetchProperties();
  };
  changeProperty = id => {
    this.props.history.push({
      pathname: "/calendar",
      search: id ? "?propertyId=" + id : ""
    });
    // this.props.fetchBookings(id);
  };

  goToTrip = id => {
    this.props.history.push({
      pathname: `/inbox/${id}`
    });
  };

  onDeleteStay = async id => {
    try {
      const trip = await this.props.deleteTrip(id);

      this.loadBookings();
      this.props.appSnackbarMessage({
        message: "Blcoked dates sucesfully removed"
      });
      return trip;
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem submitting the requested dates."
      );
      this.props.appSnackbarMessage({
        message: errorMessage
      });
      this.setState({ errorMessage });
      throw error;
    }
  };

  onRequestStay = async values => {
    try {
      let trip;
      if (values && values.id) {
        trip = await this.props.updateTrip(dasherize(values));
      } else {
        trip = await this.props.addTrip(dasherize(values));
      }
      if (trip.id > 0 && trip.status !== "Blocked") {
        this.props.history.push({
          pathname: `/inbox/${trip.id}`
        });
      } else {
        this.loadBookings();
      }
      this.props.appSnackbarMessage({
        message: "Dates blocked Sucesfully"
      });
      return trip;
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem submitting the requested dates."
      );
      this.props.appSnackbarMessage({
        message: errorMessage
      });
      this.setState({ errorMessage });
      throw error;
    }
  };

  render() {
    const id = this.props.match.params.id;
    const selectedProperty = id ? id : "all";

    return (
      <Calendar
        auth={this.props.auth}
        hasFetched={this.props.hasFetched}
        isFetching={this.props.isFetching}
        bookings={this.props.bookings}
        properties={this.props.properties}
        changeProperty={this.changeProperty}
        selectedProperty={selectedProperty}
        goToTrip={this.goToTrip}
        onRequestStay={this.onRequestStay}
        onDeleteStay={this.onDeleteStay}
      />
    );
  }
}

function mapStateToProps({ bookings, properties }, props) {
  return {
    hasFetched: bookings.hasFetched,
    isFetching: bookings.isFetching,
    properties,
    bookings
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators,
      ...messagesActionCreators,
      ...bookingsActionCreators,
      ...propertiesActionCreators,
      ...tripsActionCreators
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarContainer);
