import { apiRequest, rpcRequest } from "apis/jsonApi";
import Resource from "./resource";
import { getErrorMessage } from "helpers/utils";

let resource = new Resource({
  singularName: "user",
  pluralName: "users"
});

export function approveWaitList(userId) {
  return async function(dispatch) {
    dispatch(resource.updatingSingular());
    try {
      let data = {
        user_id: userId
      };
      await rpcRequest("approve-waitlist", data).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorUpdatingSingular(error));
          throw error;
        }
        // no update for store on rpc request
        dispatch(resource.successUpdatingPlural());
        return response;
      });
    } catch (error) {
      dispatch(resource.errorUpdatingSingular(error));
      throw error;
    }
  };
}

export function fetchUserUpdate(id, params = {}) {
  return function(dispatch) {
    dispatch(resource.updatingSingular);
    apiRequest(`users/${id}`, "GET", params)
      .then(response => {
        dispatch(resource.successUpdatingSingular(response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(resource.errorUpdatingSingular(error));
      });
  };
}

export function fetchUser(id, params = {}) {
  return function(dispatch) {
    dispatch(resource.fetchingSingular);
    apiRequest(`users/${id}`, "GET", params)
      .then(response => {
        dispatch(
          resource.successSingularMeta([response.data.id], response.data)
        );
      })
      .catch(error => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function fetchFriends(params = {}) {
  return function(dispatch, getState) {
    dispatch(resource.fetchingPlural);
    const users = apiRequest(`users/friends`, "GET", params)
      .then(response => {
        return response;
      })
      .catch(error => dispatch(resource.errorPlural(error)));
    return users;
  };
}

export function fetchUsers(params = {}, withIds = false) {
  return function(dispatch, getState) {
    dispatch(resource.fetchingPlural);
    const users = apiRequest(`users`, "GET", params)
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(u => u.id);
        if (withIds) {
          // add existing ids
          const eids = getState().users.ids;
          ids = ids.concat(eids);
        }

        dispatch(resource.successPlural(ids));
        return response;
      })
      .catch(error => dispatch(resource.errorPlural(error)));
    return users;
  };
}

export function fetchUsersByProperty(propertyId) {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`users`, "GET", { property_id: propertyId })
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function deleteGuest(id, propertyId) {
  return async function(dispatch, getState) {
    dispatch(resource.deletingSingular());
    try {
      await rpcRequest("remove-guest", {
        user_id: id,
        property_id: propertyId
      }).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        } else {
          dispatch(removeUser(id));
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function updateUserFetched(values) {
  return function(dispatch, getState) {
    dispatch(resource.successUpdatingSingular(values));
  };
}

export function removeUser(id) {
  return function(dispatch, getState) {
    const ids = getState().users.ids;
    const i = ids.indexOf(id.toString());
    if (i !== -1) {
      ids.splice(i, 1);
    }
    dispatch(resource.successDeletingSingular(ids));
  };
}

export default resource.reducer;
