import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import classNames from "classnames";
import Popover from "@material-ui/core/Popover";
import CaretIcon from "@material-ui/icons/ArrowDropDown";
import NotificationIcon from "containers/Components/NotificationIcon";
import { Link } from "react-router-dom";
import Avatar from "components/General/Avatar";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex"
  },
  accountWrapper: {
    backgroundColor: "#465172",
    color: theme.palette.secondary.white,
    display: "flex",
    width: 320,
    alignItems: "center",
    transition: theme.transitions.create("all"),
    borderBottom: "2px solid transparent",
    cursor: "pointer",
    position: "relative",
    paddingLeft: 22,
    "&:hover": {
      borderBottomColor: theme.palette.secondary.main
    },
    "&:before": {
      position: "absolute",
      left: "-10px",
      backgroundColor: theme.palette.grey.medium,
      width: "3px",
      height: "20px",
      borderRadius: "10px",
      content: "",
      display: "block",
      zIndex: "100"
    }
  },
  activeAccountWrapper: {
    borderBottomColor: theme.palette.primary.main
  },
  name: {
    color: theme.palette.secondary.white,
    fontWeight: 700,
    fontSize: 16
  },
  subText: {
    color: theme.palette.secondary.white,
    fontWeight: 400,
    fontSize: 12,
    marginTop: theme.spacing(0.5)
  },
  textWrapper: {
    marginLeft: theme.spacing(3)
  },
  icon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.secondary.white,
    flex: 1
  },
  [theme.breakpoints.down("sm")]: {
    accountWrapper: {
      width: 110
    },
    icon: {
      display: "block"
    }
  },
  [theme.breakpoints.down("sm")]: {
    accountWrapper: {
      width: 110
    },
    textWrapper: {
      display: "none"
    }
  }
}));

const AppProfileMenu = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "select-guests" : null;
  const { logout, profile } = props;
  const accountWrapperClass = open
    ? classNames(classes.accountWrapper, classes.activeAccountWrapper)
    : classes.accountWrapper;
  return (
    <div className={classes.container}>
      <NotificationIcon />
      <div className={accountWrapperClass} onClick={handleClick}>
        <Avatar
          isBig
          firstName={profile.firstName}
          lastName={profile.lastName}
          src={profile.picture}
        />
        <div className={classes.textWrapper}>
          <div className={classes.name}>
            {profile.firstName}&nbsp;{profile.lastName}
          </div>
          <div className={classes.subText}>My Account</div>
        </div>
        <CaretIcon className={classes.icon} style={{ fontSize: 30 }} />
      </div>
      <Popover
        classes={{ paper: classes.paper }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <List>
          <ListItem button component={Link} to="/account">
            <ListItemText primary="Manage Account" />
          </ListItem>
          <ListItem button component={Link} to="/settings">
            <ListItemText primary="Settings" />
          </ListItem>
          <ListItem button onClick={logout}>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default AppProfileMenu;
