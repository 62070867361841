export const checkUpdateAddress = (oldAddress, newAddress) => {
  if (oldAddress.address1 !== newAddress.address1) {
    return true;
  }
  if (oldAddress.address2 !== newAddress.address2) {
    return true;
  }
  if (oldAddress.city !== newAddress.city) {
    return true;
  }
  if (oldAddress.region !== newAddress.region) {
    return true;
  }
  if (oldAddress.postalCode !== newAddress.postalCode) {
    return true;
  }
  if (oldAddress.country !== newAddress.country) {
    return true;
  }
  return false;
};
