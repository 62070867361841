import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import { autocompletePromisified } from "helpers/autocomplete_promisified";
import { geocodePromisified } from "helpers/geocode_promisified";

class AutosuggestPlaces extends Component {
  state = {
    suggestions: [],
    value: ""
  };

  componentWillUnmount() {
    if (this.delayHandle) clearTimeout(this.delayHandle);
  }

  fetchSuggestions = ({ value }) => {
    if (this.delayHandle) clearTimeout(this.delayHandle);
    this.delayHandle = setTimeout(() => {
      autocompletePromisified({
        ...this.props.googleApiOptions,
        input: value
      })
        .then(this.updateSuggestions)
        .catch(e => {
          console.error("error retrieving data from google autocomplete", e);
        });
    }, this.props.apiDelay);
  };

  updateSuggestions = suggestions => {
    this.setState({
      suggestions: suggestions
    });
  };

  clearSuggestions = () => {
    this.setState({ suggestions: [] });
  };

  handleInputChange = (e, { newValue }) => {
    const { onChange, value } = this.props;
    if (!newValue) this.clearSuggestions();
    if (onChange) onChange(newValue);
    if (!value) this.setState({ value: newValue });
  };

  handleItemSelect = (e, { suggestion }) => {
    const { onSelect } = this.props;
    geocodePromisified({ placeId: suggestion.place_id })
      .then(result => {
        onSelect && onSelect(result[0]);
      })
      .catch(e => {
        console.error("error retrieving the geocode", e);
      });
  };

  getSuggestionValue = suggestion => {
    return suggestion.description;
  };

  render() {
    const {
      renderInput,
      renderSuggestion,
      renderSuggestionContainer,
      inputProps,
      ...rest
    } = this.props;

    return (
      <Autosuggest
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.fetchSuggestions}
        onSuggestionsClearRequested={this.clearSuggestions}
        onSuggestionSelected={this.handleItemSelect}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestionsContainer={renderSuggestionContainer}
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus: inputProps.disabled ? false : inputProps.autoFocus,
          value: this.props.value || this.state.value,
          onChange: this.handleInputChange,
          ...inputProps
        }}
        {...rest}
      />
    );
  }
}

AutosuggestPlaces.defaultProps = {
  renderInput: props => <input {...props} />,
  renderSuggestionContainer: props => (
    <div {...props.containerProps}>{props.children}</div>
  ),
  renderSuggestion: props => <div>{props.description}</div>,
  googleApiOptions: { types: ["address"] },
  apiDelay: 300
};

AutosuggestPlaces.propTypes = {
  renderInput: PropTypes.func,
  renderSuggestionContainer: PropTypes.func,
  renderSuggestion: PropTypes.func,
  googleApiOptions: PropTypes.object,
  apiDelay: PropTypes.number,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.string
};

export default AutosuggestPlaces;
