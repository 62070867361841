import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import ProgressButton from "components/Progress/ProgressButton";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  cardForm: {
    display: "block",
    margin: "10px 0 20px 0",
    maxWidth: "500px",
    padding: "10px 14px",
    fontSize: "1em",
    fontFamily: "'Source Code Pro', monospace",
    boxShadow:
      "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px",
    border: "0",
    outline: "0",
    borderRadius: "4px",
    background: "white"
  }
});

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Source Code Pro, monospace",
        "::placeholder": {
          color: "#aab7c4"
        },
        padding
      },
      stripeElement: {
        display: "block",
        margin: "10px 0 20px 0",
        maxWidth: "500px",
        padding: "10px 14px",
        fontSize: "1em",
        fontFamily: "'Source Code Pro', monospace",
        boxShadow:
          "rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px",
        border: "0",
        outline: "0",
        borderRadius: "4px",
        background: "white"
      },

      invalid: {
        color: "#9e2146"
      }
    }
  };
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      isLoading: false
    };
  }
  handleReady = element => {
    this.setState({ cardElement: element });
  };
  submit = async ev => {
    this.setState({ isLoading: true });
    let params = {};
    if (this.props.newCard) {
      params = {
        payment_method: { card: this.props.elements.getElement("card") }
      };
    }
    const { paymentIntent, error } = await this.props.stripe.confirmCardPayment(
      this.props.code,
      params
    );
    if (error) {
      // Display error.message in your UI.
      if (this.props.onError) this.props.onComplete(error);
      console.log("error", error);
    } else {
      if (this.props.onComplete) this.props.onComplete(paymentIntent);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="checkout">
        <link rel="stylesheet" type="text/css" href={"/css/stripe.css"} />
        <p>Would you like to complete the purchase?</p>
        {this.props.newCard && (
          <CardElement
            onReady={this.handleReady}
            className={classes.cardElement}
            {...createOptions(16)}
          />
        )}

        {/* <label>
          Card number
          <CardNumberElement {...createOptions(16)} />
        </label>
        <label>
          Expiration date
          <CardExpiryElement {...createOptions(16)} />
        </label>
        <label>
          CVC
          <CardCVCElement {...createOptions(16)} />
        </label> */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ProgressButton
            loading={this.state.isLoading}
            onClick={this.submit}
            label={"Pay for your trip"}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { name: "CardForm" })(
  injectStripe(CheckoutForm)
);
