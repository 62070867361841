import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as inboxesActionCreators from "../../redux/modules/inboxes";
import Inbox from "../../components/Inbox/Inbox";
import AppPageHeader from "../../components/App/AppPageHeader";
import { Helmet } from "react-helmet";

class InboxContainer extends Component {
  componentDidMount() {
    this.props.fetchInboxMessages();
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Stays&nbsp;&bull;&nbsp;Vrbff</title>
        </Helmet>
        <AppPageHeader subHeadline="Stay Requests" />
        <Inbox
          auth={this.props.auth}
          hasFetched={this.props.hasFetched}
          isFetching={this.props.isFetching}
          stays={this.props.inboxes}
          isManage={this.props.isManage}
        />
      </Fragment>
    );
  }
}

function mapStateToProps({ inboxes }, props) {
  return {
    hasFetched: inboxes.hasFetched,
    isFetching: inboxes.isFetching,
    inboxes
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...inboxesActionCreators
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxContainer);
