// @flow weak

import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class AppMetadata extends Component {
  render() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vrbff - It&#x27;s your home - not Vrbo's.</title>
        <link rel="canonical" href="http://vrbff.com" />
      </Helmet>
    );
  }
}
