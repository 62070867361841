import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as invitesActionCreators from "../../redux/modules/invites";
import Invite from "../../components/Invite/Invite";
import { getErrorMessage } from "../../helpers/utils";
import history from "../../history";
import Loading from "components/Progress/Loading";

class InviteContainer extends Component {
  state = {
    errorMessage: null
  };

  async componentDidMount() {
    try {
      const code = this.props.match.params.code;
      if (code) {
        if (this.props.isAuthenticated) {
          const invite = await this.props.fetchInviteByCode(code);
          if (invite.property) {
            const claimed = await this.props.claimInvite(code);
            if (claimed) {
              return history.replace("/");
            }
          }
        } else {
          localStorage.setItem("invite_code", code);
          localStorage.setItem("redirect_after_login", "/");
          await this.props.fetchInviteByCode(code);
        }
      }
    } catch (error) {
      let errorMessage = getErrorMessage(
        error,
        "There was a problem confirming the invite code."
      );
      this.setState({ errorMessage });
      throw error;
    }
  }

  render() {
    if (this.props.isAuthenticated) return <Loading />;
    return (
      <Invite
        errorMessage={this.state.errorMessage}
        hasFetched={this.props.hasFetched}
        isFetching={this.props.isFetching}
        invite={this.props.invite}
      />
    );
  }
}

function mapStateToProps({ invites }, props) {
  return {
    hasFetched: invites.hasFetched,
    isFetching: invites.isFetching,
    invite: invites[invites.currentId]
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...invitesActionCreators
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteContainer);
