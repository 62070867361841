import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

const MainButton = withStyles(theme => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    height: 48,
    textAlign: "center",
    padding: "6px 24px",
    lineHeight: "18px",
    border: "2px solid " + theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.text.main,
      border: "2px solid " + theme.palette.text.main
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
}))(Button);

const MainButtonOutline = withStyles(theme => ({
  root: {
    boxShadow: "none",
    textAlign: "center",
    textTransform: "none",
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    height: 48,
    color: theme.palette.primary.main,
    padding: "6px 24px",
    lineHeight: "18px",
    border: "2px solid " + theme.palette.primary.main,
    backgroundColor: "transparent",
    "&:hover": {
      border: "2px solid " + theme.palette.text.main,
      color: theme.palette.text.main,
      backgroundColor: "transparent"
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
}))(Button);

const MainFullButton = withStyles(theme => ({
  root: {
    boxShadow: theme.shadow.light,
    fontSize: 16,
    padding: "6px 18px",
    lineHeight: 1.5,
    backgroundColor: "#FFFFFF",
    borderColor: "#FFF",
    color: "inherit",
    textTransform: "uppercase",
    fontWeight: "bold",

    "&:hover": {
      backgroundColor: "#e8e8e8"
      // borderColor: '#0062cc',
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
}))(Button);

const MainDangerButton = withStyles(theme => ({
  root: {
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 4,
    fontSize: 16,
    fontWeight: 700,
    height: 48,
    textAlign: "center",
    padding: "6px 24px",
    lineHeight: "18px",
    border: "2px solid " + theme.palette.secondary.danger,
    backgroundColor: theme.palette.secondary.danger,

    "&:hover": {
      backgroundColor: "#a63029",
      borderColor: "#a63029"
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
}))(Button);

const MainApproveButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 0,
    fontSize: 16,
    padding: "6px 18px",
    border: 0,
    lineHeight: 1.5,
    backgroundColor: "#1ed642",

    "&:hover": {
      backgroundColor: "#15a00d"
      // borderColor: '#0062cc',
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
})(Button);

const MainButtonSmall = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 0,
    fontSize: 12,
    padding: "4px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#40A5CB",
    borderColor: "#40A5CB",
    "&:hover": {
      backgroundColor: "#3994b6"
      // borderColor: '#0062cc',
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    "&:disabled": {
      borderColor: "#ddd",
      opacity: "0.8"
    }
  }
})(Button);

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const MainActionButton = props => {
  let { isSmall, isDanger, isFull, isApprove, isOutline, ...rest } = props;
  let Btn = isSmall ? MainButtonSmall : MainButton;
  if (isDanger) Btn = MainDangerButton;
  if (isFull) Btn = MainFullButton;
  if (isApprove) Btn = MainApproveButton;
  if (isOutline) Btn = MainButtonOutline;
  return (
    <Btn
      variant="contained"
      component={props.to ? AdapterLink : undefined}
      color="primary"
      {...rest}
    >
      {props.children}
    </Btn>
  );
};

export default MainActionButton;
