import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "redux/modules/app";
import * as usersActionCreators from "redux/modules/users";
import AdminUsers from "components/Admin/AdminUsers";
import dasherize from "dasherize";

class AdminUsersContainer extends Component {
  state = {
    order: "asc",
    orderBy: "id",
    search: null
  };

  handleSort = (event, orderBy) => {
    let order = "desc";
    if (this.state.orderBy === orderBy && this.state.order === "desc") {
      order = "asc";
    }

    let params = {
      page: {
        number: 1,
        size: 25 // TODO: make this configurable by user profile
      },
      role: "admin",
      sort: (order === "asc" ? "" : "-") + dasherize(orderBy)
    };

    // check for search
    if (this.state.search) {
      params["filter"] = {
        search: this.state.search
      };
    }

    // run search
    this.handleSearch(params);

    this.setState({
      order,
      orderBy
    });
  };

  setSearch = search => {
    this.setState({ search });
  };

  handleSearch = params => {
    this.props.fetchUsers(params);
  };

  onApproveWaitList = userId => {
    return new Promise(async resolve => {
      try {
        await this.props.approveWaitList(userId);
        await this.props.fetchUserUpdate(userId);
        this.props.appSnackbarMessage({
          message: "Waitlist approval sent"
        });
      } catch (e) {
        this.props.appSnackbarMessage({
          message:
            "Unfortunately there was an error while sending the approval",
          type: "warning"
        });
        console.error(e);
      }
      return resolve(false);
    });
  };

  async componentDidMount() {
    let params = {
      page: {
        number: 1,
        size: 25 // TODO: make this configurable by user profile
      },
      role: "admin"
    };
    await this.handleSearch(params);
  }

  render() {
    return (
      <AdminUsers
        auth={this.props.auth}
        handleApproveWaitList={values => this.onApproveWaitList(values)}
        handleFetchCollection={this.props.fetchUsers}
        handleSearch={this.handleSearch}
        handleSort={this.handleSort}
        hasFetched={this.props.hasFetched}
        isFetching={this.props.isFetching}
        isUpdating={this.props.isUpdating}
        order={this.state.order}
        orderBy={this.state.orderBy}
        setSearch={this.setSearch}
        search={this.state.search}
        users={this.props.users}
      />
    );
  }
}

function mapStateToProps({ users }, props) {
  return {
    hasFetched: users.hasFetched,
    isFetching: users.isFetching,
    isUpdating: users.isUpdating,
    users
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators,
      ...usersActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUsersContainer);
