import React, { useEffect, useCallback } from "react";
import Dropzone from "react-dropzone";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import grey from "@material-ui/core/colors/grey";
import DeleteDialog from "../Dialogs/DeleteDialog";
import PropertyImage from "./PropertyImage";
import Loading from "../Progress/Loading";
import Message from "../Message/Message";
import SnackbarMessage from "../Message/SnackbarMessage";

import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import update from "immutability-helper";

const styles = theme => ({
  title: {
    marginTop: 8,
    marginBottom: 16
  },
  actions: {
    marginTop: 16,
    position: "relative",
    display: "block",
    textAlign: "right",
    width: "100%"
  },
  dropzone: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: grey[100],
    color: grey[600],
    marginBottom: 20,
    cursor: "pointer",
    padding: theme.spacing(2)
  },
  dropzoneActive: {
    color: theme.palette.secondary.main
  },
  dropzoneBody: {
    textAlign: "center"
  },
  dropzoneTitle: {
    flex: 1,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: 16
  },
  errorMessage: {
    marginTop: 20
  },
  headline: {
    marginBottom: 16
  },
  iconUpload: {
    fontSize: 108
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto",
    padding: 32
  },
  subheading: {
    marginTop: 16,
    marginBottom: 8
  }
});

function loadImages(images) {
  return images.ids
    .map((id, index) => {
      return {
        ...images[id],
        sortOrder: isFinite(images[id].sortOrder) ? images[id].sortOrder : 99
      };
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);
}

function setSortOrder(images) {
  return images.map((i, index) => {
    i.sortOrder = index;
    return i;
  });
}

const PropertyImages = props => {
  const { getPropertyImages } = props;
  const [stateImages, setStateImages] = React.useState(
    loadImages(props.images || [])
  );

  const onDrop = files => {
    props.handleUpload(files);
  };
  useEffect(() => {
    getPropertyImages();
    // eslint-disable-next-line
  }, []);

  const imagesSize = (props.images.ids || []).length;
  useEffect(() => {
    setStateImages(loadImages(props.images || []));
    // eslint-disable-next-line
  }, [props.images, imagesSize]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = stateImages[dragIndex];
      const result = setSortOrder(
        update(stateImages, {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]]
        })
      );
      props.savePropertyImages(result);
      return setStateImages(result);
    },
    // eslint-disable-next-line
    [stateImages]
  );

  const {
    property,
    classes,
    closeDeleteDialog,
    closeSnackbarMessage,
    deleteDialog,
    deleteDialogError,
    handleDelete,
    isDeleting,
    isUploading,
    image,
    images,
    openDeleteDialog,
    snackbarMessage
  } = props;
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <div>
              <Typography
                variant="h5"
                color="inherit"
                className={classes.title}
              >
                {property && `Images - ${property.name}`}
              </Typography>
            </div>
            {images.uploads.length > 0 &&
              images.uploads.map((upload, i) => {
                let uploadError = null;
                if (upload.error) {
                  uploadError = "An error occurred with the image upload";
                }
                return (
                  <Message
                    key={`upload${i}`}
                    className={classes.errorMessage}
                    type="danger"
                    message={uploadError}
                  />
                );
              })}
            <div className="dropzone">
              <Dropzone
                activeClassName={classes.dropzoneActive}
                className={classes.dropzone}
                onDrop={onDrop}
              >
                <div className={classes.dropzoneBody}>
                  <CloudUploadIcon className={classes.iconUpload} />
                  <Typography
                    color="inherit"
                    variant="subtitle1"
                    component="span"
                    className={classes.dropzoneTitle}
                  >
                    {"Drag files here (or "}
                    <span className={classes.link}>{"browse"}</span>
                    {") to upload property images"}
                  </Typography>
                </div>
              </Dropzone>
              {isUploading && <Loading />}
            </div>
            {images && images.ids.length > 0 && (
              <DndProvider backend={HTML5Backend}>
                <Grid container spacing={2}>
                  {stateImages.map((image, index) => {
                    return (
                      <Grid key={`image${image.id}`} item>
                        <PropertyImage
                          index={index}
                          id={image.id}
                          isDefault={image.sortOrder === 0}
                          image={image}
                          openDeleteDialog={openDeleteDialog}
                          moveCard={moveCard}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </DndProvider>
            )}
          </Paper>
        </Grid>
      </Grid>
      <DeleteDialog
        closeDialog={closeDeleteDialog}
        errorMessage={deleteDialogError}
        isDeleting={isDeleting}
        onDelete={handleDelete}
        open={deleteDialog}
        resource={image}
      />
      <SnackbarMessage
        allowUpdates={true}
        message={snackbarMessage}
        onClose={closeSnackbarMessage}
      />
    </React.Fragment>
  );
};

export default withStyles(styles, { name: "PropertyImages" })(PropertyImages);
