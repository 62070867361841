import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { getRateName } from "helpers/stays";

const styles = theme => ({
  infoContainer: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  iconInfo: {
    color: "#40A5CB",
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold"
  },
  infoTitle: {
    fontWeight: "bold"
  },
  priceRow: {
    fontSize: 14,
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 24,
    paddingRight: 24,
    borderBottom: "1px solid #ddd"
  },
  priceRowLast: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 12,
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24
  },
  totalRow: {
    fontWeight: "bold"
  },

  containerTotal: {
    borderRadius: 4,
    border: "1px solid " + theme.palette.secondary.border,
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: 50
  },
  leftSideTotal: {
    flex: 1,
    fontWeight: 700,
    fontSize: 14
  },
  leftSideText: {
    paddingLeft: 24
  },
  rightSideTotal: {
    height: "100%",
    flex: 1,
    textAlign: "center",
    fontSize: 24,
    background: theme.palette.secondary.bg,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const PaymentSummary = ({ classes, trip }) => {
  if (!trip || !trip.pricing) return <></>;

  const pricing = trip.pricing;
  // let removed = false;

  return (
    <>
      <div className={classes.pricingContainer}>
        {pricing &&
          pricing.ratestrip
            .sort(function(a, b) {
              if (a.type === "PRICE_NIGHT") {
                return -1;
              }

              return 0;
            })
            .map((r, i) => {
              // if (r.removedAt) removed = true;
              return (
                <div
                  className={
                    i === pricing.ratestrip.length - 1
                      ? classes.priceRowLast
                      : classes.priceRow
                  }
                  key={i}
                  style={{
                    textDecoration: r.removedAt ? "line-through" : "inherit"
                  }}
                >
                  <span>{getRateName(r, trip)}</span>
                  <span className={classes.rightPrice}>
                    ${r.calculatedPrice}
                  </span>
                </div>
              );
            })}
      </div>
      <div className={classes.containerTotal}>
        <div className={classes.leftSideTotal}>
          <span className={classes.leftSideText}>Price Total:</span>
        </div>
        <div className={classes.rightSideTotal}>${pricing.finalTotal}</div>
      </div>
    </>
  );
};

export default withStyles(styles, { name: "PaymentSummary" })(PaymentSummary);
