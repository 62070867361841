import React, { Component, Fragment } from "react";
import { animateScroll } from "react-scroll";
import { connect } from "react-redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";

import withRoot from "../../withRoot";
import history from "../../history";
import logo from "../../static/assets/logo/vrbff-logo-113.svg";
import AppContent from "./AppContent";
import AppMenu from "./AppMenu/AppMenu";
import AppMenuButton from "./AppMenu/AppMenuButton";
import AppProfileMenu from "./AppProfileMenu";
import AppNav from "./AppNav";
import AppNavItem from "./AppNavItem";
import AppFooter from "./AppFooter";
import OurWebSocket from "../../OurWebSocket";
import AppMetadata from "./AppMetadata";
import { getURLParams } from "../../helpers/utils";
import common from "@material-ui/core/colors/common";
import Auth from "components/Auth/Auth";
const { white } = common;

const logRocketId = process.env.REACT_APP_LOGROCKET_APP_ID;
if (logRocketId) {
  LogRocket.init(logRocketId);
  setupLogRocketReact(LogRocket);
}

const styles = theme => {
  return {
    "@global": {
      body: {
        margin: 0,
        background: Auth.isAuthenticated()
          ? "#F4F4F4"
          : theme.palette.background.default,
        color: theme.palette.text.primary,
        lineHeight: "1.2",
        overflowX: "hidden",
        WebkitFontSmoothing: "antialiased", // Antialiasing.
        MozOsxFontSmoothing: "grayscale" // Antialiasing.,
      }
    },
    appBar: {
      height: 80,
      background: "#fff",
      transition: theme.transitions.create("width"),
      borderBottom: "1px solid",
      borderBottomColor: theme.palette.grey.medium,
      boxShadow: "none"
    },
    button: {
      height: 80,
      color: theme.palette.text.main,
      fontSize: 16,
      fontWeight: "bold",
      display: "inline-flex",
      alignContent: "center",
      paddingTop: theme.spacing(3.75),
      textDecoration: "none",
      borderTop: "3px solid transparent",
      transition: theme.transitions.create("all"),
      cursor: "pointer",
      textTransform: "none",
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.primary.main,
        borderTop: "3px solid #465172"
      }
    },
    buttonSpacer: {
      width: 40
    },
    link: {
      color: "inherit",
      textDecoration: "none"
    },
    signUpSpacer: {
      width: 148
    },
    signUpButton: {
      justifyContent: "center",
      backgroundColor: theme.palette.primary.main,
      color: white,
      width: 222,
      display: "inline-flex",
      alignContent: "center",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      "&:hover": {
        backgroundColor: theme.palette.text.main,
        borderTop: "3px solid transparent",
        color: white
      }
    },
    toolBar: {
      display: "flex",
      alignItems: "stretch",
      justifyContent: "space-between",
      paddingLeft: 0,
      paddingRight: 0
    },
    logo: {
      paddingLeft: 40
    },
    mainButton: {
      borderRadius: 4,
      boxShadow: "none",
      textTransform: "none",
      fontSize: 16,
      margin: ".9rem 0",
      padding: "8px 18px",
      border: "1px solid",
      lineHeight: 1.5,
      backgroundColor: theme.palette.primary.main,
      color: white,
      cursor: "pointer",
      height: 48,
      width: 195,
      "&:hover": {
        backgroundColor: theme.palette.text.main
      }
    },
    logoButton: {
      borderTop: "3px solid transparent",
      borderRadius: "0px",
      transition: theme.transitions.create("all"),
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    rightMenu: {
      display: "flex"
    },
    [theme.breakpoints.down("xs")]: {
      facebookButtonMobile: {
        display: "flex !important"
      },
      facebookButtonDesktop: {
        display: "none !important"
      }
    },
    [theme.breakpoints.down("sm")]: {
      appBar: {
        height: 65
      },
      logo: {
        paddingLeft: 8
      },
      navMenu: {
        display: "none"
      }
    }
  };
};

class App extends Component {
  state = {
    menuOpen: false
  };

  closeMenu = () => {
    if (this.props.width === "xs" || this.props.width === "sm") {
      this.handleMenuClose();
    }
  };

  handleMenuClose = () => {
    this.setState({ menuOpen: false });
  };

  handleMenuOpen = () => {
    this.setState({ menuOpen: true });
  };

  handleAppMenuToggle = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  };

  componentDidMount() {
    OurWebSocket.setDispatch(this.props.dispatch);

    this.closeMenu();
    const currentPath = this.props.location.pathname;
    if (
      currentPath === "/" &&
      this.props.history &&
      this.props.history.location
    ) {
      const params = getURLParams(this.props.history.location.search);
      if (params.reset) {
        history.replace(`/reset/${params.reset}`);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // check for screen resize without reload
    if (nextProps.width !== this.props.width) {
      if (nextProps.width === "xs" || nextProps.width === "sm") {
        this.handleMenuClose();
      } else {
        this.handleMenuOpen();
      }
    }
  }

  handleHome() {
    history.replace("/");
    animateScroll.scrollToTop();
  }

  render() {
    const { children, classes, profile } = this.props;
    const { isAuthenticated, logout } = this.props.auth;

    const signUpClass = classNames(classes.button, classes.signUpButton);
    const currentPath = this.props.location.pathname;
    const wrapperStyle = isAuthenticated() ? "layout" : "landing";
    const userArea = isAuthenticated() ? (
      <AppProfileMenu logout={logout} profile={profile} />
    ) : (
      <Fragment>
        <Hidden smDown>
          <div className={classes.buttonSpacer} />
          <AppNavItem title="About" to="/about" currentPath={currentPath} />
          <div className={classes.buttonSpacer} />
          <AppNavItem title="Pricing" to="/pricing" currentPath={currentPath} />
          <div className={classes.buttonSpacer} />
          <AppNavItem
            title="Learn More"
            to="/learn"
            currentPath={currentPath}
          />
          <div className={classes.buttonSpacer} />
          <AppNavItem title="Log In" to="/login" currentPath={currentPath} />
          <div className={classes.signUpSpacer} />
          <Link to="/signup" className={classes.link}>
            <div className={signUpClass}>List Your Property</div>
          </Link>
        </Hidden>
      </Fragment>
    );
    return (
      <Fragment>
        <AppMetadata />
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <IconButton
              className={classes.logoButton}
              disableFocusRipple
              disableRipple
              onClick={() => this.handleHome()}
            >
              <img alt="logo" src={logo} className={classes.logo} />
            </IconButton>
            <AppNav
              isAuthenticated={isAuthenticated()}
              currentPath={currentPath}
            />
            <div className={classes.rightMenu}>
              {userArea}
              <AppMenuButton
                open={this.state.menuOpen}
                handleToggle={this.handleAppMenuToggle}
              />
            </div>
          </Toolbar>
        </AppBar>
        <AppMenu
          closeMenu={this.handleMenuClose}
          currentPath={currentPath}
          open={this.state.menuOpen}
          openLogin={this.openLogin}
          openSignup={this.openSignup}
          isAuthenticated={isAuthenticated()}
        />
        <AppContent wrapperStyle={wrapperStyle}>{children}</AppContent>
        <AppFooter
          isAuthenticated={isAuthenticated()}
          currentPath={currentPath}
        />
      </Fragment>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  ({ app }) => {
    return { profile: (app.auth && app.auth.data) || {} };
  },
  dispatch => ({ dispatch })
)(withRoot(withStyles(styles)(App)));
