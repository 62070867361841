import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";

const styles = theme => ({});

class ValidationTextField extends Component {
  render() {
    const {
      input,
      meta: { touched, error }
    } = this.props;

    const isError = touched && error ? true : false;

    return (
      <React.Fragment>
        <TextField {...input} {...this.props} error={isError} />
        {isError && <FormHelperText error={isError}>{error}</FormHelperText>}
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { name: "ValidationTextField" })(
  ValidationTextField
);
