import { createResource, updateResource } from "apis/jsonApi";
import { prepareAddress, prepareLocation } from "./prepareJson";
import { geoLocation } from "./utils";
import dasherize from "dasherize";

export const updateAddressLocation = async submitValues => {
  let addressResource = null;
  // create or update address location
  let location = "";
  if (submitValues.address.location) {
    location = prepareLocation(submitValues.address.location);
    submitValues.address.location = await createResource("location", location);
  } else {
    location = await geoLocation(
      `${submitValues.address.address1} ${submitValues.address.city} ${submitValues.address.region} ${submitValues.address.postalCode}`
    );
    if (location) {
      submitValues.address.location = await createResource(
        "location",
        dasherize(location)
      );
    }
  }

  // create or update address
  let address = prepareAddress(submitValues.address);

  if (submitValues.address.id) {
    address["id"] = submitValues.address.id;
    addressResource = await updateResource("address", address);
  } else {
    addressResource = await createResource("address", address);
  }

  return addressResource;
};
