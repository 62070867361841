import React, { Component } from "react";
import { Typography, withStyles, Grid } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import * as connectAccountsActionCreators from "../../redux/modules/connectAccounts";
import StripeAccountConnect from "containers/Components/StripeAccountConnect";
import Loading from "components/Progress/Loading";
import { capitlizeString } from "helpers/utils";

const styles = theme => ({
  stripeButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: 18
  },
  yourAccountsContainer: {
    marginTop: theme.spacing(4)
  },
  cardContainer: {
    cursor: "pointer",
    border: "4px solid rgba(0, 0, 0, 0.54)",
    borderRadius: 10,
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    margin: 10,
    height: 80,
    alignItems: "center"
  },
  cardContainerSelected: {
    border: "4px solid #40A5CB",
    borderRadius: 10,
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    margin: 10,
    height: 80,
    alignItems: "center"
  },
  cardBrand: {
    fontSize: 20
  }
});

class PaymentHandling extends Component {
  componentDidMount() {
    this.props.fetchConnectAccounts();
  }
  //   async continueCheck() {
  //     return new Promise(async resolve => {
  //       this.setState({ addGroupDialog: true });
  //       // const { data } = this.props;

  //       // if (data && data.propertyId) return resolve(true);
  //       // this.props.snackbarMessage({
  //       //   message: "You have to select a property",
  //       //   type: "warning"
  //       // });
  //       resolve("HACK NEVER");
  //     });
  //   }

  selectAccount = accountId => () => {
    this.props.setConnectAccountToProperty(accountId, this.props.property.id);
  };
  render() {
    const { property, classes, connectAccounts } = this.props;
    const selectedId = property.connectAccount && property.connectAccount.id;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">
              {"Set up bank account or debit card"}
            </Typography>
            <Typography variant="caption">
              {
                "You have to setup a bank account or debit card to be able to receive payments from Vrbff. We use Stripe to handle that."
              }
            </Typography>
            {(!property.connectAccount || !property.connectAccount.last4) && (
              <div>
                <div className={classes.stripeButton}>
                  <StripeAccountConnect propertyId={this.props.propertyId} />
                </div>
              </div>
            )}
            {property.connectAccount && property.connectAccount.last4 && (
              <>
                <Typography variant="caption">
                  The account attached to this property is the following
                </Typography>
                <div className={classes.cardContainerSelected}>
                  {property.connectAccount.type === "bank_account" && (
                    <div>
                      Bank Name: {property.connectAccount.bankName}
                      <br />
                      <br />
                      Ending with:{property.connectAccount.last4}
                    </div>
                  )}
                  {property.connectAccount.type !== "bank_account" && (
                    <div>
                      Ending with:{property.connectAccount.last4}
                      <br />
                      <br />
                      Expire: 04/24
                    </div>
                  )}
                  <div className={classes.cardBrand}>
                    {capitlizeString(
                      (property.connectAccount.type || "").replace("_", " ")
                    )}
                  </div>
                </div>
              </>
            )}

            <div className={classes.yourAccountsContainer}>
              <Typography variant="subtitle1">
                {"Your connected accounts"}
              </Typography>
              {connectAccounts.isFetching && <Loading />}
              {!connectAccounts.isFetching && connectAccounts.ids.length > 0 && (
                <div>
                  <Typography variant="caption">
                    {`You have ${connectAccounts.ids.length} account${
                      connectAccounts.ids.length === 1 ? "" : "s"
                    } You can simply click one to swap it with the current one.`}
                  </Typography>
                  <Grid container>
                    {connectAccounts.ids.map(i => {
                      const c = connectAccounts[i];

                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <div
                            onClick={
                              selectedId === c.id
                                ? () => {}
                                : this.selectAccount(c.id)
                            }
                            className={
                              selectedId === c.id
                                ? classes.cardContainerSelected
                                : classes.cardContainer
                            }
                          >
                            {c.type === "bank_account" && (
                              <div>
                                Bank Name: {c.bankName}
                                <br />
                                <br />
                                Ending with:{c.last4}
                              </div>
                            )}
                            {c.type !== "bank_account" && (
                              <div>
                                Ending with:{c.last4}
                                <br />
                                <br />
                                Expire: 04/24
                              </div>
                            )}
                            <div className={classes.cardBrand}>
                              {capitlizeString(
                                (c.type || "").replace("_", " ")
                              )}
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </div>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    connectAccounts: state.connectAccounts,
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators,
      ...connectAccountsActionCreators
    },
    dispatch
  );
}

PaymentHandling = withStyles(styles, { name: "PaymentHandling" })(
  PaymentHandling
);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(PaymentHandling);
