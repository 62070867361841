// @flow weak

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import withRoot from "../../withRoot";

const styles = () => ({
  root: {
    width: "100%"
  },
  progress: {
    margin: 100
  }
});

class Loading extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Grid
        container
        alignItems="center"
        direction="column"
        justify="center"
        className={classes.root}
      >
        <CircularProgress
          className={this.props.progress || classes.progress}
          size={this.props.size || 50}
        />
      </Grid>
    );
  }
}

export default withRoot(withStyles(styles, { name: "Loading" })(Loading));
