import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import { apiRequest, rpcRequest } from "apis/jsonApi";
import {
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  List
} from "@material-ui/core";
import Avatar from "components/General/Avatar";
import MainActionButton from "components/Buttons/MainActionButton";
import Loading from "components/Progress/Loading";
import auth from "components/Auth/Auth";

class FacebookFriendsList extends Component {
  state = { friends: [], isLoaded: false, guests: [] };
  async componentDidMount() {
    let params = {};
    if (this.props.propertyId) {
      params.property_id = this.props.propertyId;
    }

    const users = await apiRequest(`users/friends`, "GET", params);
    this.setState({ friends: users.data || [], isLoaded: true });
  }

  onClickAddGuestList = (userId, name) => async () => {
    try {
      await rpcRequest("property-add-friend", {
        property_id: this.props.propertyId,
        user_id: userId
      });
      let params = {};
      if (this.props.propertyId) {
        params.property_id = this.props.propertyId;
      }

      if (this.props.isLocal) {
        this.setState({ guests: [...this.state.guests, userId] });
      } else {
        const users = await apiRequest(`users/friends`, "GET", params);
        this.setState({ friends: users.data || [], isLoaded: true });
      }

      this.props.appSnackbarMessage({
        message: "You added " + name + " to your property"
      });
      if (this.props.onClick) this.props.onClick();
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    const { isLocal, profile } = this.props;
    const { guests } = this.state;

    if (!this.state.isLoaded) return <Loading />;
    if (profile && !profile.hasFacebook)
      return (
        <>
          <Typography
            variant="caption"
            style={{ textAlign: "center", marginTop: 8 }}
            component="div"
          >
            {
              "To be able to invite your facebook friends you need to connect your account to facebook."
            }
          </Typography>
          <MainActionButton onClick={auth.initFacebookMergeLogin}>
            Connect account to facebook
          </MainActionButton>
        </>
      );

    if (!this.state.friends || this.state.friends.length === 0)
      return (
        <>
          <Typography
            variant="caption"
            style={{ textAlign: "center", marginTop: 8 }}
            component="div"
          >
            {
              "All of your Facebook friends who use Vrbff are already invited to this property or you should invite more friends to use Vrbff."
            }
          </Typography>
        </>
      );

    return (
      <>
        <List>
          {this.state.friends.map((f, i) => (
            <ListItem key={f.id}>
              <ListItemAvatar>
                <Avatar
                  firstName={f.firstName}
                  lastName={f.lastName}
                  src={f.picture}
                />
              </ListItemAvatar>
              <ListItemText primary={f.name} />
              <ListItemSecondaryAction>
                {(!guests || guests.indexOf(f.id) === -1) && (
                  <MainActionButton
                    isSmall
                    onClick={this.onClickAddGuestList(f.id, f.name)}
                  >
                    Add to guest list
                  </MainActionButton>
                )}
                {isLocal && guests && guests.indexOf(f.id) !== -1 && (
                  <MainActionButton isSmall disabled>
                    Is a guest
                  </MainActionButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    profile: (state.app.auth && state.app.auth.data) || {}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookFriendsList);
