import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import EditorField from "components/Fields/EditorField";
import MessagePreview from "components/InviteWizard/MessagePreview";

const styles = theme => ({
  propertyNameContainer: {
    background: "white",
    padding: "12px 18px",
    marginTop: -25,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 250,
    marginBottom: 22
  },
  propertyPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "28px 0",
    padding: "0 32px"
  },
  propertyPreview: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
    marginTop: 12
  },
  messagePreview: {
    margin: "12px 0",
    width: "100%",
    borderBottom: "1px solid #00000022",
    marginBottom: 12
  },
  editorContainer: {
    marginTop: 18
  }
});

class MessageStep extends Component {
  async continueCheck() {
    return new Promise(async resolve => {
      // if (this.charCount < 10) {
      //   this.props.snackbarMessage({
      //     message:
      //       "You have to write a message which is at least 10 chars long",
      //     type: "warning"
      //   });
      //   return resolve(false);
      // }
      return resolve(true);
    });
  }

  render() {
    const { change, classes, data, properties, profile } = this.props;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">
              {"Send a custom message"}
            </Typography>
            <Typography variant="caption" component="div">
              {
                "Say something nice to your guest about following your property."
              }
            </Typography>
            <div className={classes.editorContainer}>
              <EditorField
                placeholder={"Optional..."}
                setCount={count => (this.charCount = count)}
                limit={5000}
                rawString={data.message && data.message.rawData}
                handleEditor={(pdfData, rawData, htmlData) => {
                  change("description", {
                    rawData: rawData,
                    htmlData: htmlData
                  });
                }}
              />
            </div>
          </div>
        }
        SecondComponent={
          <div>
            <Typography variant="subtitle1">{"Message Preview"}</Typography>
            <Typography variant="caption" component="div">
              {
                "This is a preview of the message your guests will receive automatically when you confirm and send the invitations"
              }
            </Typography>

            <MessagePreview
              data={data}
              properties={properties}
              profile={profile}
            />
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

MessageStep = withStyles(styles, { name: "MessageStep" })(MessageStep);

export default MessageStep;
