import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as followsActionCreators from "../../redux/modules/follows";
import * as groupsActionCreators from "../../redux/modules/groups";
import FollowRequests from "../../components/Properties/FollowRequests";
import { getErrorMessage } from "../../helpers/utils";
import PropertyManageLayout from "components/Properties/PropertyManageLayout";

class FollowRequestsContainer extends Component {
  state = {
    errorMessage: null,
    snackbarMessage: null
  };

  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  getGroups = async () => {
    await this.props.fetchGroupsForProperty(this.props.match.params.id);
  };

  getPropertyFollows = async () => {
    const id =
      (this.props.property && this.props.property.id) ||
      this.props.match.params.id;
    await this.props.fetchFollowsByProperty(id);
  };

  onConfirmFollow = async values => {
    try {
      await this.props.confirmFollow(values.follow, values.group);
      this.setState({ snackbarMessage: "Follow confirmed" });
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem confirming the follow."
      );
      this.setState({ errorMessage });
      throw error;
    }
  };

  onDeleteFollow = async id => {
    try {
      await this.props.deleteFollow(id);
      this.setState({ snackbarMessage: "Follow deleted" });
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem deleting the follow."
      );
      this.setState({ errorMessage });
      throw error;
    }
  };

  render() {
    return (
      <PropertyManageLayout title={"Follow Requests"}>
        <FollowRequests
          closeSnackbarMessage={this.closeSnackbarMessage}
          errorMessage={this.state.errorMessage}
          follows={this.props.follows}
          getGroups={this.getGroups}
          getPropertyFollows={this.getPropertyFollows}
          groups={this.props.groups}
          handleConfirmFollow={values => this.onConfirmFollow(values)}
          handleDeleteFollow={values => this.onDeleteFollow(values)}
          isDeleting={this.props.isDeleting}
          isAdding={this.props.isAdding}
          property={this.props.property}
          snackbarMessage={this.state.snackbarMessage}
        />
      </PropertyManageLayout>
    );
  }
}

function mapStateToProps({ follows, groups }) {
  return {
    follows,
    groups,
    isDeleting: follows.isDeleting,
    isAdding: follows.isAdding
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...followsActionCreators,
      ...groupsActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowRequestsContainer);
