import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "components/General/Avatar";
import grey from "@material-ui/core/colors/grey";
import HomeIcon from "../Icons/HomeHeartLight";
import { getPropertyPath } from "../../helpers/utils";
import MaterialLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";

const styles = theme => ({
  badge: {
    width: "160px",
    background: "#e43",
    position: "absolute",
    top: "24px",
    left: "-36px",
    textAlign: "center",
    lineHeight: "35px",
    letterSpacing: ".4px",
    fontWeight: 600,
    color: "#f0f0f0",
    transform: "rotate(-45deg)",
    WebkitTransform: "rotate(-45deg)",
    boxShadow: "0 0 3px rgba(0,0,0,.2)"
  },
  card: {
    display: "block",
    borderRadius: "2px",
    boxShadow: theme.shadow.light,
    cursor: "pointer",
    transition: theme.transitions.create("all"),
    position: "relative",
    width: 300,
    height: "auto",
    "&:hover": {
      boxShadow: theme.shadow.medium
    },
    "&:focus": {
      boxShadow: theme.shadow.medium
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("xs")]: {
      width: "100%"
    },
    [theme.breakpoints.up("m")]: {
      width: 300
    }
  },
  avatar: {
    boxShadow: "#fff 0 0 0 1px, #40A5CB 0 0 0 2px",
    width: 16,
    height: 16,
    display: "inline-flex",
    marginRight: theme.spacing(2)
  },
  cardLink: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none"
    }
  },
  hostWrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2)
  },
  hostName: {
    display: "inline-flex",
    fontSize: "12px",
    color: grey[800]
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
    cursor: "pointer"
  },
  editIcon: {
    color: grey[600],
    fontSize: "14px",
    padding: theme.spacing(0.1),
    marginLeft: theme.spacing(1)
  },
  editIconWrapper: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: "2px"
  },
  placeholderIcon: {
    color: grey[400],
    fontSize: "50px"
  },
  placeholderWrapper: {
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  title: {
    color: grey[800],
    fontSize: "16px"
  },
  subheader: {
    color: grey[600],
    fontSize: "14px"
  }
});

class PropertyCard extends Component {
  render() {
    const { classes, property, addPropertyCard, justLink } = this.props;

    if (!addPropertyCard) {
      const isHost = property && property.isHost;
      const cardLink = isHost
        ? property.active
          ? `/manage/property/basics/${property.id}`
          : `/add-property/${property.id}`
        : getPropertyPath(property.slug);
      const image = property.image && property.image.url;

      const host = !isHost ? (
        <div className={classes.hostWrapper}>
          {property.hosts.length > 0
            ? property.hosts.map((host, index) => {
                return (
                  <Fragment key={index}>
                    <Avatar
                      firstName={host.firstName}
                      lastName={host.lastName}
                      src={host.picture}
                      className={classes.avatar}
                    />
                    <span className={classes.hostName}>
                      Hosted by {host.firstName}
                    </span>
                  </Fragment>
                );
              })
            : null}
        </div>
      ) : (
        <IconButton className={classes.editIconWrapper}>
          {property.active && (
            <span className={classes.hostName}>Edit my property</span>
          )}
          {!property.active && (
            <span className={classes.hostName}>Continue Wizard</span>
          )}
          <EditIcon className={classes.editIcon} />
        </IconButton>
      );

      return (
        <MaterialLink
          component={Link}
          to={justLink ? justLink : cardLink}
          className={classes.cardLink}
        >
          <Card className={classes.card}>
            {isHost && property.active === false && (
              <div className={classes.badge}>Not Complete</div>
            )}
            {!image && (
              <div className={classes.placeholderWrapper}>
                <HomeIcon className={classes.placeholderIcon} />
              </div>
            )}

            {image && (
              <CardMedia
                className={classes.media}
                image={image}
                title={property.name}
              />
            )}

            <CardHeader
              classes={{
                title: classes.title,
                subheader: classes.subheader
              }}
              title={property.name}
              subheader={
                property.address &&
                `${property.address.city}, ${property.address.region}`
              }
            />

            {!justLink && host}
          </Card>
        </MaterialLink>
      );
    } else {
      return (
        <Card className={classes.card}>
          <MaterialLink
            component={Link}
            to={"/add-property"}
            className={classes.cardLink}
          >
            <div className={classes.placeholderWrapper}>
              <AddIcon className={classes.placeholderIcon} />
            </div>
            <CardHeader
              classes={{
                title: classes.title,
                subheader: classes.subheader
              }}
              title={"Add a Property!"}
              subheader={"Start the New Property Wizard"}
            />
          </MaterialLink>
        </Card>
      );
    }
  }
}

PropertyCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PropertyCard);
