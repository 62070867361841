import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loading from "components/Progress/Loading";
import { getURLParams } from "helpers/utils";
import { apiAxiosRequest } from "apis/jsonApi";
import history from "../../history";
import AppPageHeader from "components/App/AppPageHeader";
class SettingsContainer extends Component {
  state = { error: false };
  async componentDidMount() {
    const params = getURLParams(this.props.history.location.search);
    const propertyId = localStorage.getItem("stripe_cb_property");
    try {
      let data = { auth_code: params.code };
      if (propertyId) {
        data.property_id = propertyId;
      }
      await apiAxiosRequest(
        `stripe/connect_account`,
        "POST",
        data,
        {},
        true,
        true
      );

      const redirect = localStorage.getItem("stripe_cb_url");
      localStorage.removeItem("stripe_cb_url");
      localStorage.removeItem("stripe_cb_property");
      history.replace(
        redirect ? redirect.replace(process.env.REACT_APP_VRBFF_URL, "") : "/"
      );
    } catch (error) {
      this.setState({ error: error });
      console.log("error adding connect account", error);
    }
  }
  render() {
    if (this.state.error) {
      return (
        <>
          <AppPageHeader subHeadline="My Settings" headline="Settings" />
          <h1>Unfortunately something went wrong!</h1>
        </>
      );
    }
    return <Loading />;
  }
}

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsContainer);
