import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { getURLParams } from "helpers/utils";

const styles = theme => ({
  root: {},
  button: {
    color: theme.palette.text.secondary
  },
  disabledButton: {
    color: fade(theme.palette.text.secondary, 0.1)
  },
  icon: {
    height: 16
  },
  text: {
    color: theme.palette.text.secondary
  }
});

class Pagination extends Component {
  state = {
    anchorEl: undefined,
    openPageSelect: false
  };

  handleClick = event => {
    this.setState({ openPageSelect: true, anchorEl: event.currentTarget });
  };

  handlePageNext = (collection, handleFetchCollection) => {
    const urlParams = getURLParams(collection.links.next);
    const params = { ...this.props.params, ...urlParams };
    handleFetchCollection(params);
  };

  handlePagePrev = (collection, handleFetchCollection) => {
    const urlParams = getURLParams(collection.links.prev);
    const params = { ...this.props.params, ...urlParams };
    handleFetchCollection(params);
  };

  handlePageSize = (pageSize, auth, collection, handleFetchCollection) => {
    const urlParams = getURLParams(collection.links.self);
    const params = { ...this.props.params, ...urlParams };
    params.page.number = 1; // reset to page 1
    params.page.size = pageSize;

    // TODO: update the page size in the profile
    handleFetchCollection(params);
    this.handleRequestClose();
  };

  handleRequestClose = () => {
    this.setState({ openPageSelect: false });
  };

  render() {
    const {
      auth,
      classes,
      collection,
      handleFetchCollection,
      pageSize
    } = this.props;
    if (!collection.meta) {
      return null;
    }
    const pageSizes = [10, 25, 50, 100];
    const hasPrevPage = collection.meta.pageNumber > 1;
    const hasNextPage = collection.meta.pageNumber < collection.meta.pageTotal;
    const currRowsPerPage =
      collection.meta.pageSize * collection.meta.pageNumber;
    const rowsStart = currRowsPerPage - collection.meta.pageSize + 1;
    const rowsEnd =
      currRowsPerPage > collection.meta.rowsTotal
        ? collection.meta.rowsTotal
        : currRowsPerPage;

    return (
      <Grid
        container
        alignItems="center"
        direction="row"
        justify="flex-end"
        spacing={0}
        className={classes.root}
      >
        <Grid item>
          <Typography variant="body2" className={classes.text}>
            {"Rows per page:"}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            className={classes.button}
            aria-owns={this.state.openPageSelect ? "page-size-menu" : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <Typography variant="body2" className={classes.text}>
              {collection.meta.pageSize}
            </Typography>
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="page-size-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.openPageSelect}
            onClose={this.handleRequestClose}
          >
            {pageSizes.map(ps => {
              return (
                <MenuItem
                  key={ps}
                  onClick={() =>
                    this.handlePageSize(
                      ps,
                      auth,
                      collection,
                      handleFetchCollection
                    )
                  }
                  selected={ps === pageSize}
                >
                  {ps}
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.text}>
            {`${rowsStart}-${rowsEnd} of ${collection.meta.rowsTotal}`}
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            disabled={!hasPrevPage}
            className={hasPrevPage ? classes.button : classes.disabledButton}
            onClick={() =>
              this.handlePagePrev(collection, handleFetchCollection)
            }
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            disabled={!hasNextPage}
            className={hasNextPage ? classes.button : classes.disabledButton}
            onClick={() =>
              this.handlePageNext(collection, handleFetchCollection)
            }
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { name: "Pagination" })(Pagination);
