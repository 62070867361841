import React from "react";
import Fade from "react-reveal/Fade";

import Hidden from "@material-ui/core/Hidden";

import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    paddingLeft: 200,
    paddingRight: 200,
    paddingTop: 120,
    paddingBottom: 100
  },
  imgContainer: {},
  img: {
    maxWidth: 303
  },
  textTitle: {
    lineHeight: 1.4,
    maxWidth: 411,
    fontSize: 32,
    paddingTop: 129,
    paddingLeft: 24
  },
  textHeading: {
    fontWeight: "bold",
    paddingRight: 6
  },
  textCopy: {
    fontWeight: "normal"
  },
  textBody: {
    fontSize: 16,
    paddingLeft: 133,
    paddingTop: 129,
    maxWidth: 411,
    lineHeight: 1.8
  },
  [theme.breakpoints.down("lg")]: {
    root: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: 60
    },
    textTitle: {
      paddingTop: "10%"
    },
    textBody: {
      paddingLeft: "5%",
      paddingTop: "10%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 16
    },
    textTitle: {
      fontSize: 24,
      paddingTop: 0,
      paddingLeft: 0
    },
    textBody: {
      fontSize: 16,
      paddingLeft: 0,
      paddingTop: 20,
      maxWidth: 411,
      lineHeight: 1.8
    }
  }
});

const WeMakeItEasy = props => {
  const { classes } = props;
  return (
    <Fade>
      <div className={classes.root}>
        <Hidden smDown>
          <div className={classes.imgContainer}>
            <img
              src="/images/we-make-it-easy/owner-control.png"
              alt="owner control"
              className={classes.img}
            />
          </div>
        </Hidden>
        <div className={classes.textTitle}>
          <span className={classes.textHeading}>
            We make it easy to create a private network around your retreat and
            decide who pays for what
          </span>
          <span className={classes.textCopy}>(if anything)</span>
        </div>
        <div className={classes.textBody}>
          You're in total control of who sees what with your home. Want it
          completely private? Want it open to all friends? Want other invited in
          users of the site to be able to request to join your network? We give
          you the controls for every element.
        </div>
      </div>
    </Fade>
  );
};

export default withStyles(styles, { name: "WeMakeItEasy" })(WeMakeItEasy);
