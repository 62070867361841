import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "components/General/Avatar";
import { buildAddress, truncate } from "../../helpers/utils";
import HomeIcon from "../Icons/HomeHeartLight";

import { Link } from "react-router-dom";
import moment from "moment";
import { parseMessageText } from "helpers/stays";

const styles = theme => ({
  addressText: {
    fontSize: "0.75rem",
    marginTop: 5,
    color: "rgba(0, 0, 0, 0.74)"
  },
  listLeftText: {
    marginLeft: 16,
    maxWidth: 200,
    minWidth: 200
  },
  itemPending: {
    borderLeft: "2px solid yellow"
  },
  noMargin: {
    margin: 0
  },
  bold: { margin: 0, fontWeight: "bold" },
  image: {
    display: "flex",
    width: "40px",
    height: "40px",
    flexGrow: 0,
    marginRight: "8px",
    backgroundColor: "transparent"
  }
});

const TitleItem = ({ name, createdAt }) => (
  <Fragment>
    {name}
    <Typography component="span" variant="body2" color="textPrimary">
      {" - " + moment(new Date(createdAt)).format("HH:mm A, MMM DD, YYYY")}
    </Typography>{" "}
  </Fragment>
);

const SecondaryTextItem = ({
  name,
  propertyName,
  content,
  address,
  classes
}) => (
  <Fragment>
    <Typography component="span" variant="body2" color="textPrimary">
      {name}
    </Typography>
    {" — "}
    {truncate(parseMessageText(content, true), 200)}
    <Typography component="div" className={classes.addressText}>
      {propertyName}, {buildAddress(address)}
    </Typography>
  </Fragment>
);

const InboxItem = ({
  trip,
  message,
  name,
  picture,
  index,
  length,
  classes,
  isManage
}) => {
  return (
    <Fragment key={index}>
      <ListItem
        alignItems="flex-start"
        button
        component={Link}
        to={isManage ? `${trip.property.id}/${trip.id}` : `/inbox/${trip.id}`}
      >
        <ListItemAvatar>
          {picture !== null ? (
            <Avatar isBig firstName={name} src={picture} />
          ) : (
            <HomeIcon className={classes.image} />
          )}
        </ListItemAvatar>

        <ListItemText
          primary={
            <TitleItem
              name={name}
              createdAt={(message ? message.createdAt : trip.createdAt) * 1000}
            />
          }
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            message && (
              <SecondaryTextItem
                name={message.user.name}
                classes={classes}
                propertyName={trip.property.name}
                address={trip.property.address}
                content={message.content}
              />
            )
          }
        />

        <ListItemText
          className={classes.listLeftText}
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <Fragment>
              <p className={classes.bold}>{trip.status}</p>
              <p className={classes.noMargin}>
                {moment(trip.startDate).format("MMM DD, YYYY")} -{" "}
                {moment(trip.endDate).format("MMM DD, YYYY")}
              </p>
              <p className={classes.noMargin}>Adults {trip.adults}</p>
              <p className={classes.noMargin}>Children {trip.children}</p>
            </Fragment>
          }
        />
      </ListItem>
      {index < length - 1 && false && <Divider component="li" />}
    </Fragment>
  );
};

export default React.memo(withStyles(styles)(InboxItem));
