import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import { findCountryByIso2 } from "../../helpers/utils";
import { countries } from "data/countries";

const styles = theme => ({
  countryName: {
    marginLeft: 8
  },
  error: {
    color: theme.palette.error.main,
    marginTop: 8
  },
  flags: {
    background: "url(//cdn.getmarko.com/app/flags.png)",
    noRepeat: "0 0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "24px 3859px",
    width: 24,
    height: 16,
    overflow: "hidden"
  },
  flagSelected: {
    display: "flex",
    paddingLeft: 0
  },
  formControl: {}
});

class SelectCountry extends Component {
  handleChange = event => {
    const value = event.target.value;
    this.props.input.onChange(value);
  };

  renderFlag = iso2 => {
    const country = findCountryByIso2(iso2);
    return (
      <div className={this.props.classes.flagSelected}>
        <div
          className={this.props.classes.flags}
          style={{ backgroundPosition: `-1px -${country.position}px` }}
        />
        <Typography
          className={this.props.classes.countryName}
          variant="body1"
        >{` ${country.name}`}</Typography>
      </div>
    );
  };

  render() {
    const {
      classes,
      meta: { touched, error },
      input: { name, value },
      label,
      formControlClass,
      disabled
    } = this.props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    return (
      <FormControl
        className={classNames(classes.formControl, formControlClass)}
        error={touched && error !== undefined}
        fullWidth
        variant="outlined"
      >
        <InputLabel htmlFor={name} id={name + "label"}>
          {label}
        </InputLabel>
        <Select
          labelId={name + "label"}
          id={name + "select"}
          labelWidth={((label && label.length) || 0) * 10}
          data-cy={name}
          disabled={disabled}
          value={value}
          renderValue={this.renderFlag}
          onChange={this.handleChange}
          // input={<Input id={name} />}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 200
              }
            }
          }}
        >
          {countries.map(country => (
            <MenuItem key={country.iso2} value={country.iso2}>
              <div
                className={classes.flags}
                style={{ backgroundPosition: `-1px -${country.position}px` }}
              />
              <Typography
                className={classes.countryName}
                variant="body1"
              >{` ${country.name}`}</Typography>
            </MenuItem>
          ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

export default withStyles(styles, { name: "SelectCountry" })(SelectCountry);
