import React, { Component } from "react";
import PropTypes from "prop-types";
import punycode from "punycode";
import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RichUtils
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { withStyles } from "@material-ui/core/styles";
import common from "@material-ui/core/colors/common";
import grey from "@material-ui/core/colors/grey";
import EditorToPdf from "../../helpers/EditorToPdf";
import BlockStyleControls from "components/Buttons/BlockStyleControls";
import InlineStyleControls from "components/Buttons/InlineStyleControls";

const { white } = common;

const styles = theme => ({
  counter: {
    color: white,
    backgroundColor: theme.palette.secondary.main,
    display: "inline-block",
    minWidth: 50,
    borderRadius: 10,
    padding: 2,
    textAlign: "center",
    marginBottom: 5
  },
  counterOverLimit: {
    color: white,
    backgroundColor: theme.palette.error.main,
    display: "inline-block",
    minWidth: 50,
    borderRadius: 10,
    padding: 2,
    textAlign: "center",
    marginBottom: 5
  },
  editor: {
    boxSizing: "border-box",
    borderColor: "#ddd",
    borderStyle: "solid",
    borderWidth: "0 1px 1px 1px",
    cursor: "text",
    padding: 16,
    marginBottom: "1em",
    background: "#fefefe",
    minHeight: 140,
    fontSize: 16,
    color: theme.palette.text.main
  },
  toolbar: {
    borderColor: theme.palette.secondary.border,
    borderStyle: "solid",
    borderWidth: "1px",
    background: grey[100],
    borderRadius: 2,
    zIndex: 2,
    boxSizing: "border-box"
  }
});

class EditorField extends Component {
  constructor(props) {
    super(props);
    let editorState = null;
    if (!this.props.rawString) {
      editorState = EditorState.createEmpty();
    } else {
      let blockArray = convertFromRaw(this.props.rawString);
      editorState = EditorState.createWithContent(blockArray);
    }

    this.state = {
      count: this.getCharCount(editorState),
      editorState,
      initData: false
    };
    if (this.props.setCount) this.props.setCount(this.state.count);
    this.focus = () => this.editor.focus();
    this.handleKeyCommand = command => this._handleKeyCommand(command);
    this.toggleBlockType = type => this._toggleBlockType(type);
    this.toggleInlineStyle = style => this._toggleInlineStyle(style);

    this.onChange = editorState => {
      let count = this.getCharCount(editorState);
      if (this.props.setCount) this.props.setCount(count);
      let contentState = editorState.getCurrentContent();
      const editorToPdf = new EditorToPdf(contentState);
      let pdfData = editorToPdf.generate();
      let rawData = convertToRaw(contentState);
      let htmlData = stateToHTML(contentState);
      // pass to container for storage

      this.props.handleEditor(pdfData, rawData, htmlData);
      this.setState({
        count,
        editorState,
        initData: true
      });
    };
  }

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  getCharCount(editorState) {
    const decodeUnicode = str => punycode.ucs2.decode(str); // func to handle unicode characters
    const plainText = editorState.getCurrentContent().getPlainText("");
    const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
    const cleanString = plainText.replace(regex, "").trim(); // replace above characters w/ nothing
    return decodeUnicode(cleanString).length;
  }

  render() {
    const { classes, limit, placeholder } = this.props;
    const { count, editorState } = this.state;

    return (
      <div className={"Editor-root"}>
        <div className={classes.toolbar}>
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
        </div>
        <div className={classes.editor} onClick={this.focus}>
          <Editor
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={this.onChange}
            placeholder={placeholder}
            spellCheck={true}
            ref={element => {
              this.editor = element;
            }}
            style={{ fontSize: 40 }}
          />
        </div>
        <div>
          <span
            className={
              count > limit ? classes.counterOverLimit : classes.counter
            }
          >
            {count}
          </span>{" "}
          {`characters (max ${limit})`}
        </div>
      </div>
    );
  }
}

EditorField.propTypes = {
  handleEditor: PropTypes.func.isRequired,
  editorState: PropTypes.object,
  onToggle: PropTypes.func
};

export default withStyles(styles, { name: "EditorField" })(EditorField);
