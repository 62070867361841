import React, { Component } from "react";
import PropTypes from "prop-types";
import EditorButton from "./EditorButton";

const INLINE_STYLES = [
  { label: "bold", style: "BOLD" },
  { label: "italic", style: "ITALIC" },
  { label: "underline", style: "UNDERLINE" }
];

class InlineStyleControls extends Component {
  render() {
    const currentStyle = this.props.editorState.getCurrentInlineStyle();
    return (
      <span>
        {INLINE_STYLES.map(type => (
          <EditorButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={this.props.onToggle}
            style={type.style}
          />
        ))}
      </span>
    );
  }
}

InlineStyleControls.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func
};

export default InlineStyleControls;
