import { BLOCK_TYPE, INLINE_STYLE, getEntityRanges } from "draft-js-utils";
const { BOLD, ITALIC, UNDERLINE } = INLINE_STYLE;

class EditorToPdf {
  constructor(contentState) {
    this.contentState = contentState;
    this.currentBlock = 0;
    this.output = { content: [] };
    this.blocks = null;
    this.listOlAcc = [];
    this.listUlAcc = [];
  }

  generate() {
    this.blocks = this.contentState.getBlockMap().toArray();

    while (this.currentBlock < this.blocks.length) {
      this._processBlock();
    }

    return this.output;
  }

  _processBlock() {
    const block = this.blocks[this.currentBlock];

    if (
      block.getType() !== BLOCK_TYPE.UNORDERED_LIST_ITEM &&
      !!this.listUlAcc.length
    ) {
      this._updateAndResetUlList();
    }

    if (
      block.getType() !== BLOCK_TYPE.ORDERED_LIST_ITEM &&
      !!this.listOlAcc.length
    ) {
      this._updateAndResetOlList();
    }

    switch (block.getType()) {
      case BLOCK_TYPE.ORDERED_LIST_ITEM:
        this.listOlAcc.push({ block: [...this._renderBlockContent(block)] });
        break;

      case BLOCK_TYPE.UNORDERED_LIST_ITEM:
        this.listUlAcc.push({ block: [...this._renderBlockContent(block)] });
        break;

      default:
        const data = this._renderBlockContent(block);

        this.output.content.push(
          !!data.length
            ? { tag: "p", body: [{ block: [...data] }] }
            : { tag: "p", body: [{ block: [] }] }
        );
    }

    // Clear lists when is last block
    if (block.getKey() === this.contentState.getLastBlock().getKey()) {
      if (!!this.listUlAcc.length) {
        this._updateAndResetUlList();
      }

      if (!!this.listOlAcc.length) {
        this._updateAndResetOlList();
      }
    }

    this.currentBlock += 1;
  }

  _renderBlockContent(block) {
    if (block.getText() === "") {
      return [];
    }

    const ranges = getEntityRanges(block.getText(), block.getCharacterList());

    return ranges.reduce((acc, [entityKey, stylePieces]) => {
      acc.push(
        ...stylePieces
          .map(([text, style]) => {
            return {
              text,
              bold: style.has(BOLD),
              italics: style.has(ITALIC),
              underline: style.has(UNDERLINE)
            };
          })
          .filter(properties => properties.text !== " ")
      );

      return acc;
    }, []);
  }

  _updateAndResetUlList() {
    this.output.content.push({ tag: "ul", body: this.listUlAcc });
    this.listUlAcc = [];
  }

  _updateAndResetOlList() {
    this.output.content.push({ tag: "ol", body: this.listOlAcc });
    this.listOlAcc = [];
  }
}

export default EditorToPdf;
