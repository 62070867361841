import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as imagesActionCreators from "../../redux/modules/images";
import validate from "../../components/Properties/validateProperty";
import dasherize from "dasherize";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ImageFilterHdr from "@material-ui/icons/FilterHdr";
import MainActionButton from "components/Buttons/MainActionButton";
import classNames from "classnames";
import HomeIcon from "components/Icons/HomeIcon";
import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
  labelRadio: {
    borderBottom: "1px solid #dfdfdf",
    paddingTop: 18,
    paddingBottom: 18,
    alignItems: "flex-start",
    opacity: 0.7,
    "&:hover": {
      opacity: 1
    },
    "&:first-child": {
      borderTop: "1px solid #dfdfdf"
    }
  },
  selectedRadio: {
    borderBottom: "1px solid #40A5CB",
    borderTopColor: "1px solid #40A5CB",
    borderColor: "#40A5CB",
    opacity: 1,
    boxShadow: "0 -1px 0 0 #40A5CB",
    zIndex: "9",
    "&:first-child": {
      borderTopColor: "transparent"
    }
  },
  radio: {
    paddingTop: 4,
    paddingRight: 18,
    "&:hover": {
      backgroundColor: "transparent !important"
    }
  },
  followButtonContainer: {
    marginTop: 30,
    marginBottom: 20
  },
  hostedBy: {
    alignSelf: "flex-start",
    padding: 18
  },
  propertyNameContainer: {
    backgroundColor: "#F4F4F4",
    padding: "12px 18px",
    marginTop: -25,
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  propertyPreviewContainer: {
    backgroundColor: "#F4F4F4",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "28px 0"
  },
  placeholderWrapper: {
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  placeholderIcon: {
    color: grey[400],
    fontSize: "50px"
  }
});

class PrivacyStep extends Component {
  async continueCheck() {
    return new Promise(async resolve => {
      const submitter = this.props.handleSubmit(async values => {
        try {
          //Activate the property
          values.active = true;
          values.privacy =
            !values.privacy || values.privacy.length === 0
              ? "Private"
              : values.privacy;
          const property = dasherize(values);

          await this.props.updateProperty(property);
          return resolve(true);
        } catch (error) {
          console.error("error", error);
        }
      });
      const a = await submitter();
      if (a) return resolve(false);
    });
  }

  loadPreview() {
    const { property, privacyValue, classes, profile } = this.props;
    const image = property && property.image && property.image.url;
    return (
      <Paper elevation={4} className={classes.propertyPreviewContainer}>
        {privacyValue === "Private" && (
          <ImageFilterHdr
            size={100}
            style={{
              fontSize: 100,
              borderRadius: 50,
              border: "2px solid",
              paddingLeft: 5,
              paddingRight: 5,
              margin: "30px 0",
              color: "#00000044"
            }}
          ></ImageFilterHdr>
        )}
        {privacyValue !== "Private" && image && (
          <img style={{ width: "100%" }} alt="Property" src={image} />
        )}
        {privacyValue !== "Private" && !image && (
          <div className={classes.placeholderWrapper}>
            <HomeIcon className={classes.placeholderIcon} />
          </div>
        )}
        <div className={classes.propertyNameContainer}>
          <Typography variant="h6" style={{ color: "#40A5CB" }}>
            {property.name}
          </Typography>
          {privacyValue !== "Private" && (
            <Typography variant="subtitle1" style={{ color: "#00000066" }}>
              {`${property.address.city}, ${property.address.region}`}
            </Typography>
          )}
        </div>
        <div className={classes.followButtonContainer}>
          <MainActionButton isSmall>Follow property</MainActionButton>
        </div>

        <div className={classes.hostedBy}>
          <Typography variant="caption">{"Hosted by"}</Typography>
          <Typography variant="caption" style={{ fontWeight: "bold" }}>
            {" " +
              profile.firstName +
              " " +
              (privacyValue !== "Private"
                ? profile.lastName
                : profile.lastName[0]) +
              "."}
          </Typography>
        </div>
      </Paper>
    );
  }
  render() {
    const { privacyValue, change, classes } = this.props;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">{"Privacy"}</Typography>
            <Typography variant="caption" component="div">
              {
                "Vrbff offers different levels of copy text to endure your property is only visibile seen by those you want."
              }
            </Typography>
            <RadioGroup
              style={{ marginTop: 24 }}
              aria-label="gender"
              name="gender1"
              //   className={classes.group}
              value={privacyValue}
              onChange={event => {
                change("privacy", event.target.value);
              }}
            >
              <FormControlLabel
                className={
                  privacyValue === "Private"
                    ? classNames(classes.labelRadio, classes.selectedRadio)
                    : classes.labelRadio
                }
                value="Private"
                control={<Radio disableRipple className={classes.radio} />}
                label={
                  <>
                    <Typography variant="h6">{"Private"}</Typography>
                    <Typography
                      variant="caption"
                      style={{ fontSize: "0.9rem" }}
                      component="div"
                    >
                      {
                        "Users will not be able to see the property address, photos, or your last name."
                      }
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                className={
                  privacyValue === "Friends"
                    ? classNames(classes.labelRadio, classes.selectedRadio)
                    : classes.labelRadio
                }
                value="Friends"
                control={<Radio disableRipple className={classes.radio} />}
                label={
                  <>
                    <Typography variant="h6">{"Friends"}</Typography>
                    <Typography
                      variant="caption"
                      style={{ fontSize: "0.9rem" }}
                      component="div"
                    >
                      {
                        "Your Facebook friends can see a photo and your first and last name."
                      }
                    </Typography>
                  </>
                }
              />
              <FormControlLabel
                className={
                  privacyValue === "SemiPrivate"
                    ? classNames(classes.labelRadio, classes.selectedRadio)
                    : classes.labelRadio
                }
                value="SemiPrivate"
                control={<Radio disableRipple className={classes.radio} />}
                label={
                  <>
                    <Typography variant="h6">
                      {"Open to Vrbff network"}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{ fontSize: "0.9rem" }}
                      component="div"
                    >
                      {
                        "All Vrbff users will be able to see your listing - the best option for getting bookings."
                      }
                    </Typography>
                  </>
                }
              />
            </RadioGroup>
          </div>
        }
        SecondComponent={
          <div>
            <Typography variant="subtitle1">{"Property review"}</Typography>
            <Typography variant="caption" component="div">
              {
                "Your privacy settings determine the view of yout property to invited guests and existing Vrbff guests you are Facebook friends with."
              }
            </Typography>

            {this.loadPreview()}

            <Typography variant="caption" component="div">
              {
                "Once approved, guests can see all relevant details about your property."
              }
            </Typography>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  const selector = formValueSelector("PrivacyStepForm" + props.propertyId); // <-- same as form name

  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    hasFetched: state.properties.hasFetched,
    isDeleting: state.properties.isDeleting,
    isFetching: state.properties.isFetching,
    isUploading: state.images.isUploading,
    initialValues: {
      ...property
    },
    form: "PrivacyStepForm" + props.propertyId,
    property,
    privacyValue: selector(state, "privacy") || "Private"
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...imagesActionCreators
    },
    dispatch
  );
}

PrivacyStep = reduxForm({
  // form: "PrivacyStepForm", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(PrivacyStep);

PrivacyStep = withStyles(styles, { name: "PrivacyStep" })(PrivacyStep);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(PrivacyStep);
