import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as appActionCreators from "../../redux/modules/app";
import MainActionButton from "components/Buttons/MainActionButton";
import history from "../../history";
import { getErrorMessage } from "helpers/utils";
import DeleteDialog from "components/Dialogs/DeleteDialog";

const styles = theme => ({
  editorContainer: { marginTop: 20 }
});

class DangerZone extends Component {
  state = {
    deleteDialog: false,
    deleteDialogError: null,
    isDeleting: false
  };

  closeDeleteProperty = event => {
    this.setState({
      deleteDialog: false
    });
  };

  openDeleteProperty = event => {
    this.setState({ deleteDialog: true });
  };

  handleDelete = async id => {
    try {
      const propertyName = this.props.property.name;
      this.setState({ isDeleting: true });
      await this.props.deleteProperty(id);

      this.props.appSnackbarMessage({
        message: "You successfully deleted " + propertyName
      });
      await this.props.fetchProperties();
      history.replace(`/manage`);
    } catch (error) {
      console.error("error:", error);
      let deleteDialogError = getErrorMessage(
        error,
        "There was a problem deleting the property."
      );
      this.setState({ deleteDialogError, isDeleting: false });
      throw error;
    }
  };

  render() {
    const { property, classes } = this.props;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">{"Delete property"}</Typography>
            <Typography variant="caption" component="div">
              {
                "Deleting a property is permenant and cannot be undone. All of your stays and property data will be deleted. Are you sure you want to proceed?"
              }
            </Typography>
            <div className={classes.editorContainer}>
              <MainActionButton isDanger onClick={this.openDeleteProperty}>
                Delete Property
              </MainActionButton>
            </div>
            <DeleteDialog
              closeDialog={this.closeDeleteDialog}
              errorMessage={this.state.deleteDialogError}
              isDeleting={this.state.isDeleting}
              onDelete={this.handleDelete}
              open={this.state.deleteDialog}
              resource={property}
            />
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    hasFetched: state.properties.hasFetched,
    isDeleting: state.properties.isDeleting,
    isFetching: state.properties.isFetching,
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

DangerZone = withStyles(styles, { name: "DangerZone" })(DangerZone);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(DangerZone);
