import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tripsActionCreators from "../../redux/modules/trips";
import * as messagesActionCreators from "../../redux/modules/messages";
import { getErrorMessage } from "../../helpers/utils";
import dasherize from "dasherize";
import AppPageHeader from "../../components/App/AppPageHeader";
import { Helmet } from "react-helmet";
import TripDetail from "components/Trip/TripDetail";

class InboxDetailContainer extends Component {
  state = {
    errorMessage: null,
    snackbarMessage: null
  };

  componentDidMount() {
    this.props.fetchTrip(this.props.match.params.stayId);
  }
  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  handleTripUpdate = async trip => {
    try {
      trip = dasherize(trip);
      await this.props.updateTrip(trip);
    } catch (e) {
      this.setState({ snackbarMessage: e[0].title });
    }
  };

  onSendMessage = async values => {
    try {
      if (values.tripId && values.tripId > 0 && values.message) {
        const message = {
          content: values.message,
          trip: {
            id: values.tripId
          }
        };
        await this.props.addMessage(message);
      }
      this.setState({ snackbarMessage: "A new messages has been sent." });
      await this.props.fetchTrip(this.props.match.params.stayId);
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem submitting the requested dates."
      );
      this.setState({ errorMessage });
      throw error;
    }
  };

  render() {
    let trip = this.props.trips[this.props.match.params.stayId];
    let propertyName = trip ? trip.property.name : "";
    let propertyId = trip ? trip.property.id : "";

    const pageHeader = !this.props.isManage ? (
      <AppPageHeader
        breadcrumbPath={"/inbox"}
        breadcrumbTitle={"All stays"}
        // subHeadline={`Stay request for ${propertyName}`}
      />
    ) : (
      <AppPageHeader
        breadcrumbPath={`/manage/stays/${propertyId}`}
        breadcrumbTitle={`All requests for ${propertyName}`}
      />
    );
    if (this.props.hasFetched === true && this.props.error) {
      const errorMessage = getErrorMessage(
        this.props.error,
        "The request was not found"
      );
      return (
        <>
          <Helmet>
            <title>Stays&nbsp;&bull;&nbsp;Vrbff</title>
          </Helmet>
          {pageHeader}
          <h3>{errorMessage}</h3>
        </>
      );
    }
    return (
      <Fragment>
        <Helmet>
          <title>Stays&nbsp;&bull;&nbsp;Vrbff</title>
        </Helmet>
        {pageHeader}
        <TripDetail
          refetchTrip={async () =>
            this.props.fetchTrip(this.props.match.params.stayId)
          }
          handleTripUpdate={this.handleTripUpdate}
          isManage={this.props.isManage}
          closeSnackbarMessage={this.closeSnackbarMessage}
          onSendMessage={this.onSendMessage}
          errorMessaeg={this.state.errorMessage}
          snackbarMessage={this.state.snackbarMessage}
          auth={this.props.auth}
          hasFetched={this.props.hasFetched}
          isFetching={this.props.isFetching}
          trip={trip}
        />
      </Fragment>
    );
  }
}

function mapStateToProps({ trips }, props) {
  return {
    hasFetched: trips.hasFetched,
    isFetching: trips.isFetching,
    error: trips.error,
    trips
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...tripsActionCreators,
      ...messagesActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxDetailContainer);
