import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import ChildFriendlyIcon from "@material-ui/icons/ChildFriendly";
import SleepIcon from "@material-ui/icons/Brightness2";

const styles = theme => ({
  infoContainer: {
    // paddingBottom: theme.spacing(1),
    // marginBottom: theme.spacing(1)
  },
  iconInfo: {
    color: "#40A5CB",
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 8
  },
  infoTitle: {
    fontWeight: 700,
    fontSize: 14
  },
  boxContainer: {
    borderRadius: 4,
    border: "1px solid " + theme.palette.secondary.border,
    display: "flex",
    justifyItems: "center",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 16
  },
  boxBottom: {
    background: theme.palette.secondary.bg,
    paddingTop: 8,
    paddingBottom: 8,
    marginTop: 8,
    width: "100%"
  },
  bottomText: {
    textAlign: "center",
    fontSize: 24
  }
});

const TripNightsAdultsChildren = ({ classes, nights, adults, children }) => (
  <Grid container spacing={3} className={classes.infoContainer}>
    <Grid item xs={12} md={4}>
      <div className={classes.boxContainer}>
        <SleepIcon className={classes.iconInfo} />
        <Typography
          variant="button"
          color="inherit"
          className={classes.infoTitle}
        >
          Nights
        </Typography>
        <div className={classes.boxBottom}>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.bottomText}
          >
            {nights}
          </Typography>
        </div>
      </div>
    </Grid>
    <Grid item xs={12} md={4}>
      <div className={classes.boxContainer}>
        <EmojiPeopleIcon className={classes.iconInfo} />
        <Typography
          variant="button"
          color="inherit"
          className={classes.infoTitle}
        >
          Adults
        </Typography>
        <div className={classes.boxBottom}>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.bottomText}
          >
            {adults}
          </Typography>
        </div>
      </div>
    </Grid>
    <Grid item xs={12} md={4}>
      <div className={classes.boxContainer}>
        <ChildFriendlyIcon className={classes.iconInfo} />
        <Typography
          variant="button"
          color="inherit"
          className={classes.infoTitle}
        >
          Children
        </Typography>
        <div className={classes.boxBottom}>
          <Typography
            variant="h6"
            color="inherit"
            className={classes.bottomText}
          >
            {children}
          </Typography>
        </div>
      </div>
    </Grid>
  </Grid>
);

export default withStyles(styles, { name: "TripNightsAdultsChildren" })(
  TripNightsAdultsChildren
);
