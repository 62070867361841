import history from "../history";
import JsonApi from "devour-client";
import humps from "humps";
import { getToken, isTokenExpired } from "../components/Auth/Auth";
import axios from "axios";
import models from "./models";
const qs = require("qs");

// Perform setup common to all requests.
const apiUrl = process.env.REACT_APP_VRBFF_API;
const jsonApi = new JsonApi({ apiUrl });

// set the json-api (axios) token for future authenticated requests
const token = getToken();

export const axiosApi = axios.create({
  baseURL: apiUrl
});

export function setAxiosHeader() {
  // set the json-api (axios) token for future authenticated requests
  const token = getToken();
  axiosApi.defaults.headers.common["Authorization"] = "Bearer " + token;
}

if (token) {
  jsonApi.headers["Authorization"] = "Bearer " + token;
}

let errorMiddleware = {
  name: "errors-middleware",
  error: function(payload) {
    // check for expired api requests
    console.error("payload:", payload);
    if (
      payload.response &&
      payload.response.status === 401 &&
      payload.response.statusText === "Unauthorized"
    ) {
      const token = getToken();
      if (token && isTokenExpired(token)) {
        history.push({
          pathname: "/",
          query: { message: "expired" }
        });
      } else {
        history.push({ pathname: "/unauthorized" });
      }
    }

    // format errors
    let errors =
      payload.response && payload.response.data && payload.response.data.errors;

    return { errors };
  }
};

let paramsSerializerMiddleware = {
  name: "params-serializer",
  req: async payload => {
    if (payload.req.method === "GET") {
      payload.req.paramsSerializer = function(params) {
        return qs.stringify(params, {
          arrayFormat: "brackets",
          encode: true
        });
      };
    }

    return payload;
  }
};

/**
 * Transform incoming values to camelCase
 */
let humperMiddlware = {
  name: "humper",
  res: payload => {
    return humps.camelizeKeys(payload);
  }
};

jsonApi.insertMiddlewareBefore("axios-request", paramsSerializerMiddleware);
jsonApi.insertMiddlewareAfter("response", humperMiddlware);
jsonApi.replaceMiddleware("errors", errorMiddleware);

// model definitions
jsonApi.define("address", models.address);
jsonApi.define("booking", models.booking);
jsonApi.define("follow", models.follow);
jsonApi.define("group", models.group);
jsonApi.define("image", models.image);
jsonApi.define("invite", models.invite);
jsonApi.define("location", models.location);
jsonApi.define("message", models.message);
jsonApi.define("property", models.property);
jsonApi.define("trip", models.trip);
jsonApi.define("user", models.user);
jsonApi.define("notification", models.notification);
jsonApi.define("setting", models.setting);
jsonApi.define("amenity", models.amenity);
jsonApi.define("propertiesamenity", models.propertiesAmenity);
jsonApi.define("rate", models.rate);
jsonApi.define("ratestrip", models.ratesTrip);
jsonApi.define("pricing", models.pricing);
jsonApi.define("card", models.card);
jsonApi.define("connectaccount", models.connectAccount);
jsonApi.define("paymenttransaction", models.paymentTransaction);
jsonApi.define("profile", models.profile);
jsonApi.define("feed", models.feed);
export default jsonApi;

export function transformNotification(notification) {
  const data = JSON.parse(notification.data);
  let finalData = jsonApi.deserialize.resource.call(
    jsonApi,
    data.content.data,
    data.content.included
  );
  if (finalData.content && finalData.content.length > 0) {
    const content = JSON.parse(finalData.content);
    finalData.content = jsonApi.deserialize.resource.call(
      jsonApi,
      content.data,
      content.included
    );
  }

  return humps.camelizeKeys(finalData);
}

export function transformAxios(response) {
  const data = response.data;
  let finalData = jsonApi.deserialize.resource.call(
    jsonApi,
    data.data,
    data.included
  );

  return humps.camelizeKeys(finalData);
}

export async function createResource(type, resource) {
  return await new Promise((resolve, reject) => {
    jsonApi
      .create(type, resource)
      .then(response => {
        if (response.data) {
          resolve(response.data);
        }
        if (response.errors) {
          reject(response.errors);
        }
      })
      .catch(response => {
        reject(response.errors);
      });
  });
}

export async function updateResource(type, resource, params = {}, meta = {}) {
  const token = getToken();
  if (token) {
    jsonApi.headers["Authorization"] = "Bearer " + token;
  }
  return await new Promise((resolve, reject) => {
    jsonApi
      .update(type, resource, params, meta)
      .then(response => {
        if (response.data) {
          resolve(response.data);
        }
        if (response.errors) {
          reject(response.errors);
        }
      })
      .catch(response => {
        reject(response.errors);
      });
  });
}

export async function apiRequest(path, method, queryParams, resource) {
  const token = getToken();
  if (token) {
    jsonApi.headers["Authorization"] = "Bearer " + token;
  }
  return new Promise((resolve, reject) => {
    jsonApi
      .request(`${apiUrl}/${path}`, method, queryParams, resource)
      .then(response => {
        resolve(response);
      })
      .catch(response => {
        reject(response);
      });
  });
}

export function serializeResource(model, resource) {
  return jsonApi.serialize.resource.call(jsonApi, "trip", resource);
}

export async function rpcRequest(method, params) {
  setAxiosHeader();
  return await axiosApi.post("/rpc", {
    method,
    params
  });
}

export async function apiAxiosRequest(
  path,
  method,
  data,
  params = {},
  withToken = false,
  transform = false
) {
  let config = {
    method,
    params,
    url: `${apiUrl}/${path}`,
    data
  };
  if (withToken) {
    const token = getToken();
    if (token)
      config.headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      };
  }
  if (transform) {
    let response = await axios(config);
    return transformAxios(response);
  }
  return await axios(config);
}
