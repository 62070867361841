import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import Grid from "@material-ui/core/Grid";
import SendMessage from "../Inbox/SendMessage";
import SnackbarMessage from "../Message/SnackbarMessage";
import MessagesBox from "../Inbox/MessagesBox";
import Loading from "../Progress/Loading";
import { Typography, Paper } from "@material-ui/core";
import HomeIcon from "../Icons/HomeHeartLight";
import PropertySBB from "components/General/PropertySBB";
import GoogleMap from "google-map-react";
import HostedBy from "components/General/HostedBy";
import MainActionButton from "components/Buttons/MainActionButton";
import moment from "moment";
import StatusTag from "components/General/StatusTag";
import ExtensionPanel from "components/General/ExtensionPanel";
import TripDetailChangeDates from "./TripDetailChangeDates";
import TripDetailAction from "./TripDetailAction";
import { getNights, cleanHtml } from "helpers/utils";
import TripNightsAdultsChildren from "components/General/TripNightsAdultsChildren";
import PayTripDialog from "containers/Dialogs/PayTripDialog";
import PaymentSummary from "components/General/PaymentSummary";
import HostsNew from "components/General/HostsNew";
import locationIcon from "../../static/assets/icons/location.svg";
import PropertyExtensionPanel from "components/Properties/PropertyExtensionPanel";
import Avatar from "components/General/Avatar";
import { Link } from "react-router-dom";

const styles = theme => ({
  pageContainer: {
    padding: theme.spacing(3)
  },
  messageContainer: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 32,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 24,
    marginTop: 40
  },
  image: {
    display: "block",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    paddingTop: "56.25%",
    marginTop: 32
  },
  RouteManage: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    felxGrow: 1
  },
  mapOverlay: {
    width: "100%",
    height: 64,
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    position: "absolute",
    top: 0,
    paddingLeft: theme.spacing(2)
  },
  cityRegion: {
    color: "#00000099",
    marginBottom: 0
  },
  infoTitle: {
    fontWeight: "bold"
  },
  infoTitleMessages: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  containerExpandning: {
    marginTop: 24
  },
  houseRules: {
    padding: "0 " + theme.spacing(2) + "px"
  },
  actions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  rightPrice: {},
  priceRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderBottom: "1px solid #ddd"
  },
  totalRow: {
    fontWeight: "bold"
  },
  pricingContainer: {
    marginBottom: theme.spacing(2)
  },

  detailsContainer: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 32,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 24
  },
  titleDetails: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: "32px",
    marginTop: 0,
    marginBottom: 24
  },
  hosts: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main,
    marginBottom: 0,
    marginRight: 16
  },
  separator: {
    borderColor: theme.palette.secondary.border
  },
  containerDetailsMiddle: {
    borderTop: "1px solid " + theme.palette.secondary.border,
    marginTop: 16,
    paddingTop: 24,
    borderBottom: "1px solid " + theme.palette.secondary.border
  },
  location: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main
  },
  locationIcon: {
    height: 24,
    verticalAlign: "middle",
    marginRight: 8,
    color: theme.palette.primary.main
  },

  mainInfoContainer: {
    border: "1px solid " + theme.palette.secondary.border,
    borderRadius: 4
  },
  mainInfoContainerInner: {
    display: "flex"
  },
  instructionsContainer: {
    borderTop: "1px solid " + theme.palette.secondary.border,
    paddingLeft: 148,
    paddingTop: 40,
    paddingBottom: 40
  },
  mainInfoSubContainer: {
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 42,
    paddingBottom: 16,
    width: "100%"
  },
  leftSideContainer: {
    paddingTop: "32px",
    paddingLeft: "40px",
    paddingBottom: "16px"
  },
  titleText: {
    color: "#0F0F10",
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    textDecoration: "none"
  },
  mainInfoSubElements: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 24
  },
  checkinoutTitle: {
    fontSize: 14,
    fontWeight: 700
  }
});

class TripDetail extends Component {
  state = { openPay: false };
  handleSendMessage = message => {
    this.props.onSendMessage({
      message: message,
      tripId: this.props.trip.id
    });
  };

  getMap() {
    if (
      !this.props.trip ||
      !this.props.trip.property ||
      !this.props.trip.property.address ||
      !this.props.trip.property.address.location
    )
      return;

    const property = this.props.trip.property;
    let lat = property.address.location.latitude;
    let lng = property.address.location.longitude;
    // const randomLat =
    //   getRandomArbitrary(0.0005, 0.002) * (Math.random() >= 0.5 ? 1 : -1);
    // const randomLng =
    //   getRandomArbitrary(0.0005, 0.002) * (Math.random() >= 0.5 ? 1 : -1);
    // const center = { lat: lat + randomLat, lng: lng + randomLng };
    const center = { lat: lat, lng: lng };
    const Map = ({ latlng, zoom }) => (
      <div>
        <div>
          <Typography
            variant="subtitle1"
            className={this.props.classes.location}
          >
            <img
              alt="location icon"
              src={locationIcon}
              className={this.props.classes.locationIcon}
            />
            {`${property.address.city}, ${property.address.region}`}
          </Typography>
        </div>
        <div style={{ height: 300, position: "relative", marginBottom: 24 }}>
          <GoogleMap
            options={maps => ({
              fullscreenControl: false
            })}
            yesIWantToUseGoogleMapApiInternals={true}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_CLOUD_API }}
            defaultZoom={14}
            defaultCenter={center}
            onGoogleApiLoaded={({ map, maps }) =>
              /*global google*/
              new google.maps.Circle({
                strokeColor: "#40A5CB",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#40A5CB",
                fillOpacity: 0.3,
                map,
                center: center,
                radius: 500
              })
            }
          />
        </div>
        {/* Change the button depending on the status */}
        <MainActionButton isOutline fullWidth to={"/p/" + property.slug}>
          See Full Property Details
        </MainActionButton>
      </div>
    );

    return (
      <div style={{ marginTop: 24 }}>
        <Map />
      </div>
    );
  }

  getHostIds = () => {
    return this.props.trip.property.hosts.map(h => h.id);
  };

  canUserChangeDates = isHost => {
    return this.props.trip.status === "Pedning";
  };

  getInstructions = () => {
    const { classes } = this.props;
    const { instructionsAt } = this.props.trip;
    const { instructionsHtml } = this.props.trip.property;

    if (!instructionsAt) {
      return <></>;
    }

    return (
      <div className={classes.instructionsContainer}>
        <Typography
          variant="button"
          color="inherit"
          className={classes.checkinoutTitle}
        >
          Check-in Instructions
        </Typography>

        <Typography variant="body1" component="div" gutterBottom>
          <div dangerouslySetInnerHTML={{ __html: instructionsHtml }} />
        </Typography>
      </div>
    );
  };
  getMainMessage = isHost => {
    const status = this.props.trip.status;
    const classes = this.props.classes;
    if (!isHost && status === "Pending") {
      return (
        <>
          <Typography
            variant="button"
            color="inherit"
            className={classes.checkinoutTitle}
          >
            This stay is still pending
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            You can change your requested dates until it is accepted.
          </Typography>
        </>
      );
    }

    if (!isHost && status === "Declined") {
      return (
        <>
          <Typography
            variant="button"
            color="inherit"
            className={classes.checkinoutTitle}
          >
            Declined
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Unfortunately the host declined your request.
          </Typography>
        </>
      );
    }

    if (!isHost && status === "Canceled") {
      return (
        <>
          <Typography
            variant="button"
            color="inherit"
            className={classes.checkinoutTitle}
          >
            Canceled
          </Typography>
          <Typography variant="body1" component="p" gutterBottom>
            Your request was canceled.
          </Typography>
        </>
      );
    }

    return false;
  };
  render() {
    const {
      trip,
      snackbarMessage,
      closeSnackbarMessage,
      isFetching,
      handleTripUpdate,
      classes,
      refetchTrip
    } = this.props;
    const isHost = trip ? trip.property.isHost : false;
    const rules =
      trip && trip.property && trip.property.rulesHtml
        ? cleanHtml(trip.property.rulesHtml)
        : false;
    const property = trip && trip.property;
    const mainMessage = trip && this.getMainMessage(isHost);
    return (
      <Fragment>
        {isFetching && !trip && <Loading />}
        {trip && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <div className={classes.mainInfoContainer}>
                <div className={classes.mainInfoContainerInner}>
                  <div className={classes.leftSideContainer}>
                    <Avatar isProperty src={property.image.url} />
                  </div>
                  <div style={{ width: "100%" }}>
                    <div className={classes.mainInfoSubContainer}>
                      <Link
                        to={`/p/${property.slug}`}
                        className={classes.titleText}
                      >
                        {property.name}
                      </Link>
                      <div className={classes.mainInfoSubElements}>
                        <div>
                          <Typography
                            variant="subtitle1"
                            className={classes.checkinoutTitle}
                          >
                            Check-in Date
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.location}
                          >
                            {moment(trip.startDate).format("dddd, MMMM Do")}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="subtitle1"
                            className={classes.checkinoutTitle}
                          >
                            Check-out Date
                          </Typography>
                          <Typography
                            variant="subtitle1"
                            className={classes.location}
                          >
                            {moment(trip.endDate).format("dddd, MMMM Do")}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="subtitle1"
                            className={classes.checkinoutTitle}
                          >
                            Status
                          </Typography>
                          <StatusTag status={trip.status} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {mainMessage && (
                  <div className={classes.instructionsContainer}>
                    {mainMessage}
                  </div>
                )}

                {this.getInstructions()}
              </div>

              <Paper elevation={4} className={classes.messageContainer}>
                <Typography
                  color="inherit"
                  component="div"
                  className={classes.infoTitleMessages}
                >
                  Messages
                </Typography>
                <SendMessage
                  key={(trip.messages || []).length}
                  handleSendMessage={this.handleSendMessage}
                />
              </Paper>
              <div>
                <MessagesBox
                  messages={trip.messages}
                  hosts={this.getHostIds()}
                />
              </div>
              {/* <PropertyDetailBox property={trip.property} />

              <TripDetailBox
                handleTripUpdate={handleTripUpdate}
                trip={trip}
                isHost={isHost}
              /> */}
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper elevation={4} className={classes.detailsContainer}>
                <h2 className={classes.titleDetails}>
                  Details about the request
                </h2>
                {isHost && <HostedBy noBorder isRequest user={trip.user} />}
                {!isHost && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="subtitle1" className={classes.hosts}>
                      Hosts:{" "}
                    </Typography>
                    <HostsNew isSmall hosts={trip.property.hosts} />
                  </div>
                )}
                <div className={classes.containerDetailsMiddle}>
                  <TripNightsAdultsChildren
                    nights={getNights(trip.startDate, trip.endDate)}
                    adults={trip.adults || 0}
                    children={trip.children || 0}
                  />
                  <div style={{ marginTop: 24, marginBottom: 24 }}>
                    <PaymentSummary trip={trip} />
                  </div>

                  <TripDetailAction
                    className={classes.actions}
                    handleTripUpdate={handleTripUpdate}
                    handlePayTrip={() => this.setState({ openPay: true })}
                    trip={trip}
                    isHost={isHost}
                  />
                  {trip.property.image ? (
                    <div
                      className={classes.image}
                      style={{
                        backgroundImage: `url(${trip.property.image &&
                          trip.property.image.url})`
                      }}
                    />
                  ) : (
                    <HomeIcon className={classes.icon} />
                  )}
                  <PropertySBB
                    sleeps={trip.property.sleeps}
                    bathrooms={trip.property.bathrooms}
                    bedrooms={trip.property.bedrooms}
                  />
                </div>
                <div>{this.getMap()}</div>
                <div className={classes.containerExpandning}>
                  {rules && (
                    <PropertyExtensionPanel name={"House rules"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: rules
                        }}
                      ></div>
                    </PropertyExtensionPanel>
                  )}
                  {this.canUserChangeDates(isHost) && (
                    <ExtensionPanel name="Change Dates">
                      <TripDetailChangeDates
                        handleTripUpdate={handleTripUpdate}
                        trip={trip}
                        isHost={isHost}
                      />
                    </ExtensionPanel>
                  )}
                </div>
                {/* <SendMessage handleSendMessage={this.handleSendMessage} />
              <MessagesBox messages={trip.messages} /> */}
              </Paper>
            </Grid>
          </Grid>
        )}
        {this.state.openPay && (
          <PayTripDialog
            closeDialog={() => this.setState({ openPay: false })}
            finishDialog={() => {
              refetchTrip();
              this.setState({ openPay: false });
            }}
            tripId={this.props.trip && this.props.trip.id}
            open={this.state.openPay || false}
          />
        )}
        <SnackbarMessage
          allowUpdates={true}
          message={snackbarMessage}
          onClose={closeSnackbarMessage}
        />
      </Fragment>
    );
  }
}

export default withRoot(withStyles(styles)(TripDetail));
