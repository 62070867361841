import { countries } from "../data/countries";
import moment from "moment";
const qs = require("qs");

// import { checkServerIdentity } from "tls";
export function capitlizeString(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function buildLocation(suggest) {
  // Create a location object
  const location = {};

  if (suggest.place_id) {
    location.id = 0;
    location.placeId = suggest.place_id;
    location.label = suggest.formatted_address;
    location.latitude = suggest.geometry.location.lat();
    location.longitude = suggest.geometry.location.lng();
    location.formattedAddress = suggest.formatted_address;
    location.locationType =
      suggest.types && suggest.types.length > 0 ? suggest.types.join(", ") : "";

    if (suggest.address_components) {
      for (let i = 0; i < suggest.address_components.length; i++) {
        for (let j = 0; j < suggest.address_components[i].types.length; j++) {
          switch (suggest.address_components[i].types[j]) {
            case "establishment":
              location.establishmentName =
                suggest.address_components[i].short_name;
              break;
            case "point_of_interest":
              location.pointOfInterest =
                suggest.address_components[i].short_name;
              break;
            case "street_number":
              location.streetNumber = suggest.address_components[i].short_name;
              break;
            case "route":
              location.route = suggest.address_components[i].short_name;
              break;
            case "premise":
              location.premise = suggest.address_components[i].short_name;
              break;
            case "subpremise":
              location.subpremise = suggest.address_components[i].short_name;
              break;
            case "neighborhood":
              location.neighborhood = suggest.address_components[i].short_name;
              break;
            case "colloquial_area":
              location.colloquialArea =
                suggest.address_components[i].short_name;
              break;
            case "natural_feature":
              location.naturalFeature =
                suggest.address_components[i].short_name;
              break;
            case "locality":
              location.locality = suggest.address_components[i].long_name;
              break;
            case "sublocality":
              location.sublocality = suggest.address_components[i].short_name;
              break;
            case "sublocality_level_1":
              location.sublocalityLevel1 =
                suggest.address_components[i].short_name;
              break;
            case "sublocality_level_2":
              location.sublocalityLevel2 =
                suggest.address_components[i].short_name;
              break;
            case "sublocality_level_3":
              location.sublocalityLevel3 =
                suggest.address_components[i].short_name;
              break;
            case "administrative_area_level_1":
              location.administrativeAreaLevel1 =
                suggest.address_components[i].short_name;
              break;
            case "administrative_area_level_2":
              location.administrativeAreaLevel2 =
                suggest.address_components[i].short_name;
              break;
            case "administrative_area_level_3":
              location.administrativeAreaLevel3 =
                suggest.address_components[i].short_name;
              break;
            case "postal_code":
              location.postalCode = suggest.address_components[i].short_name;
              break;
            case "postal_code_suffix":
              location.postalCodeSuffix =
                suggest.address_components[i].short_name;
              break;
            case "country":
              location.country = suggest.address_components[i].short_name;
              break;
            // no default
          }
        }
      }
    }
  }
  return location;
}

export function buildStreetAddress(location) {
  let streetAddress = "";
  if (location) {
    if (location.streetNumber) {
      streetAddress = `${location.streetNumber} `;
    }
    if (location.route) {
      streetAddress += location.route;
    }
  }

  return streetAddress;
}

export function findCountryByIso2(iso2) {
  let country =
    (iso2 && countries.find(country => country.iso2 === iso2)) || null;
  return country || null;
}

function geocode(address, cb) {
  const google = window.google;
  const geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address: address }, function(results, status) {
    if (status === "OK") {
      cb(null, results);
    } else {
      cb(status);
    }
  });
}

export function geoLocation(address) {
  return new Promise((resolve, reject) => {
    geocode(address, (err, data) => {
      if (err || !data) {
        resolve(undefined);
        return;
      }
      const result = data[0];
      resolve(buildLocation(result));
    });
  });
}

export function getErrorMessage(response, errorMessage = "") {
  let errors = null;
  if (response) {
    errors =
      response.errors ||
      (response.data && response.data.errors) ||
      (response.response &&
        response.response.data &&
        response.response.data.errors) ||
      response ||
      null;
  }
  if (errors) {
    // only return the first error message
    if (errors[0] && errors[0]["detail"]) {
      errorMessage = errors[0]["detail"];
    } else if (errors[0] && errors[0]["title"]) {
      errorMessage = errors[0]["title"];
    } else if (typeof errors === "string") {
      errorMessage = errors;
    }
  }
  return errorMessage;
}

export function buildAddress(address) {
  if (!address) {
    return null;
  }
  return `${address.address1}, ${address.postalCode} ${address.city}, ${address.region}, ${address.country}`;
}

export function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export function getNights(startDate, endDate) {
  var start = moment(startDate);
  var end = moment(endDate);
  return end.diff(start, "days");
}
export function formatDateWithMonthAndTime(date) {
  return moment(date).format("MMM DD, YYYY - HH:mm A");
}
export function formatDate(date) {
  return moment(date).format("MMM DD, YYYY");
}

export function formatTimeAndDate(date) {
  return moment(date).format("HH:mm A, MMM DD, YYYY");
}

export const getPropertyPath = slug => {
  return `/p/${slug}`;
};

export const getPropertyUrl = slug => {
  const path = getPropertyPath(slug);
  return `${process.env.REACT_APP_VRBFF_URL}${path}`;
};

export function getURLParams(url) {
  const queryParams = url.split("?")[1];
  const params = qs.parse(queryParams);
  return params;
}

export function cleanHtml(text) {
  if (!text) return false;
  text = text.replace(/^<p>([\s\S]*?)<\/p>$/gm, "$1");

  if (!text || text.length === 0) return false;

  return text;
}
export const isValidEmail = email => {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

var o = {
  second: 1000,
  minute: 60 * 1000,
  hour: 60 * 1000 * 60,
  day: 24 * 60 * 1000 * 60,
  week: 7 * 24 * 60 * 1000 * 60,
  Month: 30 * 24 * 60 * 1000 * 60,
  year: 365 * 24 * 60 * 1000 * 60
};
export const timeAgo = (nd, s) => {
  let r = Math.round,
    dir = " ago",
    pl = function(v, n) {
      return s === undefined
        ? n + " " + v + (n > 1 ? "s" : "") + dir
        : n + v.substring(0, 1);
    },
    ts = Date.now() - new Date(nd).getTime(),
    ii;
  if (ts < 0) {
    ts *= -1;
    dir = " from now";
  }
  let i;
  for (i in o) {
    if (r(ts) < o[i]) return pl(ii || "m", r(ts / (o[ii] || 1)));
    ii = i;
  }
  return pl(i, r(ts / o[i]));
};
