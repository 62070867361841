import React from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import AutosuggestPlaces from "./AutosuggestPlaces";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginLeft: theme.spacing(1),
    left: 0,
    right: 0,
    width: "100%"
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  error: {
    color: theme.palette.error.main,
    width: "100%"
  },
  input: {
    width: "100%"
  },
  textField: {
    margin: theme.spacing(1)
  }
});

function renderInput(inputProps) {
  const {
    autoFocus,
    classes,
    dataCy,
    disabled,
    error,
    inputClass,
    label,
    margin,
    placeholder,
    ref,
    ...other
  } = inputProps;

  return (
    <TextField
      variant={"outlined"}
      autoFocus={autoFocus}
      disabled={disabled}
      error={error}
      placeholder={placeholder}
      label={label}
      margin={margin}
      fullWidth
      className={classNames(classes.textField, inputClass)}
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input
        },
        inputProps: {
          "data-cy": dataCy || "locationSearch"
        },
        ...other
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.description, query);
  const parts = parse(suggestion.description, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square style={{ zIndex: 2 }}>
      {children}
    </Paper>
  );
}

function renderEmpty(options) {
  return false;
}

class LocationSearchField extends React.Component {
  render() {
    const {
      autoFocus,
      classes,
      disabled,
      error,
      initialValue,
      inputClass,
      label,
      placeholder,
      onChange,
      onSelect
    } = this.props;

    return (
      <AutosuggestPlaces
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        renderSuggestionsContainer={
          disabled ? renderEmpty : renderSuggestionsContainer
        }
        renderSuggestion={renderSuggestion}
        inputProps={{
          autoFocus,
          classes,
          disabled,
          error,
          inputClass,
          label,
          placeholder,
          value: initialValue,
          shrink: "true"
        }}
        onChange={onChange}
        onSelect={onSelect}
      />
    );
  }
}

export default withStyles(styles, { name: "LocationSearchField" })(
  LocationSearchField
);
