import React from "react";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
const styles = theme => ({
  baseTag: {
    textTransform: "capitalize",
    color: theme.palette.text.main,
    fontWeight: "bold",
    fontSize: 18,
    lineHeight: "16px",
    boxShadow:
      "rgba(50, 50, 93, 0.14902) 0px 0px 3px, rgba(0, 0, 0, 0.1196078) 0px 5px 5px",
    borderRadius: 4,
    padding: "8px 16px",
    display: "inline-block"
  },

  pending: {
    backgroundColor: "#F3BE60"
  },
  canceled: {
    backgroundColor: theme.palette.secondary.danger
  },
  accepted: {
    backgroundColor: "#C5E4B8"
  },
  payment_completed: {
    backgroundColor: "#C5E4B8"
  },
  payment_error: {
    backgroundColor: theme.palette.secondary.danger
  },
  declined: {
    backgroundColor: theme.palette.secondary.danger
  }
});

const StatusTag = ({ classes, status }) => (
  <span className={classNames(classes.baseTag, classes[status.toLowerCase()])}>
    {status.replace("_", " ")}
  </span>
);

export default withStyles(styles, { name: "HostedBy" })(StatusTag);
