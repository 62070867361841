// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import common from "@material-ui/core/colors/common";
import SaveIcon from "@material-ui/icons/Save";
import withRoot from "../../withRoot";

import Message from "../Message/Message";
import ProgressButton from "../Progress/ProgressButton";
const { white } = common;

const styles = theme => ({
  button: {
    color: white,
    margin: theme.spacing(1)
  },
  iconButton: {
    marginRight: 8
  }
});

const ResponsiveDialog = withMobileDialog({ breakpoint: "xs" })(Dialog);

class EditDialog extends Component {
  render() {
    const {
      classes,
      closeDialog,
      errorMessage,
      isSaving,
      onSave,
      open,
      resource,
      children,
      title
    } = this.props;

    return (
      <div>
        {resource && (
          <ResponsiveDialog open={open} onClose={closeDialog}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              <Message type="danger" message={errorMessage} />
              {children}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                {"Cancel"}
              </Button>
              <ProgressButton
                className={classes.button}
                label="Save"
                icon={<SaveIcon className={classes.iconButton} />}
                loading={isSaving}
                onClick={() => onSave(resource.id)}
                color="primary"
              />
            </DialogActions>
          </ResponsiveDialog>
        )}
      </div>
    );
  }
}

EditDialog.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.object,
  onSave: PropTypes.func.isRequired
};

export default withRoot(withStyles(styles, { name: "EditDialog" })(EditDialog));
