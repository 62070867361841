import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ImageFilterHdr from "@material-ui/icons/Add";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";
import classnames from "classnames";
import { buildAddress } from "helpers/utils";

const styles = theme => ({
  labelRadio: {
    borderBottom: "1px solid #dfdfdf",
    paddingTop: 18,
    paddingBottom: 18,
    alignItems: "flex-start",
    opacity: 0.7,
    "&:hover": {
      opacity: 1
    },
    "&:first-child": {
      borderTop: "1px solid #dfdfdf"
    },
    "& span:nth-child(2)": {
      width: "100%"
    }
  },
  selectedRadio: {
    borderBottom: "1px solid #40A5CB",
    borderTopColor: "1px solid #40A5CB",
    borderColor: "#40A5CB",
    opacity: 1,
    boxShadow: "0 -1px 0 0 #40A5CB",
    zIndex: "9",
    "&:first-of-type": {
      borderTopColor: "transparent"
    }
  },
  radio: {
    paddingTop: 4,
    paddingRight: 18,
    "&:hover": {
      backgroundColor: "transparent !important"
    }
  },
  propertyRadioLabel: {
    display: "flex",
    justifyContent: "space-between"
  },
  propertyImage: {
    maxWidth: 150,
    maxHeight: 150,
    opacity: 0.5
  },
  propertyImageSelected: {
    maxWidth: 150,
    maxHeight: 150
  },

  followButtonContainer: {
    marginTop: 30,
    marginBottom: 20
  },
  propertyNameContainer: {
    backgroundColor: "#transparent",
    padding: "12px 18px",
    marginTop: -25,
    alignItems: "center",
    display: "flex",
    flexDirection: "column"
  },
  propertyPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "28px 0"
  }
});

class PropertiesStep extends Component {
  async continueCheck() {
    return new Promise(async resolve => {
      const { data } = this.props;

      if (data && data.propertyId) return resolve(true);
      this.props.snackbarMessage({
        message: "You have to select a property",
        type: "warning"
      });
      resolve(false);
    });
  }

  loadCreateProperty() {
    const { classes } = this.props;
    return (
      <div className={classes.propertyPreviewContainer}>
        <ImageFilterHdr
          size={100}
          style={{
            fontSize: 100,
            paddingLeft: 5,
            paddingRight: 5,
            margin: "30px 0",
            color: "#bbb"
          }}
        ></ImageFilterHdr>

        <div className={classes.propertyNameContainer}>
          <Typography variant="h6">Add a new property</Typography>
          <Typography variant="caption" style={{ textAlign: "center" }}>
            {
              "If you would like to add a new property, you can do so and then continue to invite guests"
            }
          </Typography>
        </div>
        <div className={classes.followButtonContainer}>
          <SecondaryActionButton to={"/add-property"}>
            Add new property
          </SecondaryActionButton>
        </div>
      </div>
    );
  }

  propertyRadio = (property, selectedValue) => {
    const { classes } = this.props;
    const isSelected = selectedValue === property.id;
    const image = property.image ? property.image : false;
    return (
      <FormControlLabel
        key={property.id}
        className={
          isSelected
            ? classnames(classes.labelRadio, classes.selectedRadio)
            : classes.labelRadio
        }
        value={property.id}
        control={<Radio className={classes.radio} />}
        label={
          <div className={classes.propertyRadioLabel}>
            <div>
              <Typography variant="h6">{property.name}</Typography>
              <Typography
                variant="caption"
                style={{ fontSize: "0.65rem" }}
                component="div"
              >
                {buildAddress(property.address)}
              </Typography>
            </div>
            {image && (
              <img
                src={image.url}
                className={
                  isSelected
                    ? classes.propertyImageSelected
                    : classes.propertyImage
                }
                alt={"property"}
              />
            )}
          </div>
        }
      />
    );
  };
  render() {
    const { properties, data } = this.props;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">
              {"Select your property"}
            </Typography>
            <Typography variant="caption" component="div">
              {"Choose a property to invite guests to from your properties"}
            </Typography>
            <RadioGroup
              style={{ marginTop: 24 }}
              aria-label="property"
              name="property"
              //   className={classes.group}
              value={data.propertyId}
              onChange={event => {
                this.props.change("propertyId", event.target.value);
              }}
            >
              {properties.map(p => this.propertyRadio(p, data.propertyId))}
            </RadioGroup>
          </div>
        }
        SecondComponent={
          <div>
            <Typography variant="subtitle1">
              {"Or, add a new propery, then continue"}
            </Typography>
            <Typography variant="caption" component="div">
              {
                "You can also have add a new property at this time, and then be redirected here"
              }
            </Typography>

            {this.loadCreateProperty()}
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

PropertiesStep = withStyles(styles, { name: "PropertiesStep" })(PropertiesStep);

export default PropertiesStep;
