import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    height: 48,
    justifyContent: "left",
    textTransform: "none",
    fontSize: "16px",
    paddingLeft: 32,
    fontWeight: 400,
    borderRadius: 4,
    borderColor: theme.palette.secondary.border
  },
  listItem: {
    paddingBottom: 0,
    paddingTop: 0
  },
  paper: {
    width: 286
  },
  totals: {
    padding: 10
  },
  typography: {
    padding: theme.spacing(2)
  }
}));

const SelectGuests = props => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function getGuestTitle() {
    const guestTotal = props.adults + props.children;
    let title = guestTotal;
    if (guestTotal === 1) {
      title += " guest";
    } else {
      title += " guests";
    }
    return title;
  }

  function getAdlutsChildrenTitle() {
    let title = props.adults;
    if (props.adults === 1) {
      title += " adult";
    } else {
      title += " adults";
    }

    if (props.children && props.children > 0) {
      title += ", " + props.children;
      if (props.children === 1) title += " child";
      else title += " children";
    }

    return title;
  }

  const open = Boolean(anchorEl);
  const id = open ? "select-guests" : null;

  return (
    <div className={classes.root}>
      <ListItem className={classes.listItem} disableGutters>
        <Button
          disabled={props.disabled}
          fullWidth
          variant="outlined"
          className={classes.button}
          disableRipple={true}
          onClick={handleClick}
        >
          <span className={classes.title}>
            {props.fullTitle ? getAdlutsChildrenTitle() : getGuestTitle()}
          </span>
          <ListItemSecondaryAction>
            {open && <KeyboardArrowUpIcon className={classes.icon} />}
            {!open && <KeyboardArrowDownIcon className={classes.icon} />}
          </ListItemSecondaryAction>
        </Button>
      </ListItem>
      <Popover
        classes={{ paper: classes.paper }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <List>
          <ListItem>
            <ListItemText primary="Adults" />
            <Button
              disabled={props.adults === 1}
              variant="outlined"
              size="small"
              color="primary"
              aria-label="Remove"
              className={classes.margin}
              onClick={props.handleRemoveAdults}
            >
              <RemoveIcon />
            </Button>
            <Typography variant="body1" className={classes.totals}>
              {props.adults}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              aria-label="Add"
              className={classes.margin}
              onClick={props.handleAddAdults}
            >
              <AddIcon />
            </Button>
          </ListItem>
          <ListItem>
            <ListItemText primary="Children" secondary="Ages 2-12" />
            <Button
              disabled={props.children === 0}
              variant="outlined"
              size="small"
              color="primary"
              aria-label="remove"
              className={classes.margin}
              onClick={props.handleRemoveChildren}
            >
              <RemoveIcon />
            </Button>
            <Typography variant="body1" className={classes.totals}>
              {props.children}
            </Typography>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              aria-label="Add"
              className={classes.margin}
              onClick={props.handleAddChildren}
            >
              <AddIcon />
            </Button>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default SelectGuests;
