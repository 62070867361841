import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActionCreators from "../../redux/modules/settings";
import Settings from "../../components/Settings/Settings";

class SettingsContainer extends Component {
  componentDidMount() {
    this.props.fetchSettings();
  }
  render() {
    return (
      <Settings
        auth={this.props.auth}
        hasFetched={this.props.hasFetched}
        isFetching={this.props.isFetching}
        settings={this.props.settings}
        updateSetting={this.props.settingUpdate}
      />
    );
  }
}

function mapStateToProps({ settings }, props) {
  return {
    hasFetched: settings.hasFetched,
    isFetching: settings.isFetching,
    settings: settings.ids.map(i => settings[i])
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...settingsActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsContainer);
