import React from "react";
import Divider from "@material-ui/core/Divider";

import { withStyles } from "@material-ui/core/styles";
import scrollIcon from "../../static/assets/icons/scroll-icon.svg";

export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    paddingBottom: 28,
    paddingTop: 28,
    paddingLeft: 200,
    paddingRight: 200
  },
  dividerBox: {
    alignItems: "center",
    verticalAlign: "middle"
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    width: 80,
    height: 2
  },
  scrollContainer: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: "right"
  },
  scrollText: {
    fontSize: 16,
    paddingRight: 17,
    paddingLeft: 24
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 64
    }
  }
});

const ScrollMore = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.scrollContainer}>
        <div className={classes.dividerBox}>
          <Divider className={classes.divider} />
        </div>
        <div className={classes.scrollText}>Scroll to learn more</div>
        <img
          src={scrollIcon}
          alt="scroll icon"
          className={classes.iconScroll}
        />
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "ScrollMore" })(ScrollMore);
