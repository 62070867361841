import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Avatar from "components/General/Avatar";

const StyledListItemAvatar = withStyles(theme => ({
  root: {
    minWidth: 32,
    flexShrink: 0,
    alignSelf: "flex-start",
    paddingTop: 8
  }
}))(ListItemAvatar);

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingLeft: 40,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 40,
    borderBottom: "1px solid " + theme.palette.secondary.border
  },
  placeholderIcon: {
    color: grey[400],
    fontSize: "50px"
  },
  avatarContainer: {
    alignSelf: "flex-start"
  },
  link: {
    fontWeight: 700,
    color: theme.palette.primary.link,
    fontSize: 14
  },
  extraText: {
    fontWeight: 400,
    color: theme.palette.text.main,
    fontSize: 14
  },
  time: {
    fontWeight: 400,
    color: theme.palette.primary.light,
    paddingLeft: 6,
    fontSize: 14
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 10,
    color: theme.palette.text.main
  },
  propertyImage: {
    width: 60,
    height: 40,
    marginLeft: 14,
    borderRadius: 4,
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundClip: "border-box",
    alignSelf: "flex-start"
  }
}));

const FeedItem = ({ data, onClick, to, noName, title, text }) => {
  const classes = useStyles();

  return (
    <ListItem className={classes.listItem} key={data.id}>
      <StyledListItemAvatar classes={{ root: classes.avatar }}>
        <>
          {data.user && (
            <Avatar
              isSmall
              firstName={data.user.firstName}
              lastName={data.user.lastName}
              src={data.user.picture}
            />
          )}
        </>
      </StyledListItemAvatar>

      <ListItemText
        primary={
          <>
            {!noName && (
              <Link
                to={`/users/${data.user.username}`}
                className={classes.link}
              >
                {data.user.firstName} {data.user.lastName}
              </Link>
            )}
            <span className={classes.extraText}>
              {!noName && ` `}
              {title}
            </span>
            {/* <span className={classes.time}>12h</span> */}
          </>
        }
        //secondary={<div className={classes.secondaryText}>{text}</div>}
      />
      {data.property && data.property.image && (
        <div
          className={classes.propertyImage}
          style={{
            backgroundImage: "url('" + data.property.image.url + "')"
          }}
        />
      )}
    </ListItem>
  );
};

export default FeedItem;
