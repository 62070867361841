import { apiRequest } from "apis/jsonApi";
import Resource from "./resource";

let resource = new Resource({
  singularName: "booking",
  pluralName: "bookings"
});

export function fetchBookings(propertyId) {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(propertyId ? `bookings/${propertyId}` : `bookings`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export default resource.reducer;
