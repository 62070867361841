import React, { Component } from "react";
import {
  Typography,
  withStyles,
  Input,
  InputAdornment,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import EmailIcon from "@material-ui/icons/Email";
import { getErrorMessage, isValidEmail } from "helpers/utils";
import ProgressButton from "components/Progress/ProgressButton";
import { apiRequest, rpcRequest } from "apis/jsonApi";
import Loading from "components/Progress/Loading";
import Avatar from "components/General/Avatar";

const styles = theme => ({
  guests: {
    marginTop: theme.spacing(3)
  },
  emailInput: {
    color: "#00000044",
    marginRight: theme.spacing(2),
    "&.Mui-focused": {
      color: "#40A5CB"
    }
  },
  editorContainer: { marginTop: 20 }
});

class PropertyHosts extends Component {
  state = {
    email: "",
    hosts: []
  };

  closeDeleteProperty = event => {
    this.setState({
      deleteDialog: false
    });
  };

  async componentDidMount() {
    this.loadHosts();
  }

  async loadHosts() {
    try {
      this.setState({ isLoading: true });
      const hosts = await apiRequest(
        `properties/${this.props.propertyId}/hosts`,
        "GET"
      );
      this.setState({ hosts: hosts.data || [], isLoading: false });
    } catch (error) {
      console.log("error loading hosts", error);
      this.setState({ isLoading: false });
    }
  }
  inviteClick = async () => {
    if (!isValidEmail(this.state.email)) {
      return this.setState({ emailError: "Email not valid" });
    }
    try {
      const response = await rpcRequest("add-host", {
        property_id: this.props.propertyId,
        email: this.state.email
      });
      let data = response.data;

      this.props.appSnackbarMessage({
        message: data.is_user
          ? `${data.name} was added as an host of your property.`
          : `We have sent an invite to ${data.email}.`
      });
      this.setState({ email: "" });
      this.loadHosts();
    } catch (error) {
      console.log("error inviting", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem inviting an host to the property."
      );

      this.props.appSnackbarMessage({
        message: errorMessage,
        type: "warning"
      });
    }
  };

  render() {
    const { classes } = this.props;
    const { hosts, isLoading } = this.state;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">{"Add a new host"}</Typography>
            <Typography variant="caption" component="div">
              {"You can invite a new host to your property via Email."}
            </Typography>

            <FormControl error={this.state.emailError} fullWidth>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 16 }}>
                  <Input
                    className={classes.emailInput}
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    }
                    value={this.state.email}
                    onChange={event => {
                      this.setState({ email: event.target.value || "" });
                    }}
                  />
                  {this.state.emailError && (
                    <FormHelperText id="component-error-text">
                      It should be an email
                    </FormHelperText>
                  )}
                </div>
                <ProgressButton progress onClick={this.inviteClick}>
                  Invite
                </ProgressButton>
              </div>
            </FormControl>

            <div className={classes.guests}>
              <Typography variant="subtitle1">{"Hosts list"}</Typography>
              <Typography variant="caption" component="div">
                {"List of all the hosts for this property."}
              </Typography>
              {hosts && isLoading === false && hosts.length > 0 && (
                <List>
                  {hosts.map(h => (
                    <ListItem key={`host${h.id}`}>
                      <ListItemAvatar>
                        <Avatar
                          firstName={h.firstName}
                          lastName={h.lastName}
                          src={h.picture}
                        />
                      </ListItemAvatar>
                      <ListItemText primary={h.name} />
                      {/* <ListItemSecondaryAction>
                        <IconButton
                          disabled={isDeleting}
                          edge="end"
                          aria-label="Delete"
                          onClick={() => handleDeleteGuest(id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction> */}
                    </ListItem>
                  ))}
                </List>
              )}
              {hosts && isLoading === false && hosts.length === 0 && (
                <h6>{"No guests"}</h6>
              )}

              {isLoading && <Loading />}
            </div>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators
    },
    dispatch
  );
}

PropertyHosts = withStyles(styles, { name: "PropertyHosts" })(PropertyHosts);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(PropertyHosts);
