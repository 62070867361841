import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {},
  title: {
    fontWeight: 700,
    fontSize: 16,
    marginBottom: 8
  },
  secondary: {
    fontWeight: 400,
    fontSize: 14
  }
});

class WizardFieldName extends Component {
  render() {
    const { classes, title, secondary } = this.props;
    return (
      <div className={classes.container}>
        {title && <div className={classes.title}>{title}</div>}
        {secondary && <div className={classes.secondary}>{secondary}</div>}
      </div>
    );
  }
}

export default withStyles(styles, { name: "WizardFieldName" })(WizardFieldName);
