import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Redirect, Route, Router } from "react-router-dom";
import { Switch } from "react-router";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { Provider, connect } from "react-redux";
import thunk from "redux-thunk";
import LogRocket from "logrocket";

import rootReducer from "./redux/modules";
import history from "./history";
import App from "./components/App/App";
import auth from "./components/Auth/Auth";
import About from "./components/About/About";
import ForgotPassword from "./components/Auth/ForgotPassword/ForgotPassword";
import Home from "./components/Home/Home";
import AdminUsersContainer from "./containers/Admin/AdminUsersContainer";
import CalendarContainer from "./containers/Calendar/CalendarContainer";
import DashboardContainer from "./containers/Dashboard/DashboardContainer";
import InboxDetailContainer from "./containers/Inbox/InboxDetailContainer";
import InviteContainer from "./containers/Invite/InviteContainer";
import ManageContainer from "./containers/Manage/ManageContainer";
import ManageHomeContainer from "./containers/Manage/ManageHomeContainer";
import EditPropertyContainer from "./containers/Properties/ManagePropertyContainer";
import PropertiesContainer from "./containers/Properties/PropertiesContainer";
import SettingsContainer from "./containers/Settings/SettingsContainer";
import ViewPropertyContainer from "./containers/Properties/ViewPropertyContainer";
import StaysPropertyContainer from "containers/Stays/StaysPropertyContainer";
import PropertyGuestsContainer from "containers/Properties/PropertyGuestsContainer";
import FollowRequestsContainer from "containers/Properties/FollowRequestsContainer";
import PropertyEditContainer from "containers/Properties/PropertyEditContainer";
import PropertyImagesContainer from "containers/Properties/PropertyImagesContainer";
import AddPropertyContainer from "containers/Properties/AddPropertyContainer";
import InviteWizardContainer from "containers/InviteWizard/InviteWizardContainer";
import BillOfRights from "./components/BillOfRights/BillOfRights";
import Learn from "./components/Learn/Learn";
import Login from "./components/Login/Login";
import Pricing from "./components/Pricing/Pricing";
import Privacy from "components/Privacy/Privacy";
import ResetPassword from "./components/Auth/ResetPassword/ResetPassword";
import SignUp from "./components/SignUp/SignUp";
import Terms from "components/Terms/Terms";
import Snackbar from "containers/Components/Snackbar";
import ManagePropertyContainer from "containers/Manage/ManagePropertyContainer";
import NoMatch from "components/404/NoMatch";
import AccountContainer from "containers/Account/AccountContainer";
import { StripeProvider } from "react-stripe-elements";
import StripeConnectHandlerContainer from "containers/Stripe/StripeConnectHandlerContainer";
import StaysAndRequestsContainer from "containers/Inbox/StaysAndRequestsContainer";
import User from "containers/Users/User";
import { getURLParams } from "helpers/utils";
import ConfirmEmail from "components/ConfirmEmail/ConfirmEmail";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);

const getRedirectUrl = location => {
  return location.pathname || null;
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, LogRocket.reduxMiddleware()))
);

auth.setDispatch(store.dispatch);
auth.setGetState(store.getState);
auth.init();
const HomeRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Home auth={auth} {...props} />
      )
    }
  />
);

const PublicOnlyRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth.isAuthenticated() ? (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      ) : (
        <Component auth={auth} {...props} />
      )
    }
  />
);

const InviteRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (!auth.isAuthenticated()) {
        return <Component {...props} />;
      }
      return <Component {...props} isAuthenticated />;
      // return (
      //   <Redirect
      //     to={{
      //       pathname: "/",
      //       state: { from: props.location }
      //     }}
      //   />
      // );
    }}
  />
);

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isAuthenticated()) {
        return (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        );
      }
      return <Component {...props} />;
    }}
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => <Component auth={auth} {...props} />} />
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isAuthenticated()) {
        return <Component {...props} />;
      }
      const url = getRedirectUrl(props.location);
      localStorage.setItem("redirect_after_login", url);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isAdmin()) {
        return <Component {...props} />;
      }
      const url = getRedirectUrl(props.location);
      localStorage.setItem("redirect_after_login", url);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

const ManagePropertyRoute = ({ component: Component, acceptsAll, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isAuthenticated()) {
        return (
          <ManageContainer {...props} acceptsAll={acceptsAll}>
            <Component {...props} />
          </ManageContainer>
        );
      }
      const url = getRedirectUrl(props.location);
      localStorage.setItem("redirect_after_login", url);
      return (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location }
          }}
        />
      );
    }}
  />
);

// const browserHistory = syncHistoryWithStore(history, store);

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(
      () => trackPage(props.location.pathname + props.location.search),
      [props.location.pathname, props.location.search]
    );

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

const AppRoutes = props => (
  <App auth={auth} {...props}>
    <Switch>
      <HomeRoute
        exact
        path="/"
        component={props => <DashboardContainer auth={auth} {...props} />}
      />
      <PublicOnlyRoute
        path="/confirm-email/:code"
        component={props => <ConfirmEmail auth={auth} {...props} />}
      />
      <PublicOnlyRoute
        path="/confirm-email"
        component={props => <ConfirmEmail auth={auth} {...props} />}
      />
      <InviteRoute
        path="/invite/:code"
        component={props => <InviteContainer auth={auth} {...props} />}
      />
      <LoginRoute
        path="/login"
        component={props => <Login auth={auth} {...props} />}
      />
      <PublicRoute
        path="/about"
        component={props => <About auth={auth} {...props} />}
      />
      <PublicRoute
        path="/users/:username"
        component={props => <User auth={auth} {...props} />}
      />
      <PublicRoute
        path="/forgot"
        component={props => <ForgotPassword auth={auth} {...props} />}
      />
      <PublicRoute
        path="/bor"
        component={props => <BillOfRights auth={auth} {...props} />}
      />
      <PublicRoute
        path="/learn"
        component={props => <Learn auth={auth} {...props} />}
      />
      <PublicRoute
        path="/pricing"
        component={props => <Pricing auth={auth} {...props} />}
      />
      <PublicRoute
        path="/privacy-policy"
        component={props => <Privacy auth={auth} {...props} />}
      />
      <PublicRoute
        path="/reset/:resetCode"
        component={props => <ResetPassword auth={auth} {...props} />}
      />
      <PublicRoute
        path="/signup"
        component={props => {
          const params = getURLParams(props.history.location.search);
          let pr = { ...props };
          if (params.email) {
            pr.initialValues = { email: params.email };
          }
          return <SignUp auth={auth} {...pr} />;
        }}
      />
      <PublicRoute
        path="/terms-of-service"
        component={props => <Terms auth={auth} {...props} />}
      />
      <AdminRoute
        path="/admin/users"
        component={props => <AdminUsersContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/invite-guests/:propertyId?"
        component={props => <InviteWizardContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        path="/add-property/:propertyId"
        component={props => <AddPropertyContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/add-property"
        component={props => <AddPropertyContainer auth={auth} {...props} />}
      />

      <PrivateRoute
        exact
        path="/properties"
        component={props => <PropertiesContainer auth={auth} {...props} />}
      />
      <ManagePropertyRoute
        path="/manage/guests/:id"
        component={props => <PropertyGuestsContainer auth={auth} {...props} />}
      />
      <ManagePropertyRoute
        path="/manage/follows/:id"
        component={props => <FollowRequestsContainer auth={auth} {...props} />}
      />

      <ManagePropertyRoute
        path="/manage/images/:id"
        component={props => <PropertyImagesContainer auth={auth} {...props} />}
      />
      <ManagePropertyRoute
        path="/manage/property/:name/:id"
        component={props => <ManagePropertyContainer auth={auth} {...props} />}
      />
      <ManagePropertyRoute
        path="/manage/edit/:id"
        component={props => <PropertyEditContainer auth={auth} {...props} />}
      />
      <ManagePropertyRoute
        path="/manage/calendar/:id"
        acceptsAll
        component={props => <CalendarContainer auth={auth} {...props} />}
      />
      <ManagePropertyRoute
        path="/manage/stays/:id/:stayId"
        acceptsAll
        component={props => (
          <InboxDetailContainer isManage auth={auth} {...props} />
        )}
      />
      <ManagePropertyRoute
        path="/manage/stays/:id"
        acceptsAll
        component={props => (
          <StaysPropertyContainer isManage auth={auth} {...props} />
        )}
      />
      <ManagePropertyRoute
        path="/manage"
        component={props => <ManageHomeContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        path="/properties/manage/:id"
        component={props => <EditPropertyContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/staysandrequests"
        component={props => (
          <StaysAndRequestsContainer auth={auth} {...props} />
        )}
      />
      <PrivateRoute
        exact
        path="/inbox"
        component={props => (
          <StaysAndRequestsContainer auth={auth} {...props} />
        )}
      />
      <PrivateRoute
        exact
        path="/inbox/:stayId"
        component={props => <InboxDetailContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/settings"
        component={props => <SettingsContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/account"
        component={props => <AccountContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/calendar"
        component={props => <CalendarContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/p/:slug"
        component={props => <ViewPropertyContainer auth={auth} {...props} />}
      />
      <PrivateRoute
        exact
        path="/stripe/connect"
        component={props => (
          <StripeConnectHandlerContainer auth={auth} {...props} />
        )}
      />
      <Route component={NoMatch} />
    </Switch>
    <Snackbar />
  </App>
);

const MainApp = connect(({ app }, props) => {
  return {
    loaded: app.loaded
  };
}, {})(({ loaded }) => {
  if (loaded) return <Route path="/" component={withTracker(AppRoutes)} />;

  return <></>;
});

// const MainApp =
export const makeMainRoutes = () => {
  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC}>
      <Provider store={store}>
        <Router history={history}>
          <MainApp />
        </Router>
      </Provider>
    </StripeProvider>
  );
};
