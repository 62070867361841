import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  progress: {
    marginTop: theme.spacing(1)
  }
});

class InputField extends Component {
  autoFocusEvent = e => {
    if (this.props.moveCaretToEnd) {
      this.moveCaretToEndEvent(e);
    }

    if (this.props.selectInput) {
      this.selectInputEvent(e);
    }
  };

  selectInputEvent = e => {
    e.target.select();
  };

  render() {
    const {
      autoFocus,
      classes,
      formControlClass,
      helperText,
      input,

      disabled,
      onKeyDown,
      onKeyUp,
      label,
      margin,
      type,
      placeholder,
      meta: { asyncValidating, touched, error },
      inputprops,
      variant
    } = this.props;
    const Component = variant === "outlined" ? OutlinedInput : Input;
    return (
      <FormControl
        className={formControlClass}
        error={touched && error ? true : false}
        margin={margin}
        fullWidth
      >
        <InputLabel htmlFor={input.name} variant={variant}>
          {label}
        </InputLabel>
        <Component
          label={variant === "outlined" ? label : false}
          {...input}
          autoFocus={autoFocus}
          id={input.name}
          onFocus={autoFocus && this.autoFocusEvent}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          // style={inputStyle}
          InputProps={inputprops}
          variant={variant}
          style={{ padding: "2px 12px" }}
        />
        {asyncValidating && (
          <div className={classes.progress}>
            <CircularProgress color="secondary" size={12} />
          </div>
        )}
        {touched && error && <FormHelperText>{error}</FormHelperText>}
        {!(touched && error) && helperText && (
          <FormHelperText>{helperText}</FormHelperText>
        )}
      </FormControl>
    );
  }
}

export default withStyles(styles, { name: "InputField" })(InputField);
