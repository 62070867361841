export const amenitiesConfig = {
  WIFI: {
    name: "Wifi",
    icon: "wifi"
  },
  AIR_CONDITIONING: {
    name: "Air conditioning",
    icon: "ac_unit"
  },
  HAIRDRYER: {
    name: "Hairdryer"
  },
  HANGERS: {
    name: "Hangers"
  },
  PATIO: {
    name: "Patio"
  },
  BALCONY: {
    name: "Balcony"
  },
  REFRIGERATOR: {
    name: "Refrigerator"
  },
  DISHWASHER: {
    name: "Dishwasher"
    // icon: "dishwasher"
  },
  MICROWAVE: {
    name: "Microwave"
  },
  IRON: {
    name: "Iron"
  },
  WASHER: {
    name: "Washer"
  },
  DRYER: {
    name: "Dryer"
  },
  INDOOR_FIREPLACE: {
    name: "Indoor fireplace",
    icon: "fireplace"
  },
  TV: { name: "TV", quantities: [1, 2, 3, 4, 5], icon: "tv" },
  FIRST_AID_KIT: {
    name: "First Aid Kit",
    icon: "local_hospital"
  },
  SMOKE_DETECTOR: {
    name: "Smoke detector"
    // icon: "smoke_detector"
  },
  ESSENTIALS: {
    name: "Essentials"
  },
  GYM: {
    name: "Gym",
    defaultNote: "Free, in the building or nearby",
    icon: "fitness_center"
  },
  HOT_TUB: {
    name: "Hot tub",
    icon: "hot_tub"
  },
  PRIVATE_POOL: {
    name: "Private pool",
    icon: "pool"
  },
  SHARED_POOL: {
    name: "Shared pool",
    icon: "pool"
  },
  BEACH_ACCESS: {
    name: "Beach access",
    defaultNote: "Right out of the back of the house",
    icon: "beach_access"
  },
  BEACH_ESSENTIALS: {
    name: "Beach essentials",
    defaultNote: "Umbrellas, chairs, kids toys"
  },
  PARKING: {
    name: "Parking",
    defaultNote: "Free on-site parking",
    icon: "local_parking"
  },
  GUEST_ACCESS: {
    name: "Guest access",
    defaultNote: "Keys in lockbox"
  },
  NOT_INCLUDED: {
    name: "Not included",
    defaultNote: "Private entrance"
  }
};
