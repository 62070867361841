import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertiesActionCreators from "../../redux/modules/properties";
import { withStyles } from "@material-ui/core/styles";
import PropertyCard from "../../components/Properties/PropertyCard";
import { Grid } from "@material-ui/core";
import PropertyManageLayout from "components/Properties/PropertyManageLayout";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  sectionTitle: {
    marginTop: theme.spacing(2),
    fontWeight: 300,
    fontSize: "18px"
  },
  gridItem: {
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0",
      marginBottom: "24px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "16px",
      width: "50%"
    },
    [theme.breakpoints.up("md")]: {
      width: 300
    }
  },
  buttonsContainer: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    }
  },
  inviteButton: {
    marginRight: 12
  }
});

class ManageContainer extends Component {
  async componentDidMount() {
    await this.props.fetchProperties();
  }

  getMyProperties = () => {
    const { classes, properties } = this.props;
    const newPropertyCard = (
      <>
        <h3 className={classes.sectionTitle}>Add your first property!</h3>
        <Grid className={classes.gridItem} item>
          <PropertyCard addPropertyCard={true} />
        </Grid>
      </>
    );
    if (properties.ids.length === 0) {
      return newPropertyCard;
    }
    if (properties.ids.length > 0) {
      var myproperties = [];
      properties.ids.map(id => {
        const property = properties[id];
        if (property.isHost) {
          myproperties.push(property);
        }
        return myproperties;
      });

      if (myproperties.length > 0) {
        return (
          <>
            <Grid container>
              {myproperties.map(property => (
                <Grid
                  className={classes.gridItem}
                  item
                  key={`my${property.id}`}
                >
                  <PropertyCard
                    property={property}
                    justLink={
                      property.active
                        ? "/manage/calendar/" + property.id
                        : "/add-property/" + property.id
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </>
        );
      } else return newPropertyCard;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <PropertyManageLayout
        buttons={
          <div className={classes.buttonsContainer}>
            <MainActionButton
              className={classes.inviteButton}
              to={"/invite-guests"}
            >
              {"Invite Guests"}
            </MainActionButton>
            <MainActionButton to={"/add-property"}>
              {"Add Property"}
            </MainActionButton>
          </div>
        }
        title={"My Properties"}
        Comp={this.getMyProperties}
      />
    );
  }
}

function mapStateToProps({ properties }, props) {
  return {
    hasFetched: properties.hasFetched,
    isFetching: properties.isFetching,
    properties
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators
    },
    dispatch
  );
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManageContainer)
);
