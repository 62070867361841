import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Field, Form, reduxForm } from "redux-form";
import history from "../../../history";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import common from "@material-ui/core/colors/common";
import EmailIcon from "@material-ui/icons/Email";
import withRoot from "../../../withRoot";

import ValidationTextField from "components/Fields/ValidationTextField";
import { isValidEmail } from "helpers/validators";
import Message from "components/Message/Message";
import ProgressButton from "components/Progress/ProgressButton";
import { getErrorMessage } from "helpers/utils";

import grey from "@material-ui/core/colors/grey";
const { white } = common;

const validate = (values, props) => {
  const errors = {};

  // validate email
  if (!isValidEmail(values.email)) {
    errors.email = "Enter a valid email address";
  }

  return errors;
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: white,
    margin: theme.spacing(1),
    height: 48,
    width: 195,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 0,
    "&:hover": {
      backgroundColor: theme.palette.text.main
    }
  },
  cancelButton: {
    marginLeft: 22,
    width: 193,
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none"
  },
  content: {
    fontSize: 16,
    lineHeight: 1.8,
    paddingBottom: 32
  },
  divider: {
    marginTop: 32,
    marginBottom: 32,
    width: "100%",
    height: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center"
  },
  dividerLine: {
    color: grey[600],
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: white,
    textTransform: "uppercase",
    padding: "0 10px"
  },
  fieldContainer: {
    paddingBottom: 24
  },
  field: {
    width: "100%"
  },
  forgotContainer: {
    paddingTop: 32
  },
  forgot: {
    paddingLeft: 0
  },
  formContainer: {
    width: "50%"
  },
  forgotDivider: {
    marginTop: 32,
    marginBottom: 24,
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center"
  },
  formBody: {
    paddingLeft: 200,
    paddingRight: 122
  },
  icon: {
    color: grey[300]
  },
  imageContainer: {
    width: "50%"
  },
  image: {
    maxHeight: 908,
    maxWidth: "100%"
  },
  link: {
    backgroundColor: white,
    borderWidth: 0,
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "underline",
    "&:focus": {
      outline: "none"
    }
  },
  signupContainer: {
    fontSize: 16,
    paddingTop: 32
  },
  title: {
    fontSize: 48,
    fontWeight: "bold",
    marginBottom: 64,
    marginTop: 120
  },
  [theme.breakpoints.down("lg")]: {
    formBody: {
      paddingTop: "10%",
      paddingLeft: "20%",
      paddingRight: "10%"
    }
  },
  [theme.breakpoints.down("md")]: {
    cancelButton: {
      width: 80
    },
    signupContainer: {
      paddingBottom: 32
    },
    title: {
      marginTop: 32
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block"
    },
    button: {
      width: "100%"
    },
    cancelButton: {
      marginLeft: 0,
      paddingTop: 24,
      width: "100%"
    },
    fieldContainer: {
      paddingBottom: 20
    },
    formBody: {
      paddingLeft: 20,
      paddingRight: 20
    },
    formContainer: {
      width: "100%"
    },
    imageContainer: {
      width: "100%"
    },
    signupContainer: {
      paddingBottom: 80
    },
    title: {
      fontSize: 40,
      fontWeight: "bold",
      marginBottom: 48,
      marginTop: 80
    }
  }
});

class ForgotPassword extends Component {
  state = {
    errorMessage: null,
    finished: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCancel = () => {
    history.replace("/");
  };

  handleForgotPassword = async values => {
    try {
      let res = await this.props.auth.forgotPasswordRequest(values);
      if (res === true) {
        this.setState({ finished: true });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        "There was a problem with the request"
      );
      this.setState({ errorMessage });
    }
  };

  handleSignup = () => {
    history.replace("/signup");
  };

  render() {
    const { classes, handleSubmit, submitting } = this.props;

    const { errorMessage, finished } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <div className={classes.formBody}>
            <h3 className={classes.title}>Forgot Password</h3>
            {!finished && (
              <Fragment>
                <div className={classes.content}>
                  Enter the email address associated with your account, and
                  we’ll email you a link to reset your password.
                </div>
                <Form
                  onSubmit={handleSubmit(values => {
                    this.handleForgotPassword(values);
                  })}
                >
                  <Message
                    className={classes.message}
                    type="danger"
                    message={errorMessage}
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <Field
                          component={ValidationTextField}
                          className={classes.field}
                          variant="outlined"
                          type="text"
                          name="email"
                          label="Email Address"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <EmailIcon className={classes.icon} />
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <ProgressButton
                    className={classes.button}
                    label="Send reset link"
                    loading={submitting}
                    type="submit"
                  />
                  <Button
                    className={classes.cancelButton}
                    onClick={() => this.handleCancel()}
                    color="primary"
                  >
                    {"Cancel"}
                  </Button>
                </Form>
              </Fragment>
            )}
            {finished && (
              <div className={classes.content}>
                Please follow the instructions that were sent to your email
                address.
              </div>
            )}
            <div className={classes.signupContainer}>
              Don't have an account?{" "}
              <button
                className={classes.link}
                onClick={() => this.handleSignup()}
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img
            src="/images/login/login-1300.jpg"
            srcSet="/images/login/login-540.jpg 540w,
            /images/login/login-800.jpg 800w,
            /images/login/login-1000.jpg 1000w,
            /images/login/login-1300.jpg 1300w"
            sizes="100vw"
            alt="login"
            className={classes.image}
          />
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

ForgotPassword = reduxForm({
  form: "ForgotPassword",
  validate
})(ForgotPassword);

export default withRoot(withStyles(styles)(ForgotPassword));
