import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import history from "../../history";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Avatar from "components/General/Avatar";
import { timeAgo } from "helpers/utils";
const StyledListItemAvatar = withStyles(theme => ({
  root: {
    minWidth: 32,
    flexShrink: 0,
    alignSelf: "flex-start",
    paddingTop: 8,
    position: "relative"
  }
}))(ListItemAvatar);

const useStyles = makeStyles(theme => ({
  listItem: {
    cursor: "pointer",
    paddingLeft: 40,
    paddingTop: 24,
    paddingBottom: 24,
    paddingRight: 40,
    borderBottom: "1px solid " + theme.palette.secondary.border
  },
  placeholderIcon: {
    color: grey[400],
    fontSize: "50px"
  },
  avatarContainer: {
    alignSelf: "flex-start"
  },

  link: {
    fontWeight: 700,
    color: theme.palette.primary.link,
    fontSize: 14
  },
  extraText: {
    fontWeight: 400,
    color: theme.palette.text.main,
    fontSize: 14
  },
  time: {
    fontWeight: 400,
    color: theme.palette.primary.light,
    paddingLeft: 6,
    fontSize: 14
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: 10,
    color: theme.palette.text.main
  },
  propertyImage: {
    width: 60,
    height: 40,
    marginLeft: 14,
    borderRadius: 4,
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundClip: "border-box",
    alignSelf: "flex-start"
  },
  isNew: {
    backgroundColor: "#ff0000",
    width: 12,
    height: 12,
    borderRadius: 6,
    content: "",
    fontSize: 0,
    border: "2px solid white",
    display: "inline-block",
    position: "absolute",
    top: 3,
    right: 4
  }
}));

const GenericNotification = ({
  data,
  onClick,
  to,
  noName,
  title,
  text,
  isNew
}) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classes.listItem}
      key={data.id}
      onClick={e => {
        if (e.target.tagName !== "A") {
          if (to) history.push(to);
          onClick && onClick();
        }
      }}
    >
      <StyledListItemAvatar classes={{ root: classes.avatar }}>
        <>
          {data.user && (
            <>
              <Avatar
                isSmall
                firstName={data.user.firstName}
                lastName={data.user.lastName}
                src={data.user.picture}
              />
              {isNew && <span className={classes.isNew}></span>}
            </>
          )}
        </>
      </StyledListItemAvatar>

      <ListItemText
        primary={
          <>
            {!noName && (
              <Link
                to={`/users/${data.user.username}`}
                className={classes.link}
              >
                {data.user.firstName} {data.user.lastName}
              </Link>
            )}
            <span className={classes.extraText}>
              {!noName && ` `}
              {title}
            </span>
            <span className={classes.time}>
              {timeAgo(new Date(data.createdAt * 1000), true)}
            </span>
          </>
        }
        secondaryTypographyProps={{ component: "div" }}
        secondary={<div className={classes.secondaryText}>{text}</div>}
      />
      {data.property && data.property.image && (
        <div
          className={classes.propertyImage}
          style={{
            backgroundImage: "url('" + data.property.image.url + "')"
          }}
        />
      )}
    </ListItem>
  );
};

export default GenericNotification;
