import ReactDOM from "react-dom";
import { makeMainRoutes } from "./routes";
import "./index.css";
// import registerServiceWorker from "./registerServiceWorker";
import "react-dates/initialize";

const routes = makeMainRoutes();

ReactDOM.render(routes, document.getElementById("root"));
// registerServiceWorker();
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.ready.then(registration => {
    registration.unregister();
  });
}
