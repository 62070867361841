import React from "react";
import JssProvider from "react-jss/lib/JssProvider";
import {
  MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes
} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import red from "@material-ui/core/colors/red";
import CssBaseline from "@material-ui/core/CssBaseline";
import common from "@material-ui/core/colors/common";
const { white } = common;

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#465172",
      selected: "#192959",
      dark: "#192959",
      vdark: "#1B2A56",
      link: "#445175",
      light: "#919298"
    },
    secondary: {
      white: "#fff",
      selected: "#192959",
      main: "#C5E4B8",
      hover: "#0F0F10",
      border: "#D5D5D5",
      bg: "#F4F4F4",
      danger: "#CA372F"
    },
    default: {
      main: grey[500],
      dark: grey[700]
    },
    grey: {
      light: "#fefefe",
      medium: "#ddd",
      dark: "#555"
    },
    error: {
      light: red.A100,
      main: red.A400,
      dark: red[700],
      vdark: red[900]
    },
    success: {
      main: "#a9bfbf"
    },
    background: {
      default: white,
      dark: "#f8f8f8"
    },
    text: {
      main: "#0F0F10",
      dark: "#182A56"
    }
  },
  typography: {
    fontFamily: "Poppins"
  },
  shadow: {
    light: "rgba(30,30,30,0.1) 0 0 10px 0",
    medium: "rgba(30,30,30,0.25) 0 0 12px 0",
    heavy: "rgba(30,30,30,0.4) 0 0 16px 0"
  },
  overrides: {
    MuiTypography: {
      subtitle1: {
        margin: 0,
        fontSize: 18,
        color: "#333",
        marginBottom: ".5rem"
      },
      caption: {
        fontSize: 14,
        marginBottom: ".5rem",
        color: "#777"
      }
    }
    // MuiOutlinedInput: {
    //   "& $notchedOutline": {
    //     border: "1px solid #D5D5D5"
    //   }
    // }
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    // JssProvider allows customizing the JSS styling solution.
    return (
      <JssProvider>
        {/* MuiThemeProvider makes the theme available down the React tree
          thanks to React context. */}
        <MuiThemeProvider theme={responsiveFontSizes(theme)}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </MuiThemeProvider>
      </JssProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
