import React, { Component } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ExternalLink from "@material-ui/core/Link";
import { withStyles } from "@material-ui/core/styles";

import Divider from "@material-ui/core/Divider";
import Hidden from "@material-ui/core/Hidden";

import AppFooterNavItem from "./AppFooterNavItem";
import FacebookIcon from "components/Icons/FacebookIcon";
import InstagramIcon from "components/Icons/InstagramIcon";
import TwitterIcon from "components/Icons/TwitterIcon";

import footerLogo from "../../static/assets/logo/vrbff-logo-187.svg";
import heartIcon from "../../static/assets/icons/heart-icon.svg";
import common from "@material-ui/core/colors/common";
const { white } = common;

export const styles = theme => ({
  root: {},
  bold: {
    fontWeight: "bold"
  },
  divider: {
    backgroundColor: theme.palette.primary.vdark,
    marginTop: 32,
    marginBottom: 31
  },
  exLink: {
    paddingRight: 20
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "stretch",
    borderTop: "1px solid",
    borderTopColor: theme.palette.grey.medium,
    backgroundColor: theme.palette.primary.vdark,
    boxShadow: theme.shadow.light,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    width: "auto"
  },
  landingFooter: {
    flexDirection: "column",
    marginTop: 0,
    textAlign: "center",
    "& img, & p": {
      margin: "1rem auto"
    }
  },
  heartIcon: {
    color: theme.palette.secondary.main,
    paddingLeft: 4,
    paddingRight: 4
  },
  socialIcon: {
    height: 40,
    width: 40,
    "--color-1": theme.palette.primary.dark,
    "--color-2": theme.palette.secondary.main,
    "&:hover": {
      "--color-1": white,
      "--color-2": theme.palette.text.main
    }
  },
  socialEndIcon: {
    color: theme.palette.secondary.main
  },
  links: {
    display: "flex",
    alignItems: "stretch",
    paddingTop: 16
  },
  link: {
    color: white,
    fontSize: 16,
    textDecoration: "underline"
  },
  linkSpacer: {
    width: 37
  },
  linkTop: {
    color: white,
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "none"
  },
  linkTopSpacer: {
    width: 40
  },
  footerLogo: {
    width: 187,
    height: 60,
    color: white
  },
  footer: {
    display: "flex",
    width: "100%"
  },
  footerTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    backgroundColor: theme.palette.primary.main,
    paddingLeft: 200,
    paddingRight: 200,
    paddingTop: 80
  },
  footerBottom: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    height: 65,
    backgroundColor: "#1B2A56",
    color: white,
    fontSize: 16,
    paddingLeft: 200,
    paddingRight: 200,
    width: "100%"
  },
  footerNav: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    color: white,
    fontSize: 16,
    paddingTop: 49,
    paddingBottom: 92
  },
  footerNavTextWrapper: {
    paddingBottom: 8
  },
  footerNavText: {
    marginRight: 8
  },
  [theme.breakpoints.down("md")]: {
    footerTop: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "5%"
    },
    footerBottom: {
      paddingLeft: "10%",
      paddingRight: "10%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    footerLogo: {
      width: 112,
      height: 36,
      color: white
    },
    footerNavTextWrapper: {
      paddingBottom: 0
    },
    footerNav: {
      display: "inline-block",
      fontSize: 16,
      paddingTop: 0,
      paddingBottom: 0
    },
    footerTop: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 32
    },
    footerBottom: {
      display: "inline-block",
      height: "auto",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 32,
      paddingBottom: 32
    },
    footerBottomLinks: {
      paddingTop: 32
    },
    links: {
      paddingTop: 0
    },
    socialIcons: {
      paddingBottom: 32
    }
  }
});

class AppFooter extends Component {
  render() {
    const { classes, currentPath, isAuthenticated } = this.props;
    const currentYear = new Date().getFullYear();
    const mailLinkClass = classNames(classes.link, classes.bold);
    if (isAuthenticated) {
      return null;
    } else
      return (
        <div className={classes.root}>
          <div className={classes.footerTop}>
            <div>
              <img
                src={footerLogo}
                alt="Vrbff logo"
                className={classes.footerLogo}
              />
            </div>
            <Hidden mdUp>
              <Divider className={classes.divider} />
            </Hidden>
            <div className={classes.footerNav}>
              <div className={classes.footerNavTextWrapper}>
                <span className={classes.footerNavText}>
                  Wanna get in touch?
                </span>
                <Hidden mdUp>
                  <br />
                </Hidden>
                <a className={mailLinkClass} href="mailto:hello@vrbff.com">
                  hello@vrbff.com
                </a>
              </div>
              <Hidden mdUp>
                <Divider className={classes.divider} />
              </Hidden>
              <div className={classes.links}>
                <AppFooterNavItem
                  title="About"
                  to="/about"
                  currentPath={currentPath}
                />
                <div className={classes.linkTopSpacer} />
                <AppFooterNavItem
                  title="Pricing"
                  to="/pricing"
                  currentPath={currentPath}
                />
                <div className={classes.linkTopSpacer} />
                <AppFooterNavItem
                  title="Learn More"
                  to="/learn"
                  currentPath={currentPath}
                />
              </div>
              <Hidden mdUp>
                <Divider className={classes.divider} />
              </Hidden>
              <div className={classes.socialIcons}>
                <ExternalLink
                  rel="noopener"
                  target="_blank"
                  href="https://twitter.com/getvrbff"
                  className={classes.exLink}
                >
                  <TwitterIcon className={classes.socialIcon} />
                </ExternalLink>
                <ExternalLink
                  rel="noopener"
                  target="_blank"
                  href="https://www.instagram.com/getvrbff/"
                  className={classes.exLink}
                >
                  <InstagramIcon className={classes.socialIcon} />
                </ExternalLink>
                <ExternalLink
                  rel="noopener"
                  target="_blank"
                  href="https://www.facebook.com/getvrbff/"
                  className={classes.exLink}
                >
                  <FacebookIcon className={classes.socialIcon} />
                </ExternalLink>
              </div>
            </div>
          </div>
          <div className={classes.footerBottom}>
            <div>
              &copy; Vrbff {currentYear} &mdash; Made with
              <img
                alt="heart icon"
                src={heartIcon}
                className={classes.heartIcon}
              />
              in Portland, OR.
            </div>
            <div className={classes.footerBottomLinks}>
              <div className={classes.links}>
                <Link className={classes.link} to="/terms-of-service">
                  Terms
                </Link>
                <div className={classes.linkSpacer} />
                <Link className={classes.link} to="/privacy-policy">
                  Privacy
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default withStyles(styles, { name: "AppFooter" })(AppFooter);
