let geocoder;
/**
 *
 * @param props | {address, placeId}
 * @returns {Promise}
 */
export const geocodePromisified = props => {
  const google = window.google;
  if (!geocoder) geocoder = new google.maps.Geocoder();

  return new Promise((resolve, reject) => {
    /**
     * status: OK | ZERO_RESULTS | OVER_QUERY_LIMIT | REQUEST_DENIED | INVALID_REQUEST | UNKNOWN_ERROR
     */
    geocoder.geocode(props, (results, status) => {
      if (status === "OK") resolve(results);
      reject(results);
    });
  });
};
