import React from "react";
import { Paper, Typography, withStyles } from "@material-ui/core";

import ImageFilterHdr from "@material-ui/icons/Add";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  propertyNameContainer: {
    background: "white",
    padding: "4px 18px",
    marginTop: -25,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 250,
    marginBottom: 22
  },
  propertyPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "28px 0",
    padding: "0 32px"
  },
  propertyPreview: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
    marginTop: 12,
    marginBottom: 18
  },
  messagePreview: {
    margin: "12px 0",
    width: "100%",
    borderBottom: "1px solid #00000022",
    marginBottom: 12
  },
  editorContainer: {
    marginTop: 18
  }
});

let MessagePreview = props => {
  const { classes, data, properties, profile } = props;
  let property = properties.find(p => p.id === data.propertyId);
  const propertyImage = property.image > 0 ? property.image : false;

  return (
    <Paper className={classes.propertyPreviewContainer}>
      <div
        className={classes.messagePreview}
        dangerouslySetInnerHTML={{
          __html: (data.description && data.description.htmlData) || "<br />"
        }}
      />
      <div className={classes.propertyPreview}>
        {!propertyImage && (
          <ImageFilterHdr
            size={100}
            style={{
              fontSize: 100,
              borderRadius: 50,
              border: "2px solid",
              paddingLeft: 5,
              paddingRight: 5,
              margin: "30px 0",
              color: "#00000044"
            }}
          ></ImageFilterHdr>
        )}
        {propertyImage && (
          <img
            style={{ width: "100%" }}
            alt="Property"
            src={propertyImage.url}
          />
        )}
        <div className={classes.propertyNameContainer}>
          <Typography
            variant="h6"
            style={{ color: "#40A5CB", marginBottom: 8 }}
          >
            {property.name}
          </Typography>
          <MainActionButton isSmall>Accept invitation</MainActionButton>
        </div>
        <Typography variant="caption" component="div">
          {"- " + profile.name}
        </Typography>
      </div>
    </Paper>
  );
};

MessagePreview = withStyles(styles, { name: "MessagePreview" })(MessagePreview);

export default MessagePreview;
