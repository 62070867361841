import LogRocket from "logrocket";
import OurWebSocket from "OurWebSocket";

import history from "../../history";
import decode from "jwt-decode";
import { apiAxiosRequest } from "../../apis/jsonApi";

class Auth {
  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }
  setGetState(getState) {
    this.getState = getState;
  }
  init = () => {
    this.setReduxAuth();
  };

  setReduxAuth = () => {
    if (this.dispatch && this.isAuthenticated()) {
      return apiAxiosRequest("me", "GET", null, {}, true).then(response => {
        if (response.data && response.data.email) {
          this.dispatch({
            type: "SET_AUTH",
            auth: {
              data: response.data,
              isAdmin: this.isAdmin(),
              isAuthenticated: true
            }
          });
          this.dispatch({
            type: "APP_LOADED"
          });
        }
      });
    }

    return this.dispatch({
      type: "APP_LOADED"
    });
  };
  setProfile = data => {
    // Saves profile data to localStorage
    this.setSession(data);
    localStorage.setItem("profile", JSON.stringify(data.profile));
    this.setReduxAuth();
    // set logrocket user data
    LogRocket.identify(data.profile.id, { ...data.profile });
  };

  completeLogin = data => {
    // set profile
    this.setProfile(data);

    // Inactive requires email confirmation
    if (!data.profile.active) {
      history.replace("/confirm-email");
    }

    // Active users only
    if (data.profile.active) {
      const code = localStorage.getItem("invite_code");
      if (code) {
        localStorage.removeItem("invite_code");
        return history.replace("/");
      }
      const url = localStorage.getItem("redirect_after_login");
      if (url) {
        localStorage.removeItem("redirect_after_login");
        history.replace(url);
      } else {
        history.replace(window.location.pathname);
      }
    }
  };

  initFacebookMergeLogin = () => {
    return new Promise((resolve, reject) => {
      window.FB.getLoginStatus(response => {
        if (response.status === "connected") {
          if (response.authResponse) {
            apiAxiosRequest(
              "users/facebook-merge",
              "POST",
              {
                ...response.authResponse
              },
              {},
              true
            ).then(({ data, status }) => {
              if (status === 200) {
                this.setProfile(data);
                resolve(true);
              } else {
                reject(false);
              }
            });
          }
        } else {
          window.FB.login(
            response => {
              if (response.authResponse) {
                apiAxiosRequest(
                  "users/facebook-merge",
                  "POST",
                  {
                    ...response.authResponse
                  },
                  {},
                  true
                ).then(({ data, status }) => {
                  if (status === 200) {
                    this.setProfile(data);
                    resolve(true);
                  } else {
                    reject(false);
                  }
                });
              } else {
                reject();
                //user hit cancel button
                console.log("User cancelled login or did not fully authorize.");
              }
            },
            {
              scope: "public_profile, email, user_friends"
            }
          );
        }
      });
    });
  };

  completeFacebookLogin = response => {
    // The user accepts our request.
    const code = localStorage.getItem("invite_code");
    apiAxiosRequest("auth/facebook", "POST", {
      code,
      ...response.authResponse
    }).then(({ data, status }) => {
      if (status === 200) {
        this.completeLogin(data);
      }
    });
  };

  initFacebookLogin = () => {
    window.FB.getLoginStatus(response => {
      if (response.status === "connected") {
        this.completeFacebookLogin(response);
      } else {
        window.FB.login(
          response => {
            if (response.authResponse) {
              this.completeFacebookLogin(response);
            } else {
              //user hit cancel button
              console.log("User cancelled login or did not fully authorize.");
            }
          },
          {
            scope: "public_profile, email, user_friends"
          }
        );
      }
    });
  };

  getProfile() {
    if (this && this.getState) {
      const s = this.getState();
      return (s.app && s.app.auth && s.app.auth.data) || {};
    }
    return {};
    // Retrieves the profile data from localStorage
    // const profile = localStorage.getItem("profile");
    // return profile ? JSON.parse(localStorage.profile) : {};
  }

  getRole() {
    const profile = this.getProfile();
    return profile.role || null;
  }

  setSession = authResult => {
    // Set the time that the access token will expire at
    if (authResult.profile.active) {
      let expiresAt = JSON.stringify(
        authResult.expiresIn * 1000 + new Date().getTime()
      );
      localStorage.setItem("access_token", authResult.accessToken);
      localStorage.setItem("expires_at", expiresAt);
      localStorage.setItem("profile", JSON.stringify(authResult.profile));
      //Token has changed, reload WS
      OurWebSocket.loadWS();
    }
  };

  removeSession = () => {
    // Clear access token and ID token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile");
    // user is now logged out
    // navigate to the home route
    window.location.href = "/";
  };

  logout = () => {
    window.FB.getLoginStatus(response => {
      if (response.status === "connected") {
        window.FB.logout(function(response) {});
      }
      this.removeSession();
    });
  };

  forgotPasswordRequest = async values => {
    return await apiAxiosRequest("auth/forgot", "POST", {
      email: values.email
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return true;
        }
      })
      .catch(error => {
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  resetPasswordRequest = async (values, code) => {
    return await apiAxiosRequest("auth/reset", "POST", {
      password: values.password,
      code: code
    })
      .then(({ data, status }) => {
        if (status === 200) {
          return true;
        }
      })
      .catch(error => {
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  confirmEmailRequest = async code => {
    return await apiAxiosRequest("auth/confirm-email", "POST", {
      code
    })
      .then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }
      })
      .catch(error => {
        console.log(error.response);
        throw error.response.data.errors;
      });
  };

  signup = async values => {
    const code = localStorage.getItem("invite_code");
    return await apiAxiosRequest("auth/signup", "POST", {
      code,
      email: values.email,
      first_name: values.firstName,
      last_name: values.lastName,
      password: values.password
    })
      .then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }
      })
      .catch(error => {
        throw error.response.data.errors;
      });
  };

  login = async values => {
    const code = localStorage.getItem("invite_code");
    return await apiAxiosRequest("auth/login", "POST", {
      code: code,
      email: values.email,
      password: values.password
    })
      .then(({ data, status }) => {
        if (status === 200) {
          this.completeLogin(data);
          return true;
        }
      })
      .catch(error => {
        throw error.response.data.errors;
      });
  };

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  isAdmin() {
    if (!this.isAuthenticated()) {
      return false;
    }
    // check for admin role for tenancy
    const role = this.getRole();
    return role === "admin";
  }
}

export function getToken() {
  // Retrieves the user token from localStorage
  return localStorage.getItem("access_token");
}

export function getTokenExpirationDate(token) {
  const decoded = decode(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

export default new Auth();
