import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import FeedItem from "./FeedItem";
import { Collapse } from "@material-ui/core";
import { Link } from "react-router-dom";
// import { getToken } from "components/Auth/Auth";

const styles = theme => ({
  container: {
    backgroundColor: "#FFF",
    width: 400,
    marginLeft: 40,
    marginRight: -32,
    border: "1px solid " + theme.palette.secondary.border,
    borderRight: "none",
    borderTop: "none",
    marginTop: -16,
    position: "fixed",
    top: 96,
    right: 32,
    bottom: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  headerList: {
    padding: 40,
    borderBottom: "1px solid " + theme.palette.secondary.border,
    display: "flex",
    alignContent: "space-between"
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  headerListText: {
    flex: 1,
    fontWeight: 700,
    fontSize: 16,
    color: theme.palette.primary.hover
  },
  headerListCounter: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.primary.hover
  },
  headerListCounterDot: {
    backgroundColor: "#ff0000",
    width: 8,
    height: 8,
    borderRadius: 4,

    display: "inline-block",

    marginBottom: 6,
    marginLeft: 3
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.main,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "40px"
  },
  gridItem: {
    flexGrow: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      padding: "0",
      marginBottom: "24px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "16px",
      width: "50%"
    },
    [theme.breakpoints.up("md")]: {
      width: 300
    }
  }
});

class DashboardFeed extends Component {
  getFeedText = feed => {
    switch (feed.type) {
      case "NewTrip":
        return (
          <>
            just made a trip request to{" "}
            <Link
              to={"/p/" + feed.property.slug}
              className={this.props.classes.link}
            >
              {feed.property.name}
            </Link>
          </>
        );

      case "NewFollow":
        return (
          <>
            is now following{" "}
            <Link
              to={"/p/" + feed.property.slug}
              className={this.props.classes.link}
            >
              {feed.property.name}
            </Link>
          </>
        );
      case "NewProperty":
        return (
          <>
            added{" "}
            <Link
              to={"/p/" + feed.property.slug}
              className={this.props.classes.link}
            >
              {feed.property.name}
            </Link>
          </>
        );
      default:
        break;
    }
  };

  render() {
    const { classes, feed } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.headerList}>
          <span className={classes.headerListText}>Feed</span>
        </div>
        {feed &&
          feed.ids.map(i => {
            const f = feed[i];
            return (
              <Collapse in appear enter timeout={500} key={f.id}>
                <FeedItem
                  data={f}
                  to={"/p/" + f.property.slug}
                  title={this.getFeedText(f)}
                />
              </Collapse>
            );
          })}
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(DashboardFeed));
