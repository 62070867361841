//

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import MainActionButton from "components/Buttons/MainActionButton";

class StripeAccountConnect extends Component {
  getLink = () => {
    const { auth } = this.props;
    let params = {
      "stripe_user[email]": auth.email,
      "stripe_user[first_name]": auth.firstName,
      "stripe_user[last_name]": auth.lastName,
      "stripe_user[url]": `${
        process.env.REACT_APP_VRBFF_URL
      }/users/${auth.username || auth.id}`
    };
    params = Object.keys(params)
      .map(k => {
        return k + "=" + params[k];
      })
      .join("&");

    let state = "state";
    return `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_VRBFF_URL}/stripe/connect&client_id=${process.env.REACT_APP_STRIPE_ID}&state=${state}&${params}`;
  };

  handleClick = () => {
    localStorage.setItem("stripe_cb_url", window.location.href);
    if (this.props.propertyId) {
      localStorage.setItem("stripe_cb_property", this.props.propertyId);
    }

    window.location = this.getLink();
  };

  render() {
    return (
      <MainActionButton onClick={this.handleClick}>
        Setup via Stripe
      </MainActionButton>
    );
  }
}

function mapStateToProps({ app }, props) {
  return {
    auth: app.auth && app.auth.data
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeAccountConnect);
