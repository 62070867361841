import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Popover } from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import Avatar from "components/General/Avatar";
import history from "../../history";

const SMALL_AVATAR = 24;
const BIG_AVATAR = 30;
const styles = theme => ({
  avatar: {
    cursor: "pointer",
    width: BIG_AVATAR,
    height: BIG_AVATAR,
    boxShadow: "#fff 0 0 0 2px, #FFF 0 0 0 4px",
    display: "inline-flex"
  },
  avatarSmall: {
    cursor: "pointer",
    boxShadow: "#fff 0 0 0 1px, #FFF 0 0 0 2px",
    width: SMALL_AVATAR,
    height: SMALL_AVATAR,
    display: "inline-flex"
  },

  avatarNot: {
    boxShadow: "#fff 0 0 0 2px, #C5E4B8 0 0 0 4px"
  },
  avatarNotSmall: {
    boxShadow: "#fff 0 0 0 1px, #C5E4B8 0 0 0 2px"
  },

  avatarStack: {
    display: "flex",
    alignItems: "center"
  },

  title: {
    display: "inline-flex",
    fontSize: "14px",
    color: grey[800],
    marginRight: 20
  },
  titleSmall: {
    display: "inline-flex",
    fontSize: "12px",
    color: grey[800],
    marginRight: 10
  },
  name: {
    display: "inline-flex",
    fontSize: "14px",
    color: grey[800],
    marginLeft: 8
  },
  nameSmall: {
    display: "inline-flex",
    fontSize: "12px",
    color: grey[800],
    marginLeft: 8
  },
  avatarItem: {
    height: SMALL_AVATAR + 8,
    borderRadius: BIG_AVATAR / 2,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    transition: "margin 0.1s ease-in-out",

    marginLeft: -10,

    "& span": {
      display: "none"
    }
  },
  avatarItemSmall: {
    height: SMALL_AVATAR + 6,
    boxShadow:
      "rgba(50, 50, 93, 0.14902) 0px 0px 3px, rgba(0, 0, 0, 0.1196078) 0px 5px 5px",
    borderRadius: SMALL_AVATAR / 2,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    marginTop: 6,
    marginBottom: 6,
    transition: "margin 0.1s ease-in-out",

    marginLeft: -6,

    "& span": {
      display: "none"
    }
  },
  additional: {
    display: "block",
    width: BIG_AVATAR,
    height: BIG_AVATAR,
    textAlign: "center",
    boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    borderRadius: "100%",
    paddingTop: 8,
    backgroundColor: "white",
    cursor: "pointer",
    zIndex: 2,
    fontSize: "13px",
    color: grey[800]
  },
  additionalSmall: {
    display: "block",
    width: SMALL_AVATAR,
    height: SMALL_AVATAR,
    textAlign: "center",
    boxShadow: "#fff 0 0 0 1px, #40A5CB 0 0 0 2px",
    borderRadius: "100%",
    paddingTop: 2,
    backgroundColor: "white",
    cursor: "pointer",
    zIndex: 2,
    fontSize: "13px",
    color: grey[800]
  },
  before: {
    marginLeft: 0
  },
  open: {
    width: "auto",
    cursor: "pointer",
    marginLeft: 0,
    paddingRight: 16,
    marginRight: -11,
    "& span": {
      display: "inline-block",
      marginRight: 10
    }
  },
  noPointer: {
    cursor: "default"
  },
  listContainer: {
    "& > span": {
      height: BIG_AVATAR,
      marginTop: 10,
      marginBottom: 10
    }
  },
  noBorder: {
    boxShadow: "none",
    cursor: "pointer"
  }
});

const HostsNew = ({ isSmall, classes, hosts }) => {
  const limit = 3;
  const [selected, setSelected] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const openProfile = username => {
    history.push(`/users/${username}`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cName = name => {
    if (isSmall) {
      return classes[name + "Small"];
    }

    return classes[name];
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  let visibleHosts = [];
  let moreHosts = [];
  (hosts || []).forEach((h, index) => {
    if (index < limit || limit === hosts.length - 1)
      visibleHosts.push(
        <span
          key={index}
          onClick={() =>
            selected === index ? openProfile(h.username) : setSelected(index)
          }
          className={classNames(
            cName("avatarItem"),
            selected === index && classes.open,
            selected > index && classes.before
          )}
        >
          <Avatar
            className={classNames(
              cName("avatar"),
              selected !== index && cName("avatarNot")
            )}
            src={h.picture}
            isSmallHost
            firstName={h.firstName}
            lastName={h.lastName}
          />
          <span className={cName("name")}>
            {h.firstName} {h.lastName}
          </span>
        </span>
      );
    else {
      moreHosts.push(
        <span
          key={index}
          onClick={() => openProfile(h.username)}
          className={classNames(cName("avatarItem"), classes.noBorder)}
        >
          <Avatar
            className={classNames(cName("avatar"))}
            src={h.picture}
            isSmallHost
            firstName={h.firstName}
            lastName={h.lastName}
          />
          <span className={cName("name")} style={{ display: "block" }}>
            {h.firstName} {h.lastName}
          </span>
        </span>
      );
    }
  });
  return (
    <div className={classes.avatarStack}>
      {visibleHosts.length > 0 && visibleHosts}
      {moreHosts.length > 0 && (
        <>
          <span onClick={handleClick} className={cName("avatarItem")}>
            <span
              className={cName("additional")}
              style={{ display: "block", paddingTop: 4 }}
            >
              +{moreHosts.length}
            </span>
          </span>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <div
              className={cName("listContainer")}
              style={{ margin: "0px 20px" }}
            >
              {moreHosts}
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

export default withStyles(styles, { name: "HostsNew" })(HostsNew);
