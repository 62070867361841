import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({});

function TwitterIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Twitter Icon</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="landing-page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="01-vrbff-lp@2x"
            transform="translate(-1320.000000, -3716.000000)"
          >
            <g id="footer" transform="translate(0.000000, 3532.000000)">
              <g id="Twitter" transform="translate(1321.000000, 185.000000)">
                <path
                  d="M0,20 C0,8.95430446 8.95430446,0 20,0 C31.0456955,0 40,8.95430446 40,20 C40,31.0456955 31.0456955,40 20,40 C8.95430446,40 0,31.0456955 0,20 Z"
                  id="back"
                  stroke="var(--color-2, currentColor)"
                  fill="var(--color-2, currentColor)"
                ></path>
                <path
                  d="M19.6415538,17.3491807 L19.6756678,17.8937408 L19.1070998,17.82706 C17.0375124,17.5714502 15.2294662,16.7045994 13.6943326,15.2487347 L12.9438229,14.526359 L12.7505097,15.0598056 C12.3411408,16.2489471 12.6026821,17.5047693 13.455534,18.3493932 C13.9103884,18.816159 13.8080462,18.8828398 13.0234224,18.605003 C12.7505097,18.5160952 12.5117112,18.4494144 12.4889685,18.4827548 C12.409369,18.5605491 12.6822816,19.571875 12.8983374,19.97196 C13.1939928,20.5276335 13.7966748,21.0721936 14.4562137,21.3944843 L15.0134103,21.6500941 L14.3538715,21.6612076 C13.7170753,21.6612076 13.6943326,21.6723211 13.7625608,21.905704 C13.989988,22.6280796 14.8883254,23.3949091 15.889005,23.7283133 L16.5940293,23.9616962 L15.9799759,24.3173273 C15.0702671,24.8285469 14.0013593,25.1174972 12.9324515,25.1397241 C12.4207403,25.1508376 12,25.1952915 12,25.2286319 C12,25.3397666 13.3873059,25.962121 14.1946724,26.2066174 C16.6167721,26.928993 19.4937261,26.6178158 21.6542844,25.3842205 C23.189418,24.5062563 24.7245516,22.7614413 25.4409472,21.0721936 C25.8275735,20.1720025 26.2141997,18.5272087 26.2141997,17.7381522 C26.2141997,17.2269326 26.2483138,17.1602517 26.8851099,16.5490108 C27.2603648,16.1933797 27.6128769,15.8044082 27.6811051,15.6932735 C27.7948187,15.4821176 27.7834473,15.4821176 27.203508,15.6710466 C26.2369424,16.0044507 26.1004861,15.9599968 26.5780832,15.4598906 C26.9305954,15.1042595 27.3513357,14.4596782 27.3513357,14.2707492 C27.3513357,14.2374088 27.1807653,14.2929761 26.9874522,14.3929974 C26.7827677,14.5041321 26.3279133,14.6708341 25.9867725,14.7708554 L25.3727191,14.9597844 L24.8155224,14.5930398 C24.5084957,14.3929974 24.0763841,14.1707279 23.8489569,14.1040471 C23.2690175,13.9484585 22.3820515,13.9706855 21.8589689,14.148501 C20.4375489,14.6486072 19.5392115,15.9377699 19.6415538,17.3491807 Z"
                  id="Shape"
                  fill="var(--color-1, none)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default withStyles(styles)(TwitterIcon);
