import React, { Component } from "react";
import history from "../../../history";

import Button from "@material-ui/core/Button";
import AppMenuItem from "./AppMenuItem";

import { withStyles } from "@material-ui/core/styles";
import common from "@material-ui/core/colors/common";
const { white } = common;

export const styles = theme => ({
  root: {
    position: "fixed",
    top: 64,
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    zIndex: 1200,
    paddingTop: 64,
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 88
  },
  navItem: {
    textAlign: "center",
    paddingBottom: 48
  },
  navLink: {
    cursor: "pointer",
    fontSize: 16,
    color: white,
    textDecoration: "none",
    paddingBottom: 7.5,
    backgroundColor: theme.palette.primary.main,
    borderColor: "none",
    borderTop: 0,
    borderRight: 0,
    borderLeft: 0,
    borderBottom: `3px solid ${theme.palette.primary.main}`,
    "&:hover": {
      borderBottom: `3px solid ${theme.palette.secondary.main}`
    }
  },
  navSignUp: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    borderRadius: 4,
    color: theme.palette.primary.main,
    backgroundColor: white,
    textDecoration: "none",
    textTransform: "none",
    height: 48,
    textAlign: "center",
    width: "100%"
  },
  signUpLink: {
    color: "inherit",
    cursor: "pointer",
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "none"
  }
});

class AppMenu extends Component {
  handleSignup = () => {
    history.replace("/signup");
    this.props.closeMenu();
  };

  render() {
    const {
      classes,
      closeMenu,
      currentPath,
      isAuthenticated,
      open
    } = this.props;
    if (!open) {
      return null;
    }

    if (isAuthenticated) {
      return (
        <nav className={classes.root}>
          <AppMenuItem
            closeMenu={closeMenu}
            aliasPath="/p/"
            currentPath={currentPath}
            to="/"
            title="Properties"
          />
          <AppMenuItem
            closeMenu={closeMenu}
            currentPath={currentPath}
            to="/inbox"
            title="Stays & Requests"
          />
          <AppMenuItem
            closeMenu={closeMenu}
            currentPath={currentPath}
            to="/manage"
            title="Manage"
          />
        </nav>
      );
    }

    return (
      <nav className={classes.root}>
        <AppMenuItem
          closeMenu={closeMenu}
          currentPath={currentPath}
          to="/about"
          title="About"
        />
        <AppMenuItem
          closeMenu={closeMenu}
          currentPath={currentPath}
          to="/pricing"
          title="Pricing"
        />
        <AppMenuItem
          closeMenu={closeMenu}
          currentPath={currentPath}
          to="/learn"
          title="Learn More"
        />
        <AppMenuItem
          closeMenu={closeMenu}
          currentPath={currentPath}
          to="/login"
          title="Log In"
        />
        <Button
          onClick={() => this.handleSignup()}
          className={classes.navSignUp}
        >
          List Your Property
        </Button>
      </nav>
    );
  }
}

export default withStyles(styles, { name: "AppMenu" })(AppMenu);
