import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({});

function LinkedInIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>LinkedIn Icon</title>
        <desc>Created with Sketch.</desc>
        <g
          id="linked-in@2x"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <circle
            id="Oval"
            fill="var(--color-1, none)"
            cx="20"
            cy="20"
            r="12"
          ></circle>
          <path
            d="M20,0 C8.955,0 0,8.955 0,20 C0,31.045 8.955,40 20,40 C31.045,40 40,31.045 40,20 C40,8.955 31.045,0 20,0 Z M16.6666667,26.6666667 L13.3333333,26.6666667 L13.3333333,16.6666667 L16.6666667,16.6666667 L16.6666667,26.6666667 Z M15,15.1816667 C13.9883333,15.1816667 13.1666667,14.355 13.1666667,13.3333333 C13.1666667,12.3133333 13.9866667,11.485 15,11.485 C16.0133333,11.485 16.8333333,12.3133333 16.8333333,13.3333333 C16.8333333,14.355 16.0116667,15.1816667 15,15.1816667 Z M28.3333333,26.6666667 L25.0033333,26.6666667 L25.0033333,21.8983333 C25.0033333,18.7633333 21.6666667,19.0283333 21.6666667,21.8983333 L21.6666667,26.6666667 L18.3333333,26.6666667 L18.3333333,16.6666667 L21.6666667,16.6666667 L21.6666667,18.4883333 C23.12,15.795 28.3333333,15.595 28.3333333,21.0683333 L28.3333333,26.6666667 Z"
            id="Shape"
            fill="var(--color-2, currentColor)"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default withStyles(styles)(LinkedInIcon);
