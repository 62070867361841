// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import common from "@material-ui/core/colors/common";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import MainActionButton from "components/Buttons/MainActionButton";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import {
  Input,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  InputAdornment
} from "@material-ui/core";
import {
  getRateNameFromNights,
  getRateNameFromNightsTotal,
  rateTypeToText
} from "helpers/stays";
import DatePicker from "containers/Components/DatePicker";
import moment from "moment";
import { formatDate } from "helpers/utils";

const { white } = common;

const styles = theme => ({
  button: {
    backgroundColor: "#40A5CB",
    color: white,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3994b6"
    }
  },
  iconButton: {
    marginRight: 8
  },
  description: {
    marginBottom: theme.spacing(1)
  }
});

const ResponsiveDialog = withMobileDialog({ breakpoint: "xs" })(Dialog);

class PricingDialog extends Component {
  constructor(props) {
    super(props);
    let data = {};
    if (props.data) {
      data = props.data;
      if (data.startDate) {
        data.startDate = moment(props.data.startDate);
        data.endDate = moment(props.data.endDate);
      }
    }
    this.state = { activeStep: 0, errors: {}, data: data };
  }
  setType = event => {
    this.setState({ data: { ...this.state.data, type: event.target.value } });
  };

  changePrice = event => {
    this.setState({ data: { ...this.state.data, price: event.target.value } });
  };

  changeName = event => {
    this.setState({ data: { ...this.state.data, name: event.target.value } });
  };
  getSteps = () => {
    let name = "Choose a name";
    if (this.state.activeStep > 0) name = `Name: ${this.state.data.name}`;
    return [name, "Choose a price", "Select dates"];
  };

  getStepDescription = step => {
    switch (step) {
      case 0:
        return `This is a simple description for the fee/cost you're going to ask your guests in this group to pay. Guests will see this in any booking requests and completed invoices emailed to them. We will also roll up your revenue for year-end tax purposes as an owner.`;
      case 1:
        return "There are several ways you can add pricing. You can have a rate per night, a rate per person and a one off price. This allows you to add things like one-off use of the BBQ or local taxes you have to cover. Select a price type to see more on how that pricing works.";
      case 2:
        return `Pricing can also be set for specific dates. For example, during the busy season your rates might be higher for January - June if you're near the equator. During off-peak times you can decrease your rates as well. If no date is chosen, it will be assumed it is charged year round.`;
      default:
        return "Unknown step";
    }
  };

  getStepContent = step => {
    let data = this.state.data;
    switch (step) {
      case 0:
        return (
          <>
            <FormControl error={this.state.errors[step]} fullWidth>
              <InputLabel id="demo-simple-select-label">Name</InputLabel>
              <Input
                className={this.props.classes.emailInput}
                fullWidth
                label="Name"
                value={this.state.data.name || ""}
                onChange={this.changeName}
                //   onChange={(event, b, c) => {
                //     let emails = [...data.emails];
                //     emails[index] = event.target.value || "";
                //     this.props.change("emails", emails);
                //   }}
              />
              {this.state.errors[step] && (
                <FormHelperText id="component-error-text">
                  The pricing should have a name
                </FormHelperText>
              )}
            </FormControl>
          </>
        );
      case 1:
        return (
          <>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Price</InputLabel>
              <Input
                className={this.props.classes.price}
                fullWidth
                startAdornment={
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                }
                value={this.state.data.price || ""}
                onChange={this.changePrice}
              />
            </FormControl>

            {this.state.data.price && (
              <FormControl className={this.props.classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Pricing type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.data.type || 0}
                  onChange={this.setType}
                >
                  <MenuItem value={0}>Select a pricing type</MenuItem>
                  <MenuItem value={"PRICE_NIGHT"}>
                    {rateTypeToText("PRICE_NIGHT")}
                  </MenuItem>
                  <MenuItem value={"PERSON_NIGHT"}>
                    {rateTypeToText("PERSON_NIGHT")}
                  </MenuItem>
                  <MenuItem value={"ONE_OFF"}>
                    {rateTypeToText("ONE_OFF")}
                  </MenuItem>
                </Select>

                {this.state.data.type === "PRICE_NIGHT" && (
                  <FormHelperText id="component-text">
                    The fee you're charging on a nightly basis. For example, for
                    2 nights and 2 adults:{" "}
                    {getRateNameFromNights(
                      data.type,
                      data.price,
                      data.name,
                      2,
                      2
                    )}{" "}
                    Total:{" $"}
                    {getRateNameFromNightsTotal(
                      data.type,
                      data.price,
                      data.name,
                      2,
                      2
                    )}
                  </FormHelperText>
                )}
                {this.state.data.type === "PERSON_NIGHT" && (
                  <FormHelperText id="component-text">
                    The fee you're charging on a per person basis. For example,
                    for 2 nights and 2 adults:{" "}
                    {getRateNameFromNights(
                      data.type,
                      data.price,
                      data.name,
                      2,
                      2
                    )}{" "}
                    Total:{" $"}
                    {getRateNameFromNightsTotal(
                      data.type,
                      data.price,
                      data.name,
                      2,
                      2
                    )}
                  </FormHelperText>
                )}
                {this.state.data.type === "ONE_OFF" && (
                  <FormHelperText id="component-text">
                    This is a fee you want to incur one-time during the visit.
                    This might be a cleaning fee or use of common areas. For
                    example, for 2 nights and 2 adults: Current total + "Named
                    Fee" Total: $
                    {getRateNameFromNightsTotal(
                      data.type,
                      data.price,
                      data.name,
                      2,
                      2
                    )}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </>
        );
      case 2:
        let initialDate = false;
        if (
          this.props.data &&
          this.props.data.startDate &&
          this.props.data.endDate
        ) {
          initialDate = moment.range(
            moment(this.props.data.startDate),
            moment(this.props.data.endDate)
          );
        }
        return (
          <>
            <DatePicker
              key={"pickerproperty"}
              arriveText={"From"}
              departText={"To"}
              initialDate={initialDate}
              onSelect={value => {
                this.setState({
                  data: { ...data, startDate: value.start, endDate: value.end }
                });
              }}
              maximumDays={365 * 4}
            />
            {this.state.data.startDate && (
              <FormHelperText id="component-text">
                This pricing will be used just for the period between{" "}
                {formatDate(this.state.data.startDate)} and{" "}
                {formatDate(this.state.data.endDate)}
              </FormHelperText>
            )}
          </>
        );
      default:
        return "Unknown step";
    }
  };

  handleNext = () => {
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  save = () => {
    if (this.props.onSave) {
      this.props.onSave(this.state.data);
    }
  };
  render() {
    const { classes, closeDialog, open, resource } = this.props;
    const steps = this.getSteps();
    const { activeStep } = this.state;
    return (
      <div>
        {resource && (
          <ResponsiveDialog open={open} onClose={closeDialog}>
            <DialogTitle>
              {resource.id
                ? "Edit Pricing - " + this.state.data.name
                : activeStep > 0
                ? `New Pricing - ${this.state.data.name}`
                : "New Pricing"}
            </DialogTitle>
            <DialogContent>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <Typography className={classes.description}>
                        {this.getStepDescription(index)}
                      </Typography>
                      {this.getStepContent(index)}
                      <div className={classes.actionsContainer}>
                        <div>
                          {activeStep !== 0 && (
                            <MainActionButton
                              disabled={activeStep === 0}
                              onClick={this.handleBack}
                              className={classes.button}
                            >
                              Back
                            </MainActionButton>
                          )}
                          {activeStep === steps.length - 1 && (
                            <MainActionButton
                              variant="contained"
                              color="primary"
                              onClick={this.save}
                              className={classes.button}
                            >
                              {"Save"}
                            </MainActionButton>
                          )}
                          {activeStep !== steps.length - 1 && (
                            <MainActionButton
                              variant="contained"
                              color="primary"
                              onClick={this.handleNext}
                              className={classes.button}
                            >
                              {"Next"}
                            </MainActionButton>
                          )}
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                {"Cancel"}
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        )}
      </div>
    );
  }
}

PricingDialog.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.object,
  onSave: PropTypes.func.isRequired
};

export default withStyles(styles, { name: "PricingDialog" })(PricingDialog);
