import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import history from "../../history";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import ButtonArrowIcon from "components/Icons/ButtonArrowIcon";
import common from "@material-ui/core/colors/common";
const { white } = common;

export const styles = theme => ({
  root: {
    position: "relative"
  },
  heroButton: {
    fontSize: 20,
    boxShadow: "none",
    textTransform: "none",
    height: 60,
    justifyContent: "space-between"
  },
  heroButtonIcon: {
    width: 36,
    height: 36
  },
  heroBlock1: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "30%",
    height: "100%"
  },
  heroBlock2: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "40%",
    height: "100%"
  },
  heroBlock3: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "50%",
    height: "100%"
  },
  heroBlock4: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "60%",
    height: "100%"
  },
  heroBody: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    height: "100%",
    boxSizing: "border-box"
  },
  heroContainer: {
    position: "absolute",
    top: 0,
    zIndex: 3,
    width: "100%",
    height: "100%",
    paddingTop: "10%",
    paddingLeft: "10%",
    paddingRight: "5%"
  },
  heroText: {
    fontSize: 16,
    lineHeight: 1.8,
    maxWidth: 519
  },
  heroButtonContainer: {
    marginTop: 40
  },
  heroContent: {
    paddingLeft: 20,
    width: "60%",
    textAlign: "center"
  },
  heroCopy: {
    minWidth: 300,
    width: "40%"
  },
  heroImage: {
    height: "auto",
    maxWidth: "100%"
  },
  heroTitle: {
    color: theme.palette.text.main,
    fontSize: 34,
    fontWeight: "bold",
    paddingBottom: 32,
    margin: 0
  },
  heroVideo: {
    display: "inline-block",
    backgroundColor: white,
    maxHeight: 413,
    maxWidth: 736,
    width: "100%",
    textAlign: "center"
  },
  highlight: {
    background: `linear-gradient(180deg,rgba(255,255,255,0) 40%, ${theme.palette.secondary.main} 40%)`,
    display: "inline"
  },
  iconScroll: {
    width: 24,
    height: 24
  },
  learnMore: {
    color: white,
    fontSize: 10,
    paddingLeft: 4
  },
  text: {
    color: white
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
  },
  player: {
    position: "absolute",
    top: 0,
    left: 0
  },
  [theme.breakpoints.down("lg")]: {
    heroContainer: {
      paddingTop: "10%"
    }
  },
  [theme.breakpoints.down("md")]: {
    heroContent: {
      paddingLeft: 20,
      paddingRight: 20,
      width: "100%",
      textAlign: "center"
    },
    heroContainer: {
      paddingTop: "5%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    heroContainer: {
      position: "relative",
      zIndex: 3,
      paddingTop: 32,
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: "100%"
    },
    heroBody: {
      display: "inline-block",
      width: "100%"
    },
    heroCopy: {
      marginTop: -80,
      width: "100%"
    },
    heroContent: {
      display: "block",
      position: "relative",
      bottom: 80
    },
    heroButtonContainer: {
      marginTop: 32
    },
    heroText: {
      maxWidth: "100%"
    },
    heroTitle: {
      fontSize: 32
    }
  }
});

const HeroButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    "& span": {
      color: theme.palette.secondary.main
    },
    "&:hover": {
      backgroundColor: theme.palette.text.main,
      color: white,
      "& span": {
        color: white
      }
    }
  },
  label: {
    height: 36
  },
  contained: {
    "&:hover": {
      boxShadow: "none"
    }
  },
  containedSizeLarge: {
    padding: "12px 20px 12px 19px"
  },
  iconSizeLarge: {
    height: 36,
    width: 36
  }
}))(Button);

class LearnHero extends Component {
  handleSignup = () => {
    history.replace("/signup");
  };

  getHeroVideo = () => {
    const { classes } = this.props;
    const url = "https://www.youtube.com/embed/8UfM3fnC5Nc";
    return (
      <div className={classes.heroVideo}>
        <div className={classes.playerWrapper}>
          <div
            className={classes.player}
            style={{ height: "100%", width: "100%" }}
          >
            <div style={{ height: "100%", width: "100%" }}>
              <iframe
                frameBorder="0"
                allowFullScreen="1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                title="YouTube video player"
                width="100%"
                height="100%"
                src={`${url}?autoplay=0&mute=0&controls=1&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&widgetid=3`}
                id="widget4"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Fade>
        <div className={classes.root}>
          <div>
            <img
              src="/images/home/hero-2600.jpg"
              srcSet="/images/home/hero-500.jpg 500w,
            /images/home/hero-800.jpg 800w,
            /images/home/hero-1080.jpg 1080w,
            /images/home/hero-1600.jpg 1600w,
            /images/home/hero-2000.jpg 2000w,
            /images/home/hero-2600.jpg 2600w"
              sizes="100vw"
              alt="home"
              className={classes.heroImage}
            />
          </div>
          <div>
            <Hidden smDown>
              <div className={classes.heroBlock1} />
              <div className={classes.heroBlock2} />
              <div className={classes.heroBlock3} />
              <div className={classes.heroBlock4} />
            </Hidden>
            <Hidden mdUp>
              <div className={classes.heroContent}>{this.getHeroVideo()}</div>
            </Hidden>
            <div className={classes.heroContainer}>
              <div className={classes.heroBody}>
                <div className={classes.heroCopy}>
                  <h3 className={classes.heroTitle}>
                    <span>Tired of the big listing sites</span>
                    <div>
                      <span className={classes.highlight}>
                        stealing your guests and profits?
                      </span>
                    </div>
                  </h3>
                  <div className={classes.heroText}>
                    Come on over to honest payment practices and transparent pricing with 
                    no hidden costs. Vrbff puts you in charge of your income and guestlist.
                    <p>Vrbff is a complete short-term rental listing platform for owners 
                      and hosts. We are the only platform that allows you to list your 
                      property privately, and then choose who is invited to rent based 
                      on your criteria.</p>
                  </div>
                  <div className={classes.heroButtonContainer}>
                    <HeroButton
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.heroButton}
                      endIcon={
                        <ButtonArrowIcon className={classes.heroButtonIcon} />
                      }
                      onClick={() => this.handleSignup()}
                    >
                      <div className={classes.text}>{"List Your Property"}</div>
                    </HeroButton>
                  </div>
                </div>
                <Hidden smDown>
                  <div className={classes.heroContent}>
                    {this.getHeroVideo()}
                  </div>
                </Hidden>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default withStyles(styles, { name: "LearnHero" })(LearnHero);
