import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertiesActionCreators from "../../redux/modules/properties";
import Properties from "../../components/Properties/Properties";
import { Helmet } from "react-helmet";

class PropertiesContainer extends Component {
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Properties&nbsp;&bull;&nbsp;Vrbff</title>
        </Helmet>

        <Properties
          auth={this.props.auth}
          hasFetched={this.props.hasFetched}
          isFetching={this.props.isFetching}
          properties={this.props.properties}
        />
      </Fragment>
    );
  }
}

function mapStateToProps({ properties }, props) {
  return {
    hasFetched: properties.hasFetched,
    isFetching: properties.isFetching,
    properties
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertiesContainer);
