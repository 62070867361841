import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { convertToRaw, EditorState } from "draft-js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as imagesActionCreators from "../../redux/modules/images";
import validate from "../../components/Properties/validateProperty";
import dasherize from "dasherize";
import EditorField from "components/Fields/EditorField";
import WizardFieldName from "components/wizard/WizardFieldName";

const styles = theme => ({
  editorContainer: { marginTop: 20, marginBottom: 30 }
});

const editorState = EditorState.createEmpty();
const contentState = editorState.getCurrentContent();
const emptyRaw = convertToRaw(contentState);

class DescriptionStep extends Component {
  state = {};
  async continueCheck() {
    return new Promise(async resolve => {
      const submitter = this.props.handleSubmit(async values => {
        try {
          const property = dasherize(values);

          await this.props.updateProperty(property);
          return resolve(true);
        } catch (error) {
          console.error("error", error);
        }
      });
      const a = await submitter();
      if (a) return resolve(false);
    });
  }

  handleEditor = name => (pdfData, rawData, htmlData) => {
    this.props.change(name + "Raw", JSON.stringify(rawData));
    this.props.change(name + "Html", htmlData);
    this.setState({ [name + "Raw"]: rawData });
  };

  setCharacterCount = name => characterCount => {
    this.setState({
      [name + "Count"]: characterCount
    });
  };

  render() {
    const { property, classes } = this.props;
    let characterLimit = 5000;
    return (
      <WizardStepContainer
        Component={
          <div>
            <WizardFieldName
              title={"Property Description"}
              secondary={
                "Most of your guests have stayed at your place already - but giving a good description helps remind them of the great times they have had there. NOTE: you can always edit this later."
              }
            />
            <div className={classes.editorContainer}>
              <Field
                name="description"
                limit={characterLimit}
                handleEditor={this.handleEditor("description")}
                component={EditorField}
                rawString={
                  (property &&
                    property.descriptionRaw &&
                    JSON.parse(property.descriptionRaw)) ||
                  emptyRaw
                }
                setCount={this.setCharacterCount("description")}
              />
            </div>

            <WizardFieldName
              title={"Property Rules"}
              secondary={
                "You can describe the house rules here, the guests can see this all the time. NOTE: you can always edit this later."
              }
            />
            <div className={classes.editorContainer}>
              <Field
                name="rules"
                limit={characterLimit}
                handleEditor={this.handleEditor("rules")}
                component={EditorField}
                rawString={
                  (property &&
                    property.rulesRaw &&
                    JSON.parse(property.rulesRaw)) ||
                  emptyRaw
                }
                setCount={this.setCharacterCount("rules")}
              />
            </div>

            <WizardFieldName
              title={"Property Instructions"}
              secondary={
                "Here you can describe how guests get into the home as well as details like Wi-fi password, location of hot tub, etc. The guest will see this once the trip is approved. NOTE: you can always edit this later."
              }
            />
            <div className={classes.editorContainer}>
              <Field
                name="instructions"
                limit={characterLimit}
                handleEditor={this.handleEditor("instructions")}
                component={EditorField}
                rawString={
                  (property &&
                    property.instructionsRaw &&
                    JSON.parse(property.instructionsRaw)) ||
                  emptyRaw
                }
                setCount={this.setCharacterCount("description")}
              />
            </div>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  const selector = formValueSelector("DescriptionStepForm" + props.propertyId); // <-- same as form name

  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    hasFetched: state.properties.hasFetched,
    isDeleting: state.properties.isDeleting,
    isFetching: state.properties.isFetching,
    isUploading: state.images.isUploading,
    initialValues: {
      ...property
    },
    form: "DescriptionStepForm" + props.propertyId,
    property,
    descriptionRaw: selector(state, "descriptionRaw"),
    descriptionHtml: selector(state, "descriptionHtml"),
    rulesRaw: selector(state, "rulesRaw"),
    rulesHtml: selector(state, "rulesHtml"),
    instructionsRaw: selector(state, "instructionsRaw"),
    instructionsHtml: selector(state, "instructionsHtml")
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...imagesActionCreators
    },
    dispatch
  );
}

DescriptionStep = reduxForm({
  // form: "DescriptionStepForm", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(DescriptionStep);

DescriptionStep = withStyles(styles, { name: "DescriptionStep" })(
  DescriptionStep
);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(DescriptionStep);
