import { apiRequest } from "apis/jsonApi";
import Resource from "./resource";

let resource = new Resource({
  singularName: "propertyStay",
  pluralName: "propertyStays"
});

export function fetchPropertyStay(id) {
  return function(dispatch) {
    dispatch(resource.fetchingSingular);
    apiRequest(`inbox/${id}`, "GET")
      .then(response => {
        dispatch(resource.successSingular([response.data.id], response.data));
      })
      .catch(error => {
        console.error(error);
        dispatch(resource.errorSingular(error));
      });
  };
}

export function fetchPropertyStays(id) {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`stays/property${id !== "all" ? "/" + id : ""}`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export default resource.reducer;
