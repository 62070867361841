import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";

import Hero from "./Hero";
import ScrollMore from "./ScrollMore";
import Sanctuary from "./Sanctuary";
import HowItWorks from "components/HowItWorks/HowItWorks";
import BetterGuest from "./BetterGuest"
import CookieConsent from "react-cookie-consent";

import common from "@material-ui/core/colors/common";
const { white } = common;

const styles = theme => ({
  learnMore: {
    color: white,
    fontSize: 10,
    paddingLeft: 4
  }
});

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Hero {...this.props} />
        <Hidden smDown>
          <ScrollMore />
        </Hidden>
        <Sanctuary />
        <Hidden smDown>
          <ScrollMore />
        </Hidden>
        <BetterGuest />
        <Hidden smDown>
          <ScrollMore />
        </Hidden>
        <HowItWorks />
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="vrbffconsent"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            background: "#3898EC",
            color: "#ff",
            fontSize: "13px"
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          <Link to="/privacy-policy" className={classes.learnMore}>
            <span>Learn more >></span>
          </Link>
        </CookieConsent>
      </React.Fragment>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Home));
