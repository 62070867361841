import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  container: {
    backgroundColor: "#fff",
    boxShadow: theme.shadow.light,
    borderRadius: 4
  },
  secondContainer: {
    padding: 32,
    backgroundColor: "#f8f8f8",
    borderLeft: "1px solid #efefef",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      borderTop: "1px solid #efefef"
    }
  }
});

class WizardStepContainer extends Component {
  render() {
    const { classes, Component, SecondComponent, darkComponent } = this.props;
    return (
      <div
        className={classes.container}
        style={{
          backgroundColor: darkComponent ? "#F4F4F4" : "#FFF"
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={SecondComponent ? 6 : 12}
            style={{ padding: 40 }}
          >
            {Component}
          </Grid>
          {SecondComponent && (
            <Grid item xs={12} md={6} className={classes.secondContainer}>
              {SecondComponent}
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { name: "WizardStepContainer" })(
  WizardStepContainer
);
