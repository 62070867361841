// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MaterialLink from "@material-ui/core/Link";

export const styles = theme => ({
  number: {
    marginTight: 24
  },
  navLink: {
    fontWeight: "500",
    color: theme.palette.text.main,
    paddingBottom: 36,
    textDecoration: "none",
    textAlign: "right",
    transition: theme.transitions.create("all"),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    fontSize: 16,
    cursor: "default",
    "&:hover": {
      textDecoration: "none"
    }
  },
  clickableItem: {
    cursor: "pointer",
    "&:hover": {
      color: "#333"
    }
  },
  doneItem: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  activeItem: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontSize: 16,
    "&:hover": {
      color: theme.palette.text.main
    }
  },
  icon: {
    transition: theme.transitions.create("all"),
    marginLeft: theme.spacing(2.5),
    width: "22px"
  },
  checkCircle: {
    color: "#40A5CB"
  },
  radioboxBlank: {
    color: "#DEDEDE"
  },
  radioboxActive: {
    color: "#40A5CB"
  },
  checkCircleActive: {
    marginLeft: 38,
    width: 20,
    height: 20,
    backgroundColor: "white",
    border: "5px solid #465172",
    borderRadius: 10
  },
  checkCircleFull: {
    marginLeft: 38,
    width: 20,
    height: 20,
    backgroundColor: "#465172",
    border: "5px solid #465172",
    borderRadius: 10,
    position: "relative",
    "&:before": {
      top: "15px",
      right: "4px",
      width: "2px",
      height: "37px",
      content: '""',
      position: "absolute",
      background: "#465172",
      marginLeft: "-2px"
    }
  },
  checkCircleEmpty: {
    marginLeft: 38,
    width: 20,
    height: 20,
    border: "2.5px solid " + theme.palette.secondary.border,
    borderRadius: 10,
    position: "relative",
    "&:before": {
      bottom: "16px",
      right: "7px",
      width: "2px",
      height: "37px",
      content: '""',
      position: "absolute",
      background: "#D5D5D5",
      marginLeft: "-2px"
    }
  }
});

class WizardListButton extends Component {
  static propTypes = {
    children: PropTypes.node,
    currentPath: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    to: PropTypes.string
  };
  render() {
    const {
      classes,
      currentPath,
      title,
      to,
      isDone,
      isCancel,
      noIcon,
      isActive,
      count,
      ...rest
    } = this.props;
    let linkClass = classNames(
      classes.navLink,
      { [classes.clickableItem]: isDone || isCancel },
      {
        [classes.doneItem]: isDone
      },
      { [classes.activeItem]: isActive }
    );

    return (
      <MaterialLink color="textPrimary" className={linkClass} {...rest}>
        {/* <span className={classes.number}>{count}</span> */}
        <span>{title}</span>
        {!noIcon && isDone && <span className={classes.checkCircleFull}></span>}
        {!noIcon && !isDone && (
          <span
            className={
              isActive ? classes.checkCircleActive : classes.checkCircleEmpty
            }
          ></span>
        )}
      </MaterialLink>
    );
  }
}

export default withStyles(styles, { name: "WizardListButton" })(
  WizardListButton
);
