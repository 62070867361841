import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import * as groupsActionCreators from "../../redux/modules/groups";
import * as ratesActionCreators from "../../redux/modules/rates";
import Loading from "components/Progress/Loading";
import dasherize from "dasherize";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import MainActionButton from "components/Buttons/MainActionButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PricingDialog from "components/Dialogs/PricingDialog";
import AddGroupDialog from "components/Dialogs/AddGroupDialog";
import { rateTypeToText } from "helpers/stays";
import { getErrorMessage } from "helpers/utils";
import DeleteDialog from "components/Dialogs/DeleteDialog";

const styles = theme => ({
  guests: {
    marginTop: theme.spacing(3)
  },
  emailInput: {
    color: "#00000044",
    marginRight: theme.spacing(2),
    "&.Mui-focused": {
      color: "#40A5CB"
    }
  },
  editorContainer: { marginTop: 20 },

  heading: {
    fontSize: theme.typography.pxToRem(15)
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20
  },
  details: {
    alignItems: "center"
  },
  column: {
    flexBasis: "33.33%"
  },
  columnRight: {
    flexBasis: "33.33%",
    textAlign: "right"
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  setupAccount: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  table: {
    width: "100%"
  },
  guestsCount: {
    color: "rgba(0,0,0,0.54)",
    fontSize: "small"
  }
});

class GroupsPricing extends Component {
  state = {
    email: "",
    hosts: [],
    expanded: {}
  };

  async continueCheck() {
    return new Promise(async resolve => {
      this.setState({ addGroupDialog: true });
      // const { data } = this.props;

      // if (data && data.propertyId) return resolve(true);
      // this.props.snackbarMessage({
      //   message: "You have to select a property",
      //   type: "warning"
      // });
      resolve("HACK NEVER");
    });
  }
  saveAddGroup = async name => {
    let group = { name: name, property: { id: this.props.propertyId } };

    try {
      await this.props.addGroup(group);
      await this.loadGroups();
      this.closeAddGroupDialog();
    } catch (e) {
      console.log("error save add group", e);
    }
  };
  closeAddGroupDialog = () => {
    this.setState({ addGroupDialog: false });
  };

  async componentDidMount() {
    this.loadGroups();
  }

  async loadGroups() {
    try {
      this.setState({ isLoading: true });
      await this.props.fetchGroupsForProperty(this.props.propertyId);
      this.setState({ isLoading: false });
    } catch (error) {
      console.log("error load groups", error);
      this.setState({ isLoading: false });
    }
  }

  openPricingDialog = (groupId, pricingDialogData) => () => {
    this.setState({
      pricingDialogOpen: true,
      pricingDialogGroupId: groupId,
      pricingDialogData
    });
  };

  closePricingDialog = () => {
    this.setState({
      pricingDialogOpen: false,
      pricingDialogGroupId: false,
      pricingDialogData: false
    });
  };
  savePricing = async pricing => {
    pricing.property = { id: this.props.propertyId };
    pricing.group = { id: this.state.pricingDialogGroupId };
    if (pricing.startDate) {
      pricing.startDate = pricing.startDate.format("YYYY-MM-DD");
      pricing.endDate = pricing.endDate.format("YYYY-MM-DD");
    }
    if (pricing.price) {
      pricing.price = parseInt(pricing.price || 0);
    }
    try {
      if (pricing.id) {
        await this.props.updateRate(dasherize(pricing));
      } else {
        await this.props.addRate(dasherize(pricing));
      }
      await this.loadGroups();
      this.closePricingDialog();
    } catch (e) {
      console.log("error saving a price", e);
    }
  };

  handleExpansionChange = name => (event, isExpanded) => {
    let expanded = { ...this.state.expanded };
    expanded[name] = isExpanded;
    this.setState({ expanded });
  };
  handleOpenAddPricing = name => (event, isExpanded) => {
    let expanded = { ...this.state.expanded };
    expanded[name] = true;
    this.openPricingDialog(name)();
    this.setState({ expanded });
  };

  openRateDeleteDialog = data => () => {
    this.setState({ deleteRateDialogOpen: true, deleteRateDialogData: data });
  };
  closeRateDeleteDialog = () => {
    this.setState({ deleteRateDialogOpen: false });
  };

  onDeleteRate = async id => {
    try {
      this.setState({ isDeletingRate: true });
      await this.props.deleteRate(id);
      this.props.appSnackbarMessage({
        message: "The rate was sucesfully deleted"
      });
      await this.loadGroups();
      this.setState({ isDeletingRate: false, deleteRateDialogOpen: false });
    } catch (error) {
      console.error("error:", error);
      let deleteRateError = getErrorMessage(
        error,
        "There was a problem deleting the Rate."
      );
      this.props.appSnackbarMessage({
        message: deleteRateError
      });
    }
  };

  render() {
    const { classes, groups, property } = this.props;
    const { isLoading, expanded } = this.state;
    let ourGroups = groups.ids.length > 0 ? groups.ids : false;
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="caption" component="div">
              {`Groups allow you to organize your guests based on what you would want them to pay. Some people might just pay the cleaning fee, others might have a nightly rate. You can have as many groups as you’d like along with specific fees associated (or not in some cases) for each group.`}
            </Typography>
            <div className={classes.setupAccount}>
              <MainActionButton to={`/manage/guests/${this.props.propertyId}`}>
                Edit Group Memberships
              </MainActionButton>{" "}
            </div>

            {(!property.connectAccount || !property.connectAccount.last4) && (
              <div className={classes.setupAccount}>
                <Typography variant="caption" component="div">
                  Looks like you did not set up your bank account to be able to
                  get paid
                </Typography>
                <MainActionButton
                  to={`/manage/property/paymenthandling/${this.props.propertyId}`}
                >
                  Setup Bank Account
                </MainActionButton>{" "}
              </div>
            )}
            {/* <StripeAccountConnect /> */}
            {ourGroups &&
              ourGroups.map(gr => {
                const g = groups[gr];
                return (
                  <ExpansionPanel
                    expanded={expanded[g.id] || false}
                    onChange={this.handleExpansionChange(g.id)}
                    key={g.id}
                  >
                    <ExpansionPanelSummary
                      expandIcon={
                        <>
                          <ExpandMoreIcon />
                        </>
                      }
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                    >
                      <div className={classes.column}>
                        <Typography className={classes.heading}>
                          {g.name}{" "}
                          <span className={classes.guestsCount}>
                            ({g.guestsCount}{" "}
                            {g.guestsCount === 1 ? "member" : "members"})
                          </span>
                        </Typography>
                      </div>
                      <div className={classes.column}>
                        <Typography className={classes.secondaryHeading}>
                          {g.rates && g.rates.length > 0
                            ? "Has Pricing"
                            : "Does not have pricing"}
                        </Typography>
                      </div>
                      <div className={classes.columnRight}>
                        {(!g.rates || g.rates.length < 1) &&
                          !(expanded[g.id] || false) && (
                            <MainActionButton
                              isSmall
                              onClick={this.handleOpenAddPricing(g.id)}
                            >
                              Add Pricing
                            </MainActionButton>
                          )}
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                      <div className={classes.table}>
                        {g.rates && g.rates.length > 0 && (
                          <Table
                            className={classes.table}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Price</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Dates</TableCell>
                                <TableCell align="right">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {g.rates.map(r => (
                                <TableRow key={r.id}>
                                  <TableCell component="th" scope="row">
                                    ${r.price}
                                  </TableCell>
                                  <TableCell>{r.name}</TableCell>
                                  <TableCell>
                                    {rateTypeToText(r.type)}
                                  </TableCell>
                                  <TableCell>
                                    {r.startDate
                                      ? `${r.startDate} - ${r.endDate}`
                                      : ""}
                                  </TableCell>
                                  <TableCell align="right">
                                    <DeleteIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={this.openRateDeleteDialog(r)}
                                    />
                                    <EditIcon
                                      style={{ cursor: "pointer" }}
                                      onClick={this.openPricingDialog(g.id, r)}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        )}
                      </div>
                    </ExpansionPanelDetails>
                    <Divider />
                    <ExpansionPanelActions>
                      <MainActionButton onClick={this.openPricingDialog(g.id)}>
                        Add pricing
                      </MainActionButton>
                      {!g.isGlobal && (
                        <MainActionButton isDanger>
                          Remove Group
                        </MainActionButton>
                      )}
                    </ExpansionPanelActions>
                  </ExpansionPanel>
                );
              })}
            {isLoading && <Loading />}

            <DeleteDialog
              closeDialog={this.closeRateDeleteDialog}
              isDeleting={this.state.isDeletingRate}
              onDelete={this.onDeleteRate}
              open={this.state.deleteRateDialogOpen || false}
              resource={this.state.deleteRateDialogData}
            />
            <PricingDialog
              data={this.state.pricingDialogData}
              key={this.state.pricingDialogGroupId}
              open={this.state.pricingDialogOpen || false}
              closeDialog={this.closePricingDialog}
              onSave={this.savePricing}
              resource={{}}
            ></PricingDialog>
            {this.state.addGroupDialog && (
              <AddGroupDialog
                open={this.state.addGroupDialog}
                closeDialog={this.closeAddGroupDialog}
                onSave={this.saveAddGroup}
                resource={{}}
              ></AddGroupDialog>
            )}
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    groups: state.groups,
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators,
      ...groupsActionCreators,
      ...ratesActionCreators
    },
    dispatch
  );
}

GroupsPricing = withStyles(styles, { name: "GroupsPricing" })(GroupsPricing);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(GroupsPricing);
