import { isPOBox, isValidStateCode } from "../../helpers/validators";

const validate = (values, props) => {
  const errors = {
    address: {},
    mailing: {}
  };

  if (values.address) {
    // validate address
    if (!values.address.address1) {
      errors.address["address1"] = "Enter a valid street address.";
    }
    if (values.address.address1 && isPOBox(values.address.address1)) {
      errors.address["address1"] =
        "Primary street address can not be a PO Box.";
    }
    if (!values.address.city) {
      errors.address["city"] = "Enter a valid city.";
    }
    if (
      !values.address.region ||
      !isValidStateCode(values.address.region, values.address.country)
    ) {
      errors.address["region"] =
        'Two letter capitalized abbreviation required ("CA", "NY", etc.)';
    }
    if (!values.address.postalCode) {
      errors.address["postalCode"] = "Enter a valid zip or postal code.";
    }
    if (!values.address.country) {
      errors.address["country"] = "Enter a valid country.";
    }
  }

  return errors;
};

export default validate;
