import React, { Component } from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Popper from "@material-ui/core/Popper";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import * as datepickerActionCreators from "../../redux/modules/datepicker";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const MAXIMUM_DAYS = 365;

class DatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      value: props.initialDate ? props.initialDate : null,
      anchorEl: null
    };

    this.buttonRef = React.createRef();
  }
  handleClick = event => {
    this.setState({
      anchorEl: this.state.anchorEl ? null : event ? event.currentTarget : null
    });
  };

  openCalendar = () => {
    this.setState({
      anchorEl: this.buttonRef.current
    });
  };

  onSelect = (value, states) => {
    if (value.start.isSame(value.end, "day")) return;

    this.setState({ value, states, anchorEl: null });
    if (this.props.onSelect) this.props.onSelect(value);
  };

  componentDidMount() {
    if (this.props.notLoad) return;

    if (this.props.propertyId && this.props.propertyId !== "all")
      this.props.fetchDates(this.props.propertyId);
  }

  renderSelectionValue = () => {
    const { value } = this.state;
    return `${(value && value.start.format("MM/DD/YYYY")) ||
      this.props.arriveText ||
      "Arrive"} -> ${(value && value.end.format("MM/DD/YYYY")) ||
      this.props.departText ||
      "Depart"}`;
  };

  getDateRanges = () => {
    if (!this.props.datepicker || !this.props.datepicker.ids) return [];
    return this.props.datepicker.ids
      .filter(id => {
        if (this.props.blockId && this.props.blockId === id) return false;
        return true;
      })
      .map(id => {
        let dates = this.props.datepicker[id];

        return {
          range: moment.range(moment(dates.startDate), moment(dates.endDate)),
          state: this.prepareStatus(dates.status)
        };
      });
  };
  prepareStatus = status => {
    if (status !== "Pending") return "Accepted";

    return status;
  };
  reset = value => {
    this.setState({ value });
  };

  render() {
    const { anchorEl } = this.state;
    const { disabled, style, maximumDays, isPendingBlocked } = this.props;
    const open = Boolean(anchorEl);
    const id = open ? "no-transition-popper" : undefined;
    const stateDefinitions = {
      available: {
        color: null,
        label: "Available"
      },
      Pending: {
        color: "#ffd200",
        label: "Pending",
        selectable: isPendingBlocked ? false : true
      },
      Accepted: {
        selectable: false,
        color: "#78818b",
        label: "Booked"
      }
    };

    return (
      <div>
        <Button
          ref={this.buttonRef}
          disabled={disabled}
          variant="outlined"
          size="large"
          onClick={this.handleClick}
          fullWidth
          style={{
            height: 48,
            justifyContent: "flex-start",
            textTransform: "none",
            fontWeight: 400,
            fontSize: "16px",
            textAlign: "left",
            paddingLeft: 32,
            borderRadius: 4,
            borderColor: "#D5D5D5",
            ...style
          }}
        >
          {this.renderSelectionValue()}
        </Button>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          style={{
            zIndex: 2000
          }}
          onClose={this.handleClick}
        >
          <Paper>
            <ClickAwayListener onClickAway={this.handleClick}>
              <DateRangePicker
                value={this.state.value}
                onSelect={this.onSelect}
                singleDateRange={true}
                numberOfCalendars={2}
                firstOfWeek={1}
                minimumDate={new Date()}
                maximumDate={moment()
                  .add(maximumDays || MAXIMUM_DAYS, "days")
                  .toDate()}
                stateDefinitions={stateDefinitions}
                dateStates={this.getDateRanges()}
                defaultState="available"
                showLegend={true}
              />
            </ClickAwayListener>
          </Paper>
        </Popper>
      </div>
    );
  }
}

function mapStateToProps({ datepicker }, props) {
  return {
    hasFetched: datepicker.hasFetched,
    isFetching: datepicker.isFetching,
    datepicker
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...datepickerActionCreators
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(DatePicker);
