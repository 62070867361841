import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "components/General/Avatar";
import withRoot from "../../withRoot";
import { formatDateWithMonthAndTime } from "../../helpers/utils";
import { parseMessageText } from "helpers/stays";
import { Grid, Paper } from "@material-ui/core";

const styles = theme => ({
  container: {
    marginTop: theme.spacing(4)
  },
  leftContainer: {
    display: "flex",
    alignItems: "center"
  },
  userName: {
    fontWeight: "bold",
    paddingLeft: 20,
    paddingRight: theme.spacing(1)
  },
  date: {
    marginTop: theme.spacing(2),
    fontWeight: "bold",
    fontSize: 12,
    color: "#787A80"
  },
  host: {
    backgroundColor: "#C5E4B8",
    height: 24,
    borderRadius: 16,
    padding: "4px 16px"
  },
  messageContainer: {
    borderRadius: 4,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 28,
    paddingBottom: 28
  },
  hostTag: {
    marginLeft: 68
  }
});

const MessageItem = ({ message, classes, fromHost }) => {
  return (
    <Grid
      container
      spacing={2}
      key={"message" + message.id}
      className={classes.container}
    >
      <Grid item sm={3}>
        <div className={classes.leftContainer} style={{ width: "100%" }}>
          <Avatar
            isBig
            firstName={message.user.firstName}
            lastName={message.user.lastName}
            src={message.user.picture}
          />
          <span className={classes.userName}>
            {message.user.firstName} {message.user.lastName[0]}.
          </span>
        </div>
        {fromHost && (
          <div className={classes.hostTag}>
            <span className={classes.host}>Host</span>
          </div>
        )}
      </Grid>
      <Grid item sm={9}>
        <Paper elevation={4} className={classes.messageContainer}>
          <Typography variant="body1" component="p" gutterBottom>
            {parseMessageText(message.content)}
          </Typography>
          <div className={classes.date}>
            {formatDateWithMonthAndTime(new Date(message.createdAt * 1000))}
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default withRoot(withStyles(styles)(MessageItem));
