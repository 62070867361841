import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import ManageAccount from "components/Account/ManageAccount";

class AccountContainer extends Component {
  componentDidMount() {}

  uploadPicture = async (files, cb) => {
    await this.props.uploadProfileImage(files, {}, cb);
    this.props.auth.setReduxAuth();
  };
  render() {
    return (
      <ManageAccount
        uploadPicture={this.uploadPicture}
        data={this.props.authData}
      />
    );
  }
}

function mapStateToProps({ app }, props) {
  return {
    authData: (app.auth && app.auth.data) || false
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
