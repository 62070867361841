import axios from "axios";
import { getToken } from "../../components/Auth/Auth";

const initialState = { notificationCounter: 0, loaded: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "APP_SNACKBAR_MESSAGE":
      return { ...state, snackbar: action.data };
    case "APP_SNACKBAR_CLOSE":
      return { ...state, snackbar: undefined };
    case "APP_SET_NOTIFICATION_COUNTER":
      return { ...state, notificationCounter: action.counter };
    case "APP_LOADED":
      return { ...state, loaded: true };
    case "SET_AUTH":
      return { ...state, auth: action.auth };
    default:
      return state;
  }
};

export function appSnackbarMessage(data) {
  return function(dispatch) {
    dispatch({
      type: "APP_SNACKBAR_MESSAGE",
      data
    });
  };
}

export function appSnackbarClose(data) {
  return function(dispatch) {
    dispatch({
      type: "APP_SNACKBAR_CLOSE"
    });
  };
}

export function uploadProfileImage(files, params = {}, cb) {
  return async function(dispatch) {
    let uploads = [];
    // build uploads first
    for (let i = 0; i < files.length; i++) {
      // add to uploads
      let file = files[i];
      let filename = file.name;
      uploads[i] = { id: i, filename, progress: 0, error: null };
    }
    for (let i = 0; i < files.length; i++) {
      try {
        let file = files[i];
        let filename = file.name;
        // set index to id
        file.id = i;
        // set the file params
        let fparams = params;
        fparams.filename = filename;
        let resp = await uploadRequest(
          `me/picture`,
          "POST",
          file,
          fparams,
          dispatch,
          uploads
        );
        if (resp.status !== 200) {
          let errorMessage = resp.data.errors[0].title;
          uploads[i].error = errorMessage;
          if (cb) cb(false, errorMessage);
          return;
        }
        if (cb) cb(true);
      } catch (error) {
        if (cb) cb(false, error);
        console.error("error:", error);
      }
    }
  };
}

export async function uploadRequest(
  path,
  method,
  data,
  params = {},
  dispatch,
  uploads
) {
  const token = getToken();
  let progress = 0;
  return await axios({
    headers: { Authorization: "Bearer " + token },
    method,
    params,
    onUploadProgress: function(progressEvent) {
      progress = Math.floor(
        Number(Number(progressEvent.loaded) / Number(data.size)) * 100
      );
      uploads[data.id].progress = progress;
    },
    url: `${process.env.REACT_APP_VRBFF_API}/${path}`,
    data
  });
}

export default reducer;
