import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

const styles = theme => ({
  avatar: {
    boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: 30,
    height: 30,
    marginRight: theme.spacing(2)
  },
  hostedText: {
    marginBottom: 0
  },
  hostedBy: {
    borderBottom: "1px solid #ddd",
    display: "flex",
    alignItems: "center",
    height: 70
  },
  hostedByNoBorder: {
    display: "flex",
    alignItems: "center",
    height: 70
  }
});

const HostedBy = ({ classes, user, isRequest, noBorder, className }) => (
  <div
    className={
      (className && className) || noBorder
        ? classes.hostedByNoBorder
        : classes.hostedBy
    }
  >
    {user.picture && (
      <Avatar alt={user.name} src={user.picture} className={classes.avatar} />
    )}
    <Typography variant="caption" className={classes.hostedText}>
      {isRequest ? "requested by" : "Hosted by"}
      <b>{" " + user.firstName + " " + user.lastName}</b>
    </Typography>
  </div>
);

export default withStyles(styles, { name: "HostedBy" })(HostedBy);
