import React from "react";

import { withStyles } from "@material-ui/core/styles";
import MaterialAvatar from "@material-ui/core/Avatar";
import classNames from "classnames";
import PersonIcon from "@material-ui/icons/Person";

const SMALL_AVATAR = 24;
const NORMAL_AVATAR = 30;
const BIG_AVATAR = 48;
const HUGE_AVATAR = 100;
const PROPERTY_AVATAR = 68;
function stringToHslColor(str, s, l) {
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  var h = hash % 360;
  return "hsl(" + h + ", " + s + "%, " + l + "%)";
}

const styles = theme => ({
  avatar: {
    // boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: NORMAL_AVATAR,
    height: NORMAL_AVATAR
  },
  avatarBig: {
    // boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: BIG_AVATAR,
    height: BIG_AVATAR,
    boxShadow: "#fff 0 0 0 2px, white 0 0 0 2px"
  },
  avatarProperty: {
    width: PROPERTY_AVATAR,
    height: PROPERTY_AVATAR,
    boxShadow: "#fff 0 0 0 2px, white 0 0 0 2px"
  },
  avatarHuge: {
    // boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: HUGE_AVATAR,
    height: HUGE_AVATAR
  },
  avatarSmall: {
    // boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: SMALL_AVATAR,
    height: SMALL_AVATAR
  },
  avatarSmallHost: {
    boxShadow: "#fff 0 0 0 2px, #FFF 0 0 0 4px",
    width: SMALL_AVATAR + 4,
    height: SMALL_AVATAR + 4
  }
});

const Avatar = ({
  isBig,
  isSmall,
  className,
  classes,
  src,
  firstName,
  lastName,
  isHuge,
  isSmallHost,
  isProperty
}) => {
  let avatarClass = classes.avatar;
  if (isHuge) avatarClass = classes.avatarHuge;
  if (isBig) avatarClass = classes.avatarBig;
  if (isSmall) avatarClass = classes.avatarSmall;
  if (isSmallHost) avatarClass = classes.avatarSmallHost;
  if (isProperty) avatarClass = classes.avatarProperty;
  const fName = firstName || "";
  const lName = lastName || "";
  const name = fName + " " + lName;
  if (!src && (firstName || lastName)) {
    return (
      <MaterialAvatar
        className={classNames(avatarClass, className)}
        style={{ backgroundColor: stringToHslColor(name, 60, 60) }}
        alt={name}
      >
        {((fName[0] || "") + (lName[0] || "")).toUpperCase()}
      </MaterialAvatar>
    );
  }
  if (!src) {
    return (
      <MaterialAvatar className={classNames(avatarClass, className)}>
        <PersonIcon />
      </MaterialAvatar>
    );
  }

  return (
    <MaterialAvatar
      className={classNames(avatarClass, className)}
      src={src}
      alt={name}
    />
  );
};

export default withStyles(styles, { name: "Avatar" })(Avatar);
