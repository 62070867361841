import { apiRequest, createResource, updateResource } from "apis/jsonApi";
import Resource from "./resource";
import { getErrorMessage } from "helpers/utils";

let resource = new Resource({
  singularName: "trip",
  pluralName: "trips"
});

export function fetchTrip(id) {
  return function(dispatch) {
    dispatch(resource.fetchingSingular);
    apiRequest(`inbox/${id}`, "GET")
      .then(response => {
        dispatch(resource.successSingular([response.data.id], response.data));
      })
      .catch(error => {
        dispatch(resource.errorSingular(error));
      });
  };
}

export function updateTrip(values) {
  return function(dispatch, getState) {
    dispatch(resource.updatingSingular());
    const trip = updateResource("trip", values)
      .then(response => {
        dispatch(resource.successUpdatingSingular(response));
        return response;
      })
      .catch(error => {
        dispatch(resource.errorUpdatingSingular(error));
        throw error;
      });
    return trip;
  };
}

export function deleteTrip(id) {
  return async function(dispatch, getState) {
    dispatch(resource.deletingSingular());
    try {
      await apiRequest(`trips/${id}`, "DELETE").then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function addTrip(values) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    const ids = getState().trips.ids;
    try {
      const trip = await createResource("trip", values);
      if (trip) {
        ids.unshift(trip.id);
        dispatch(resource.successAddingSingular(ids, trip));
      }
      return trip;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export default resource.reducer;
