import React from "react";
import Fade from "react-reveal/Fade";

import Grid from "@material-ui/core/Grid";
import ExternalLink from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";

import LinkedInIcon from "components/Icons/LinkedInIcon";
import TwitterIcon from "components/Icons/TwitterIcon";

import teamIcon from "../../static/assets/icons/team.svg";
import common from "@material-ui/core/colors/common";
const { white } = common;

export const styles = theme => ({
  root: {
    paddingLeft: 200,
    paddingRight: 200,
    paddingTop: 120,
    paddingBottom: 100
  },
  exLink: {
    padding: 0
  },
  iconTitle: {
    height: 32,
    paddingRight: 19
  },
  imgProfile: {
    height: 85
  },
  paper: {
    backgroundColor: "#F5F5F5",
    padding: 64,
    fontSize: 16,
    lineHeight: 1.8,
    marginBottom: 24
  },
  profile: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 55
  },
  profileInfo: {
    paddingLeft: 23,
    paddingRight: 30
  },
  profileName: {
    fontSize: 24,
    fontWeight: "bold"
  },
  profileTitle: {
    fontSize: 16
  },
  profileLinks: {
    paddingTop: 32
  },
  socialIcon: {
    height: 40,
    width: 40,
    "--color-1": theme.palette.primary.dark,
    "--color-2": theme.palette.secondary.main,
    paddingRight: 8,
    "&:hover": {
      "--color-1": white,
      "--color-2": theme.palette.text.main
    }
  },
  team: {
    paddingTop: 64
  },
  teamIcon: {
    height: 48,
    width: 48
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 48
  },
  titleText: {
    fontSize: 32,
    fontWeight: "bold",
    paddingLeft: 32
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 80
    },
    imgProfile: {
      height: 58
    },
    paper: {
      padding: 32,
      marginBottom: 64
    },
    profile: {
      paddingTop: 0
    },
    profileInfo: {
      paddingLeft: 16,
      paddingRight: 0
    },
    profileLinks: {
      paddingTop: 20,
      paddingBottom: 20
    },
    team: {
      paddingTop: 32
    },
    teamIcon: {
      height: 40,
      width: 40
    },
    title: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: 32
    },
    titleText: {
      fontSize: 24,
      fontWeight: "bold",
      paddingLeft: 21
    }
  }
});

const Team = props => {
  const { classes } = props;
  return (
    <Fade>
      <div className={classes.root}>
        <div className={classes.title}>
          <img alt="team icon" src={teamIcon} className={classes.teamIcon} />
          <div className={classes.titleText}>Team</div>
        </div>
        <div className={classes.team}>
          <Grid container>
            <Grid item xs={12} lg={4} className={classes.profile}>
              <img
                alt="scott kveton"
                src="/images/about/team/scott-profile.png"
                className={classes.imgProfile}
              />
              <div className={classes.profileInfo}>
                <div className={classes.profileName}>Scott Kveton</div>
                <div className={classes.profileTitle}>Co-Founder &amp; CEO</div>
                <div className={classes.profileLinks}>
                  <ExternalLink
                    rel="noopener"
                    target="_blank"
                    href="https://www.linkedin.com/in/kveton/"
                    className={classes.exLink}
                  >
                    <LinkedInIcon className={classes.socialIcon} />
                  </ExternalLink>
                  <ExternalLink
                    rel="noopener"
                    target="_blank"
                    href="https://twitter.com/kveton"
                    className={classes.exLink}
                  >
                    <TwitterIcon className={classes.socialIcon} />
                  </ExternalLink>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Paper className={classes.paper} elevation={0}>
                Scott is a passionate entrepreneur with 20+ years of experience
                building and leading teams and companies that make products at
                Internet scale. He has gotten to work at some amazing places
                like Amazon, Rulespace and MassRoots as well as co-founding
                several companies including Urban Airship (now Airship), Odava
                and Vrbff. Scott has raised over $50 million in venture capital
                and focuses on building great teams, developing strategy,
                corporate development, creating positive, passionate cultures
                and recruiting top talent.
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.profile}>
              <img
                alt="brent hieggelke"
                src="/images/about/team/brent-profile.png"
                className={classes.imgProfile}
              />
              <div className={classes.profileInfo}>
                <div className={classes.profileName}>Brent Hieggelke</div>
                <div className={classes.profileTitle}>Advisory Board</div>
                <div className={classes.profileLinks}>
                  <ExternalLink
                    rel="noopener"
                    target="_blank"
                    href="https://www.linkedin.com/in/brenthieggelke/"
                    className={classes.exLink}
                  >
                    <LinkedInIcon className={classes.socialIcon} />
                  </ExternalLink>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Paper className={classes.paper} elevation={0}>
              High growth CMO / marketing executive comfortable with chaos in start-up and fast-paced environments. Very experienced with marketing turnarounds and reboots for start-ups and growth stage companies across early and emerging categories.
Seasoned evangelist, keynote, and panelist across all media, marketing, and mobile topics. Start-up executive (CEO of Second Porch where he built the first sharing economy vacation rental site in 2009 and sold it to HomeAway in 2011 pre-IPO).
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.profile}>
              <img
                alt="dan warner"
                src="/images/about/team/dan-profile.png"
                className={classes.imgProfile}
              />
              <div className={classes.profileInfo}>
                <div className={classes.profileName}>Dan Warner</div>
                <div className={classes.profileTitle}>Advisory Board</div>
                <div className={classes.profileLinks}>
                  <ExternalLink
                    rel="noopener"
                    target="_blank"
                    href="https://www.linkedin.com/in/dwarner/"
                    className={classes.exLink}
                  >
                    <LinkedInIcon className={classes.socialIcon} />
                  </ExternalLink>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Paper className={classes.paper} elevation={0}>
              Dan is a strong technical lead and has experience with companies like ShopIgniter and Lululemon. He loves building software as well as staying in vacation homes.
              </Paper>
            </Grid>
            <Grid item xs={12} lg={4} className={classes.profile}>
              <img
                alt="eric mason"
                src="/images/about/team/eric-profile.png"
                className={classes.imgProfile}
              />
              <div className={classes.profileInfo}>
                <div className={classes.profileName}>Eric Mason</div>
                <div className={classes.profileTitle}>Advisory Board</div>
                <div className={classes.profileLinks}>
                  <ExternalLink
                    rel="noopener"
                    target="_blank"
                    href="https://www.linkedin.com/in/wericmason/"
                    className={classes.exLink}
                  >
                    <LinkedInIcon className={classes.socialIcon} />
                  </ExternalLink>
                  <ExternalLink
                    rel="noopener"
                    target="_blank"
                    href="https://twitter.com/VRGuy"
                    className={classes.exLink}
                  >
                    <TwitterIcon className={classes.socialIcon} />
                  </ExternalLink>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Paper className={classes.paper} elevation={0}>
                Eric has held leadership positions throughout the services and
                venture sectors and has successfully started, developed, grew
                and repositioned various organizations. An accomplished
                strategist, he understands consumers and market trends by
                conducting constant in-depth quantitative and qualitative
                research and turning that research into actionable strategies
                allowing companies to achieve and exceed desired growth goals
                while maintaining a high degree of internal cohesiveness. Eric
                also bridges the divide between traditional industries and
                emerging industries one in which he was instrumental in
                facilitating, the shared accommodation industry known as the
                Vacation Rental or Hosted Accommodation industry.
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    </Fade>
  );
};

export default withStyles(styles, { name: "Team" })(Team);
