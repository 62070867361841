import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as tripsActionCreators from "../../redux/modules/trips";
import * as messagesActionCreators from "../../redux/modules/messages";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InboxContainer from "./InboxContainer";
import FollowRequestsContainer from "./FollowRequestsContainer";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

class StaysAndRequestsContainer extends Component {
  state = {
    errorMessage: null,
    snackbarMessage: null,
    currentTab: 0
  };

  handleChange = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  handleChangeIndex = index => {
    this.setState({ currentTab: index });
  };

  render() {
    const { currentTab } = this.state;

    return (
      <Fragment>
        <Paper>
          <Tabs
            value={currentTab}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Stay Requests" />
            <Tab label="Follow Requests" />
          </Tabs>
        </Paper>
        <TabPanel index={0} value={currentTab}>
          <InboxContainer {...this.props} />
        </TabPanel>
        <TabPanel index={1} value={currentTab}>
          <FollowRequestsContainer {...this.props} />
        </TabPanel>
      </Fragment>
    );
  }
}

function mapStateToProps({ trips }, props) {
  return {
    hasFetched: trips.hasFetched,
    isFetching: trips.isFetching,
    trips
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...tripsActionCreators,
      ...messagesActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaysAndRequestsContainer);
