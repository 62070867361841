import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import grey from "@material-ui/core/colors/grey";
import withRoot from "../../withRoot";
import Loading from "../Progress/Loading";
import HomeIcon from "../Icons/HomeHeartLight";
import PropertyGallery from "./PropertyGallery";
import PropertyExtensionPanel from "./PropertyExtensionPanel";
import RequestStay from "./RequestStay/RequestStay";
import SnackbarMessage from "../Message/SnackbarMessage";
import GoogleMap from "google-map-react";
import { Hidden } from "@material-ui/core";
import { amenitiesConfig } from "helpers/amenities";
import Avatar from "components/General/Avatar";
import locationIcon from "../../static/assets/icons/location.svg";
import arrowDownCircleIcon from "../../static/assets/icons/arrow-down-circle.svg";
import endPointCircleIcon from "../../static/assets/icons/end-point-circle.svg";
import HostsNew from "components/General/HostsNew";
import { cleanHtml } from "helpers/utils";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PropertySBB from "components/General/PropertySBB";

const styles = theme => ({
  title: {
    marginTop: 8,
    marginBottom: 16
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  actions: {
    marginTop: 16,
    position: "relative",
    display: "block",
    textAlign: "right",
    width: "100%"
  },
  buttonDelete: {
    marginRight: 8
  },
  buttonBack: {
    marginBottom: 8
  },
  container: {
    padding: 24
  },
  headline: {
    marginBottom: 16
  },
  icon: {
    color: grey[200],
    maxWidth: 440,
    [theme.breakpoints.up("xs")]: {
      width: 360
    },
    [theme.breakpoints.up("sm")]: {
      width: 440
    }
  },
  image: {
    height: 512,
    width: "auto"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto"
  },
  textField: {
    width: 286
  },
  containerMainContent: {
    padding: 32,
    paddingTop: 8
  },
  infoContainer: {
    borderBottom: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  iconInfo: {
    color: "#40A5CB",
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold"
  },
  infoTitle: {
    fontWeight: "bold"
  },
  amenitiesTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  descriptionContainer: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  requestTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  avatar: {
    boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: 30,
    height: 30,
    marginRight: theme.spacing(2)
  },
  hostedText: {
    marginBottom: 0
  },
  hostedBy: {
    display: "flex",
    alignItems: "center"
  },
  propertyLocation: {
    marginTop: theme.spacing(2)
  },
  containerSide: {},
  containerExpandning: {
    marginTop: theme.spacing(2)
  },
  houseRules: {
    padding: "0 " + theme.spacing(2) + "px"
  },
  containerTitle: {
    padding: 32,
    paddingBottom: 8
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    color: "#00000066",
    marginBottom: 0,
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(3),
    borderRight: "1px solid #00000066"
  },
  SBB: {
    paddingTop: theme.spacing(2),
    borderTop: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  amenitiesIcon: {
    marginRight: theme.spacing(1),
    fontSize: 22
  },
  amenitiesText: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  fieldTitle: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "24px",
    marginBottom: 9
  },
  location: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main
  },
  locationIcon: {
    height: 24,
    verticalAlign: "middle",
    marginRight: 8,
    color: theme.palette.primary.main
  },
  leftSideContainerEnd: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "100%",
    zIndex: 1,
    "&:before": {
      top: "0",
      marginLeft: "auto",
      width: "1px",
      height: 36,
      content: '""',
      position: "absolute",
      background: theme.palette.secondary.border,
      zIndex: 0
      // marginLeft: "-2px"
    }
  },
  leftSideContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    height: "100%",
    zIndex: 1,
    "&:before": {
      top: "0",
      marginLeft: "auto",
      marginBottom: -40,
      width: "1px",
      bottom: 0,
      content: '""',
      position: "absolute",
      background: theme.palette.secondary.border,
      zIndex: 0
      // marginLeft: "-2px"
    }
  },

  mainInfoContainer: {
    marginLeft: 24,
    border: "1px solid " + theme.palette.secondary.border,
    borderRadius: 4
  },
  mainInfoSubContainer: {
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 32,
    paddingBottom: 16
  },
  titleText: {
    color: "#0F0F10",
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    marginBottom: 8
  },
  mainInfoSubElements: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mainInfoHostsContainer: { display: "flex", alignItems: "center" },
  hosts: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main,
    marginBottom: 0,
    marginRight: 16
  },
  mainGeneralInfo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingTop: 16,
    borderTop: "1px solid #d5d5d5",
    paddingRight: 40,
    paddingLeft: 40,
    marginBottom: 8
  },
  generalInfoText: {
    fontWeight: 700,
    marginLeft: 8
  },
  leftSideImg: {
    marginTop: 36,
    alignSelf: "flex-start",
    zIndex: 1
  }
});

class ViewProperty extends Component {
  state = {
    startDate: null,
    endDate: null,
    focusedInput: null
  };

  getMap() {
    if (
      !this.props.property ||
      !this.props.property.address ||
      !this.props.property.address.location
    )
      return;
    let lat = this.props.property.address.location.latitude;
    let lng = this.props.property.address.location.longitude;
    // const randomLat =
    //   getRandomArbitrary(0.0005, 0.002) * (Math.random() >= 0.5 ? 1 : -1);
    // const randomLng =
    //   getRandomArbitrary(0.0005, 0.002) * (Math.random() >= 0.5 ? 1 : -1);
    // const center = { lat: lat + randomLat, lng: lng + randomLng };
    const center = { lat: lat, lng: lng };
    const Map = ({ latlng, zoom }) => (
      <div style={{ height: 400, borderRadius: 4 }}>
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_CLOUD_API }}
          defaultZoom={14}
          defaultCenter={center}
          onGoogleApiLoaded={({ map, maps }) =>
            /*global google*/
            new google.maps.Circle({
              strokeColor: "#40A5CB",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#40A5CB",
              fillOpacity: 0.3,
              map,
              center: center,
              radius: 500
            })
          }
        />
      </div>
    );

    return <Map />;
  }

  render() {
    const {
      property,
      classes,
      closeSnackbarMessage,
      handleRequestStay,
      isFetching,
      snackbarMessage
    } = this.props;

    const description =
      property && property.descriptionHtml
        ? cleanHtml(property.descriptionHtml)
        : false;
    const rules =
      property && property.rulesHtml ? cleanHtml(property.rulesHtml) : false;
    return (
      <main>
        {isFetching && <Loading />}
        {!isFetching && property && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {property.images.length === 0 && (
                <HomeIcon style={{ fontSize: 216 }} className={classes.icon} />
              )}
              {property.images.length > 0 && (
                <PropertyGallery
                  images={(property.images || []).sort(
                    (a, b) => a.sortOrder - b.sortOrder
                  )}
                />
              )}
              <Grid container style={{ marginTop: 40 }}>
                <Hidden smDown>
                  <Grid item md={1}>
                    <div className={classes.leftSideContainer}>
                      <Avatar isProperty src={property.image.url} />
                    </div>
                  </Grid>
                </Hidden>
                <Grid item sm={12} md={11}>
                  <div className={classes.mainInfoContainer}>
                    <div className={classes.mainInfoSubContainer}>
                      <div className={classes.titleText}>{property.name}</div>
                      <div className={classes.mainInfoSubElements}>
                        <Typography
                          variant="subtitle1"
                          className={classes.location}
                        >
                          <img
                            alt="location icon"
                            src={locationIcon}
                            className={classes.locationIcon}
                          />
                          {`${property.address.city}, ${property.address.region}`}
                        </Typography>
                        <div className={classes.mainInfoHostsContainer}>
                          <Typography
                            variant="subtitle1"
                            className={classes.hosts}
                          >
                            Hosts:{" "}
                          </Typography>{" "}
                          <HostsNew isSmall hosts={property.hosts} />
                        </div>
                      </div>
                    </div>
                    <div className={classes.mainGeneralInfo}>
                      <PropertySBB
                        sleeps={property.sleeps}
                        bathrooms={property.bathrooms}
                        bedrooms={property.bedrooms}
                      />
                    </div>
                  </div>
                </Grid>
                {description && (
                  <>
                    <Hidden smDown>
                      <Grid item sm={1} style={{ marginTop: 40 }}>
                        {!rules && property.amenities.length === 0 ? (
                          <div className={classes.leftSideContainerEnd}>
                            <img
                              alt={"end icon"}
                              src={endPointCircleIcon}
                              className={classes.leftSideImg}
                            />
                          </div>
                        ) : (
                          <div className={classes.leftSideContainer}>
                            <img
                              alt={"arrow down"}
                              src={arrowDownCircleIcon}
                              className={classes.leftSideImg}
                            />
                          </div>
                        )}
                      </Grid>
                    </Hidden>
                    <Grid
                      item
                      sm={12}
                      md={11}
                      style={{ marginTop: 40, paddingLeft: 24 }}
                    >
                      <PropertyExtensionPanel name={"From the host"}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: description
                          }}
                        ></div>
                      </PropertyExtensionPanel>
                    </Grid>
                  </>
                )}

                {property.amenities.length > 0 && (
                  <>
                    <Hidden smDown>
                      <Grid item sm={1} style={{ marginTop: 40 }}>
                        {!rules ? (
                          <div className={classes.leftSideContainerEnd}>
                            <img
                              alt={"end icon"}
                              src={endPointCircleIcon}
                              className={classes.leftSideImg}
                            />
                          </div>
                        ) : (
                          <div className={classes.leftSideContainer}>
                            <img
                              alt={"arrow down"}
                              src={arrowDownCircleIcon}
                              className={classes.leftSideImg}
                            />
                          </div>
                        )}
                      </Grid>
                    </Hidden>
                    <Grid
                      item
                      sm={12}
                      md={11}
                      style={{ marginTop: 40, paddingLeft: 24 }}
                    >
                      <PropertyExtensionPanel name={"Amenities"}>
                        <Grid container>
                          {property.amenities.map(a => {
                            return (
                              <Grid key={a.amenity.name} item xs={12} md={4}>
                                <div className={classes.amenitiesText}>
                                  <CheckCircleIcon
                                    className={classes.locationIcon}
                                  />
                                  {amenitiesConfig[a.amenity.name].name}
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </PropertyExtensionPanel>
                    </Grid>
                  </>
                )}
                {rules && (
                  <>
                    <Hidden smDown>
                      <Grid item sm={1} style={{ marginTop: 40 }}>
                        <div className={classes.leftSideContainerEnd}>
                          <img
                            alt={"end icon"}
                            src={endPointCircleIcon}
                            className={classes.leftSideImg}
                          />
                        </div>
                      </Grid>
                    </Hidden>
                    <Grid
                      item
                      sm={12}
                      md={11}
                      style={{ marginTop: 40, paddingLeft: 24 }}
                    >
                      <PropertyExtensionPanel name={"House rules"}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: rules
                          }}
                        ></div>
                      </PropertyExtensionPanel>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className={classes.containerSide}>
                <RequestStay
                  handleRequestStay={handleRequestStay}
                  property={property}
                  Map={
                    <div className={classes.propertyLocation}>
                      <div className={classes.fieldTitle}>
                        Property Location
                      </div>
                      {this.getMap()}
                    </div>
                  }
                />

                {/* <div className={classes.containerExpandning}>
                  {property.rulesHtml && (
                    <ExtensionPanel expanded name="House Rules">
                      <div
                        className={classes.houseRules}
                        dangerouslySetInnerHTML={{ __html: property.rulesHtml }}
                      />
                    </ExtensionPanel>
                  )}
                </div> */}
              </div>
            </Grid>
          </Grid>
        )}
        <SnackbarMessage
          allowUpdates={true}
          message={snackbarMessage}
          onClose={closeSnackbarMessage}
        />
      </main>
    );
  }
}

export default withRoot(
  withStyles(styles, { name: "ViewProperty" })(ViewProperty)
);
