import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";

export default class Properties extends Component {
  render() {
    return (
      <main>
        <Typography variant="h6">{"Properties"}</Typography>
      </main>
    );
  }
}

