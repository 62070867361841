import React, { Component } from "react";
import { animateScroll } from "react-scroll";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
  button: {
    height: 80,
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: "bold",
    display: "inline-flex",
    alignContent: "center",
    paddingTop: theme.spacing(3.75),
    textDecoration: "none",
    borderTop: "3px solid transparent",
    transition: theme.transitions.create("all"),
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      borderTop: `3px solid ${theme.palette.primary.main}`
    }
  },
  link: {
    color: "inherit",
    textDecoration: "none"
  },
  activeItem: {
    color: theme.palette.primary.main,
    borderTop: `3px solid ${theme.palette.primary.main}`
  }
});

class AppNavItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    currentPath: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    to: PropTypes.string,
    aliasPath: PropTypes.string
  };
  render() {
    const { classes, currentPath, title, to, aliasPath } = this.props;
    let buttonClass = classNames(classes.button);
    const toLen = to.length;
    if (to) {
      if (toLen === 1) {
        if (currentPath && to === currentPath) {
          buttonClass = classNames(buttonClass, classes.activeItem);
        }
        if (aliasPath && currentPath.includes(aliasPath)) {
          buttonClass = classNames(buttonClass, classes.activeItem);
        }
      }
      if (toLen > 1 && currentPath && to === currentPath.substring(0, toLen)) {
        buttonClass = classNames(buttonClass, classes.activeItem);
      }
    }
    return (
      <Link
        to={to}
        className={classes.link}
        onClick={() => animateScroll.scrollToTop()}
      >
        <div className={buttonClass}>{title}</div>
      </Link>
    );
  }
}

export default withStyles(styles, { name: "AppNavItem" })(AppNavItem);
