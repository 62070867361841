import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, Fields, reduxForm } from "redux-form";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import ProgressButton from "../Progress/ProgressButton";
import DeleteDialog from "../Dialogs/DeleteDialog";
import AddressField from "../Fields/AddressField";
import EditorField from "../Fields/EditorField";
import InputField from "../Fields/InputField";
import Message from "../Message/Message";
import SnackbarMessage from "../Message/SnackbarMessage";
import validate from "./validateProperty";
import SelectPrivacy from "components/Fields/SelectPrivacy";

const styles = theme => ({
  actions: {
    marginTop: 16,
    position: "relative",
    display: "block",
    textAlign: "right",
    width: "100%"
  },
  buttonDelete: {
    marginRight: 8
  },
  headline: {
    marginBottom: 16
  },
  title: {
    marginTop: 8,
    marginBottom: 16
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto",
    padding: 32
  },
  subheading: {
    marginTop: 16,
    marginBottom: 8
  }
});

const required = val => {
  if (val === undefined) return "Required field";
};

class PropertyEdit extends Component {
  render() {
    const {
      property,
      classes,
      closeDeleteDialog,
      closeSnackbarMessage,
      deleteDialog,
      deleteError,
      errorMessage,
      handleDelete,
      handleDescriptionEditor,
      handleSubmit,
      isDeleting,
      onSubmit,
      openDeleteDialog,
      setCharacterCount,
      snackbarMessage,
      submitting
    } = this.props;

    let characterLimit = 5000;
    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Paper className={classes.paper}>
              <div>
                <Typography
                  variant="h5"
                  color="inherit"
                  className={classes.title}
                >
                  {!property && "Add Property"}
                  {property && `Edit - ${property.name}`}
                </Typography>
              </div>
              <div className={classes.headline}>
                <Message type="danger" message={errorMessage} />
              </div>
              <form
                onSubmit={handleSubmit(values => {
                  onSubmit(values);
                })}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name="name"
                      type="text"
                      component={InputField}
                      label="Name"
                      placeholder="Enter property name"
                      validate={required}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Fields
                        names={[
                          "address.id",
                          "address.address1",
                          "address.address2",
                          "address.city",
                          "address.region",
                          "address.postalCode",
                          "address.postalCodeSuffix",
                          "address.country",
                          "address.location"
                        ]}
                        type="text"
                        props={Object.assign({}, this.props, {
                          classes: {}
                        })}
                        allowPOBoxes={false}
                        component={AddressField}
                        required={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={classes.subheading}
                    >
                      {"Property Description"}
                    </Typography>
                    <Field
                      name="description"
                      limit={characterLimit}
                      handleEditor={handleDescriptionEditor}
                      component={EditorField}
                      rawString={
                        property && JSON.parse(property.descriptionRaw)
                      }
                      setCount={setCharacterCount}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      className={classes.subheading}
                    >
                      {"Property Privacy"}
                    </Typography>
                    <Field
                      name="privacy"
                      limit={characterLimit}
                      component={SelectPrivacy}
                      required={true}
                    />
                  </Grid>
                </Grid>
                <div className={classes.actions}>
                  {property && (
                    <Button
                      variant="outlined"
                      color="default"
                      className={classes.buttonDelete}
                      onClick={event => openDeleteDialog(event)}
                    >
                      <DeleteIcon />
                      {"Delete"}
                    </Button>
                  )}
                  <ProgressButton
                    label="Save"
                    icon={<SaveIcon />}
                    loading={submitting}
                    type="submit"
                    color="secondary"
                    variant="outlined"
                  />
                </div>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <DeleteDialog
          closeDialog={closeDeleteDialog}
          errorMessage={deleteError}
          isDeleting={isDeleting}
          onDelete={handleDelete}
          open={deleteDialog}
          resource={property}
        />
        <SnackbarMessage
          allowUpdates={true}
          message={snackbarMessage}
          onClose={closeSnackbarMessage}
          type="success"
        />
      </React.Fragment>
    );
  }
}

PropertyEdit = reduxForm({
  form: "PropertyEdit",
  enableReinitialize: true,
  validate
})(PropertyEdit);

PropertyEdit = connect((state, props) => {
  return {
    initialValues: {
      ...props.property
    }
  };
})(PropertyEdit);

export default withStyles(styles, { name: "PropertyEdit" })(PropertyEdit);
