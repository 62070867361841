import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertiesActionCreators from "../../redux/modules/properties";
import ManageProperty from "../../components/Properties/ManageProperty";

class ManagePropertyContainer extends Component {
  async componentDidMount() {
    // load existing property for edit
    const id = this.props.match.params.id;
    if (id > 0) {
      await this.props.fetchProperty(id);
    }
  }

  render() {
    return <ManageProperty property={this.props.property} />;
  }
}

function mapStateToProps({ properties }, props) {
  const id = props.match.params.id;
  let property = properties[id] || null;
  return {
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePropertyContainer);
