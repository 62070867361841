import React, { Fragment } from "react";
import Fade from "react-reveal/Fade";

import Hidden from "@material-ui/core/Hidden";

import MeetTheTeam from "./MeetTheTeam";
import Team from "./Team";

import locationIcon from "../../static/assets/icons/location.svg";
import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1
  },
  iconHome: {
    height: 60
  },
  mapContainer: {
    width: "50%"
  },
  mapImg: {
    maxHeight: 620,
    maxWidth: "100%"
  },
  mapInfo: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    paddingTop: 44,
    paddingBottom: 48
  },
  mapTitle: {
    fontSize: 16,
    fontWeight: "bold",
    paddingRight: 33
  },
  mapAddress: {
    fontSize: 16,
    lineHeight: 1.8
  },
  mapIcon: {
    paddingLeft: 25
  },
  locationIcon: {
    height: 40
  },
  textContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  textTitle: {
    lineHeight: 1.4,
    fontSize: 32
  },
  textHeading: {
    fontWeight: "bold"
  },
  textCopy: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: 1.8,
    paddingTop: 16
  },
  textBody: {
    maxWidth: 519
  },
  [theme.breakpoints.down("md")]: {
    textBody: {
      paddingLeft: 20,
      paddingRight: 20
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block",
      paddingTop: 64
    },
    mapContainer: {
      width: "100%"
    },
    iconHome: {
      height: 48
    },
    textTitle: {
      fontSize: 32
    },
    textContainer: {
      width: "100%",
      alignItems: "normal",
      paddingBottom: 64
    }
  }
});

const About = props => {
  const { classes } = props;
  return (
    <Fragment>
      <Fade>
        <div className={classes.root}>
          <div className={classes.textContainer}>
            <div className={classes.textBody}>
              <div className={classes.textTitle}>
                <span className={classes.textHeading}>We are hosts and we’re done with VRBO and Airbnb.</span>
              </div>
              <div className={classes.textCopy}>
              Like you, we are property owners and rental hosts. We started Vrbff because 
              we are finished being abused, cheated, and disrespected by the big sites.
              <p>Vrbff is led by a team of industry specialists with 50+ years of experience 
                building companies in the mobile marketing and web analytics spaces. Together 
                we have raised over $100 million in capital to build compelling businesses.
              </p>
              <p>Now we are working restoring sanity and decency to vacation rentals. As 
                the fat sites have grown, they have lost their way – they’ve forgotten about 
                honesty, respect, and integrity.
              </p>
              <p>But finally, there is a choice. We invite you to join us.</p>
              </div>
            </div>
          </div>
          <div className={classes.mapContainer}>
            <img
              src="/images/about/map/map-1300.jpg"
              srcSet="/images/about/map/map-540.jpg 540w,
            /images/about/map/map-800.jpg 800w,
            /images/about/map/map-1000.jpg 1000w,
            /images/about/map/map-1300.jpg 1300w"
              sizes="100vw"
              alt="your home"
              className={classes.mapImg}
            />
            <div className={classes.mapInfo}>
              <div className={classes.mapTitle}>Address:</div>
              <div className={classes.mapAddress}>
                121 SW Morrison St.
                <br />
                Suite 2105
                <br />
                Portland, OR 97204
                <br />
              </div>
              <div className={classes.mapIcon}>
                <img
                  alt="location icon"
                  src={locationIcon}
                  className={classes.locationIcon}
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <Hidden smDown>
        <MeetTheTeam />
      </Hidden>
      <Team />
    </Fragment>
  );
};

export default withStyles(styles, { name: "About" })(About);
