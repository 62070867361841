import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as followsActionCreators from "../../redux/modules/follows";
import FollowRequests from "../../components/Inbox/FollowRequests";
import AppPageHeader from "../../components/App/AppPageHeader";
import { Helmet } from "react-helmet";

class FollowRequestsContainer extends Component {
  componentDidMount() {
    this.props.fetchFollowsByUser();
  }
  render() {
    return (
      <Fragment>
        <Helmet>
          <title>Stays&nbsp;&bull;&nbsp;Vrbff</title>
        </Helmet>
        <AppPageHeader subHeadline="Follow Requests" />
        <FollowRequests
          auth={this.props.auth}
          hasFetched={this.props.hasFetched}
          isFetching={this.props.isFetching}
          follows={this.props.follows}
          isManage={this.props.isManage}
        />
      </Fragment>
    );
  }
}

function mapStateToProps({ follows }, props) {
  return {
    hasFetched: follows.hasFetched,
    isFetching: follows.isFetching,
    follows
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...followsActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FollowRequestsContainer);
