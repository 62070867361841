// @flow weak

import React from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";

export const styles = theme => ({
  layout: {
    flexGrow: 1,
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: "auto",
    minHeight: "80vh"
  },
  landing: {
    flexGrow: 1,
    // header height
    marginTop: 80,
    width: "auto"
  },
  [theme.breakpoints.down("sm")]: {
    landing: {
      marginTop: 65
    }
  }
});

function AppContent(props, context) {
  const { classes, children, wrapperStyle } = props;
  const wrapperClass =
    wrapperStyle === "landing" ? classes.landing : classes.layout;
  return <main className={wrapperClass}>{children}</main>;
}

AppContent.propTypes = {
  children: PropTypes.node
};

export default withStyles(styles, { name: "AppContent" })(AppContent);
