import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import InboxItem from "./InboxItem";
import Loading from "components/Progress/Loading";

export default class Inbox extends Component {
  render() {
    const { auth, isManage, stays, title } = this.props;
    const profile = auth.getProfile();
    const profileId = profile.id;

    if (this.props.isFetching && this.props.stays.ids.length === 0)
      return (
        <main>
          <Loading />
        </main>
      );

    return (
      <main>
        <Typography variant="h6">{title}</Typography>
        {(!stays || !stays.ids || stays.ids.length === 0) && (
          <Typography variant="h6">No stay requests.</Typography>
        )}
        {stays && stays.ids && stays.ids.length > 0 && (
          <Card>
            <List>
              {this.props.stays.ids
                .sort((a, b) => b - a)
                .map((id, index) => {
                  const trip = this.props.stays[id];
                  const isOwner =
                    parseInt(trip.user.id, 10) !== parseInt(profileId, 10);
                  // if (!trip.lastMessage) return <></>;
                  return (
                    <InboxItem
                      key={id}
                      isManage={isManage}
                      message={trip.lastMessage}
                      trip={trip}
                      index={index}
                      length={this.props.stays.ids.length}
                      name={isOwner ? trip.user.name : trip.property.name}
                      picture={
                        isOwner
                          ? trip.user.picture
                          : trip.property.image
                          ? trip.property.image.url
                          : null
                      }
                    />
                  );
                })}
            </List>
          </Card>
        )}
      </main>
    );
  }
}
