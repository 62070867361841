import React, { Component } from "react";
import {
  Typography,
  withStyles,
  Input,
  InputAdornment,
  FormControl
} from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import FormHelperText from "@material-ui/core/FormHelperText";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/AddCircle";
import { isValidEmail } from "helpers/utils";

const styles = theme => ({
  emailInput: {
    color: "#00000044",
    marginTop: 22,
    "&.Mui-focused": {
      color: "#40A5CB"
    }
  },
  addMore: {
    color: "#40A5CB",
    cursor: "pointer",
    marginTop: 22,
    display: "flex",
    alignItems: "center",
    fontStyle: "italic"
  },
  addIcon: {
    marginRight: 8
  }
});

class GuestsStep extends Component {
  state = {
    errors: {}
  };
  async continueCheck() {
    return new Promise(async resolve => {
      const { data } = this.props;
      let errors = {};
      let valid = 0;
      data.emails.forEach((e, index) => {
        if (!e || e === "") return;
        if (isValidEmail(e)) valid = valid + 1;
        else errors[index] = true;
      });

      //If there are errors display them
      if (Object.keys(errors).length > 0) {
        this.setState({ errors: errors });
        return resolve(false);
      }

      if (valid > 0) {
        return resolve(true);
      }

      this.props.snackbarMessage({
        message: "You have to enter at least one email",
        type: "warning"
      });
      this.setState({ errors: {} });
      resolve(false);
    });
  }

  onAddMore = () => {
    let emails = [...this.props.data.emails];
    emails.push("");
    this.props.change("emails", emails);
  };

  render() {
    const { classes, data } = this.props;

    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">{"invite Guests"}</Typography>
            <Typography variant="caption" component="div">
              {
                "Enter the emails of guests you'd like to receive an invitation. They'll get an exclusive link to join the VRBFF network, and immediately be able to see the property you've selected on the previous step."
              }
            </Typography>
            {(data.emails || []).map((e, index) => (
              <FormControl
                key={index}
                error={this.state.errors[index]}
                fullWidth
              >
                <Input
                  className={classes.emailInput}
                  fullWidth
                  startAdornment={
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  }
                  value={e}
                  onChange={(event, b, c) => {
                    let emails = [...data.emails];
                    emails[index] = event.target.value || "";
                    this.props.change("emails", emails);
                  }}
                />
                {this.state.errors[index] && (
                  <FormHelperText id="component-error-text">
                    It should be an email
                  </FormHelperText>
                )}
              </FormControl>
            ))}

            <span className={classes.addMore} onClick={this.onAddMore}>
              <AddIcon className={classes.addIcon} /> Add more
            </span>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

GuestsStep = withStyles(styles, { name: "GuestsStep" })(GuestsStep);

export default GuestsStep;
