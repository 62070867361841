export function isPOBox(value) {
  const re = /\b(?:p[.\s]?o?[.\s]?b?[.\s]?)\b(?:box)?|post{1}\s(?:office)?\s?(:?box|bin)|mail{1}\s(?:office)?\s?(:?box|bin){1}/i;
  return re.test(value);
}

export function isValidStateCode(region, country) {
  const validStates = [
    "AL",
    "AK",
    "AS",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "GU",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MH",
    "MA",
    "MI",
    "FM",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "MP",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "VI",
    "WA",
    "WV",
    "WI",
    "WY"
  ];
  if ((validStates.includes(region) && country === "US") || country !== "US") {
    return true;
  } else {
    return false;
  }
}

// Check for valid email address
export function isValidEmail(value) {
  const re = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
  return re.test(value);
}

// Check a password between 8 to 20 characters which contains at least one numeric digit or special character
export function isValidPassword(value) {
  const re = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*[#$^+=_.\-!*()@%&])).{8,30}$/;
  return re.test(value);
}
