import React, { Component, Fragment } from "react";
import dasherize from "dasherize";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as followsActionCreators from "../../redux/modules/follows";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as messagesActionCreators from "../../redux/modules/messages";
import * as tripsActionCreators from "../../redux/modules/trips";
import ViewProperty from "../../components/Properties/ViewProperty";
import Loading from "../../components/Progress/Loading";
import RequestProperty from "../../components/Properties/RequestProperty";
import { getErrorMessage } from "../../helpers/utils";
import AppPageHeader from "../../components/App/AppPageHeader";
import { Helmet } from "react-helmet";

class ViewPropertyContainer extends Component {
  state = {
    errorMessage: null,
    snackbarMessage: null
  };

  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  async componentDidMount() {
    // load property
    const slug = this.props.match.params.slug;
    if (slug) {
      await this.props.fetchPropertyBySlug(slug);
    }
  }

  onFollow = async () => {
    try {
      const { property } = this.props;
      const follow = await this.props.addFollow({ property });
      if (follow.id > 0) {
        property.hasRequested = true;
        this.props.updatePropertyRequest(property);
        this.setState({ snackbarMessage: "Follow request sent" });
      }
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem with the follow request."
      );
      this.setState({ errorMessage });
      throw error;
    }
  };

  onRequestStay = async values => {
    try {
      const trip = await this.props.addTrip(dasherize(values));

      if (trip.id > 0) {
        this.props.history.push({
          pathname: `/inbox/${trip.id}`
        });
      }
      this.setState({ snackbarMessage: "Requested dates sent" });
      return trip;
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem submitting the requested dates."
      );
      this.setState({ errorMessage });
      throw error;
    }
  };

  render() {
    let { isAdding, isFetching, property } = this.props;
    if (!property || isFetching) {
      return <Loading />;
    }

    if (property && property.hasAccess) {
      return (
        <Fragment>
          <Helmet>
            <title>Properties&nbsp;&bull;&nbsp;Vrbff</title>
          </Helmet>
          <AppPageHeader breadcrumbTitle="All Properties" breadcrumbPath="/" />
          <ViewProperty
            closeSnackbarMessage={this.closeSnackbarMessage}
            handleRequestStay={values => this.onRequestStay(values)}
            property={property}
            snackbarMessage={this.state.snackbarMessage}
          />
        </Fragment>
      );
    }

    return (
      <RequestProperty
        closeSnackbarMessage={this.closeSnackbarMessage}
        errorMessage={this.state.errorMessage}
        handleFollow={this.onFollow}
        isAdding={isAdding}
        property={property}
        snackbarMessage={this.state.snackbarMessage}
      />
    );
  }
}

function mapStateToProps({ follows, messages, properties, trips }, props) {
  return {
    hasFetched: properties.hasFetched,
    isAdding: follows.isAdding || messages.isAdding || trips.isAdding,
    isDeleting: properties.isDeleting,
    isFetching: properties.isFetching,
    property: properties[properties.currentId]
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...followsActionCreators,
      ...messagesActionCreators,
      ...propertiesActionCreators,
      ...tripsActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPropertyContainer);
