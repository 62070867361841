import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "components/General/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import ProgressButton from "../Progress/ProgressButton";
import SelectGroup from "../Fields/SelectGroup";
import Message from "../Message/Message";
import SnackbarMessage from "../Message/SnackbarMessage";

const validate = (values, props) => {
  const errors = {};
  if (!values[`group${props.follow}`]) {
    errors[`group${props.follow}`] = "Select a group";
  }
  return errors;
};

const styles = theme => ({
  // avatar: {
  //   marginTop: 16
  // },
  button: {
    marginRight: 10
  },
  headline: {
    marginBottom: 16
  },

  title: {
    marginTop: 8,
    marginBottom: 16
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto",
    padding: 32
  }
});

class FollowRequests extends Component {
  state = {
    follow: null
  };

  componentDidMount() {
    this.props.getPropertyFollows();
    this.props.getGroups();
  }

  onSubmit = async values => {
    // Convert dynamic field name back to group
    await this.props.handleConfirmFollow({
      follow: values.follow,
      group: values[`group${values.follow}`]
    });
  };

  render() {
    const {
      classes,
      closeSnackbarMessage,
      errorMessage,
      follows,
      groups,
      handleDeleteFollow,
      handleSubmit,
      property,
      isDeleting,
      snackbarMessage
    } = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                variant="h5"
                color="inherit"
                className={classes.title}
              >
                {property && `Follow Requests - ${property.name}`}
              </Typography>
              {errorMessage && (
                <div className={classes.headline}>
                  <Message type="danger" message={errorMessage} />
                </div>
              )}
              <form
                onSubmit={handleSubmit(values => {
                  this.onSubmit(values);
                })}
              >
                <Grid container>
                  <Grid item xs={12}>
                    {follows && follows.ids.length > 0 && (
                      <div className={classes.follows}>
                        <List>
                          {follows.ids.map(id => (
                            <ListItem key={`follow${id}`}>
                              <ListItemAvatar className={classes.avatar}>
                                <Avatar
                                  firstName={follows[id].user.firstName}
                                  lastName={follows[id].user.lastName}
                                  src={follows[id].user.picture}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                className={classes.text}
                                primary={follows[id].user.name}
                              />
                              <Field
                                variant="outlined"
                                name={`group${id}`}
                                placeholder={"Group"}
                                type="string"
                                groups={groups}
                                component={SelectGroup}
                              />
                              <ProgressButton
                                className={classes.button}
                                label="Confirm"
                                icon={<AddIcon />}
                                progress
                                onClick={async () => {
                                  await new Promise((resolve, reject) => {
                                    const han = handleSubmit(async values => {
                                      console.log(values);
                                      await this.props.handleConfirmFollow({
                                        follow: id,
                                        group: values[`group${id}`]
                                      });
                                      resolve();
                                    });
                                    han();
                                  });
                                }}
                              />
                              <ProgressButton
                                isDanger
                                className={classes.button}
                                label="Delete"
                                icon={<DeleteIcon />}
                                loading={isDeleting}
                                onClick={() => handleDeleteFollow(id)}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )}
                    {follows && follows.ids.length === 0 && (
                      <div className={classes.follows}>
                        {"No follow requests"}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
        <SnackbarMessage
          allowUpdates={true}
          message={snackbarMessage}
          onClose={closeSnackbarMessage}
        />
      </React.Fragment>
    );
  }
}

FollowRequests = reduxForm({
  form: "FollowRequests",
  enableReinitialize: true,
  validate
})(FollowRequests);

const selector = formValueSelector("FollowRequests");
FollowRequests = connect(state => {
  return {
    follow: selector(state, "follow")
  };
})(FollowRequests);

export default withStyles(styles, { name: "FollowRequests" })(FollowRequests);
