// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import common from "@material-ui/core/colors/common";
import SaveIcon from "@material-ui/icons/Save";
import withRoot from "../../withRoot";

import Message from "../Message/Message";
import ProgressButton from "../Progress/ProgressButton";
import {
  Input,
  FormControl,
  FormHelperText,
  InputLabel
} from "@material-ui/core";

const { white } = common;

const styles = theme => ({
  button: {
    backgroundColor: "#40A5CB",
    color: white,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3994b6"
    }
  },
  iconButton: {
    marginRight: 8
  }
});

const ResponsiveDialog = withMobileDialog({ breakpoint: "xs" })(Dialog);

class EditDialog extends Component {
  state = { name: "", errors: false };

  changeName = event => {
    this.setState({ name: event.target.value });
  };

  render() {
    const {
      classes,
      closeDialog,
      errorMessage,
      isSaving,
      onSave,
      open,
      resource
    } = this.props;

    return (
      <div>
        {resource && (
          <ResponsiveDialog open={open} onClose={closeDialog}>
            <DialogTitle>Add a new group</DialogTitle>
            <DialogContent>
              <Message type="danger" message={errorMessage} />
              <FormControl error={this.state.errors} fullWidth>
                <InputLabel id="demo-simple-select-label">Name</InputLabel>
                <Input
                  className={this.props.classes.emailInput}
                  fullWidth
                  label="Group Name"
                  value={this.state.name || ""}
                  onChange={this.changeName}
                  //   onChange={(event, b, c) => {
                  //     let emails = [...data.emails];
                  //     emails[index] = event.target.value || "";
                  //     this.props.change("emails", emails);
                  //   }}
                />
                {this.state.errors && (
                  <FormHelperText id="component-error-text">
                    The pricing should have a name
                  </FormHelperText>
                )}
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                {"Cancel"}
              </Button>
              <ProgressButton
                disabled={this.state.name.length < 1}
                className={classes.button}
                label="Save"
                icon={<SaveIcon className={classes.iconButton} />}
                loading={isSaving}
                onClick={() => onSave(this.state.name)}
                type="button"
              />
            </DialogActions>
          </ResponsiveDialog>
        )}
      </div>
    );
  }
}

EditDialog.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.object,
  onSave: PropTypes.func.isRequired
};

export default withRoot(withStyles(styles, { name: "EditDialog" })(EditDialog));
