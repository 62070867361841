import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => {
  return {
    root: {},
    right: {
      float: "right"
    }
  };
};

class EnhancedTableHead extends Component {
  static propTypes = {
    columnData: PropTypes.array.isRequired,
    enableSelectAll: PropTypes.bool,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const {
      columnData,
      enableSelectAll,
      onSelectAllClick,
      numSelected,
      order,
      orderBy,
      rowCount
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {enableSelectAll && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={numSelected === rowCount && rowCount > 0}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )}
          {columnData.map(column => {
            return (
              <TableCell
                padding={column.padding || "default"}
                key={`table${column.id}`}
                align={column.numeric ? "right" : "left"}
              >
                {column.nosort === true && column.label}
                {column.nosort !== true && (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={this.createSortHandler(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                )}
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

export default withStyles(styles, { name: "EnhancedTableHead" })(
  EnhancedTableHead
);
