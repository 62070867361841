import React from "react";
import { animateScroll } from "react-scroll";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { withStyles } from "@material-ui/core/styles";
import common from "@material-ui/core/colors/common";
const { white } = common;

export const styles = theme => ({
  navItem: {
    textAlign: "center",
    paddingBottom: 48
  },
  navLink: {
    fontSize: 16,
    color: white,
    textDecoration: "none",
    paddingBottom: 7.5,
    "&:hover": {
      borderBottom: `3px solid ${theme.palette.secondary.main}`
    }
  },
  activeItem: {
    borderBottom: `3px solid ${theme.palette.secondary.main}`
  }
});

const handleMenuItem = props => {
  props.closeMenu();
  animateScroll.scrollToTop();
};

const AppMenuItem = props => {
  const { aliasPath, classes, currentPath, to, title } = props;
  let linkClass = classes.navLink;
  if (to === currentPath) {
    linkClass = classNames(linkClass, classes.activeItem);
  } else if (aliasPath && currentPath.includes(aliasPath)) {
    linkClass = classNames(linkClass, classes.activeItem);
  }
  return (
    <div className={classes.navItem}>
      <Link to={to} className={linkClass} onClick={() => handleMenuItem(props)}>
        {title}
      </Link>
    </div>
  );
};

export default withStyles(styles, { name: "AppMenuItem" })(AppMenuItem);
