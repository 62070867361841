import React from "react";

import Grid from "@material-ui/core/Grid";

import { withStyles } from "@material-ui/core/styles";
import howItWorksIcon from "../../static/assets/icons/how-it-works.svg";

export const styles = theme => ({
  root: {
    paddingLeft: 200,
    paddingRight: 200,
    paddingTop: 104
  },
  container: {
    paddingLeft: 47,
    paddingTop: 64
  },
  iconHowItWorks: {
    height: 60
  },
  img: {
    width: "100%",
    maxWidth: 320
  },
  imgContainer: {
    textAlign: "center"
  },
  imgTitle: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
    textAlign: "center",
    paddingTop: 32
  },
  imgText: {
    fontSize: 16,
    lineHeight: 1.8,
    paddingTop: 8,
    paddingBottom: 40,
    paddingLeft: "7.5%",
    paddingRight: "7.5%",
    textAlign: "center"
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  titleText: {
    fontSize: 32,
    fontWeight: "bold",
    paddingLeft: 44
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 48
    },
    container: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingTop: 40
    },
    iconHowItWorks: {
      height: 48
    },
    imgText: {
      paddingBottom: 64
    },
    imgTitle: {
      paddingTop: 20
    },
    titleText: {
      fontSize: 24
    }
  }
});

const HowItWorks = props => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <img
          src={howItWorksIcon}
          alt="how it works icon"
          className={classes.iconHowItWorks}
        />
        <div className={classes.titleText}>How it works</div>
      </div>
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <div className={classes.imgContainer}>
              <img
                src="/images/how-it-works/gallery.png"
                alt="gallery"
                className={classes.img}
              />
            </div>
            <div className={classes.imgTitle}>Photo Gallery</div>
            <div className={classes.imgText}>
              A great way to show off your property.
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className={classes.imgContainer}>
              <img
                src="/images/how-it-works/calendar.png"
                alt="calendar"
                className={classes.img}
              />
            </div>
            <div className={classes.imgTitle}>Calendar</div>
            <div className={classes.imgText}>
              Our shared calendar allows you to block off dates and see upcoming
              bookings at a glance.
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className={classes.imgContainer}>
              <img
                src="/images/how-it-works/bookings.png"
                alt="bookings"
                className={classes.img}
              />
            </div>
            <div className={classes.imgTitle}>You Control Your Bookings</div>
            <div className={classes.imgText}>
              As an owner, you decide the price per night, any discounts and of
              course who stays at your home.
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className={classes.imgContainer}>
              <img
                src="/images/how-it-works/local-guide.png"
                alt="bookings"
                className={classes.img}
              />
            </div>
            <div className={classes.imgTitle}>Local Guide</div>
            <div className={classes.imgText}>
              List your favorite places to go to give your guests a really
              special experience.
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className={classes.imgContainer}>
              <img
                src="/images/how-it-works/house-info.png"
                alt="house info"
                className={classes.img}
              />
            </div>
            <div className={classes.imgTitle}>House Information</div>
            <div className={classes.imgText}>
              Set a description, house rules and list the amenities for your
              guests.
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <div className={classes.imgContainer}>
              <img
                src="/images/how-it-works/guest-management.png"
                alt="guest management"
                className={classes.img}
              />
            </div>
            <div className={classes.imgTitle}>Guest Management</div>
            <div className={classes.imgText}>
              Easily group your guests giving them different pricing, ability to
              book out, maximum length of stays, etc.
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default withStyles(styles, { name: "HowItWorks" })(HowItWorks);
