import { apiRequest } from "apis/jsonApi";
import Resource from "./resource";

let resource = new Resource({
  singularName: "feed",
  pluralName: "feeds"
});

export function fetchFeed() {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`feed`, "GET", {})
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function addFeedFromPushNotification(feed) {
  return function(dispatch, getState) {
    const ids = getState().feeds.ids;
    ids.unshift(feed.id);

    dispatch(resource.addingPlural({ data: [feed] }));
    dispatch(resource.successPlural(ids));
  };
}

export default resource.reducer;
