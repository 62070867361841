// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import common from "@material-ui/core/colors/common";
import { Elements } from "react-stripe-elements";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cardsActionCreators from "../../redux/modules/cards";
import { Grid } from "@material-ui/core";
import Loading from "components/Progress/Loading";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { getNights } from "helpers/utils";
import { apiRequest, apiAxiosRequest } from "apis/jsonApi";
import TripNightsAdultsChildren from "components/General/TripNightsAdultsChildren";
import PaymentSummary from "components/General/PaymentSummary";

import CardForm from "components/Payment/CardForm";

const { white } = common;

function getSteps() {
  return ["Payment Summary", "Select Card", "Pay"];
}

const styles = theme => ({
  button: {
    backgroundColor: "#40A5CB",
    color: white,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#3994b6"
    }
  },
  iconButton: {
    marginRight: 8
  },
  cardContainer: {
    border: "4px solid rgba(0, 0, 0, 0.54)",
    borderRadius: 10,
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    margin: 10,
    height: 80,
    alignItems: "center"
  },
  cardContainerSelected: {
    border: "4px solid #40A5CB",
    borderRadius: 10,
    padding: 8,
    display: "flex",
    justifyContent: "space-between",
    margin: 10,
    height: 80,
    alignItems: "center"
  },
  cardBrand: {
    fontSize: 20
  }
});

const ResponsiveDialog = withMobileDialog({ breakpoint: "sm" })(Dialog);

class PayTripDialog extends Component {
  state = {
    activeStep: 0,
    cardSelected: 0,
    isLoaded: false,
    mounted: false,
    aa: "",
    errors: false,
    paymentComplete: false,
    paymentChecked: false
  };

  async componentDidMount() {
    const cards = await apiRequest(`cards`, "GET", {});
    const trip = await apiRequest(`inbox/${this.props.tripId}`, "GET", {});
    this.setState({
      cards: cards.data || [],
      trip: trip.data || {},
      isLoaded: true
    });

    // this.props.fetchCards(cb);
    this.setState({ mounted: true });
  }

  preparePayment = async () => {
    let data = { trip_id: this.props.tripId };
    if (this.state.cardSelected !== 0) {
      data.card_id = this.state.cardSelected;
    }
    const payment = await apiAxiosRequest(
      `stripe/payment`,
      "POST",
      data,
      {},
      true,
      true
    );
    this.setState({ code: payment.intentSecretId });
  };
  changeName = event => {
    this.setState({ name: event.target.value });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };
  handleNext = () => {
    this.setState({ code: false });
    if (this.state.activeStep === 1) {
      this.preparePayment();
    }
    this.setState({ activeStep: this.state.activeStep + 1 });
  };

  loadContent = () => {
    const { activeStep } = this.state;
    const steps = getSteps();
    return (
      <>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            // if (isStepOptional(index)) {
            //   labelProps.optional = <Typography variant="caption">Optional</Typography>;
            // }
            // if (isStepSkipped(index)) {
            //   stepProps.completed = false;
            // }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length && (
            <div>Your payment was succesfull</div>
          )}
          {activeStep !== steps.length && (
            <div>
              {this.getStepContent(activeStep)}
              {/* <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Back
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </div> */}
            </div>
          )}
        </div>
      </>
    );
  };

  selectCard = id => () => {
    this.setState({ cardSelected: id });
  };
  loadCardSelection = () => {
    const { cards } = this.state;
    if (cards.length === 0) {
      return this.handleNext();
    }
    const { classes } = this.props;
    return (
      <>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div
              onClick={this.selectCard(0)}
              className={
                this.state.cardSelected === 0
                  ? classes.cardContainerSelected
                  : classes.cardContainer
              }
            >
              <div className={classes.cardBrand}>Add New card</div>
            </div>
          </Grid>

          {cards.map(c => {
            return (
              <Grid item xs={12} sm={6}>
                <div
                  onClick={this.selectCard(c.id)}
                  className={
                    this.state.cardSelected === c.id
                      ? classes.cardContainerSelected
                      : classes.cardContainer
                  }
                >
                  <div>
                    Ending with:{c.last4}
                    <br />
                    <br />
                    Expire: 04/24
                  </div>
                  <div className={classes.cardBrand}>{c.brand}</div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  };

  loadInfoTripPayment = () => {
    const { trip } = this.state;
    return (
      <>
        <TripNightsAdultsChildren
          nights={getNights(trip.startDate, trip.endDate)}
          adults={trip.adults || 0}
          children={trip.children || 0}
        />
        <PaymentSummary trip={trip} />
      </>
    );
  };

  loadPay = () => {
    if (!this.state.code) {
      return <Loading />;
    }

    return (
      <>
        {!this.state.paymentComplete && (
          <Elements>
            <CardForm
              newCard={this.state.cardSelected === 0}
              code={this.state.code}
              onComplete={this.onPaymentComplete}
              onError={this.onPaymentError}
            />
          </Elements>
        )}
        {this.state.paymentComplete && !this.state.paymentChecked && (
          <Loading />
        )}
        {this.state.paymentComplete && this.state.paymentChecked && (
          <div>Payment completed</div>
        )}
      </>
    );
  };

  onPaymentComplete = result => {
    console.log("error", result);
  };

  onPaymentComplete = result => {
    console.log("result", result);
    this.setState({ paymentComplete: result });
    this.checkPaymentResult();
  };

  checkPaymentResult = () => {
    setTimeout(async () => {
      const trip = await apiRequest(`inbox/${this.props.tripId}`, "GET", {});
      if (trip && trip.data && trip.data.status === "Accepted") {
        return setTimeout(() => this.checkPaymentResult(), 1);
      } else {
        return this.setState({ paymentChecked: true });
      }
    }, 2000);
  };
  getStepContent(step) {
    switch (step) {
      case 0:
        return this.loadInfoTripPayment();
      case 1:
        return this.loadCardSelection();
      case 2:
        return this.loadPay();
      default:
        return <></>;
    }
  }

  render() {
    const { classes, closeDialog, finishDialog, open, isFetching } = this.props;

    const { activeStep } = this.state;
    const steps = getSteps();
    return (
      <div>
        <ResponsiveDialog open={open} onClose={closeDialog}>
          <DialogTitle>Payment</DialogTitle>
          <DialogContent>
            {!this.state.mounted || (isFetching && <Loading />)}
            {this.state.mounted && !isFetching && this.loadContent()}
            {/* <Message type="danger" message={errorMessage} />
              <FormControl error={this.state.errors} fullWidth>
                <InputLabel id="demo-simple-select-label">Name</InputLabel>
                <Input
                  className={this.props.classes.emailInput}
                  fullWidth
                  label="Group Name"
                  value={this.state.name || ""}
                  onChange={this.changeName}
                  //   onChange={(event, b, c) => {
                  //     let emails = [...data.emails];
                  //     emails[index] = event.target.value || "";
                  //     this.props.change("emails", emails);
                  //   }}
                />
                {this.state.errors && (
                  <FormHelperText id="component-error-text">
                    The pricing should have a name
                  </FormHelperText>
                )}
              </FormControl> */}
          </DialogContent>
          <DialogActions>
            {activeStep !== steps.length && (
              <>
                {!this.state.paymentComplete && (
                  <>
                    <Button onClick={closeDialog} color="primary">
                      {"Cancel"}
                    </Button>
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                  </>
                )}

                {activeStep < steps.length - 1 && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                    className={classes.button}
                  >
                    Next
                  </Button>
                )}

                {activeStep === steps.length - 1 &&
                  this.state.paymentComplete &&
                  this.state.paymentChecked && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={finishDialog}
                      className={classes.button}
                    >
                      Finish
                    </Button>
                  )}
              </>
            )}
            {activeStep === steps.length && (
              <Button onClick={closeDialog} color="primary">
                Close
              </Button>
            )}
          </DialogActions>
        </ResponsiveDialog>
      </div>
    );
  }
}

PayTripDialog.propTypes = {
  title: PropTypes.string,
  resource: PropTypes.object
  // onSave: PropTypes.func.isRequired
};

function mapStateToProps({ cards, app }, props) {
  // let c = (cards.ids || []).map(n => cards[n]).filter(Boolean);
  return { cards: [], isFetching: false };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...cardsActionCreators
    },
    dispatch
  );
}

export default withStyles(styles, { name: "PayTripDialog" })(
  connect(mapStateToProps, mapDispatchToProps)(PayTripDialog)
);
