import React from "react";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import withRoot from "../../withRoot";
import MessageItem from "./MessageItem";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  }
});

const MessageBox = ({ messages, hosts, classes }) => {
  return (
    <>
      <List>
        {messages
          .sort((a, b) => b.id - a.id)
          .map((message, index) => (
            <MessageItem
              key={message.id}
              message={message}
              fromHost={hosts.indexOf(message.user.id) !== -1}
              index={index}
              length={messages.length}
            />
          ))}
      </List>
    </>
  );
};

export default withRoot(withStyles(styles)(MessageBox));
