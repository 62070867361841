import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, Form, reduxForm } from "redux-form";
import history from "../../../history";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import common from "@material-ui/core/colors/common";
import LockIcon from "@material-ui/icons/Lock";
import withRoot from "../../../withRoot";

import ValidationTextField from "components/Fields/ValidationTextField";
import { isValidPassword } from "helpers/validators";
import Message from "components/Message/Message";
import ProgressButton from "components/Progress/ProgressButton";
import { getErrorMessage } from "helpers/utils";

import grey from "@material-ui/core/colors/grey";
const { white } = common;

const validate = (values, props) => {
  const errors = {};

  // validate password
  if (!isValidPassword(values.password)) {
    errors.password = "Enter a valid password";
  }

  if (values.password !== values.repeatPassword) {
    errors.repeatPassword = "Passwords do not match";
  }

  return errors;
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: white,
    margin: theme.spacing(1),
    height: 48,
    width: 195,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 0,
    "&:hover": {
      backgroundColor: theme.palette.text.main
    }
  },
  cancelButton: {
    marginLeft: 22,
    width: 193,
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none"
  },
  content: {
    fontSize: 16,
    lineHeight: 1.8,
    paddingBottom: 32
  },
  divider: {
    marginTop: 32,
    marginBottom: 32,
    width: "100%",
    height: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center"
  },
  dividerLine: {
    color: grey[600],
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: white,
    textTransform: "uppercase",
    padding: "0 10px"
  },
  fieldContainer: {
    paddingBottom: 24
  },
  field: {
    width: "100%"
  },
  forgotContainer: {
    paddingTop: 32
  },
  forgot: {
    paddingLeft: 0
  },
  formContainer: {
    width: "50%"
  },
  forgotDivider: {
    marginTop: 32,
    marginBottom: 24,
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center"
  },
  formBody: {
    paddingLeft: 200,
    paddingRight: 122
  },
  icon: {
    color: grey[300]
  },
  imageContainer: {
    width: "50%"
  },
  image: {
    maxHeight: 908,
    maxWidth: "100%"
  },
  link: {
    backgroundColor: white,
    borderWidth: 0,
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "underline",
    "&:focus": {
      outline: "none"
    }
  },
  message: {
    marginBottom: 20
  },
  signupContainer: {
    fontSize: 16,
    paddingTop: 32
  },
  title: {
    fontSize: 48,
    fontWeight: "bold",
    marginBottom: 64,
    marginTop: 120
  },
  [theme.breakpoints.down("lg")]: {
    formBody: {
      paddingTop: "10",
      paddingLeft: "20%",
      paddingRight: "10%"
    }
  },
  [theme.breakpoints.down("md")]: {
    cancelButton: {
      width: 80
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block"
    },
    button: {
      width: "100%"
    },
    cancelButton: {
      marginLeft: 0,
      paddingTop: 24,
      width: "100%"
    },
    fieldContainer: {
      paddingBottom: 20
    },
    formBody: {
      paddingLeft: 20,
      paddingRight: 20
    },
    formContainer: {
      width: "100%"
    },
    imageContainer: {
      width: "100%"
    },
    signupContainer: {
      paddingBottom: 80
    },
    title: {
      fontSize: 40,
      fontWeight: "bold",
      marginBottom: 48,
      marginTop: 80
    }
  }
});

class ResetPassword extends Component {
  state = {
    errorMessage: null,
    finished: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    // reset code required
    const resetCode = this.getResetCode();
    if (!resetCode) {
      this.handleCancel();
    }
  }

  getResetCode = () => {
    if (this.props.match.params && this.props.match.params.resetCode) {
      return this.props.match.params.resetCode;
    }

    return null;
  };

  handleCancel = () => {
    history.replace("/");
  };

  handleResetPassword = async values => {
    try {
      const resetCode = this.getResetCode();
      let res = await this.props.auth.resetPasswordRequest(values, resetCode);
      if (res === true) {
        history.push("/login", { reset: true });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        "There was a problem with the request"
      );
      this.setState({ errorMessage });
    }
  };

  handleSignup = () => {
    history.push("/signup");
  };

  render() {
    const { classes, handleSubmit, submitting } = this.props;

    const { errorMessage } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <div className={classes.formBody}>
            <h3 className={classes.title}>Reset Password</h3>
            <div className={classes.content}>
              Enter and re-enter a new password.
            </div>
            <Form
              onSubmit={handleSubmit(values => {
                this.handleResetPassword(values);
              })}
            >
              <Message
                className={classes.message}
                type="danger"
                message={errorMessage}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      component={ValidationTextField}
                      className={classes.field}
                      variant="outlined"
                      type="password"
                      name="password"
                      label="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon className={classes.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      component={ValidationTextField}
                      className={classes.field}
                      variant="outlined"
                      type="password"
                      name="repeatPassword"
                      label="Repeat Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon className={classes.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <ProgressButton
                className={classes.button}
                label="Reset"
                loading={submitting}
                type="submit"
              />
              <Button
                className={classes.cancelButton}
                onClick={() => this.handleCancel()}
                color="primary"
              >
                {"Cancel"}
              </Button>
            </Form>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img
            src="/images/login/login-1300.jpg"
            srcSet="/images/login/login-540.jpg 540w,
            /images/login/login-800.jpg 800w,
            /images/login/login-1000.jpg 1000w,
            /images/login/login-1300.jpg 1300w"
            sizes="100vw"
            alt="login"
            className={classes.image}
          />
        </div>
      </div>
    );
  }
}

ResetPassword.propTypes = {
  classes: PropTypes.object.isRequired
};

ResetPassword = reduxForm({
  form: "ResetPassword",
  validate
})(ResetPassword);

export default withRoot(withStyles(styles)(ResetPassword));
