import { apiRequest } from "apis/jsonApi";
import Resource from "./resource";

let resource = new Resource({
  singularName: "card",
  pluralName: "cards"
});

export function fetchCards() {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`images`, "GET", {})
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}
