import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import deleteIcon from "../../static/assets/icons/delete-icon-default.svg";

const styles = theme => ({
  imageDetails: {
    padding: "24px 40px"
  },
  imageTitle: {
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "28px",
    height: 60,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  propertyPreviewContainer: {
    width: 280,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "hidden"
  },
  propertyImage: {
    width: "100%",
    paddingTop: "70%",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  link: {
    color: theme.palette.primary.link,
    fontSize: 14,
    lineHeight: "24px",
    textDecoration: "underline",
    cursor: "pointer"
  },
  actions: {
    width: "100%",
    paddingLeft: "40px",
    paddingRight: "40px",
    display: "flex",
    alignItems: "center",
    placeContent: "space-between",
    paddingBottom: "24px"
  }
});

const style = {
  cursor: "move"
};
const PropertyImage = ({
  id,
  index,
  moveCard,
  classes,
  image,
  openDeleteDialog,
  openEditDialog,
  isDefault
}) => {
  return (
    <div style={{ ...style }}>
      <Paper elevation={4} className={classes.propertyPreviewContainer}>
        <div
          className={classes.propertyImage}
          style={{
            backgroundImage: "url('" + image.url + "')"
          }}
        ></div>
        <div className={classes.imageDetails}>
          <div className={classes.imageTitle}>
            {isDefault ? image.filename + " (Default Image)" : image.filename}
          </div>
        </div>
        <div className={classes.actions}>
          <span
            className={classes.link}
            onClick={event => openEditDialog(event, image.id)}
          >
            Edit Name
          </span>
          <img
            alt="delete"
            src={deleteIcon}
            style={{ cursor: "pointer" }}
            onClick={event => openDeleteDialog(event, image.id)}
          ></img>
        </div>
      </Paper>
    </div>
  );
};
export default withStyles(styles)(PropertyImage);
