import React from "react";
import Fade from "react-reveal/Fade";

import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import guestsIcon from "../../static/assets/icons/guests.svg";
import ownersIcon from "../../static/assets/icons/owners.svg";
import common from "@material-ui/core/colors/common";
import history from "../../history";
import HowItWorks from "components/HowItWorks/HowItWorks";
import { Grid, Button } from "@material-ui/core";
import ButtonArrowIcon from "components/Icons/ButtonArrowIcon";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DoneIcon from "@material-ui/icons/Done";

const { white } = common;

export const styles = theme => ({
  root: {
    position: "relative",
    paddingBottom: 200
  },
  avatarContainer: {
    position: "absolute",
    top: 420,
    width: "55%",
    zIndex: 10
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    justifyContent: "center",
    textAlign: "center",
    maxWidth: 800
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: 24,
    fontWeight: "bold",
    height: 64,
    width: 64,
    position: "absolute",
    top: "50%",
    right: -20
  },
  footer: {
    paddingBottom: 100
  },
  heroBlock1: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "30%",
    height: "100%"
  },
  heroBlock2: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "40%",
    height: "100%"
  },
  heroBlock3: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "50%",
    height: "100%"
  },
  heroBlock4: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "60%",
    height: "100%"
  },
  heroContainer: {
    position: "absolute",
    top: 0,
    zIndex: 3,
    width: "100%",
    height: "100%",
    boxSizing: "border-box"
  },
  heroImage: {
    height: "auto",
    maxWidth: "100%"
  },
  heroBody: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    height: "100%",
    boxSizing: "border-box"
  },
  title: {
    color: theme.palette.text.main,
    fontSize: 32,
    fontWeight: "bold",
    lineHeight: 1.4,

    paddingRight: "7.5%",
    maxWidth: 600
  },
  titleText: {
    fontSize: 16
  },
  heroContent: {
    // width: "60%"
  },
  highlight: {
    paddingLeft: 8,
    paddingRight: 8,
    background: `linear-gradient(180deg,rgba(255,255,255,0) 40%, ${theme.palette.secondary.main} 40%)`,
    display: "inline"
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 200,
    paddingLeft: 20,
    paddingRight: 20,
    height: "100%"
  },
  card: {
    width: 411,
    paddingRight: 20
  },
  iconContainer: {
    backgroundColor: white,
    borderRadius: "4px 4px 0 0",
    display: "flex",
    justifyContent: "center",
    paddingTop: 40,
    paddingBottom: 40,
    width: "100%"
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  iconText: {
    fontSize: 16,
    fontWeight: "bold",
    paddingTop: 8
  },
  currentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    paddingTop: 40,
    paddingBottom: 40
  },
  currentPrice: {
    color: theme.palette.secondary.main,
    fontSize: 96,
    fontWeight: "bold",
    paddingBottom: 8
  },
  perPriceText: {
    color: white,
    fontSize: 16,
    fontWeight: "bold"
  },
  perAmountText: {
    color: theme.palette.secondary.main,
    paddingLeft: 4,
    paddingRight: 4
  },
  pricing: {
    maxWidth: 800
  },
  regularContainer: {
    borderRadius: "0 0 4px 4px",
    backgroundColor: theme.palette.text.main,
    color: white,
    textAlign: "center",
    paddingTop: 32,
    paddingBottom: 32
  },
  regularText: {
    fontSize: 16
  },
  regularTextSale: {
    fontWeight: "bold",
    textDecoration: "line-through"
  },
  icon: {
    height: 72,
    width: 72
  },
  [theme.breakpoints.down("md")]: {
    cardContainer: {
      paddingTop: "10%"
    },
    heroContainer: {
      paddingTop: "5%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      paddingBottom: 32
    },
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      color: "rgba(0, 0, 0, 0.5)",
      fontSize: 24,
      fontWeight: "bold",
      height: 64,
      width: 64,
      position: "absolute",
      left: "calc(50% - 32px)",
      bottom: -42,
      top: "unset"
    },
    avatarContainer: {
      position: "relative",
      top: 0,
      width: "100%",
      marginTop: -20,
      marginBottom: -20,
      zIndex: 10
    },
    currentContainer: {
      paddingTop: 32,
      paddingBottom: 32
    },
    currentPrice: {
      fontSize: 64
    },
    heroContainer: {
      position: "relative",
      zIndex: 3,
      paddingTop: 32,
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: "100%"
    },
    title: {
      color: theme.palette.text.main,
      fontSize: 32,
      fontWeight: "bold",
      marginTop: 0,
      marginBottom: 20,
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      width: "100%"
    },
    heroBody: {
      display: "inline-block",
      width: "100%"
    },
    heroContent: {
      textAlign: "center",
      width: "100%",
      paddingTop: 32
    },
    icon: {
      height: 40,
      width: 40
    },
    iconContainer: {
      paddingTop: 32,
      paddingBottom: 32
    },
    iconWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center"
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0
    },
    card: {
      alignSelf: "center",
      border: "1px solid #D5D5D5",
      borderRadius: 4,
      width: "100%",
      maxWidth: 411,
      paddingRight: 0,
      marginTop: 20
    }
  },
  heroButtonIcon: {
    width: 36,
    height: 36
  },
  containerHeroTitle: {
    paddingLeft: "7.5%",
    paddingTop: 200
  },
  bulletListHero: {
    fontSize: 24
  },
  currentPriceYear: {
    verticalAlign: "middle",
    fontSize: 32
  },
  perPriceTextDescription: {
    fontSize: 14,
    color: "white",
    marginTop: 8
  }
});

const HeroButton = withStyles(theme => ({
  root: {
    fontSize: 20,
    boxShadow: "none",
    textTransform: "none",
    height: 60,
    justifyContent: "space-between",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "& span>span": {
      color: theme.palette.secondary.main
    },
    "&:hover": {
      backgroundColor: theme.palette.secondary.hover,
      color: white,
      "& span": {
        color: white
      }
    }
  },
  label: {
    height: 36
  },
  contained: {
    "&:hover": {
      boxShadow: "none"
    }
  },
  containedSizeLarge: {
    padding: "12px 20px 12px 19px"
  },
  iconSizeLarge: {
    height: 36,
    width: 36
  }
}))(Button);

const Pricing = props => {
  const { classes } = props;

  return (
    <Fade>
      <div className={classes.root}>
        <div>
          <img
            src="/images/pricing/pricing-hero-2600.jpg"
            srcSet="/images/pricing/pricing-hero-500.png 500w,
            /images/pricing/pricing-hero-800.jpg 800w,
            /images/pricing/pricing-hero-1080.jpg 1080w,
            /images/pricing/pricing-hero-1600.jpg 1600w,
            /images/pricing/pricing-hero-2000.jpg 2000w,
            /images/pricing/pricing-hero-2600.jpg 2600w"
            sizes="100vw"
            alt="home"
            className={classes.heroImage}
          />
        </div>
        <div>
          <Hidden smDown>
            <div className={classes.heroBlock1} />
            <div className={classes.heroBlock2} />
            <div className={classes.heroBlock3} />
            <div className={classes.heroBlock4} />
          </Hidden>
          <div className={classes.heroContainer}>
            <div className={classes.heroBody}>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                  height: "100%",
                  boxSizing: "border-box"
                }}
              >
                <Grid item xs={12} md={6} lg={5} style={{ paddingLeft: 40 }}>
                  <Hidden mdUp>
                    <h3 className={classes.title}>Pricing</h3>

                    <div className={classes.titleText}>
                      You're in control. You get to choose your pricing
                      structure.
                    </div>
                  </Hidden>
                  <Hidden smDown>
                    <div className={classes.containerHeroTitle}>
                      <h3 className={classes.title}>
                        <span>Putting owners </span>
                        <span className={classes.highlight}>
                          back in charge
                        </span>
                      </h3>
                      <List className={classes.bulletListHero}>
                        <ListItem>
                          <ListItemIcon>
                            <DoneIcon />
                          </ListItemIcon>
                          30 day free trial
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <DoneIcon />
                          </ListItemIcon>
                          No hidden fees
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <DoneIcon />
                          </ListItemIcon>
                          No holds on your money
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <DoneIcon />
                          </ListItemIcon>
                          100% satisfaction guaranteed
                        </ListItem>
                      </List>
                      <p />
                      <HeroButton
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.heroButton}
                        endIcon={
                          <ButtonArrowIcon className={classes.heroButtonIcon} />
                        }
                        onClick={() => history.push("/signup")}
                      >
                        <div className={classes.text}>
                          {"List Your Property"}
                        </div>
                      </HeroButton>
                    </div>
                  </Hidden>
                </Grid>
                <Grid item xs={12} md={6} lg={7}>
                  <div className={classes.heroContent}>
                    <div className={classes.cardContainer}>
                      <div
                        className={classes.card}
                        style={{ position: "relative" }}
                      >
                        <div className={classes.iconContainer}>
                          <div className={classes.iconWrapper}>
                            <img
                              alt="owners icon"
                              src={ownersIcon}
                              className={classes.icon}
                            />
                            <div className={classes.iconText}>Owners</div>
                          </div>
                        </div>
                        <div className={classes.currentContainer}>
                          <div className={classes.currentPrice}>
                            $199
                            <span className={classes.perPriceTextDescription}>
                              / year
                            </span>
                          </div>
                          <div className={classes.perPriceText}>
                            or
                            <span className={classes.perAmountText}>$19</span>/
                            month
                          </div>
                          <div className={classes.perPriceTextDescription}>
                            (Unlimited bookings, Unlimited guests)
                          </div>
                        </div>
                        <div className={classes.regularContainer}>
                          <div className={classes.regularText}>
                            Regularly
                            <span className={classes.regularTextSale}>
                              $299/year
                            </span>
                          </div>
                        </div>
                        <Avatar className={classes.avatar}>OR</Avatar>
                      </div>
                      <div className={classes.card}>
                        <div className={classes.iconContainer}>
                          <div className={classes.iconWrapper}>
                            <img
                              alt="guests icon"
                              src={guestsIcon}
                              className={classes.icon}
                            />
                            <div className={classes.iconText}>Guests</div>
                          </div>
                        </div>
                        <div className={classes.currentContainer}>
                          <div className={classes.currentPrice}>
                            $19
                            <span className={classes.perPriceTextDescription}>
                              / booking
                            </span>
                          </div>
                          <div className={classes.perPriceText}>&nbsp;</div>
                          <div className={classes.perPriceTextDescription}>
                            (No owner listing fee)
                          </div>
                        </div>
                        <div className={classes.regularContainer}>
                          <div className={classes.regularText}>
                            Regularly
                            <span className={classes.regularTextSale}>
                              $29/booking
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
      <HowItWorks />
      <div className={classes.footer} />
    </Fade>
  );
};

export default withStyles(styles, { name: "Pricing" })(Pricing);
