import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CloseIcon from "@material-ui/icons/Close";
import amber from "@material-ui/core/colors/amber";
import red from "@material-ui/core/colors/red";

const styles = theme => ({
  root: {
    display: "flex"
  },
  actions: {
    position: "relative",
    textAlign: "right"
  },
  danger: {
    display: "flex",
    backgroundColor: fade(theme.palette.error.main, 0.2),
    color: red.A700,
    borderRadius: 3,
    paddingLeft: 16,
    padding: 8,
    width: "inherit",
    marginBottom: 10
  },
  dangerText: {
    color: red.A700,
    lineHeight: 2
  },
  flexGrow: {
    flex: "1 1 auto"
  },
  hide: {
    display: "none"
  },
  warning: {
    display: "flex",
    backgroundColor: fade(amber[500], 0.2),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.textColor,
    borderRadius: 3,
    paddingLeft: 16,
    padding: 8,
    width: "inherit",
    marginBottom: 10
  },
  warningText: {
    color: theme.palette.textColor,
    lineHeight: 2
  },
  success: {
    display: "flex",
    backgroundColor: fade(theme.palette.secondary.main, 0.2),
    color: theme.palette.success.main,
    borderRadius: 3,
    paddingLeft: 16,
    padding: 8,
    width: "inherit",
    marginBottom: 10
  },
  successText: {
    color: theme.palette.success.main,
    lineHeight: 2
  }
});

class Message extends Component {
  state = {
    hide: false
  };

  hideMessage(event) {
    this.setState({
      hide: true
    });
  }

  render() {
    const { classes, className, items, message, type } = this.props;

    function setStyle(type) {
      switch (type) {
        case "danger":
          return classes.danger;
        case "warning":
          return classes.warning;
        default:
        case "success":
          return classes.success;
      }
    }

    function setTextStyle(type) {
      switch (type) {
        case "danger":
          return classes.dangerText;
        case "warning":
          return classes.warningText;
        default:
        case "success":
          return classes.successText;
      }
    }

    return message ? (
      <div
        className={
          this.state.hide ? classes.hide : classNames(setStyle(type), className)
        }
      >
        <Typography
          className={setTextStyle(type)}
          variant="body2"
          component="div"
        >
          {message}
          {items && (
            <ul>
              {items.map((item, i) => (
                <li key={`item${i}`}>{item}</li>
              ))}
            </ul>
          )}
        </Typography>
        <div className={classes.flexGrow} />
        <div className={classes.actions}>
          <IconButton size="small" onClick={event => this.hideMessage(event)}>
            <CloseIcon className={setTextStyle(type)} />
          </IconButton>
        </div>
      </div>
    ) : (
      false
    );
  }
}

export default withStyles(styles, { name: "Message" })(Message);
