import React, { Component } from "react";
import AppPageHeader from "components/App/AppPageHeader";

import Avatar from "components/General/Avatar";
import ProgressButton from "components/Progress/ProgressButton";
import { Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";

export default class ManageAccount extends Component {
  state = { loading: false };
  handleUpload = async files => {
    this.setState({ loading: true });
    await this.props.uploadPicture(files, (res, rej) => {
      this.setState({ loading: false });
    });
  };

  render() {
    const { data } = this.props;
    return (
      <>
        <AppPageHeader subHeadline="Manage Account" headline="Account" />
        {data && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
              alignItems: "center"
            }}
          >
            <div style={{ marginBottom: 28 }}>
              <Avatar
                isHuge
                firstName={data.firstName}
                lastName={data.lastName}
                src={data.picture}
              />
            </div>
            <Dropzone
              onDrop={this.handleUpload}
              style={{ border: 0, width: "inherit", heigth: "inherit" }}
            >
              <ProgressButton loading={this.state.isLoading} isSmall>
                Change Avatar
              </ProgressButton>
            </Dropzone>
            <div style={{ marginTop: 28 }}>
              <Typography variant="caption" component="div">
                {data.firstName} {data.lastName}
              </Typography>
            </div>
          </div>
        )}
      </>
    );
  }
}
