import React, { Component } from "react";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";

import LearnHero from "./LearnHero";
import ScrollMore from "components/Home/ScrollMore";
import InvitedGuest from "components/Home/InvitedGuest";
import HowItWorks from "components/HowItWorks/HowItWorks";
import WeMakeItEasy from "components/Home/WeMakeItEasy";

const styles = theme => ({});

class Learn extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <LearnHero {...this.props} />
        <Hidden smDown>
          <ScrollMore />
        </Hidden>
        <InvitedGuest />
        <HowItWorks />
        <WeMakeItEasy />
      </React.Fragment>
    );
  }
}

Learn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Learn));
