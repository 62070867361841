import ReconnectingWebSocket from "reconnecting-websocket";
import { getToken } from "components/Auth/Auth";
import { transformNotification } from "apis/jsonApi";
import { appSnackbarMessage } from "redux/modules/app";
import { fetchNotifications } from "redux/modules/notifications";
import { addFeedFromPushNotification } from "redux/modules/feeds";

class OurWebSocket {
  constructor() {
    this.dispatch = () => console.warn("dispatch in websocket is not set yet");
    this.loadWS();
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  sendMessage(message) {
    this.ws.send(message);
  }

  getNotificationMessage(type) {
    switch (type) {
      case "PropertyFriendAdd":
        return "You have been added to a property";
      case "PropertyFollowRequest":
        return "You have received a new follow request";
      case "PropertyFollowRequestAccepted":
        return "Your follow request has been accepted";
      case "PropertyNewTripRequest":
        return "You have received a new trip request";
      case "PropertyNewMessageTrip":
        return "You have received a new message on a trip request";
      case "PropertyAddedAsHost":
        return "You have been added as an owner";
      default:
        return false;
    }
  }
  loadWS() {
    if (this.ws && this.ws.close) {
      this.ws.close();
    }
    if (!process.env.REACT_APP_VRBFF_WS) {
      return console.warn(
        "Cannot start WebSocket because ENV var 'REACT_APP_VRBFF_WS' is not set"
      );
    }

    const token = getToken();
    if (!token) return false;

    this.ws = new ReconnectingWebSocket(
      process.env.REACT_APP_VRBFF_WS + "?token=" + token
    );

    this.ws.addEventListener("message", message => {
      const data = JSON.parse(message.data);
      if (data.type === "notification") {
        const notification = transformNotification(message);
        const notificationMessage = this.getNotificationMessage(
          notification.type
        );
        if (notificationMessage) {
          this.dispatch(appSnackbarMessage({ message: notificationMessage }));
        }

        this.dispatch(fetchNotifications());
      }
      if (data.type === "feed") {
        const feed = transformNotification(message);
        this.dispatch(addFeedFromPushNotification(feed));
      }
    });
  }
}

export default new OurWebSocket();
