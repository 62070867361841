import React from "react";
import Fade from "react-reveal/Fade";

import { withStyles } from "@material-ui/core/styles";
import yourHomeIcon from "../../static/assets/icons/your-home.svg";

export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    paddingLeft: 200,
    paddingRight: 200,
    paddingTop: 120
  },
  divider: {
    color: theme.palette.secondary.main,
    width: 80
  },
  iconHome: {
    height: 60
  },
  imgContainer: {
    width: "50%"
  },
  img: {
    maxWidth: "100%"
  },
  textContainer: {
    width: "50%",
    paddingLeft: 134
  },
  textTitle: {
    lineHeight: 1.4,
    paddingTop: 40,
    fontSize: 32
  },
  textHeading: {
    fontWeight: "bold",
    paddingRight: 6
  },
  textCopy: {
    fontWeight: "normal"
  },
  textBody: {
    fontSize: 16,
    lineHeight: 1.8,
    paddingTop: 16
  },
  [theme.breakpoints.down("lg")]: {
    root: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: 60
    },
    textContainer: {
      paddingLeft: "5%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 64
    },
    imgContainer: {
      width: "100%"
    },
    iconHome: {
      height: 48
    },
    textBody: {
      paddingTop: 14
    },
    textTitle: {
      paddingTop: 20,
      fontSize: 24
    },
    textContainer: {
      width: "100%",
      paddingLeft: 0,
      paddingTop: 32
    }
  }
});

const InvitedGuest = props => {
  const { classes } = props;
  return (
    <Fade>
      <div className={classes.root}>
        <div className={classes.imgContainer}>
          <img
            src="/images/your-home/your-home.png"
            srcSet="/images/your-home/your-home-540.jpg 540w,
            /images/your-home/your-home-800.jpg 800w,
            /images/your-home/your-home-1000.jpg 1000w,
            /images/your-home/your-home-1300.jpg 1300w"
            sizes="100vw"
            alt="your home"
            className={classes.img}
          />
        </div>
        <div className={classes.textContainer}>
          <img
            src={yourHomeIcon}
            alt="your home icon"
            className={classes.iconHome}
          />
          <div className={classes.textTitle}>
            <span className={classes.textHeading}>
              A guest is someone you invite.
            </span>
          </div>
          <div className={classes.textBody}>
            Whether you’re renting out your home, or just trading and sharing 
            with friends and family, Vrbff has you covered. 
            <p>Vrbff is the first and only platform that lets you decide who is invited. 
             You can publish your listing to your entire Facebook network, to your 
             existing book of clients, or to all of Vrbff’s Friends of Friends 
             network.
             </p>
            <p>You decide who is invited.</p>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default withStyles(styles, { name: "InvitedGuest" })(InvitedGuest);
