import React, { Component } from "react";
import { Typography, withStyles } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import MessagePreview from "components/InviteWizard/MessagePreview";
import history from "../../history";

const styles = theme => ({
  propertyNameContainer: {
    background: "white",
    padding: "12px 18px",
    marginTop: -25,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 250,
    marginBottom: 22
  },
  propertyPreviewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "28px 0",
    padding: "0 32px"
  },
  propertyPreview: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    maxWidth: 300,
    marginTop: 12
  },
  messagePreview: {
    margin: "12px 0",
    width: "100%",
    borderBottom: "1px solid #00000022",
    marginBottom: 12
  },
  editorContainer: {
    marginTop: 18
  },

  content: {
    marginTop: 38,
    display: "flex"
  },
  guestListContainer: {
    maxWidth: 200
  },
  guestEmail: {
    border: "1px solid #f7f7f7",
    backgroundColor: "#FFF",
    fontSize: 10,
    fontStyle: "italic",
    padding: "4 8px",
    margin: "4px 0"
  },
  previewContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  }
});

class ConfirmStep extends Component {
  constructor(props) {
    super(props);

    this.finalData = this.prepareFinalData();
  }

  prepareFinalData = () => {
    let { data } = this.props;

    let finalData = {
      propertyId: data.propertyId,
      emails: data.emails.filter(e => e.length > 0)
    };
    if (data.descriotion) {
      finalData.content = data.description.htmlData;
    }
    return finalData;
  };
  async continueCheck() {
    return new Promise(async resolve => {
      try {
        await this.props.sendInvites(
          this.finalData.emails,
          this.finalData.content,
          this.finalData.propertyId
        );
        this.props.snackbarMessage({
          message:
            this.finalData.emails.length > 1
              ? "Your invites are on the way!"
              : "The invite is on the way!"
        });
        history.push(`/`);
      } catch (e) {
        this.props.snackbarMessage({
          message: "Unfortunately there was an error while sending the invites",
          type: "warning"
        });
        console.error(e);
      }
      return resolve(false);
    });
  }

  render() {
    const { classes, data, properties, profile } = this.props;
    const d = this.finalData;
    return (
      <WizardStepContainer
        darkComponent
        Component={
          <div>
            <Typography variant="subtitle1">
              {"Confirm your guestlist and message"}
            </Typography>
            <Typography variant="caption" component="div">
              {
                "Make sure the guests and message looks correct, then send your invitations!"
              }
            </Typography>

            <div className={classes.content}>
              <div className={classes.guestListContainer}>
                <Typography variant="caption" component="div">
                  {"Sending to " + d.emails.length + " guests"}
                </Typography>
                {d.emails.map((e, index) => (
                  <div className={classes.guestEmail} key={index}>
                    {e}
                  </div>
                ))}
              </div>
              <div className={classes.previewContainer}>
                <MessagePreview
                  data={data}
                  properties={properties}
                  profile={profile}
                />
              </div>
            </div>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

ConfirmStep = withStyles(styles, { name: "ConfirmStep" })(ConfirmStep);

export default ConfirmStep;
