import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from "../../redux/modules/app";
import * as notificationsActionCreators from "../../redux/modules/notifications";
import { withStyles } from "@material-ui/core/styles";
import { Popover, Typography, Badge } from "@material-ui/core";
import notificationIcon from "../../static/assets/icons/notification-icon.svg";
import GenericNotification from "components/Notifications/GenericNotification";

const StyledBadge = withStyles(theme => ({
  root: {
    width: 80,
    display: "inline-flex",
    alignContent: "center",
    backgroundColor: "#465172",
    textDecoration: "none",
    borderBottom: "2px solid transparent",
    borderRight: "1px solid #192959",
    transition: theme.transitions.create("all"),
    cursor: "pointer",
    color: theme.palette.secondary.white,
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      textDecoration: "none",
      backgroundColor: theme.palette.primary.selected,
      borderBottomColor: theme.palette.secondary.main
    }
  },
  badge: {
    right: 30,
    top: 27,
    backgroundColor: "#ff0000",
    width: 12,
    height: 12,
    borderRadius: 6,
    content: "",
    fontSize: 0,
    border: "2px solid white",
    minWidth: 12,
    padding: 0
  }
}))(Badge);

export const styles = theme => ({
  activeItem: {
    fontWeight: "500",
    backgroundColor: theme.palette.primary.selected,
    borderBottomColor: theme.palette.primary.main
  },
  typography: {
    padding: 18
  },
  [theme.breakpoints.down("sm")]: {
    badgeIcon: {
      marginLeft: "auto"
    }
  },
  popover: {
    maxWidth: 400,
    border: "1px solid " + theme.palette.secondary.border
  },
  headerList: {
    padding: 40,
    borderBottom: "1px solid " + theme.palette.secondary.border,
    display: "flex",
    alignContent: "space-between"
  },
  headerListText: {
    flex: 1,
    fontWeight: 700,
    fontSize: 16,
    color: theme.palette.primary.hover
  },
  headerListCounter: {
    fontWeight: 400,
    fontSize: 16,
    color: theme.palette.primary.hover
  },
  headerListCounterDot: {
    backgroundColor: "#ff0000",
    width: 12,
    height: 12,
    borderRadius: 6,

    display: "inline-block",
    border: "2px solid white",
    marginBottom: 6,
    marginLeft: 3
  }
});

class NotificationIcon extends Component {
  state = { anchorEl: null, open: false, previousCounter: 0, toRead: [] };
  async componentDidMount() {
    this.props.fetchNotifications();
  }

  handleClick = event => {
    this.setState({
      previousCounter: this.props.counter,
      toRead: (this.props.notifications || []).map(n =>
        n.viewed === false ? n.id : false
      )
    });
    this.props.readAllNotifications();
    this.props.resetCounter();
    this.setState({ anchorEl: event.currentTarget });
  };
  handleClose = event => {
    this.setState({ anchorEl: null });
  };

  clickOnNotification = () => {
    this.setState({ anchorEl: null });
  };

  getNotificationComponent = (notification, isNew) => {
    switch (notification.type) {
      case "PropertyFriendAdd":
        return (
          <GenericNotification
            isNew={isNew}
            key={notification.id}
            onClick={this.clickOnNotification}
            data={notification}
            to={"/p/" + notification.property.slug}
            title={"added you to " + notification.property.name}
            text={
              "Your friend " +
              notification.user.name +
              " added you to their property"
            }
          />
        );
      case "PropertyFollowRequest":
        return (
          <GenericNotification
            isNew={isNew}
            key={notification.id}
            onClick={this.clickOnNotification}
            data={notification}
            to={"/manage/follows/" + notification.property.id}
            title={"follow request"}
            text={`${notification.user.firstName} ${notification.user.lastName} wants to follow ${notification.property.name}`}
          />
        );
      case "PropertyFollowRequestAccepted":
        return (
          <GenericNotification
            isNew={isNew}
            key={notification.id}
            onClick={this.clickOnNotification}
            data={notification}
            to={"/p/" + notification.property.slug}
            noName
            title={"Follow request accepted"}
            text={`Now you can make a trip request to ${notification.property.name}`}
          />
        );
      case "PropertyNewTripRequest":
        return (
          <GenericNotification
            isNew={isNew}
            key={notification.id}
            onClick={this.clickOnNotification}
            data={notification}
            to={"/inbox/" + notification.trip.id}
            title={"trip request"}
            text={`${notification.user.firstName} ${notification.user.lastName} made a trip request for ${notification.property.name}`}
          />
        );
      case "PropertyNewMessageTrip":
        return (
          <GenericNotification
            isNew={isNew}
            key={notification.id}
            onClick={this.clickOnNotification}
            data={notification}
            to={"/inbox/" + notification.trip.id}
            title={"sent a new message"}
            text={`${notification.user.firstName} ${notification.user.lastName} sent a new message to a trip for the property ${notification.property.name}`}
          />
        );
      case "PropertyAddedAsHost":
        return (
          <GenericNotification
            isNew={isNew}
            key={notification.id}
            onClick={this.clickOnNotification}
            data={notification}
            to={"/manage/calendar/" + notification.property.id}
            title={"added you as an host"}
            text={`Now you can manage ${notification.property.name}`}
          />
        );
      default:
        return <></>;
    }
  };

  showNotificationList = () => {
    return this.props.notifications.map(n =>
      this.getNotificationComponent(
        n,
        (this.state.previousCounter > 0 &&
          this.state.toRead.indexOf(n.id) !== -1) ||
          false
      )
    );
  };

  render() {
    const { classes, counter, notifications } = this.props;
    const { anchorEl, previousCounter } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "notification-popover" : undefined;

    return (
      <Fragment>
        <StyledBadge
          className={(open && classes.activeItem) || null}
          badgeContent={counter}
          color="primary"
          onClick={this.handleClick}
        >
          <img
            alt={"notification icon"}
            src={notificationIcon}
            style={{ fontSize: 28 }}
          />
        </StyledBadge>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          PaperProps={{ className: classes.popover }}
        >
          <div className={classes.headerList}>
            <span className={classes.headerListText}>Notifications</span>
            {previousCounter > 0 && (
              <span className={classes.headerListCounter}>
                {previousCounter}
                <span className={classes.headerListCounterDot}></span>
              </span>
            )}
          </div>
          {notifications.length === 0 && (
            <Typography className={classes.typography}>
              There are no notifications at the moment
            </Typography>
          )}
          {notifications.length > 0 && this.showNotificationList()}
        </Popover>
      </Fragment>
    );
  }
}

function mapStateToProps({ notifications, app }, props) {
  let noti = (notifications.ids || [])
    .map(n => notifications[n])
    .filter(Boolean);
  return { notifications: noti, counter: app.notificationCounter || 0 };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...appActionCreators,
      ...notificationsActionCreators,
      resetCounter: () => {
        return { type: "APP_SET_NOTIFICATION_COUNTER", counter: 0 };
      }
    },
    dispatch
  );
}

export default withStyles(styles, { name: "NotificationIcon" })(
  connect(mapStateToProps, mapDispatchToProps)(NotificationIcon)
);
