import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import history from "../../history";

import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import { withStyles } from "@material-ui/core/styles";

import ButtonArrowIcon from "components/Icons/ButtonArrowIcon";
import common from "@material-ui/core/colors/common";
const { white } = common;

export const styles = theme => ({
  root: {
    position: "relative"
  },
  heroButton: {
    fontSize: 20,
    boxShadow: "none",
    textTransform: "none",
    height: 60,
    justifyContent: "space-between"
  },
  heroButtonIcon: {
    width: 36,
    height: 36
  },
  heroBlock1: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "30%",
    height: "100%"
  },
  heroBlock2: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "40%",
    height: "100%"
  },
  heroBlock3: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "50%",
    height: "100%"
  },
  heroBlock4: {
    position: "absolute",
    top: 0,
    background: "rgba(255,255,255,0.5)",
    width: "60%",
    height: "100%"
  },
  heroContainer: {
    position: "absolute",
    top: 0,
    zIndex: 3,
    paddingTop: 200,
    paddingLeft: 200,
    maxWidth: 600
  },
  heroCopy: {
    fontSize: 16,
    lineHeight: 1.8,
    maxWidth: 519
  },
  heroButtonContainer: {
    marginTop: 40
  },
  heroImage: {
    height: "auto",
    maxWidth: "100%"
  },
  heroTitle: {
    color: theme.palette.text.main,
    fontSize: 48,
    fontWeight: "bold",
    paddingBottom: 32,
    margin: 0
  },
  highlight: {
    background: `linear-gradient(180deg,rgba(255,255,255,0) 40%, ${theme.palette.secondary.main} 40%)`,
    display: "inline"
  },
  iconScroll: {
    width: 24,
    height: 24
  },
  learnMore: {
    color: white,
    fontSize: 10,
    paddingLeft: 4
  },
  text: {
    color: white
  },
  [theme.breakpoints.down("lg")]: {
    heroContainer: {
      paddingTop: "10%"
    }
  },
  [theme.breakpoints.down("md")]: {
    heroContainer: {
      paddingTop: "5%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    heroContainer: {
      position: "relative",
      zIndex: 3,
      paddingTop: 32,
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: "100%"
    },
    heroButtonContainer: {
      marginTop: 32
    },
    heroCopy: {
      maxWidth: "100%"
    },
    heroTitle: {
      fontSize: 32
    }
  }
});

const HeroButton = withStyles(theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
    "& span": {
      color: theme.palette.secondary.main
    },
    "&:hover": {
      backgroundColor: theme.palette.text.main,
      color: white,
      "& span": {
        color: white
      }
    }
  },
  label: {
    height: 36
  },
  contained: {
    "&:hover": {
      boxShadow: "none"
    }
  },
  containedSizeLarge: {
    padding: "12px 20px 12px 19px"
  },
  iconSizeLarge: {
    height: 36,
    width: 36
  }
}))(Button);

class Hero extends Component {
  handleSignup = () => {
    history.replace("/signup");
  };

  render() {
    const { classes } = this.props;

    return (
      <Fade>
        <div className={classes.root}>
          <div>
            <img
              src="/images/home/hero-2600.jpg"
              srcSet="/images/home/hero-500.jpg 500w,
            /images/home/hero-800.jpg 800w,
            /images/home/hero-1080.jpg 1080w,
            /images/home/hero-1600.jpg 1600w,
            /images/home/hero-2000.jpg 2000w,
            /images/home/hero-2600.jpg 2600w"
              sizes="100vw"
              alt="home"
              className={classes.heroImage}
            />
          </div>
          <div>
            <Hidden smDown>
              <div className={classes.heroBlock1} />
              <div className={classes.heroBlock2} />
              <div className={classes.heroBlock3} />
              <div className={classes.heroBlock4} />
            </Hidden>
            <div className={classes.heroContainer}>
              <h3 className={classes.heroTitle}>
                <span>Vrbff puts hosts</span>
                <div>
                  <span className={classes.highlight}>back in control.</span>
                </div>
              </h3>
              <div className={classes.heroCopy}>
                Acquiring qualified renters is a process – not the impossible
                mystery VRBO makes it seem.
                <p>
                  Vrbff is the only listing platform designed for hosts. Now you
                  can list your property privately, and then choose who is
                  invited to rent based on <strong>your</strong> criteria.
                </p>
                <p>
                  <strong>No more disrespect</strong>, no more shorted payments,
                  hidden costs, or funds withheld for sketchy excuses. Owners
                  deserve respect and control – and now they have it. Join us.
                </p>
                <p>
                  After all, it’s <strong>your</strong> home – not VRBO’s.
                </p>
              </div>
              <div className={classes.heroButtonContainer}>
                <HeroButton
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.heroButton}
                  endIcon={
                    <ButtonArrowIcon className={classes.heroButtonIcon} />
                  }
                  onClick={() => this.handleSignup()}
                >
                  <div className={classes.text}>{"List Your Property"}</div>
                </HeroButton>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    );
  }
}

export default withStyles(styles, { name: "Hero" })(Hero);
