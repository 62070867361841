import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import Button from "@material-ui/core/Button";
import { fade } from "@material-ui/core/styles/colorManipulator";
import grey from "@material-ui/core/colors/grey";

const styles = theme => ({
  button: {
    background: grey[100],
    color: theme.palette.primary.main,
    borderRadius: 0,
    minWidth: 32,
    height: 50,
    width: 80,
    padding: "4px 8px",
    border: "1px solid " + theme.palette.secondary.border,
    borderWidth: "0 1px 0 1px",
    "&:hover": {
      backgroundColor: fade(theme.palette.text.primary, 0.12),
      color: theme.palette.textColor
    }
  },
  buttonActive: {
    background: theme.palette.primary.main,
    border: 0,
    borderRadius: 0,
    minWidth: 32,
    height: 50,
    width: 80,
    padding: "4px 8px",
    color: "#FFF",
    "&:hover": {
      backgroundColor: fade(theme.palette.text.primary, 0.12),
      color: theme.palette.textColor
    }
  },
  icon: {
    color: grey[500]
  },
  iconActive: {
    color: "#FFF",
    backgroundColor: "#FFF"
  }
});
class EditorButton extends Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    const svgIcon = label => {
      switch (label) {
        case "bold":
          return <FormatBoldIcon />;
        case "italic":
          return <FormatItalicIcon />;
        case "underline":
          return <FormatUnderlinedIcon />;
        case "unordered-list-item":
          return <FormatListBulletedIcon />;
        case "ordered-list-item":
          return <FormatListNumberedIcon />;
        // no default
      }
    };

    const { classes } = this.props;

    return (
      <Button
        className={this.props.active ? classes.buttonActive : classes.button}
        type="button"
        onMouseDown={this.onToggle}
      >
        {svgIcon(this.props.label)}
      </Button>
    );
  }
}

EditorButton.propTypes = {
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  style: PropTypes.string.isRequired
};

export default withStyles(styles, { name: "EditorButton" })(EditorButton);
