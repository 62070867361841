import React from "react";
import Fade from "react-reveal/Fade";

import { withStyles } from "@material-ui/core/styles";
import betterGuestsIcon from "../../static/assets/icons/guests.svg";

export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    paddingLeft: 200,
    paddingRight: 200,
    paddingTop: 120
  },
  divider: {
    color: theme.palette.secondary.main,
    width: 80
  },
  iconHome: {
    height: 60
  },
  imgContainer: {
    width: "50%"
  },
  img: {
    maxWidth: "100%"
  },
  textContainer: {
    width: "50%",
    paddingRight: 60
  },
  textTitle: {
    lineHeight: 1.4,
    paddingTop: 40,
    fontSize: 32
  },
  textHeading: {
    fontWeight: "bold",
    paddingRight: 6
  },
  textCopy: {
    fontWeight: "normal"
  },
  textBody: {
    fontSize: 16,
    lineHeight: 1.8,
    paddingTop: 16
  },
  [theme.breakpoints.down("lg")]: {
    root: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: 60
    },
    textContainer: {
      paddingLeft: "5%"
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 64
    },
    imgContainer: {
      width: "100%"
    },
    iconHome: {
      height: 48
    },
    textBody: {
      paddingTop: 14
    },
    textTitle: {
      paddingTop: 20,
      fontSize: 24
    },
    textContainer: {
      width: "100%",
      paddingLeft: 0,
      paddingTop: 32
    }
  }
});

const BetterGuest = props => {
  const { classes } = props;
  return (
    <Fade>
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <img
            src={betterGuestsIcon}
            alt="your guests icon"
            className={classes.iconHome}
          />
          <div className={classes.textTitle}>
            <span className={classes.textHeading}>
            Rent to better guests by choosing who gets invited.
            </span>
          </div>
          <div className={classes.textBody}>
            <p>
            After listing your property, get better guests by choosing whom to invite. 
            Automatically invite your personal or business networks. Access the Vrbff 
            Friends-of-Friends network of guests who have been personally invited by 
            other hosts like you to properties of a similar profile with yours. And 
            finally direct market your property automatically based on criteria you 
            establish as right for your property.
            </p>
            <p>
            You’re the owner. Shouldn’t you be in control?
            </p>
          </div>
        </div>
        <div className={classes.imgContainer}>
          <img
            src="/images/better-guests/better-guests.png"
            srcSet="/images/better-guests/better-guests-540.jpg 540w,
            /images/better-guests/better-guests-800.jpg 800w,
            /images/better-guests/better-guests-1000.jpg 1000w,
            /images/better-guests/better-guests-1300.jpg 1300w"
            sizes="100vw"
            alt="better guests"
            className={classes.img}
          />
        </div>
      </div>
    </Fade>
  );
};

export default withStyles(styles, { name: "BetterGuest" })(BetterGuest);
