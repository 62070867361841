// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import BackIcon from "@material-ui/icons/KeyboardArrowLeft";

import MaterialLink from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  pageHeader: {
    display: "block",
    marginBottom: theme.spacing(2)
  },
  pageHeaderHeadline: {
    margin: "0",
    fontSize: 16,
    fontWeight: "400",
    lineHeight: "28px",
    color: theme.palette.text.main
  },
  pageHeaderSubheadline: {
    margin: "0",
    fontSize: 48,
    fontWeight: "700",
    color: theme.palette.text.main,
    lineHeight: "60px",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  breadcrumbLink: {
    textTransform: "uppercase",
    fontSize: "11px",
    fontWeight: "bold",
    color: theme.palette.grey.dark,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "2px",
    transition: theme.transitions.create("all"),
    backgroundColor: theme.palette.grey.light,

    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main
    }
  },
  backIcon: {
    width: "16px",
    marginRight: theme.spacing(0.5),
    top: "1px",
    position: "relative"
  }
});

class AppPageHeader extends Component {
  static propTypes = {
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    breadcrumbPath: PropTypes.string,
    breacrumbTitle: PropTypes.string
  };

  render() {
    const { classes, breadcrumbPath, breadcrumbTitle } = this.props;

    const breadcrumbItem = breadcrumbPath ? (
      <MaterialLink
        component={Link}
        to={breadcrumbPath}
        className={classes.breadcrumbLink}
      >
        <BackIcon className={classes.backIcon} />
        <span>{breadcrumbTitle}</span>
      </MaterialLink>
    ) : null;

    const headline = this.props.headline ? (
      <h1 className={classes.pageHeaderHeadline}>{this.props.headline}</h1>
    ) : null;

    const subHeadline = this.props.subHeadline ? (
      <h2 className={classes.pageHeaderSubheadline}>
        {this.props.subHeadline}
      </h2>
    ) : null;

    return (
      <div className={classes.pageHeader}>
        {breadcrumbItem}
        {headline}
        {subHeadline}
      </div>
    );
  }
}

export default withStyles(styles, { name: "AppPageHeader" })(AppPageHeader);
