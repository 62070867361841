import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as followsActionCreators from "../../redux/modules/follows";
import * as appActionCreators from "../../redux/modules/app";
import * as feedActionCreators from "../../redux/modules/feeds";
import Dashboard from "../../components/Dashboard/Dashboard";
import DashboardFeed from "../../components/Dashboard/DashboardFeed";
import AppPageHeader from "../../components/App/AppPageHeader";
import MainActionButton from "components/Buttons/MainActionButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const styles = theme => ({
  mainContainer: {
    display: "flex",
    marginRight: 400 //needed for feed
  },
  containerTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 80,
    borderBottom: "1px solid #D5D5D5",
    marginBottom: 44
  },
  [theme.breakpoints.down("sm")]: {
    mainContainer: {
      marginRight: 0
    },
    containerTitle: {
      flexDirection: "column"
    }
  }
});

class DashboardContainer extends Component {
  state = {
    errorMessage: null,
    snackbarMessage: null
  };

  checkMessage = message => {
    // check for messages
    let snackbarMessage = null;
    if (message) {
      switch (message) {
        case "property-updated":
          snackbarMessage = "Property updated";
          break;
        // no default
      }
      this.setState({ snackbarMessage });
    }
  };

  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  async componentDidMount() {
    await this.props.fetchProperties();
    await this.props.fetchFeed();
  }

  deleteProperty = async propertyId => {
    try {
      await this.props.deleteProperty(propertyId);
      this.props.appSnackbarMessage({
        message: "The property was successfully deleted"
      });
      await this.props.fetchProperties();
    } catch (error) {
      this.props.appSnackbarMessage({
        message:
          "Unfortunately something went wrong while deleting your property",
        type: "warning"
      });
    }
  };

  followProperty = async property => {
    try {
      const follow = await this.props.addFollow({ property });
      if (follow.id > 0) {
        property.hasRequested = true;
        this.props.updatePropertyRequest(property);
        this.props.appSnackbarMessage({
          message: "Follow request sent"
        });
      }
    } catch (error) {
      console.error("error:", error);
      this.props.appSnackbarMessage({
        message: "There was a problem with the follow request.",
        type: "warning"
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.mainContainer}>
        <div style={{ flex: 1 }}>
          <div className={classes.containerTitle}>
            <AppPageHeader
              subHeadline="My properties"
              headline="Welcome to Vrbff"
            />
            <div style={{ marginTop: 34 }}>
              {/* <MainActionButton
                to="/add-property"
                isOutline
                style={{ marginRight: 24, marginBottom: 24 }}
              >
                <AddCircleIcon style={{ fontSize: 18, marginRight: 4 }} />{" "}
                Invite Owners
              </MainActionButton> */}
              <MainActionButton to="/add-property" style={{ marginBottom: 24 }}>
                <AddCircleIcon style={{ fontSize: 18, marginRight: 4 }} /> Add
                Property
              </MainActionButton>
            </div>
          </div>
          <Dashboard
            auth={this.props.auth}
            errorMessage={this.state.errorMessage}
            hasFetched={this.props.hasFetched}
            isFetching={this.props.isFetching}
            snackbarMessage={this.state.snackbarMessage}
            properties={this.props.properties}
            deleteProperty={this.deleteProperty}
            followProperty={this.followProperty}
          />
        </div>
        <DashboardFeed
          feed={this.props.feeds}
          hasFetched={this.props.hasFetchedFeed}
          isFetching={this.props.isFetchingFeed}
        />
      </div>
    );
  }
}

function mapStateToProps({ properties, feeds }, props) {
  return {
    isFetchingFeed: feeds.isFetching,
    hasFetchedFeed: feeds.hasFetched,
    feeds: feeds,
    hasFetched: properties.hasFetched,
    isFetching: properties.isFetching,
    properties
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...followsActionCreators,
      ...appActionCreators,
      ...feedActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DashboardContainer));
