import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({});

function ButtonArrowIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>4C6BFDE0-EEE9-4215-87D2-FD938658FB16</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="Asset-Artboard-Page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="button-arrow-default">
            <ellipse
              id="Oval"
              fill="currentColor"
              cx="17.6923077"
              cy="17.7039474"
              rx="17.6923077"
              ry="17.7039474"
            ></ellipse>
            <path
              d="M14.9159195,23.5681112 L22.1231971,18.064819 C22.2349553,17.979488 22.3005348,17.8468716 22.3005348,17.7062028 C22.3005348,17.5655341 22.2349553,17.4329176 22.1231971,17.3475866 L14.9159195,11.8397835"
              id="Shape"
              stroke="#0F0F10"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default withStyles(styles)(ButtonArrowIcon);
