import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import moment from "moment";
import DatePicker from "../../containers/Components/DatePicker";
import SelectGuests from "../Properties/RequestStay/SelectGuests";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  updateButton: {
    marginTop: theme.spacing(2)
  },
  declineButton: {
    borderColor: "red",
    color: "red"
  },
  containerButtons: {
    marginTop: 20
  },
  requestTitle: {
    marginTop: 10
  }
});

const TripDetailBox = ({ trip, handleTripUpdate, classes }) => {
  const [tripState, setTripState] = useState({
    startDate: moment(trip.startDate),
    endDate: moment(trip.endDate),
    adults: trip.adults,
    children: trip.children
  });

  const handleGuestChanges = (name, value) => () => {
    setTripState({ ...tripState, [name]: tripState[name] + value });
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography
        variant="body2"
        color="inherit"
        className={classes.requestTitle}
      >
        {"Dates"}
      </Typography>
      <DatePicker
        blockId={trip.id}
        initialDate={moment.range(tripState.startDate, tripState.endDate)}
        propertyId={trip.property.id}
        onSelect={value =>
          setTripState({
            ...tripState,
            startDate: value.start,
            endDate: value.end
          })
        }
      />

      <Typography
        variant="body2"
        color="inherit"
        className={classes.requestTitle}
      >
        {"Guests"}
      </Typography>
      <SelectGuests
        adults={tripState.adults}
        children={tripState.children}
        fullTitle
        handleAddAdults={handleGuestChanges("adults", 1)}
        handleRemoveAdults={handleGuestChanges("adults", -1)}
        handleAddChildren={handleGuestChanges("children", 1)}
        handleRemoveChildren={handleGuestChanges("children", -1)}
      />

      <MainActionButton
        className={classes.updateButton}
        fullWidth
        onClick={() =>
          handleTripUpdate({
            ...tripState,
            id: trip.id,
            startDate: tripState.startDate.format("YYYY-MM-DD"),
            endDate: tripState.endDate.format("YYYY-MM-DD")
          })
        }
      >
        Update
      </MainActionButton>
    </div>
  );
};

export default withRoot(withStyles(styles)(TripDetailBox));
