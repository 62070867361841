import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ProgressButton from "components/Progress/ProgressButton";
import Loading from "components/Progress/Loading";
import Pagination from "components/Pagination/Pagination";
import Search from "components/Search/Search";
import EnhancedTableHead from "components/Table/EnhancedTableHead";
import Avatar from "components/General/Avatar";
const styles = theme => ({
  root: {
    width: "100%"
  },
  actions: {
    display: "flex",
    flexDirection: "row"
  },
  action: {
    marginBottom: 16
  },
  button: {
    margin: theme.spacing(1)
  },
  icon: {
    width: 16
  },
  paper: {
    marginTop: theme.spacing(3),
    background: theme.palette.background.paper,
    width: "100%",
    overflowX: "auto",
    paddingBottom: 20
  },
  container: {
    padding: 20
  },
  footer: {
    marginTop: 10
  },
  headline: {
    display: "flex",
    justifyContent: "space-between"
  },
  tabContainer: {
    padding: 20
  },
  tableRow: {
    cursor: "pointer",
    height: 65
  },
  tableWrapper: {
    overflowX: "auto"
  },
  title: {
    marginTop: 16
  }
});

class AdminUsers extends Component {
  render() {
    const {
      auth,
      classes,
      handleFetchCollection,
      handleApproveWaitList,
      handleSearch,
      handleSort,
      hasFetched,
      order,
      orderBy,
      isFetching,
      isUpdating,
      search,
      setSearch,
      users
    } = this.props;

    // TODO: move pageSize to a user profile setting
    const pageSize = 25;

    const columnData = [
      { id: "id", numeric: true, disablePadding: false, label: "id" },
      {
        id: "picture",
        numeric: false,
        disablePadding: false,
        label: "Picture"
      },
      {
        id: "firstName",
        numeric: false,
        disablePadding: false,
        label: "First Name"
      },
      {
        id: "lastName",
        numeric: false,
        disablePadding: false,
        label: "Last Name"
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email"
      },
      {
        id: "active",
        numeric: false,
        disablePadding: false,
        label: "Active"
      }
    ];
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Search
            title="Users"
            order={order}
            orderBy={orderBy}
            onRequestSort={handleSort}
            pageSize={pageSize}
            params={{ role: "admin" }}
            handleSearch={handleSearch}
            search={search}
            setSearch={setSearch}
          />
          {isFetching && <Loading />}
          {!isFetching && (
            <React.Fragment>
              {users.ids.length > 0 && (
                <React.Fragment>
                  <div className={classes.tableWrapper}>
                    <Table>
                      <EnhancedTableHead
                        columnData={columnData}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleSort}
                        pageSize={pageSize}
                      />
                      <TableBody>
                        {users.ids.map(id => {
                          const user = users[id];
                          return (
                            <TableRow
                              className={classes.tableRow}
                              key={id}
                              hover
                            >
                              <TableCell align="right">{user.id}</TableCell>
                              <TableCell>
                                <Avatar
                                  firstName={user.firstName}
                                  lastName={user.lastName}
                                  src={user.picture}
                                />
                              </TableCell>
                              <TableCell>{user.firstName}</TableCell>
                              <TableCell>{user.lastName}</TableCell>
                              <TableCell>{user.email}</TableCell>
                              <TableCell>
                                {!user.active && (
                                  <ProgressButton
                                    variant="outlined"
                                    color="primary"
                                    label="Approve"
                                    loading={isUpdating}
                                    onClick={() => handleApproveWaitList(id)}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                  <div className={classes.footer}>
                    <Pagination
                      auth={auth}
                      collection={users}
                      handleFetchCollection={handleFetchCollection}
                      pageSize={pageSize}
                      params={{ role: "admin" }}
                    />
                  </div>
                </React.Fragment>
              )}
              {hasFetched && users.ids.length === 0 && (
                <div className={classes.container}>
                  <Typography variant="body2">{"No users found."}</Typography>
                </div>
              )}
            </React.Fragment>
          )}
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles, { name: "AdminUsers" })(AdminUsers);
