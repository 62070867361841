import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import withRoot from "../../withRoot";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import BookingForm from "./BookingForm";
import { buildAddress } from "../../helpers/utils";
import MainActionButton from "components/Buttons/MainActionButton";

const localizer = momentLocalizer(moment);

const styles = theme => ({
  paper: {
    padding: theme.spacing(2)
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  blockDate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

class Calendar extends Component {
  state = {
    newEvent: false
  };

  handleChange = event => {
    this.props.changeProperty(
      event.target.value === "all" ? false : event.target.value
    );
  };

  handleSelect = ({ start, end }) => {
    if (start.getTime() === end.getTime()) {
      end = moment(end)
        .add(1, "days")
        .toDate();
    }
    this.setState({ newEvent: { start, end } });
  };

  newBlockDate = () => {
    const start = moment(new Date()).toDate();
    const end = moment(new Date())
      .add(1, "days")
      .toDate();
    this.setState({ newEvent: { start, end } });
  };

  changeBlocked = trip => {
    const start = moment(trip.startDate).toDate();
    const end = moment(trip.endDate).toDate();
    this.setState({
      newEvent: { trip, start, end }
    });
  };

  render() {
    const { newEvent } = this.state;
    const {
      bookings,
      classes,
      properties,
      selectedProperty,
      goToTrip,
      onRequestStay,
      onDeleteStay
    } = this.props;

    const dropDownProperties = (properties.ownPropertiesIds || []).map(i => {
      const p = properties[i];
      const image = p.image > 0 ? p.image.url : null;
      return {
        value: p.id,
        name: p.name,
        address: buildAddress(p.address),
        img: image
      };
    });
    const events = (bookings.ids || [])
      .map(i => {
        const b = bookings[i];
        var offset = new Date().getTimezoneOffset();

        return {
          id: i,
          title: b.property.name + " - " + b.user.name + " - " + b.status,
          allDay: true,
          start: moment(b.startDate).toDate(),
          end: moment(b.endDate + " 12:00:01")
            .add(offset, "minutes")
            .toDate(),
          tripId: b.id,
          status: b.status,
          trip: b
        };
      })
      .filter(
        f =>
          f.status !== "Canceled" &&
          f.status !== "Declined" &&
          f.status !== "Expired"
      );

    const customDayPropGetter = date => {
      if (newEvent && date >= newEvent.start && date <= newEvent.end) {
        return {
          style: { backgroundColor: "rgba(0, 0, 0, 0.1)" }
        };
      }
      return {};
    };

    return (
      <main>
        <Typography variant="h6">{"Calendar"}</Typography>
        <MainActionButton
          onClick={this.newBlockDate}
          className={classes.blockDate}
        >
          Block a date
        </MainActionButton>
        <Grid container spacing={3}>
          <Grid item s={12} md={12}>
            <div style={{ height: 600 }}>
              <BigCalendar
                selectable
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                onSelectEvent={event =>
                  event.status === "Blocked"
                    ? this.changeBlocked(event.trip)
                    : goToTrip(event.tripId)
                }
                dayPropGetter={customDayPropGetter}
                onSelectSlot={this.handleSelect}
                eventPropGetter={(event, start, end, isSelected) => {
                  return (
                    (event.status === "Pending" && {
                      style: {
                        backgroundColor: "rgb(255, 210, 0)",
                        color: "#000"
                      }
                    }) ||
                    (event.status === "Blocked" && {
                      style: {
                        backgroundColor: "rgb(120, 129, 139)",
                        color: "#000"
                      }
                    }) || {
                      style: {
                        backgroundColor: "#3174ad"
                      }
                    }
                  );
                }}
              />
              <Legend />
            </div>
          </Grid>
          {/* {newEvent && (
            <Grid item s={12} md={3}>
              <Paper className={classes.paper}>
                <BookingForm
                  start={newEvent.start}
                  end={newEvent.end}
                  setNewEventDate={dates =>
                    this.setState({
                      newEvent: {
                        ...newEvent,
                        start: dates.start,
                        end: dates.end
                      }
                    })
                  }
                  cancelEvent={() =>
                    this.setState({
                      newEvent: false
                    })
                  }
                  selectedProperty={selectedProperty}
                  dropDownProperties={dropDownProperties}
                  onRequestStay={onRequestStay}
                />
              </Paper>
            </Grid>
          )} */}
        </Grid>
        {newEvent && (
          <BookingForm
            open={true}
            start={newEvent.start}
            end={newEvent.end}
            setNewEventDate={dates =>
              this.setState({
                newEvent: {
                  ...newEvent,
                  start: dates.start,
                  end: dates.end
                }
              })
            }
            cancelEvent={() =>
              this.setState({
                newEvent: false
              })
            }
            selectedProperty={selectedProperty}
            dropDownProperties={dropDownProperties}
            onRequestStay={onRequestStay}
            onDeleteStay={onDeleteStay}
            trip={newEvent.trip}
          />
        )}
      </main>
    );
  }
}

const legendStyles = theme => ({
  legend: {
    color: "#52575e",
    fontSize: 14,
    listStyleType: "none",
    margin: "20px 0",
    padding: 0
  },
  legendItem: {
    display: "inline-block",
    margin: "0 20px"
  },
  legendItemColor: {
    borderRadius: "50%",
    display: "inline-block",
    height: 14,
    marginRight: 6,
    verticalAlign: "middle",
    width: 14,
    border: "1px solid rgba(0, 0, 0, 0.25)"
  }
});

const Legend = withStyles(legendStyles)(({ classes }) => {
  return (
    <ul className={classes.legend}>
      <li className={classes.legendItem}>
        <span
          className={classes.legendItemColor}
          style={{ backgroundColor: "rgb(255, 210, 0)" }}
        ></span>
        <span>Pending</span>
      </li>
      <li className={classes.legendItem}>
        <span
          className={classes.legendItemColor}
          style={{ backgroundColor: "rgb(120, 129, 139)" }}
        ></span>
        <span>Blocked</span>
      </li>
      <li className={classes.legendItem}>
        <span
          className={classes.legendItemColor}
          style={{ backgroundColor: "#3174ad" }}
        ></span>
        <span>Booked</span>
      </li>
    </ul>
  );
});
export default withRoot(withStyles(styles)(Calendar));
