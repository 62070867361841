import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import moment from "moment";
import { Grid } from "@material-ui/core";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  acceptButton: {
    borderColor: "#008028",
    color: "#008028"
  },
  declineButton: {
    borderColor: "red",
    color: "red"
  },
  containerButtons: {},
  requestTitle: {
    marginTop: 10
  }
});

const TripDetailBox = ({
  trip,
  isHost,
  handleTripUpdate,
  handlePayTrip,
  classes,
  className
}) => {
  const [tripState] = useState({
    startDate: moment(trip.startDate),
    endDate: moment(trip.endDate),
    adults: trip.adults,
    children: trip.children
  });

  const getCanceledStatusView = () => {
    return <></>;
  };

  const getDeclinedStatusView = () => {
    return <></>;
  };

  const getAcceptedStatusView = () => {
    return (
      <>
        <Grid container spacing={4} className={classes.containerButtons}>
          {!isHost && (
            <Grid item xs={6} style={{ paddingRight: 1 }}>
              <MainActionButton
                fullWidth
                label="Accept"
                onClick={() => handlePayTrip()}
              >
                Pay
              </MainActionButton>
            </Grid>
          )}
          <Grid item xs={isHost ? 12 : 6}>
            <MainActionButton
              isDanger
              fullWidth
              onClick={() =>
                handleTripUpdate({
                  id: trip.id,
                  status: "Canceled"
                })
              }
            >
              Cancel
            </MainActionButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const getBlockedStatusView = () => {
    return (
      <>
        {isHost && (
          <Grid container spacing={4} className={classes.containerButtons}>
            <Grid item xs={12}>
              <MainActionButton
                isDanger
                fullWidth
                onClick={() =>
                  handleTripUpdate({
                    id: trip.id,
                    status: "Canceled"
                  })
                }
              >
                Cancel
              </MainActionButton>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const getPendingStatusView = () => {
    return (
      <>
        {isHost && (
          <Grid container spacing={4} className={classes.containerButtons}>
            <Grid item xs={6} style={{ paddingRight: 1 }}>
              <MainActionButton
                fullWidth
                label="Accept"
                onClick={() =>
                  handleTripUpdate({
                    ...tripState,
                    id: trip.id,
                    status: "Accepted",
                    startDate: tripState.startDate.format("YYYY-MM-DD"),
                    endDate: tripState.endDate.format("YYYY-MM-DD")
                  })
                }
              >
                Accept
              </MainActionButton>
            </Grid>
            <Grid item xs={6}>
              <MainActionButton
                isDanger
                fullWidth
                onClick={() =>
                  handleTripUpdate({
                    id: trip.id,
                    status: "Declined"
                  })
                }
              >
                Decline
              </MainActionButton>
            </Grid>
          </Grid>
        )}
        {!isHost && (
          <Grid container spacing={4} className={classes.containerButtons}>
            <Grid item xs={12}>
              <MainActionButton
                isDanger
                fullWidth
                onClick={() =>
                  handleTripUpdate({
                    id: trip.id,
                    status: "Canceled"
                  })
                }
              >
                Cancel
              </MainActionButton>
            </Grid>
          </Grid>
        )}
      </>
    );
  };
  return (
    <div className={className}>
      {trip.status === "Pending" && getPendingStatusView()}
      {trip.status === "Accepted" && getAcceptedStatusView()}
      {trip.status === "Canceled" && getCanceledStatusView()}
      {trip.status === "Declined" && getDeclinedStatusView()}
      {trip.status === "Blocked" && getBlockedStatusView()}
    </div>
  );
};

export default withRoot(withStyles(styles)(TripDetailBox));
