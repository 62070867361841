import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { convertToRaw, EditorState } from "draft-js";
import dasherize from "dasherize";
import * as propertiesActionCreators from "../../redux/modules/properties";
import WizardContainer from "components/wizard/WizardContainer";
import BasicsStep from "containers/PropertyWizard/BasicsStep";
import AddressStep from "containers/PropertyWizard/AddressStep";
import PrivacyStep from "containers/PropertyWizard/PrivacyStep";
import DescriptionStep from "containers/PropertyWizard/DescriptionStep";
import ShareStep from "containers/PropertyWizard/ShareStep";

class AddPropertyContainer extends Component {
  constructor(props) {
    super(props);

    this.profile = this.props.auth.getProfile();
  }

  async componentDidMount() {
    if (this.props.match.params.propertyId) {
      await this.props.fetchProperty(this.props.match.params.propertyId);
    } else {
      let values = {};
      //add empty state for editor
      let editorState = EditorState.createEmpty();
      const contentState = editorState.getCurrentContent();
      const emptyRaw = convertToRaw(contentState);
      values.descriptionRaw = JSON.stringify(emptyRaw);
      values.rulesRaw = JSON.stringify(emptyRaw);
      values.instructionsRaw = JSON.stringify(emptyRaw);

      const property = dasherize(values);
      const response = await this.props.addProperty(property);
      this.props.history.replace("/add-property/" + response.id);
      this.setState({ property: response });
    }
  }

  render() {
    const { propertyId } = this.props.match.params;

    if (!propertyId) return <div>Loading</div>;
    const data = [
      { name: "The Basics", component: BasicsStep },
      { name: "Description", component: DescriptionStep },
      { name: "Address", component: AddressStep },
      // { name: "Amenities", component: AmenitiesStep },
      // { name: "Rates", component: BasicsStep },
      { name: "Privacy", component: PrivacyStep },
      // { name: "Calendar", component: BasicsStep },
      { name: "Share", component: ShareStep }
    ];
    return (
      <WizardContainer
        data={data}
        onClose={() => {
          this.props.history.push("/manage/property/basics/" + propertyId);
        }}
        onCancel={async () => {
          try {
            await this.props.deleteProperty(propertyId);
            this.props.history.replace("/");
          } catch (e) {
            this.props.history.replace("/");
          }
        }}
        extraProps={{
          propertyId: propertyId,
          profile: this.profile
        }}
        title={"Adding New Property"}
      />
    );
  }
}

function mapStateToProps({ properties }, props) {
  let property;
  if (props.property || props.isCreate) {
    property = props.property;
  } else {
    const id = props.match.params.propertyId;
    property = properties[id] || null;
  }

  return {
    hasFetched: properties.hasFetched,
    isDeleting: properties.isDeleting,
    isFetching: properties.isFetching,
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPropertyContainer);
