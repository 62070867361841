import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, Form, reduxForm } from "redux-form";
import { Link } from "react-router-dom";
import history from "../../history";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import common from "@material-ui/core/colors/common";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";
import PersonIcon from "@material-ui/icons/Person";
import LockIcon from "@material-ui/icons/Lock";
import grey from "@material-ui/core/colors/grey";
import withRoot from "../../withRoot";

import ValidationTextField from "components/Fields/ValidationTextField";
import { isValidEmail, isValidPassword } from "helpers/validators";
import Message from "components/Message/Message";
import ProgressButton from "components/Progress/ProgressButton";
import { getErrorMessage } from "../../helpers/utils";
const { white } = common;

const validate = (values, props) => {
  const errors = {};

  // validate email
  if (!isValidEmail(values.email)) {
    errors.email = "Enter a valid email address";
  }

  // validate first name
  if (!values.firstName) {
    errors.firstName = "Enter a first name";
  }

  // validate last name
  if (!values.lastName) {
    errors.lastName = "Enter a last name";
  }

  // validate password
  if (!isValidPassword(values.password)) {
    errors.password = "Enter a valid password";
  }

  return errors;
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: white,
    margin: theme.spacing(1),
    height: 48,
    width: 195,
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "bold",
    marginLeft: 0,
    "&:hover": {
      backgroundColor: theme.palette.text.main
    }
  },
  cancelButton: {
    marginLeft: 22,
    width: 193,
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: "bold",
    textTransform: "none"
  },
  divider: {
    marginTop: 32,
    marginBottom: 32,
    width: "100%",
    height: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center"
  },
  dividerLine: {
    color: grey[600],
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: white,
    textTransform: "uppercase",
    padding: "0 10px"
  },
  facebookButton: {
    backgroundColor: "#3351a2",
    color: white,
    borderRadius: "4px",
    height: 48,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#3b5998"
    }
  },
  fieldContainer: {
    paddingBottom: 24
  },
  field: {
    width: "100%"
  },
  formBody: {
    paddingLeft: 200,
    paddingRight: 122
  },
  formContainer: {
    width: "100%"
  },
  heroCopy: {
    fontSize: 16,
    lineHeight: 1.8,
    maxWidth: 519,
    marginBottom: 64
  },
  highlight: {
    background: `linear-gradient(180deg,rgba(255,255,255,0) 40%, ${theme.palette.secondary.main} 40%)`,
    display: "inline"
  },
  imageContainer: {
    width: "100%",
    textAlign: "right"
  },
  image: {
    maxHeight: 908,
    maxWidth: "100%"
  },
  icon: {
    color: grey[300]
  },
  link: {
    backgroundColor: white,
    borderWidth: 0,
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontSize: 16,
    fontWeight: "bold",
    textDecoration: "underline",
    "&:focus": {
      outline: "none"
    }
  },
  loginContainer: {
    fontSize: 16,
    paddingBottom: 120
  },
  loginDivider: {
    marginTop: 32,
    marginBottom: 24,
    width: "100%",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    textAlign: "center"
  },
  message: {
    marginBottom: 20
  },
  title: {
    fontSize: 48,
    fontWeight: "bold",
    marginTop: 120
  },
  [theme.breakpoints.down("lg")]: {
    formBody: {
      paddingTop: "10",
      paddingLeft: "20%",
      paddingRight: "10%"
    }
  },
  [theme.breakpoints.down("md")]: {
    cancelButton: {
      width: 80
    }
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block"
    },
    button: {
      width: "100%"
    },
    cancelButton: {
      marginLeft: 0,
      paddingTop: 24,
      width: "100%"
    },
    fieldContainer: {
      paddingBottom: 20
    },
    formBody: {
      paddingLeft: 20,
      paddingRight: 20
    },
    formContainer: {
      width: "100%"
    },
    heroCopy: {
      marginBottom: 48
    },
    imageContainer: {
      width: "100%"
    },
    loginContainer: {
      paddingBottom: 80
    },
    title: {
      fontSize: 40,
      fontWeight: "bold",
      marginTop: 80
    }
  }
});

class SignUp extends Component {
  state = {
    errorMessage: null
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.auth.isAuthenticated()) {
      history.replace("/");
    }
  }

  facebookLogin() {
    this.props.auth.initFacebookLogin();
  }

  handleSignup = async values => {
    try {
      await this.props.auth.signup(values);
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        "There was a problem with signup"
      );
      this.setState({ errorMessage });
    }
  };

  handleCancel = () => {
    history.replace("/");
  };

  render() {
    const { classes, handleSubmit, submitting } = this.props;
    const { errorMessage } = this.state;

    return (
      <div className={classes.root}>
        <div className={classes.formContainer}>
          <div className={classes.formBody}>
            <h3 className={classes.title}>
              List Your <span className={classes.highlight}>Property</span>
            </h3>
            <div className={classes.heroCopy}>
              Free through the end of February 2020.
            </div>
            <Button
              fullWidth
              className={classes.facebookButton}
              onClick={() => this.facebookLogin()}
              startIcon={<FacebookIcon />}
            >
              Sign up with Facebook
            </Button>
            <div className={classes.divider}>
              <span className={classes.dividerLine}>or</span>
            </div>
            <Form
              onSubmit={handleSubmit(values => {
                this.handleSignup(values);
              })}
            >
              <Message
                className={classes.message}
                type="danger"
                message={errorMessage}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      component={ValidationTextField}
                      className={classes.field}
                      variant="outlined"
                      type="text"
                      name="email"
                      label="Email address"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <EmailIcon className={classes.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.fieldContainer}>
                    <Field
                      component={ValidationTextField}
                      className={classes.field}
                      variant="outlined"
                      type="text"
                      name="firstName"
                      label="First name"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon className={classes.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div className={classes.fieldContainer}>
                    <Field
                      component={ValidationTextField}
                      className={classes.field}
                      variant="outlined"
                      type="text"
                      name="lastName"
                      label="Last name"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonIcon className={classes.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <Field
                      component={ValidationTextField}
                      className={classes.field}
                      variant="outlined"
                      type="password"
                      name="password"
                      label="Create a password"
                      helperText={
                        <span>
                          <li>At least 8 characters</li>
                          <li>Contains a number or symbol</li>
                        </span>
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockIcon className={classes.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
              <ProgressButton
                className={classes.button}
                label="Sign up"
                loading={submitting}
                type="submit"
              />
              <Button
                className={classes.cancelButton}
                onClick={() => this.handleCancel()}
                color="primary"
              >
                {"Cancel"}
              </Button>
              <div className={classes.loginDivider} />
              <div className={classes.loginContainer}>
                Already have a Vrbff account?{" "}
                <Link to="/login" className={classes.link}>
                  Log in
                </Link>
              </div>
            </Form>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img
            src="/images/signup/signup-1300.jpg"
            srcSet="/images/signup/signup-540.jpg 540w,
            /images/signup/signup-800.jpg 800w,
            /images/signup/signup-1000.jpg 1000w,
            /images/signup/signup-1300.jpg 1300w"
            sizes="100vw"
            alt="login"
            className={classes.image}
          />
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  classes: PropTypes.object.isRequired
};

SignUp = reduxForm({
  form: "Signup",
  validate
})(SignUp);

export default withRoot(withStyles(styles)(SignUp));
