import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as appActionCreators from "../../redux/modules/app";
import BasicsStep from "containers/PropertyWizard/BasicsStep";
import { withStyles } from "@material-ui/core";
import PropertyManageLayout from "components/Properties/PropertyManageLayout";
import DescriptionStep from "containers/PropertyWizard/DescriptionStep";
import AddressStep from "containers/PropertyWizard/AddressStep";
import PrivacyStep from "containers/PropertyWizard/PrivacyStep";
import Loading from "components/Progress/Loading";
import DangerZone from "containers/PropertyWizard/DangerZone";
import AmenitiesStep from "containers/PropertyWizard/AmenitiesStep";
import ShareStep from "containers/PropertyWizard/ShareStep";
import Hosts from "containers/PropertyWizard/Hosts";
import GroupsPricing from "containers/PropertyWizard/GroupsPricing";
import PaymentHandling from "containers/PropertyWizard/PaymentHandling";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  layout: {
    display: "flex",
    width: "auto"
  },
  title: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  actionButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "flex-end",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: "rgba(255,255,255,0.94)",
      boxShadow: theme.shadow.medium,
      zIndex: "999"
    }
  },
  mainActionButton: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    }
  }
});

class ManagePropertyContainer extends Component {
  constructor(props) {
    super(props);

    this.profile = this.props.auth.getProfile();
  }

  async componentDidMount() {
    if (this.props.match.params.id) {
      await this.props.fetchProperty(this.props.match.params.id);
    }
  }

  getSelectedProps = () => {
    switch (this.props.match.params.name) {
      case "edit":
      case "basics":
        return {
          Comp: BasicsStep,
          title: "Basics",
          buttonBottom: true
        };
      case "description":
        return {
          Comp: DescriptionStep,
          title: "Description",
          buttonBottom: true
        };
      case "address":
        return {
          Comp: AddressStep,
          title: "Address",
          buttonBottom: true
        };
      case "amenities":
        return {
          Comp: AmenitiesStep,
          title: "Amenities",
          buttonBottom: true
        };
      case "privacy":
        return {
          Comp: PrivacyStep,
          title: "Privacy",
          buttonBottom: true
        };
      case "last":
        return {
          Comp: ShareStep,
          title: "last"
        };
      case "groupspricing":
        return {
          Comp: GroupsPricing,
          title: "Groups & Pricing",
          buttonName: "Add Group"
        };
      case "paymenthandling":
        return {
          Comp: PaymentHandling,
          title: "Payment Handling",
          buttonName: false
        };
      case "hosts":
        return {
          Comp: Hosts,
          title: "Manage Hosts",
          buttonName: false
        };
      case "danger":
        return {
          Comp: DangerZone,
          title: "Danger zone",
          buttonName: false
        };
      default:
        return {};
    }
  };

  onClick = async ref => {
    let result = false;
    if (
      ref &&
      ref.ref &&
      ref.ref.current &&
      ref.ref.current.wrapped &&
      ref.ref.current.wrapped.current &&
      ref.ref.current.wrapped.current.continueCheck
    ) {
      //Neded for reduxForm component
      result = await ref.ref.current.wrapped.current.continueCheck();
    } else if (ref.continueCheck) {
      result = await ref.continueCheck();
    }

    if (result === "HACK NEVER") return;

    if (result)
      return this.props.appSnackbarMessage({
        message: "Property changes saved successfully"
      });
    if (!result)
      return this.props.appSnackbarMessage({
        message: "Unfortunately there was a problem saving your changes",
        type: "warning"
      });
  };

  render() {
    const propertyId = this.props.match.params.id;
    const name = this.props.match.params.name;
    const selectedProps = this.getSelectedProps();
    if (!this.props.property) return <Loading />;
    return (
      <PropertyManageLayout
        key={"manage" + name + propertyId}
        onClick={this.onClick}
        buttonName={"Save"}
        stepProps={{
          propertyId: propertyId,
          profile: this.profile,
          editMode: true,
          ...(selectedProps.extra || {})
        }}
        {...selectedProps}
      />
    );
  }
}

function mapStateToProps({ properties }, props) {
  let property;
  if (props.property || props.isCreate) {
    property = props.property;
  } else {
    const id = props.match.params.id;
    property = properties[id] || null;
  }

  return {
    hasFetched: properties.hasFetched,
    isDeleting: properties.isDeleting,
    isFetching: properties.isFetching,
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ManagePropertyContainer)
);
