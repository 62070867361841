import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import InputField from "components/Fields/InputField";
import { Field } from "redux-form";
import WizardFieldName from "./WizardFieldName";

const styles = theme => ({
  container: {},
  fieldContainer: {
    marginTop: 24
  }
});

class WizardTextField extends Component {
  render() {
    const { classes, title, secondary, ...rest } = this.props;
    return (
      <div className={classes.container}>
        <WizardFieldName title={title} secondary={secondary} />
        <div className={classes.fieldContainer}>
          <Field
            type="text"
            component={InputField}
            inputStyle={{ color: "#0F0F10", fontSize: 16 }}
            variant="outlined"
            {...rest}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { name: "WizardTextField" })(WizardTextField);
