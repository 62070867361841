import React from "react";
import StayAcceptedMessage from "components/Stay/Messages/StayAcceptedMessage";
import StayDeclinedMessage from "components/Stay/Messages/StayDeclinedMessage";
import StayCanceledMessage from "components/Stay/Messages/StayCanceledMessage";
import StayUpdatedMessage from "components/Stay/Messages/StayUpdatedMessage";
import { getNights } from "./utils";

function hasJsonStructure(str) {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return type === "[object Object]" || type === "[object Array]";
  } catch (err) {
    return false;
  }
}

export function parseMessageText(text, asString) {
  if (!hasJsonStructure(text)) return text;

  let messageObj = JSON.parse(text);
  switch (messageObj.type) {
    case "accepted":
      return asString ? (
        getStayAcceptedMessage(messageObj)
      ) : (
        <StayAcceptedMessage message={messageObj} />
      );
    case "declined":
      return asString ? (
        getStayDeclinedMessage(messageObj)
      ) : (
        <StayDeclinedMessage message={messageObj} />
      );
    case "canceled":
      return asString ? (
        getStayCanceledMessage(messageObj)
      ) : (
        <StayCanceledMessage message={messageObj} />
      );
    case "updated":
      return asString ? (
        getStayUpdatedMessage(messageObj)
      ) : (
        <StayUpdatedMessage message={messageObj} />
      );
    default:
      return false;
  }
}

function getStayAcceptedMessage(messageObj) {
  return `The Stay requested got accepted. Checkin ${messageObj.start_date} and Checkout ${messageObj.end_date}`;
}

function getStayDeclinedMessage(messageObj) {
  return `The Stay requested got declined.`;
}
function getStayCanceledMessage(messageObj) {
  return `The Stay requested got canceled.`;
}
function getStayUpdatedMessage(messageObj) {
  return `The Stay requested got updated. Checkin ${messageObj.start_date} and Checkout ${messageObj.end_date}`;
}

export function getRateName(rate, trip) {
  const nights = getNights(trip.startDate, trip.endDate);
  if (rate.type === "PRICE_NIGHT") return `$${rate.price} x ${nights} nights`;

  if (rate.type === "PERSON_NIGHT")
    return `$${rate.price} x ${nights} nights x ${trip.adults} adults - ${rate.name}`;

  return `$${rate.price} - ${rate.name}`;
}

export function getRateNameFromNights(type, price, name, nights, adults) {
  price = parseInt(price);
  if (type === "PRICE_NIGHT") return `$${price} x ${nights} nights`;

  if (type === "PERSON_NIGHT")
    return `$${price} x ${nights} nights x ${adults}`;

  return `${price}`;
}

export function getRateNameFromNightsTotal(type, price, name, nights, adults) {
  price = parseInt(price);
  if (type === "PRICE_NIGHT") return price * nights;

  if (type === "PERSON_NIGHT") return price * nights * adults;

  return price;
}

export function rateTypeToText(type) {
  switch (type) {
    case "PRICE_NIGHT":
      return "Price per night";
    case "PERSON_NIGHT":
      return "Price per person per night";
    case "ONE_OFF":
      return "One off price";
    default:
      return "";
  }
}
