// @flow weak

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  check: {
    height: 14
  },
  label: {
    marginLeft: 4
  },
  progress: {
    color: "#fff"
  },
  button: {}
});

class ProgressButton extends Component {
  state = {
    isLoading: false
  };
  onClickBtn = async () => {
    if (this.props.onClick) {
      if (this.props.progress) {
        this.setState({ isLoading: true });
      }
      console.log("asd");
      await this.props.onClick();
      console.log("asd1");
      if (this.props.progress) {
        this.setState({ isLoading: false });
      }
    }
  };
  render() {
    const {
      color = "primary",
      classes,
      className,
      disabled,
      icon,
      label,
      loading,
      type,
      isSmall,
      isDanger,
      isApprove,
      fullWidth = false
    } = this.props;

    const { isLoading } = this.state;

    return (
      <MainActionButton
        color={color}
        className={className || classes.button}
        disabled={isLoading || loading || disabled}
        type={type}
        onClick={this.onClickBtn}
        fullWidth={fullWidth}
        isFull={fullWidth}
        isSmall={isSmall}
        isDanger={isDanger}
        isApprove={isApprove}
      >
        {loading || this.state.isLoading ? (
          <CircularProgress size={14} className={classes.progress} />
        ) : (
          icon
        )}
        <span className={classes.label}>{label || this.props.children}</span>
      </MainActionButton>
    );
  }
}

export default withStyles(styles, { name: "ProgressButton" })(ProgressButton);
