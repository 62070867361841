import { apiRequest, createResource, rpcRequest } from "apis/jsonApi";
import Resource from "./resource";
import { getErrorMessage } from "helpers/utils";

let resource = new Resource({
  singularName: "group",
  pluralName: "groups"
});

export function fetchGroupsForProperty(id) {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`groups/property/${id}`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(g => g.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function addGroup(values) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    try {
      const message = await createResource("group", values);

      return message;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function setGroupToUserForProperty(id, userId, propertyId) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    try {
      await rpcRequest("set-group-to-user-for-property", {
        group_id: id,
        user_id: userId,
        property_id: propertyId
      }).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorAddingSingular(error));
          throw error;
        } else {
        }
      });
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export default resource.reducer;
