import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import locationIcon from "../../static/assets/icons/location.svg";

const styles = theme => ({
  infoContainer: {
    borderBottom: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  iconInfo: {
    color: "#40A5CB",
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold"
  },
  infoTitle: {
    fontWeight: "bold"
  },
  mainGeneralInfo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingTop: 16,
    marginBottom: 8
  },
  generalInfoText: {
    fontWeight: 700,
    marginLeft: 8
  },
  location: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main
  },
  locationIcon: {
    height: 24,
    verticalAlign: "middle",
    marginRight: 8,
    color: theme.palette.primary.main
  }
});

const PropertySBB = ({ classes, sleeps, bathrooms, bedrooms, className }) => (
  <div className={classes.mainGeneralInfo}>
    <Typography variant="subtitle1" className={classes.location}>
      <img
        alt="location icon"
        src={locationIcon}
        className={classes.locationIcon}
      />
      Sleeps:
      <span className={classes.generalInfoText}>{sleeps}</span>
    </Typography>
    <Typography variant="subtitle1" className={classes.location}>
      <img
        alt="location icon"
        src={locationIcon}
        className={classes.locationIcon}
      />
      Bedrooms:
      <span className={classes.generalInfoText}>{bedrooms}</span>
    </Typography>
    <Typography variant="subtitle1" className={classes.location}>
      <img
        alt="location icon"
        src={locationIcon}
        className={classes.locationIcon}
      />
      Bathrooms:
      <span className={classes.generalInfoText}>{bathrooms}</span>
    </Typography>
  </div>
);

export default withStyles(styles, { name: "PropertySBB" })(PropertySBB);
