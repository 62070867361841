import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import { convertToRaw, EditorState } from "draft-js";
import dasherize from "dasherize";
import * as propertiesActionCreators from "../../redux/modules/properties";
import PropertyEdit from "../../components/Properties/PropertyEdit";
import { getErrorMessage } from "../../helpers/utils";
import history from "../../history";
import { checkUpdateAddress } from "../../helpers/checkUpdateAddress";
import { updateAddressLocation } from "../../helpers/updateAddressLocation";

class PropertyEditContainer extends Component {
  state = {
    characterCount: null,
    errorMessage: null,
    deleteFollowError: null,
    deleteImage: false,
    deleteImageError: null,
    deleteProperty: false,
    deletePropertyError: null,
    descriptionRaw: null,
    descriptionHtml: null,
    image: null,
    snackbarMessage: null
  };

  async componentDidMount() {
    if (this.props.isCreate) return;
    // load existing property for edit
    const id = this.props.match.params.id;
    if (id > 0) {
      await this.props.fetchProperty(id);
    }
  }

  closeDeleteProperty = event => {
    this.setState({
      deleteProperty: false
    });
  };

  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  openDeleteProperty = event => {
    this.setState({ deleteProperty: true });
  };

  onDeleteProperty = async id => {
    try {
      await this.props.deleteProperty(id);
      history.replace(`/?message=deleted`);
    } catch (error) {
      console.error("error:", error);
      let deletePropertyError = getErrorMessage(
        error,
        "There was a problem deleting the property."
      );
      this.setState({ deletePropertyError });
      throw error;
    }
  };

  onSubmit = async values => {
    if (values.id > 0) {
      try {
        const property = dasherize(values);

        // create or update address location
        if (values.address) {
          let addressResource;
          let updatedAddress = checkUpdateAddress(values, values.address);
          if (updatedAddress) {
            addressResource = await updateAddressLocation(values);
          }
          property.address = addressResource;
        }

        await this.props.updateProperty(property);
        this.setState({ snackbarMessage: `${property.name} updated` });
      } catch (error) {
        console.error("error:", error);
        let errorMessage = getErrorMessage(
          error,
          "There was a problem updating the property."
        );
        this.setState({ errorMessage });
        throw error;
      }
    } else {
      try {
        if (!values.descriptionRaw) {
          let editorState = EditorState.createEmpty();
          const contentState = editorState.getCurrentContent();
          const emptyRaw = convertToRaw(contentState);
          values.descriptionRaw = JSON.stringify(emptyRaw);
        }
        const property = dasherize(values);

        // create address location
        if (values.address) {
          const addressResource = await updateAddressLocation(values);
          property.address = addressResource;
        }

        const response = await this.props.addProperty(property);
        this.setState({ snackbarMessage: `${property.name} created` });
        history.push(`/manage/edit/${response.id}`);
      } catch (error) {
        console.error("error:", error);
        let errorMessage = getErrorMessage(
          error,
          "There was a problem creating the property."
        );
        this.setState({ errorMessage });
        throw error;
      }
    }
  };

  handleDescriptionEditor = (pdfData, rawData, htmlData) => {
    this.props.change("descriptionRaw", JSON.stringify(rawData));
    this.props.change("descriptionHtml", htmlData);
    this.setState({ descriptionRaw: rawData });
  };

  setCharacterCount = characterCount => {
    this.setState({
      characterCount
    });
  };

  render() {
    return (
      <PropertyEdit
        change={this.props.change}
        closeDeleteDialog={this.closeDeleteProperty}
        closeSnackbarMessage={this.closeSnackbarMessage}
        deleteDialog={this.state.deleteProperty}
        deleteError={this.state.deletePropertyError}
        errorMessage={this.state.errorMessage}
        handleDelete={values => this.onDeleteProperty(values)}
        handleDescriptionEditor={this.handleDescriptionEditor}
        isDeleting={this.props.isDeleting}
        openDeleteDialog={this.openDeleteProperty}
        onSubmit={values => this.onSubmit(values)}
        property={this.props.property}
        setCharacterCount={this.setCharacterCount}
        snackbarMessage={this.state.snackbarMessage}
      />
    );
  }
}

function mapStateToProps({ properties }, props) {
  let property;
  if (props.property || props.isCreate) {
    property = props.property;
  } else {
    const id = props.match.params.id;
    property = properties[id] || null;
  }

  return {
    hasFetched: properties.hasFetched,
    isDeleting: properties.isDeleting,
    isFetching: properties.isFetching,
    property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      form: "PropertyEdit",
      ...propertiesActionCreators
    },
    dispatch
  );
}

PropertyEditContainer = reduxForm({
  form: "PropertyEdit", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true
})(PropertyEditContainer);

const selector = formValueSelector("PropertyEdit"); // <-- same as form name
PropertyEditContainer = connect(state => ({
  descriptionRaw: selector(state, "descriptionRaw"),
  descriptionHtml: selector(state, "descriptionHtml")
}))(PropertyEditContainer);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyEditContainer);
