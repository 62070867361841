import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import Carousel, { Modal, ModalGateway } from "react-images";

const useStyles = makeStyles(theme => ({
  rightContainer: {
    flexDirection: "column",
    display: "flex",
    paddingLeft: theme.spacing(2)
  },
  mainImage: {
    marginTop: 8,
    marginBottom: 8,
    minWidth: "100%",
    paddingTop: "70%",
    minHeight: 500,
    alignSelf: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  extraImage: {
    marginTop: 8,
    marginBottom: 8,
    borderRadius: 4,
    minWidth: "100%",
    alignSelf: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  number: {
    alignSelf: "center",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "48px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.3)"
  },
  dotContainer: {
    alignSelf: "flex-end",
    marginBottom: 24,
    display: "flex"
  },
  dot: {
    marginRight: 4,
    marginLeft: 4,
    backgroundColor: "white",
    width: 8,
    height: 8,
    borderRadius: 4
  }
}));

const PropertyGallery = ({ images }) => {
  let galleryImages = [...images];
  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const [galleryState, setGalleryState] = useState({
    isOpen: false,
    currentIndex: 0
  });

  const mainWidth = isMobile || galleryImages.length < 2 ? 12 : 9;
  let mainImage = galleryImages.length > 0 ? galleryImages.shift() : false;

  return (
    <div>
      <Grid container style={{ marginTop: -8 }}>
        <Grid item xs={mainWidth} sm={mainWidth}>
          <div
            key={`image${mainImage.id}`}
            className={classes.mainImage}
            style={{
              backgroundImage: `url(${mainImage.url})`
            }}
            onClick={() => {
              setGalleryState({ isOpen: true, currentIndex: 0 });
            }}
          >
            {isMobile && galleryImages.length > 1 && (
              <div className={classes.dotContainer}>
                {galleryImages.map(i => (
                  <div key={i.id} className={classes.dot}></div>
                ))}
              </div>
            )}
            }
          </div>
        </Grid>
        {mainWidth === 9 && (
          <Grid item sm={3} className={classes.rightContainer}>
            {galleryImages.reduce((el, image, index) => {
              if (index > 2) return el;
              el.push(
                <div
                  key={`image${image.id}`}
                  className={classes.extraImage}
                  onClick={() => {
                    setGalleryState({ isOpen: true, currentIndex: index + 1 });
                  }}
                  style={{
                    backgroundImage: `url(${image.url})`
                  }}
                >
                  {index === 2 && galleryImages.length > 3 && (
                    <div className={classes.number}>
                      +{galleryImages.length - 3}
                    </div>
                  )}
                </div>
              );
              return el;
            }, [])}
          </Grid>
        )}
      </Grid>
      <ModalGateway>
        {galleryState.isOpen ? (
          <Modal
            onClose={() => setGalleryState({ isOpen: false })}
            isFullscreen
          >
            <Carousel
              isFullscreen
              currentIndex={galleryState.currentIndex}
              views={images.map(i => ({
                src: i.url,
                caption: i.filename
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PropertyGallery;
