import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import WizardListButton from "./WizardListButton";
import MainActionButton from "components/Buttons/MainActionButton";
import Close from "@material-ui/icons/Close";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 24,
    marginBottom: 14
  },
  layout: {
    display: "flex",
    width: "auto"
  },
  sidebarContainer: {
    position: "relative",
    padding: "2em 0",
    marginTop: "2em",
    marginBottom: "2em"
    // "&:before": {
    //   content: '""',
    //   position: "absolute",
    //   top: 0,
    //   // left: 18px;
    //   height: "100%",
    //   width: 4,
    //   background: "blue",
    //   left: "50%",
    //   marginLeft: -2
    // }
  },
  title: {
    fontWeigth: 700,
    fontSize: 32,
    lineHeigth: "48px",
    marginLeft: theme.spacing(30),
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  actionButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "flex-end",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: "rgba(255,255,255,0.94)",
      boxShadow: theme.shadow.medium,
      zIndex: "999"
    }
  },
  mainActionButton: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    }
  },
  mobileCancelButton: {
    display: "none",
    cursor: "pointer",
    color: "#777",
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex"
    }
  },
  mobileBackButton: {
    marginRight: 24,
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      marginRight: "auto"
    }
  },
  sidebar: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-end",
    width: theme.spacing(24),
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
});

class WizardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0
    };
  }

  getCurrentStep = () => {
    const { data, extraProps } = this.props;
    const { currentIndex } = this.state;
    const currentStep = data[currentIndex];
    const Step = currentStep.component;

    return (
      <Step
        ref={r => (this.currentStepRef = r)}
        title={currentStep.name}
        {...currentStep.extraProps}
        {...extraProps}
      />
    );
  };

  continueHandler = async () => {
    const { currentIndex } = this.state;
    let result = false;
    if (
      this.currentStepRef &&
      this.currentStepRef.ref &&
      this.currentStepRef.ref.current &&
      this.currentStepRef.ref.current.wrapped &&
      this.currentStepRef.ref.current.wrapped.current &&
      this.currentStepRef.ref.current.wrapped.current.continueCheck
    ) {
      //Neded for reduxForm component
      result = await this.currentStepRef.ref.current.wrapped.current.continueCheck();
    } else if (this.currentStepRef && this.currentStepRef.continueCheck) {
      result = await this.currentStepRef.continueCheck();
    }

    if (result) return this.setState({ currentIndex: currentIndex + 1 });
  };

  onClose = () => {
    if (this.props.onClose) this.props.onClose();
  };

  onCancel = () => {
    if (this.props.onCancel) this.props.onCancel();
  };

  render() {
    const { classes, data, title } = this.props;
    const { currentIndex } = this.state;
    const isLast = currentIndex === data.length - 1;
    const currentStep = data[currentIndex];
    return (
      <Fragment>
        <div className={classes.container}>
          <h2 className={classes.title}>
            {currentStep.title ? currentStep.title : title}
          </h2>
          <div className={classes.mobileCancelButton}>
            <Close className={classes.cancelIcon} onClick={this.onCancel} />
          </div>
          <div className={classes.actionButtonContainer}>
            {this.state.currentIndex > 0 && (
              <MainActionButton
                isOutline
                className={classes.mobileBackButton}
                onClick={() =>
                  this.setState({ currentIndex: currentIndex - 1 })
                }
              >
                Back to Step {currentIndex}
              </MainActionButton>
            )}
            {!currentStep.actionTitle && currentIndex === data.length - 1 && (
              <MainActionButton
                className={classes.mainActionButton}
                onClick={this.onClose}
              >
                Finish
              </MainActionButton>
            )}
            {!currentStep.actionTitle && currentIndex !== data.length - 1 && (
              <MainActionButton
                className={classes.mainActionButton}
                onClick={this.continueHandler}
              >
                Continue to Step {currentIndex + 2}
              </MainActionButton>
            )}
            {currentStep.actionTitle && (
              <MainActionButton
                className={classes.mainActionButton}
                onClick={
                  currentStep.actionClick
                    ? currentStep.actionClick
                    : this.continueHandler
                }
              >
                {currentStep.actionTitle}
              </MainActionButton>
            )}
          </div>
        </div>
        <div className={this.props.classes.layout}>
          <div className={classes.sidebar}>
            <div className={classes.sidebarContainer}>
              {data.map((d, i) => (
                <WizardListButton
                  key={"wizardList" + i}
                  count={i + 1}
                  title={d.name}
                  isDone={currentIndex > i}
                  isActive={currentIndex === i}
                  onClick={() =>
                    currentIndex > i ? this.setState({ currentIndex: i }) : null
                  }
                />
              ))}
            </div>
            {!isLast && (
              <WizardListButton
                title={"Cancel"}
                noIcon
                isCancel
                onClick={this.onCancel}
              />
            )}
          </div>
          <div style={{ flex: 1 }}>{this.getCurrentStep()}</div>
        </div>
      </Fragment>
    );
  }
}

export default withStyles(styles)(WizardContainer);
