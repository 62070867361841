import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import TextField from "@material-ui/core/TextField";
import MainActionButton from "components/Buttons/MainActionButton";

const styles = theme => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: "center"
  },
  sendMessageButton: {
    marginTop: theme.spacing(2)
  }
});

class Inbox extends Component {
  state = {
    message: ""
  };
  handleSendMessage = () => {
    this.props.handleSendMessage(this.state.message);
  };

  onChangeText = event => {
    this.setState({ message: event.target.value });
  };
  render() {
    const { message } = this.state;
    const { classes } = this.props;

    return (
      <>
        <TextField
          id="outlined-dense-multiline"
          label="Type your message"
          variant="outlined"
          multiline
          fullWidth
          rows="5"
          value={message}
          onChange={this.onChangeText}
        />
        <MainActionButton
          isOutline
          className={classes.sendMessageButton}
          onClick={this.handleSendMessage}
        >
          {"Send Message"}
        </MainActionButton>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(Inbox));
