import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as usersActionCreators from "../../redux/modules/users";
import * as appActionCreators from "../../redux/modules/app";
import * as groupsActionCreators from "../../redux/modules/groups";
import PropertyGuests from "../../components/Properties/PropertyGuests";
import { getErrorMessage } from "../../helpers/utils";
import PropertyManageLayout from "components/Properties/PropertyManageLayout";

class PropertyGuestsContainer extends Component {
  state = {
    errorMessage: null,
    snackbarMessage: null
  };

  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  getPropertyGuests = async () => {
    const id =
      (this.props.property && this.props.property.id) ||
      this.props.match.params.id;
    await this.props.fetchUsersByProperty(id);
    await this.props.fetchGroupsForProperty(id);
  };

  onDeleteGuest = async id => {
    try {
      const propertyId = this.props.property.id;
      await this.props.deleteGuest(id, propertyId);
      this.setState({ snackbarMessage: "Guest deleted" });
    } catch (error) {
      console.error("error:", error);
      let errorMessage = getErrorMessage(
        error,
        "There was a problem deleting the guest."
      );
      this.setState({ errorMessage });
      throw error;
    }
  };

  render() {
    const { property } = this.props;
    return (
      <PropertyManageLayout title={"Guest List"}>
        <PropertyGuests
          closeSnackbarMessage={this.closeSnackbarMessage}
          errorMessage={this.state.errorMessage}
          getPropertyGuests={this.getPropertyGuests}
          handleDeleteGuest={values => this.onDeleteGuest(values)}
          isDeleting={this.props.isDeleting}
          property={property}
          snackbarMessage={this.state.snackbarMessage}
          users={this.props.users}
          groups={this.props.groups}
          getFriends={this.props.fetchFriends}
          appSnackbarMessage={this.props.appSnackbarMessage}
          setGroupToUserForProperty={this.props.setGroupToUserForProperty}
        />
      </PropertyManageLayout>
    );
  }
}

function mapStateToProps({ users, properties, groups }, props) {
  let propertyId = props.match.params.id;
  let property =
    (properties.ownPropertiesIds || []).indexOf(propertyId) !== -1
      ? properties[propertyId]
      : false;

  return {
    isFetching: users.isFetching,
    users,
    groups,
    property: property
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...usersActionCreators,
      ...appActionCreators,
      ...groupsActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyGuestsContainer);
