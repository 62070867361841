import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertyStaysCreators from "../../redux/modules/propertyStays";
import Inbox from "../../components/Inbox/Inbox";
import PropertyManageLayout from "components/Properties/PropertyManageLayout";

class StaysPropertyContainer extends Component {
  componentDidMount() {
    this.props.fetchPropertyStays(this.props.match.params.id);
  }

  render() {
    if (this.props.isManage) {
      return (
        <PropertyManageLayout
          title={"Stay requests"}
          stepProps={{
            auth: this.props.auth,
            hasFetched: this.props.hasFetched,
            isFetching: this.props.isFetching,
            stays: this.props.propertyStays
          }}
          Comp={Inbox}
        />
      );
    }
    return (
      <Inbox
        auth={this.props.auth}
        hasFetched={this.props.hasFetched}
        isFetching={this.props.isFetching}
        stays={this.props.propertyStays}
        isManage={this.props.isManage}
      />
    );
  }
}

function mapStateToProps({ propertyStays }, props) {
  return {
    hasFetched: propertyStays.hasFetched,
    isFetching: propertyStays.isFetching,
    propertyStays
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertyStaysCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StaysPropertyContainer);
