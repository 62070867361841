import React, { Component } from "react";
import PropTypes from "prop-types";
import history from "../../history";

import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";
import { getErrorMessage } from "helpers/utils";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1
  },
  body: {
    paddingLeft: 200,
    paddingRight: 122
  },
  container: {
    width: "100%"
  },
  copy: {
    fontSize: 16,
    lineHeight: 1.8,
    maxWidth: 519,
    marginBottom: 64
  },
  imageContainer: {
    width: "100%",
    textAlign: "right"
  },
  image: {
    maxHeight: 908,
    maxWidth: "100%"
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    marginTop: 120
  },
  [theme.breakpoints.down("lg")]: {
    body: {
      paddingTop: "10",
      paddingLeft: "20%",
      paddingRight: "10%"
    }
  },

  [theme.breakpoints.down("sm")]: {
    root: {
      display: "inline-block"
    },
    body: {
      paddingLeft: 20,
      paddingRight: 20
    },
    container: {
      width: "100%"
    },
    heroCopy: {
      marginBottom: 48
    },
    imageContainer: {
      width: "100%"
    },
    title: {
      fontSize: 24,
      fontWeight: "bold",
      marginTop: 80
    }
  }
});

class ConfirmEmail extends Component {
  state = {
    errorMessage: null
  };

  getConfirmEmailCode = () => {
    if (this.props.match.params && this.props.match.params.code) {
      return this.props.match.params.code;
    }

    return null;
  };

  handleConfirmEmail = async confirmEmailCode => {
    try {
      let res = await this.props.auth.confirmEmailRequest(confirmEmailCode);
      if (res === true) {
        history.push("/", { confirm: true });
      }
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        "There was a problem with the confirm email request"
      );
      this.setState({ errorMessage });
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    const confirmEmailCode = this.getConfirmEmailCode();
    if (confirmEmailCode) {
      this.handleConfirmEmail(confirmEmailCode);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.body}>
            <h3 className={classes.title}>Thanks for Signing Up!</h3>
            <div className={classes.copy}>
              Check your inbox. An email has been sent with instructions to
              confirm your email address and activate your account.
              <br />
              PS - check your spam folder if you don't see the email right away!
            </div>
          </div>
        </div>
        <div className={classes.imageContainer}>
          <img
            src="/images/signup/signup-1300.jpg"
            srcSet="/images/signup/signup-540.jpg 540w,
            /images/signup/signup-800.jpg 800w,
            /images/signup/signup-1000.jpg 1000w,
            /images/signup/signup-1300.jpg 1300w"
            sizes="100vw"
            alt="login"
            className={classes.image}
          />
        </div>
      </div>
    );
  }
}

ConfirmEmail.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(ConfirmEmail));
