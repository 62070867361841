import React, { Fragment } from "react";
import { apiAxiosRequest } from "apis/jsonApi";
import { withStyles } from "@material-ui/core/styles";
import Loading from "components/Progress/Loading";
import Avatar from "components/General/Avatar";
import { Grid } from "@material-ui/core";
import PropertyCardPrivacy from "components/Properties/PropertyCardPrivacy";
import auth from "components/Auth/Auth";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link } from "react-router-dom";

export const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    backgroundColor: "red"
  },
  propertyContainer: {
    width: "100%"
  },
  container: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    alignItems: "center",
    marginLeft: auth.isAuthenticated() ? null : 32,
    marginRight: auth.isAuthenticated() ? null : 32
  },
  sectionTitle: {
    color: theme.palette.text.main,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "40px",
    marginTop: 75
  },
  username: {
    color: theme.palette.text.main,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "40px"
  },
  containerUserInfo: {
    marginLeft: 32
  },
  settingsIcon: {
    marginLeft: 8,
    marginBottom: -3
  },
  numberProperties: {
    fontWeight: 700
  },
  textExtra: {
    fontSize: 16
  },
  color: {
    color: theme.palette.text.main
  }
});

class User extends React.Component {
  state = { user: false, isLoaded: false };

  async componentDidMount() {
    const username = this.props.match.params.username;

    const user = await apiAxiosRequest(
      `user/${username}`,
      "GET",
      {},
      {},
      true,
      true
    );

    const profile = auth.getProfile();

    this.setState({
      user: user || false,
      isLoaded: true,
      isLogged: !!profile.id,
      isSameUser: parseInt(profile.id) === parseInt(user.id)
    });
  }

  render() {
    const { classes } = this.props;
    const { isSameUser } = this.state;
    const data = this.state.user;
    return (
      <Fragment>
        {!data && <Loading />}
        {data && (
          <div className={classes.container}>
            <div style={{ marginBottom: 28, display: "flex" }}>
              <Avatar
                isHuge
                firstName={data.firstName}
                lastName={data.lastName}
                src={data.picture}
              />
              <div className={classes.containerUserInfo}>
                <h3 className={classes.username}>
                  {data.firstName} {data.lastName}
                  {isSameUser && (
                    <Link to={"/account"} className={classes.color}>
                      <SettingsIcon className={classes.settingsIcon} />
                    </Link>
                  )}
                </h3>
                <div className={classes.textExtra}>
                  <span className={classes.numberProperties}>
                    {data.properties.length}
                  </span>
                  <span> Properties</span>
                  <br />
                  <span className={classes.numberProperties}>
                    {data.propertiesFollowed}
                  </span>
                  <span> Followed</span>
                </div>
              </div>
            </div>
            <div className={classes.propertyContainer}>
              <h3 className={classes.sectionTitle}>
                {data.firstName}'s Properties
              </h3>
              <Grid container spacing={4}>
                {data &&
                  data.properties &&
                  data.properties.map(property => (
                    <Grid
                      className={classes.gridItem}
                      item
                      key={`my${property.id}`}
                      xs={12}
                      md={4}
                      sm={6}
                      lg={3}
                      xl={2}
                    >
                      <PropertyCardPrivacy property={property} />
                    </Grid>
                  ))}
              </Grid>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withStyles(styles, { name: "User" })(User);
