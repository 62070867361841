let autocompleteService;
/**
 *
 * @param props | {input, options}
 * @returns {Promise}
 */
export const autocompletePromisified = props => {
  if (!autocompleteService) {
    const google = window.google;
    autocompleteService = new google.maps.places.AutocompleteService();
  }

  return new Promise((resolve, reject) => {
    /**
     * status: OK | ZERO_RESULTS | OVER_QUERY_LIMIT | REQUEST_DENIED | INVALID_REQUEST | UNKNOWN_ERROR
     */
    autocompleteService.getPlacePredictions(props, (results, status) => {
      if (status === "OK") resolve(results);
      reject(results);
    });
  });
};
