import React, { Component } from "react";
import { Typography, TextField, Grid } from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as imagesActionCreators from "../../redux/modules/images";
import * as appActionCreators from "../../redux/modules/app";
import validate from "../../components/Properties/validateProperty";
import dasherize from "dasherize";
import Dropzone from "react-dropzone";
import Loading from "../../components/Progress/Loading";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import history from "../../history";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";

import update from "immutability-helper";
import PropertyImage from "../../components/Properties/PropertyImage";
import SnackbarMessage from "components/Message/SnackbarMessage";
import DeleteDialog from "components/Dialogs/DeleteDialog";
import { getErrorMessage } from "helpers/utils";
import EditDialog from "components/Dialogs/EditDialog";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MainActionButton from "components/Buttons/MainActionButton";
import WizardFieldName from "components/wizard/WizardFieldName";
import WizardTextField from "components/wizard/WizardTextField";
// import MainActionButton from "components/Buttons/MainActionButton";
const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 8,
    marginBottom: 16
  },
  actions: {
    marginTop: 16,
    position: "relative",
    display: "block",
    textAlign: "right",
    width: "100%"
  },
  uploadArea: {
    marginTop: 30
  },
  dropzone: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    border: "2px dashed " + theme.palette.secondary.border,
    borderRadius: 4
  },
  dropzoneActive: {
    color: theme.palette.secondary.main
  },
  dropzoneBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    paddingTop: 30
  },
  dropzoneTitle: {
    flex: 1,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    color: theme.palette.text.main,
    marginBottom: 40,
    marginTop: 16
  },
  errorMessage: {
    marginTop: 20
  },
  headline: {
    marginBottom: 16
  },
  iconUpload: {
    fontSize: 50,
    color: theme.palette.primary.main
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto",
    padding: 32
  },
  subheading: {
    marginTop: 28,
    marginBottom: 8
  },
  imagesDescription: {
    fontSize: 16,
    color: theme.palette.text.main,
    marginTop: 40,
    marginBottom: 40
  }
}));

const required = val => {
  if (val === undefined || val.length === 0) return "Required field";
};

const validateNumbers = val => {
  if (val === undefined || val.length === 0) return "Required field";
  const v = parseFloat(val);
  if (isNaN(v) || v < 1 || v > 999)
    return "Field must be a number between 1 and 999";
};

const formatNumber = (value, name) => {
  if (value === "0" || value === 0) return "";
  if (Number.isInteger(value)) return parseInt(value);

  return value;
};
function loadImages(images) {
  return images.ids
    .map((id, index) => {
      return {
        ...images[id],
        sortOrder: isFinite(images[id].sortOrder) ? images[id].sortOrder : 99
      };
    })
    .sort((a, b) => a.sortOrder - b.sortOrder);
}

function setSortOrder(images) {
  return images.map((i, index) => {
    i.sortOrder = index;
    return i;
  });
}

class BasicsStep extends Component {
  state = {
    images: [],
    snackbarMessage: null,
    deleteImage: false,
    editImage: false,
    image: null,
    deleteDialog: false,
    deleteDialogError: null,
    isDeleting: false
  };

  closeDeleteProperty = event => {
    this.setState({
      deleteDialog: false
    });
  };

  openDeleteProperty = event => {
    this.setState({ deleteDialog: true });
  };

  handleDelete = async id => {
    try {
      const propertyName = this.props.property.name;
      this.setState({ isDeleting: true });
      await this.props.deleteProperty(id);

      this.props.appSnackbarMessage({
        message: "You successfully deleted " + propertyName
      });
      await this.props.fetchProperties();
      history.replace(`/manage`);
    } catch (error) {
      console.error("error:", error);
      let deleteDialogError = getErrorMessage(
        error,
        "There was a problem deleting the property."
      );
      this.setState({ deleteDialogError, isDeleting: false });
      throw error;
    }
  };

  async componentDidMount() {
    setTimeout(async () => await this.loadImages(this.props.propertyId), 200);
  }

  async loadImages(id) {
    await this.props.fetchImagesByProperty(id);

    setTimeout(
      () => this.setState({ images: loadImages(this.props.images) }),
      200
    );
  }
  handleUpload = async files => {
    const id = this.props.propertyId;
    await this.props.uploadImages(files, {
      property_id: id
    });
    // load images after upload
    setTimeout(async () => await this.loadImages(id), 300);
  };

  async continueCheck() {
    return new Promise(async resolve => {
      const submitter = this.props.handleSubmit(async values => {
        try {
          values.bathrooms = parseFloat(
            parseFloat(values.bathrooms).toFixed(1)
          );
          values.sleeps = parseFloat(parseFloat(values.sleeps).toFixed(1));
          values.bedrooms = parseFloat(parseFloat(values.bedrooms).toFixed(1));
          const property = dasherize(values);
          await this.props.updateProperty(property);
          return resolve(true);
        } catch (error) {
          console.error("error", error);
        }
      });
      const a = await submitter();
      if (a) return resolve(false);
    });
  }

  onMoveImage = (sourceId, sourceIndex, targetIndex, targetId) => {
    const result = setSortOrder(
      swap(this.state.images, sourceIndex, targetIndex)
    );
    this.props.sortImages(result);
    this.setState({ images: result });
  };

  moveCard = (dragIndex, hoverIndex) => {
    const dragCard = this.state.images[dragIndex];
    const result = setSortOrder(
      update(this.state.images, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      })
    );
    this.props.sortImages(result);
    this.setState({ images: result });
  };

  closeEditImage = event => {
    this.setState({
      editImage: false
    });
  };

  openEditImage = (event, id) => {
    let image = this.props.images[id];
    this.setState({ editImage: true, editImageContent: image.filename, image });
  };
  onChangeEditImageContent = event => {
    this.setState({ editImageContent: event.target.value });
  };
  onEditImage = async () => {
    const content = this.state.editImageContent;
    this.setState({ isSaving: true });
    if (!content || content.length === 1) {
      return this.setState({
        editDialogError: "The name cannot be empty",
        isSaving: false
      });
    }
    try {
      await this.props.updateImage({
        id: this.state.image.id,
        filename: this.state.editImageContent
      });
      await this.loadImages(this.props.propertyId);
      this.setState({ isSaving: false, editImage: false });
    } catch (e) {
      this.setState({
        editDialogError: "There was a problem saving your change",
        isSaving: false
      });
    }
  };
  closeDeleteImage = event => {
    this.setState({
      deleteImage: false
    });
  };

  closeDeleteDialog = () => {
    this.setState({
      deleteDialog: false
    });
  };
  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  openDeleteImage = (event, id) => {
    let image = this.props.images[id];
    this.setState({ deleteImage: true, image });
  };

  getPropertyImages = async () => {
    const id =
      (this.props.property && this.props.property.id) ||
      this.props.match.params.id;
    await this.props.fetchImagesByProperty(id);
  };

  onDeleteImage = async id => {
    try {
      let image = this.props.images[id];
      await this.props.deleteImage(id);
      const propertyId = this.props.propertyId;
      await this.loadImages(propertyId);
      this.closeDeleteImage();
      this.setState({ snackbarMessage: `${image.filename} deleted` });
    } catch (error) {
      console.error("error:", error);
      let deleteImageError = getErrorMessage(
        error,
        "There was a problem deleting the image."
      );
      this.setState({ deleteImageError });
      throw error;
    }
  };

  getBoxesPerRow = () => {
    let bpr = 1;
    if (this.props.isSm) {
      bpr = 1;
    }

    if (this.props.isMd) {
      bpr = 2;
    }
    if (this.props.isLg) {
      bpr = 3;
    }
    if (this.props.isXl) {
      bpr = 4;
    }
    return bpr;
  };
  render() {
    const { classes, isUploading } = this.props;
    const { images } = this.state;
    const boxesPerRow = this.getBoxesPerRow();
    const boxesHeight = 390;
    const boxesTotalHeight =
      Math.ceil(images.length / boxesPerRow) * boxesHeight;

    return (
      <>
        <WizardStepContainer
          Component={
            <div>
              <WizardTextField
                title={"Property Name"}
                validate={required}
                secondary={
                  "This will be the display name of your property for guests you have invited."
                }
                name="name"
              />

              <Grid container spacing={3} className={classes.subheading}>
                <Grid item xs={12} md={4}>
                  <WizardTextField
                    title={"Sleeps"}
                    type="number"
                    secondary={"Will indicate how many sleeps are available."}
                    name="sleeps"
                    format={formatNumber}
                    validate={validateNumbers}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <WizardTextField
                    title={"Bathrooms"}
                    type="number"
                    secondary={
                      "Will indicate how many bathrooms are available."
                    }
                    name="bathrooms"
                    format={formatNumber}
                    validate={validateNumbers}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <WizardTextField
                    title={"Bedrooms"}
                    type="number"
                    secondary={"Will indicate how many bedrooms are available."}
                    name="bedrooms"
                    format={formatNumber}
                    validate={validateNumbers}
                  />
                </Grid>
              </Grid>
              <div className={classes.uploadArea}>
                <WizardFieldName title={"Property Images"} />
                <div className="dropzone">
                  <Dropzone
                    activeClassName={classes.dropzoneActive}
                    className={classes.dropzone}
                    onDrop={this.handleUpload}
                  >
                    <div className={classes.dropzoneBody}>
                      <CloudUploadIcon className={classes.iconUpload} />
                      <Typography
                        color="inherit"
                        variant="subtitle1"
                        component="span"
                        className={classes.dropzoneTitle}
                      >
                        {"Drag files here (or "}
                        <span className={classes.link}>{"browse"}</span>
                        {") to upload property images"}
                      </Typography>
                    </div>
                  </Dropzone>
                  {isUploading && <Loading />}
                </div>
              </div>
              {images && images.length > 0 && (
                <Typography className={classes.imagesDescription}>
                  {
                    "Drag and drop your images to change their order. First image will always be the default."
                  }
                </Typography>
              )}

              {images && images.length > 0 && (
                <GridContextProvider onChange={this.onMoveImage}>
                  <div
                    style={{
                      width: "100%"
                    }}
                  >
                    <GridDropZone
                      id="zone"
                      style={{ height: boxesTotalHeight }}
                      boxesPerRow={boxesPerRow}
                      rowHeight={boxesHeight}
                    >
                      {images.map((image, index) => {
                        return (
                          <GridItem key={`image${image.id}`}>
                            <PropertyImage
                              index={index}
                              id={image.id}
                              isDefault={image.sortOrder === 0}
                              image={image}
                              openDeleteDialog={this.openDeleteImage}
                              openEditDialog={this.openEditImage}
                              // moveCard={this.moveCard}
                            />
                          </GridItem>
                        );
                      })}
                    </GridDropZone>
                  </div>
                </GridContextProvider>
              )}
              {this.props.editMode && (
                <>
                  <div style={{ marginTop: 24, marginBottom: 24 }}>
                    <WizardFieldName
                      title={"Delete property"}
                      secondary={
                        "Deleting a property is permenant and cannot be undone. All of your stays and property data will be deleted. Are you sure you want to proceed?"
                      }
                    />
                  </div>
                  <div className={classes.editorContainer}>
                    <MainActionButton
                      isDanger
                      onClick={this.openDeleteProperty}
                    >
                      Delete Property
                    </MainActionButton>
                  </div>
                </>
              )}
            </div>
          }
        ></WizardStepContainer>
        <DeleteDialog
          closeDialog={this.closeDeleteImage}
          errorMessage={this.state.deleteDialogError}
          //   isDeleting={isDeleting}
          onDelete={this.onDeleteImage}
          open={this.state.deleteImage}
          resource={this.state.image}
        />
        <DeleteDialog
          closeDialog={this.closeDeleteDialog}
          errorMessage={this.state.deleteDialogError}
          isDeleting={this.state.isDeleting}
          onDelete={this.handleDelete}
          open={this.state.deleteDialog}
          resource={this.props.property}
        />

        <EditDialog
          closeDialog={this.closeEditImage}
          errorMessage={this.state.editDialogError}
          onSave={this.onEditImage}
          title={"Edit image name"}
          isSaving={this.state.isSaving}
          open={this.state.editImage}
          resource={this.state.image}
        >
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Image's name"
            value={this.state.editImageContent || ""}
            onChange={this.onChangeEditImageContent}
            fullWidth
          />
        </EditDialog>
        <SnackbarMessage
          allowUpdates={true}
          message={this.state.snackbarMessage}
          onClose={this.closeSnackbarMessage}
        />
      </>
    );
  }
}

function mapStateToProps({ properties, images }, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = properties[props.propertyId] || null;
  }

  return {
    hasFetched: properties.hasFetched,
    isDeleting: properties.isDeleting,
    isFetching: properties.isFetching,
    isUploading: images.isUploading,
    initialValues: {
      ...property
    },
    form: "PropertyEdit" + props.propertyId,
    property,
    images
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...imagesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

BasicsStep = reduxForm({
  // form: "PropertyEdit", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(BasicsStep);

const withMediaQuery = () => Component => {
  const Handle = (props, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up("sm"));
    const isMd = useMediaQuery(theme.breakpoints.up("md"));
    const isLg = useMediaQuery(theme.breakpoints.up("lg"));
    const isXl = useMediaQuery(theme.breakpoints.up("xl"));

    const mediaQuery = useMediaQuery("(min-width:600px)");
    return (
      <Component
        isSm={isSm}
        isMd={isMd}
        isLg={isLg}
        isXl={isXl}
        classes={classes}
        mediaQuery={mediaQuery}
        {...props}
        ref={ref}
      />
    );
  };

  const name = Component.displayName || Component.name;
  Handle.displayName = `withForwardedRef(${name})`;

  return React.forwardRef(Handle);
};

export default withMediaQuery()(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    BasicsStep
  )
);
