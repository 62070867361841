import React, { Component } from "react";
import {
  Typography,
  withStyles,
  TextField,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, formValueSelector } from "redux-form";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as imagesActionCreators from "../../redux/modules/images";
import * as appActionCreators from "../../redux/modules/app";
import validate from "../../components/Properties/validateProperty";
import dasherize from "dasherize";
import CopyIcon from "@material-ui/icons/FileCopy";
import MainActionButton from "components/Buttons/MainActionButton";
import CopyToClipboard from "components/General/CopyToClipboard";
import copy from "clipboard-copy";
import { getPropertyUrl } from "helpers/utils";
import SecondaryActionButton from "components/Buttons/SecondaryActionButton";
import { Link } from "react-router-dom";
import FacebookFriendsList from "containers/Components/FacebookFriendsList";

const styles = theme => ({
  contentContainer: {
    marginTop: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  facebook: {
    marginTop: 40
  },
  marginTop12: {
    marginTop: 12
  }
});

class ShareStep extends Component {
  async continueCheck() {
    return new Promise(async resolve => {
      const submitter = this.props.handleSubmit(async values => {
        try {
          values.privacy =
            !values.privacy || values.privacy.length === 0
              ? "Private"
              : values.privacy;
          const property = dasherize(values);

          await this.props.updateProperty(property);
          return resolve(true);
        } catch (error) {
          console.error("error", error);
        }
      });
      const a = await submitter();
      if (a) return resolve(false);
    });
  }

  render() {
    const { property, classes } = this.props;
    const link = getPropertyUrl(property.slug);
    const clickToCopyLink = (
      <TextField
        id="outlined-adornment-password"
        variant="outlined"
        value={link}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard>
                {({ copy }) => (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      copy(link);
                      this.props.appSnackbarMessage({
                        message: "Copied to clipboard"
                      });
                    }}
                    onMouseDown={event => {
                      event.preventDefault();
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                )}
              </CopyToClipboard>
            </InputAdornment>
          )
        }}
      />
    );
    return (
      <WizardStepContainer
        Component={
          <div>
            <Typography variant="subtitle1">
              {"Congratulations! Your property is now privately listed on Vrbff"}
            </Typography>
            <Typography variant="caption" component="div">
              {"You can make more changes below, or return to your dashboard."}
            </Typography>
            <div className={classes.contentContainer}>
              <Typography variant="subtitle1" className={classes.marginTop12}>
                {"Manage Dates"}
              </Typography>
              <Typography
                variant="caption"
                style={{ textAlign: "center" }}
                component="div"
              >
                {
                  "You can customize the availability of your property, as well as change any other settings."
                }
              </Typography>
              <MainActionButton
                className={classes.marginTop12}
                to={`/manage/calendar/${this.props.property.id}`}
                component={Link}
              >
                View Calendar
              </MainActionButton>
              <br />
              <br />
              <Typography variant="subtitle1" className={classes.marginTop12}>
                {"Manage amenities"}
              </Typography>
              <Typography
                variant="caption"
                style={{ textAlign: "center" }}
                component="div"
              >
                {
                  "You can set the amenities of the property, so people know which ones are included in the house"
                }
              </Typography>
              <MainActionButton
                className={classes.marginTop12}
                to={`/manage/property/amenities/${this.props.property.id}`}
                component={Link}
              >
                Manage amenities
              </MainActionButton>
              <br />
              <br />
              <Typography variant="subtitle1" className={classes.friends}>
                {"Return to Dashboard"}
              </Typography>
              <SecondaryActionButton
                to={`/`}
                component={Link}
                className={classes.marginTop12}
                isSmall
              >
                Return to Dashboard
              </SecondaryActionButton>
            </div>
          </div>
        }
        SecondComponent={
          <div>
            <Typography variant="subtitle1">
              {"Share your property with friends and family!"}
            </Typography>
            <Typography variant="caption" component="div">
              {
                "Your property has a dedicated link on Vrnff you can share with friends and family, or use other convenient methods to get the word out."
              }
            </Typography>

            <div className={classes.contentContainer}>
              <Typography variant="subtitle1">{"Direct link"}</Typography>
              {clickToCopyLink}
              <MainActionButton
                className={classes.marginTop12}
                isSmall
                onClick={() => {
                  copy(link);
                  this.props.appSnackbarMessage({
                    message: "Copied to clipboard"
                  });
                }}
              >
                Copy to clipboard
              </MainActionButton>
              <br />
              <br />
              <Typography variant="subtitle1">{"Invite friends"}</Typography>
              <MainActionButton
                className={classes.marginTop12}
                isSmall
                to={"/invite-guests?propertyId=" + property.id}
              >
                Invite friends to Vrbff
              </MainActionButton>

              {/* <Typography variant="subtitle1" className={classes.facebook}>
                {"Share on Facebook"}
              </Typography>
              <Typography
                variant="caption"
                style={{ textAlign: "center" }}
                component="div"
              >
                {
                  "Let your friends know that your property is listed on Vrbff by posting on Facebook."
                }
              </Typography> */}
              <Typography variant="subtitle1" className={classes.facebook}>
                {"Add facebook friends as guests"}
              </Typography>
              <Typography
                variant="caption"
                style={{ textAlign: "center" }}
                component="div"
              >
                {
                  "Your facebook friends that already use Vrbff, can be added as guests"
                }
              </Typography>
              <div style={{ width: "100%" }}>
                <FacebookFriendsList
                  propertyId={property.id}
                  onClick={this.clickedOnFacebookFriend}
                  isLocal
                />
              </div>
              {/* <MainActionButton
                className={classes.marginTop12}
                isSmall
                disabled
              >
                Share on Facebook
              </MainActionButton> */}
            </div>
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

const selector = formValueSelector("ShareStepForm"); // <-- same as form name

function mapStateToProps(state, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    hasFetched: state.properties.hasFetched,
    isDeleting: state.properties.isDeleting,
    isFetching: state.properties.isFetching,
    isUploading: state.images.isUploading,
    initialValues: {
      ...property
    },
    property,
    privacyValue: selector(state, "privacy") || "Private"
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...imagesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

ShareStep = reduxForm({
  form: "ShareStepForm", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(ShareStep);

ShareStep = withStyles(styles, { name: "ShareStep" })(ShareStep);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ShareStep);
