import React, { Component } from "react";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const styles = theme => ({
  error: {
    color: theme.palette.error.main,
    marginTop: 8
  },
  formControl: {
    marginTop: 4,
    marginRight: 10,
    minWidth: 120
  }
});

class SelectPrivacy extends Component {
  handleChange = event => {
    const value = event.target.value;
    this.props.changeFunc && this.props.changeFunc(value);
    this.props.input.onChange(value);
  };

  render() {
    const {
      meta: { touched, error },
      input: { name, value },
      classes,
      disabled,
      formControlClass,
      margin,
      placeholder
    } = this.props;

    return (
      <FormControl
        className={classNames(classes.formControl, formControlClass)}
        error={touched && error !== undefined}
        margin={margin}
      >
        <InputLabel htmlFor={name}>{placeholder}</InputLabel>
        <Select
          disabled={disabled}
          value={value}
          onChange={this.handleChange}
          input={<Input id={name} />}
        >
          <MenuItem key={"Private"} value={"Private"}>
            Private
          </MenuItem>
          <MenuItem key={"Private"} value={"Private"}>
            Friends
          </MenuItem>
          <MenuItem key={"SemiPrivate"} value={"SemiPrivate"}>
            Semi-Private
          </MenuItem>
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  }
}

export default withStyles(styles, { name: "SelectPrivacy" })(SelectPrivacy);
