// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import common from "@material-ui/core/colors/common";
import DeleteIcon from "@material-ui/icons/Delete";
import withRoot from "../../withRoot";

import Message from "../Message/Message";
import ProgressButton from "../Progress/ProgressButton";
const { white } = common;

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.error.dark,
    color: white,
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.error.vdark
    }
  },
  iconButton: {
    marginRight: 8
  }
});

const ResponsiveDialog = withMobileDialog({ breakpoint: "xs" })(Dialog);

class DeleteDialog extends Component {
  render() {
    const {
      classes,
      closeDialog,
      errorMessage,
      isDeleting,
      message,
      onDelete,
      open,
      resource
    } = this.props;

    return (
      <div>
        {resource && (
          <ResponsiveDialog open={open} onClose={closeDialog}>
            <DialogTitle>{`Are you sure you want to delete ${resource.name ||
              resource.filename}?`}</DialogTitle>
            <DialogContent>
              <Message type="danger" message={errorMessage} />
              <DialogContentText>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                {"Cancel"}
              </Button>
              <ProgressButton
                className={classes.button}
                label="Delete"
                icon={<DeleteIcon className={classes.iconButton} />}
                loading={isDeleting}
                onClick={() => onDelete(resource.id)}
                type="button"
              />
            </DialogActions>
          </ResponsiveDialog>
        )}
      </div>
    );
  }
}

DeleteDialog.propTypes = {
  message: PropTypes.string,
  resource: PropTypes.object,
  onDelete: PropTypes.func.isRequired
};

export default withRoot(
  withStyles(styles, { name: "DeleteDialog" })(DeleteDialog)
);
