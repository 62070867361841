import React, { Component, Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import PropertyCard from "../Properties/PropertyCard";
import withRoot from "../../withRoot";
import PropertyCardPrivacy from "components/Properties/PropertyCardPrivacy";
// import { getToken } from "components/Auth/Auth";

const styles = theme => ({
  sectionTitle: {
    color: theme.palette.text.main,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "40px",
    marginTop: 75
  },
  gridItem: {
    // flexGrow: 0
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   padding: "0",
    //   marginBottom: "24px"
    // },
    // [theme.breakpoints.up("sm")]: {
    //   padding: "16px",
    //   width: "50%"
    // },
    // [theme.breakpoints.up("md")]: {
    //   width: 300
    // }
  }
});

class Home extends Component {
  getMyProperties = () => {
    const { classes, properties } = this.props;
    const newPropertyCard = (
      <Fragment>
        <h3 className={classes.sectionTitle}>Add your first property!</h3>
        <Grid container spacing={4}>
          <Grid className={classes.gridItem} item xs={12} md={4} sm={6} lg={3}>
            <PropertyCard addPropertyCard={true} />
          </Grid>
        </Grid>
      </Fragment>
    );
    if (properties.ids.length === 0) {
      return newPropertyCard;
    }
    if (properties.ids.length > 0) {
      var myproperties = [];
      properties.ids.map(id => {
        const property = properties[id];
        if (property.isHost) {
          myproperties.push(property);
        }
        return myproperties;
      });

      if (myproperties.length > 0) {
        return (
          <Fragment>
            <h3 className={classes.sectionTitle}>Properties I own</h3>
            <Grid container spacing={4}>
              {myproperties.map(property => (
                <Grid
                  className={classes.gridItem}
                  item
                  key={`my${property.id}`}
                  xs={12}
                  md={4}
                  sm={6}
                  lg={3}
                  xl={2}
                >
                  <PropertyCardPrivacy
                    property={property}
                    deleteProperty={
                      !property.active
                        ? async () => {
                            await this.props.deleteProperty(property.id);
                          }
                        : false
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      } else return newPropertyCard;
    }
  };

  getFriendsProperties = () => {
    const { classes, properties } = this.props;
    if (!properties) {
      return null;
    }

    if (properties.ids.length > 0) {
      let sharedproperties = [];
      properties.ids.map(id => {
        const property = properties[id];
        if (!property.isHost && property.isFriend) {
          sharedproperties.push(property);
        }
        return sharedproperties;
      });
      if (sharedproperties.length > 0) {
        return (
          <Fragment>
            <h3 className={classes.sectionTitle}>
              Properties shared by friends
            </h3>
            <Grid container spacing={4}>
              {sharedproperties.map(property => (
                <Grid
                  className={classes.gridItem}
                  item
                  key={`my${property.id}`}
                  xs={12}
                  md={4}
                  sm={6}
                  lg={3}
                  xl={2}
                >
                  <PropertyCardPrivacy property={property} />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      }
    }
  };

  getSharedProperties = () => {
    const { classes, properties } = this.props;
    if (!properties) {
      return null;
    }

    if (properties.ids.length > 0) {
      let sharedproperties = [];
      properties.ids.map(id => {
        const property = properties[id];
        if (!property.isHost) {
          sharedproperties.push(property);
        }
        return sharedproperties;
      });
      if (sharedproperties.length > 0) {
        return (
          <Fragment>
            <h3 className={classes.sectionTitle}>Properties I follow</h3>
            <Grid container spacing={4}>
              {sharedproperties.map(property => (
                <Grid
                  className={classes.gridItem}
                  item
                  key={`my${property.id}`}
                  xs={12}
                  md={4}
                  sm={6}
                  lg={3}
                  xl={2}
                >
                  <PropertyCardPrivacy property={property} />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      }
    }
  };

  getOpps = () => {
    const { classes, properties } = this.props;
    if (!properties || !properties.opps) {
      return null;
    }

    if (properties.opps.length > 0) {
      let opProperties = [];
      properties.opps.forEach(id => {
        const property = properties[id];

        opProperties.push(property);

        return opProperties;
      });
      opProperties = opProperties.sort(function(a, b) {
        if (a.Privacy === "Friends") {
          return -1;
        }
        return 1;
      });

      if (opProperties.length > 0) {
        return (
          <Fragment>
            <h3 className={classes.sectionTitle}>Other People's Properties</h3>
            <Grid container spacing={4}>
              {opProperties.map(property => (
                <Grid
                  xs={12}
                  md={4}
                  lg={3}
                  sm={6}
                  xl={2}
                  className={classes.gridItem}
                  item
                  key={`my${property.id}`}
                >
                  <PropertyCardPrivacy
                    property={property}
                    followProperty={async () => {
                      await this.props.followProperty(property);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        );
      }
    }
  };

  login() {
    this.props.auth.login();
  }

  render() {
    return (
      <Fragment>
        {this.getSharedProperties()}
        {/* {this.getFriendsProperties()} */}
        {this.getMyProperties()}
        {this.getOpps()}
      </Fragment>
    );
  }
}

export default withRoot(withStyles(styles)(Home));
