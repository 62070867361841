import { apiRequest, createResource, rpcRequest } from "apis/jsonApi";
import Resource from "./resource";
import { getErrorMessage } from "helpers/utils";

let resource = new Resource({
  singularName: "follow",
  pluralName: "follows"
});

export function fetchFollowsByUser() {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`follows/user`, "GET", {})
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function fetchFollowsByProperty(propertyId) {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`follows`, "GET", { property_id: propertyId })
      .then(response => {
        dispatch(resource.addingPlural(response));
        let ids = response.data.map(p => p.id);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export function addFollow(values) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    const ids = getState().follows.ids;
    try {
      const follow = await createResource("follow", values);
      if (follow) {
        ids.unshift(follow.id);
        dispatch(resource.successAddingSingular(ids, follow));
      }
      return follow;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function confirmFollow(id, groupId) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    try {
      await rpcRequest("confirm-follow", {
        follow_id: id,
        group_id: groupId
      }).then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorAddingSingular(error));
          throw error;
        } else {
          dispatch(removeFollow(id));
        }
      });
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function deleteFollow(id) {
  return async function(dispatch, getState) {
    dispatch(resource.deletingSingular());
    try {
      await apiRequest(`follows/${id}`, "DELETE").then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        } else {
          dispatch(removeFollow(id));
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function removeFollow(id) {
  return function(dispatch, getState) {
    const ids = getState().follows.ids;
    const i = ids.indexOf(id.toString());
    if (i !== -1) {
      ids.splice(i, 1);
    }
    dispatch(resource.successDeletingSingular(ids));
  };
}

export default resource.reducer;
