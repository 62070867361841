import React, { Component } from "react";
import { apiRequest, rpcRequest } from "apis/jsonApi";
import {
  Grid,
  Switch,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
  withStyles,
  TextField
} from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm } from "redux-form";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as imagesActionCreators from "../../redux/modules/images";
import * as appActionCreators from "../../redux/modules/app";
import validate from "../../components/Properties/validateProperty";

import Loading from "components/Progress/Loading";
import { amenitiesConfig } from "helpers/amenities";

const styles = theme => ({
  listItem: {
    listStyleType: "none",
    backgroundColor: "blue"
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: 22
  },
  listItemText: {
    display: "flex",
    alignItems: "center"
  }
});

class AmenitiesStep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amenities: false,
      propertyAmenities:
        (props.amenities &&
          props.amenities.length > 0 &&
          this.preparePropertyAmenities(props.amenities)) ||
        {}
    };
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.amenities.length !== prevProps.amenities.length &&
      this.props.amenities.length > 0
    ) {
      this.loadPropertyAmenities(this.props.amenities);
    }
  }
  preparePropertyAmenities(amenities) {
    let propertyAmenities = {};
    amenities.forEach(a => {
      propertyAmenities[a.amenity.name] = {
        propertyId: this.props.property.id,
        amenityId: a.amenity.id,
        note: a.note,
        quantity: a.quantity
      };
    });

    return propertyAmenities;
  }
  loadPropertyAmenities(amenities) {
    this.setState({
      propertyAmenities: this.preparePropertyAmenities(amenities)
    });
  }
  async componentDidMount() {
    const amenities = await apiRequest(`amenities`, "GET");
    this.setState({ amenities: amenities.data });
  }

  async continueCheck() {
    return new Promise(async resolve => {
      const submitter = this.props.handleSubmit(async values => {
        try {
          await rpcRequest("set-amenities", {
            amenities: Object.keys(this.state.propertyAmenities).map(k => {
              return {
                note: this.state.propertyAmenities[k].note || "",
                quantity: this.state.propertyAmenities[k].quantity || "",
                amenity_id: this.state.propertyAmenities[k].amenityId
              };
            }),
            property_id: this.props.property.id
          });

          // const property = dasherize(values);
          // if (values.address) {
          //   let addressResource;
          //   let updatedAddress = checkUpdateAddress(values, values.address);
          //   if (updatedAddress) {
          //     addressResource = await updateAddressLocation(values);
          //   }
          //   property.address = addressResource;
          // } else {
          //   this.props.appSnackbarMessage({
          //     message: "You have to set an address",
          //     type: "warning"
          //   });
          //   return resolve(false);
          // }

          // await this.props.updateProperty(property);
          return resolve(true);
        } catch (error) {
          console.error("error", error);
          return resolve(false);
        }
      });
      const a = await submitter();
      if (a) return resolve(false);
    });
  }
  handleChangeNote = name => event => {
    const message = event.target.value;
    let currentAmenities = { ...this.state.propertyAmenities };
    if (currentAmenities[name]) {
      currentAmenities[name].note = message;
    }
    this.setState({ propertyAmenities: currentAmenities });
  };
  handleToggle = (value, data) => () => {
    let currentAmenities = { ...this.state.propertyAmenities };
    if (currentAmenities[value]) {
      delete currentAmenities[value];
    } else {
      currentAmenities[value] = {
        propertyId: this.props.property.id,
        amenityId: data.id,
        note: (data.isNote && amenitiesConfig[value].defaultNote) || undefined
      };
    }

    this.setState({ propertyAmenities: currentAmenities });
  };

  render() {
    const { propertyAmenities } = this.state;
    const { classes } = this.props;

    return (
      <WizardStepContainer
        Component={
          <>
            {!this.state.amenities && <Loading />}
            {this.state.amenities && (
              <div>
                <Grid container>
                  {this.state.amenities.map(a => {
                    return (
                      <Grid item xs={12} md={6}>
                        <ListItem ContainerComponent={"div"}>
                          {/* <ListItemIcon>
          <WifiIcon />
        </ListItemIcon> */}
                          <ListItemText
                            disableTypography
                            id="switch-list-label-wifi"
                            primary={
                              <div className={classes.listItemText}>
                                <Icon className={classes.icon}>
                                  {amenitiesConfig[a.name].icon ||
                                    "check_circle"}
                                </Icon>
                                {amenitiesConfig[a.name].name}
                              </div>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Switch
                              edge="end"
                              onChange={this.handleToggle(a.name, a)}
                              checked={!!propertyAmenities[a.name]}
                              inputProps={{
                                "aria-labelledby": "switch-list-label-wifi"
                              }}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        {(a.isQuantity || a.isNote) &&
                          !!propertyAmenities[a.name] && (
                            <Grid container className={classes.more}>
                              {a.isNote && (
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    multiline
                                    rows="5"
                                    className={classes.textField}
                                    margin="none"
                                    variant="outlined"
                                    onChange={this.handleChangeNote(a.name)}
                                    value={propertyAmenities[a.name].note || ""}
                                    fullWidth
                                  ></TextField>
                                </Grid>
                              )}
                            </Grid>
                          )}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}
          </>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    hasFetched: state.properties.hasFetched,
    isDeleting: state.properties.isDeleting,
    isFetching: state.properties.isFetching,
    initialValues: {
      ...property
    },
    form: "AmenitiesStepForm" + props.propertyId,
    property,
    amenities: property.amenities || []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...imagesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

AmenitiesStep = reduxForm({
  // form: "AmenitiesStepForm", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(AmenitiesStep);

AmenitiesStep = withStyles(styles, { name: "AmenitiesStep" })(AmenitiesStep);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(AmenitiesStep);
