import React, { Component } from "react";
import { Paper, withStyles } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import ImageFilterHdr from "@material-ui/icons/FilterHdr";
import MainActionButton from "components/Buttons/MainActionButton";
import grey from "@material-ui/core/colors/grey";
import ProgressButton from "components/Progress/ProgressButton";
import locationIcon from "../../static/assets/icons/location.svg";
import HostsNew from "components/General/HostsNew";

const styles = theme => ({
  badge: {
    width: "160px",
    background: "#e43",
    position: "absolute",
    top: "24px",
    left: "-36px",
    textAlign: "center",
    lineHeight: "35px",
    letterSpacing: ".4px",
    fontWeight: 600,
    color: "#f0f0f0",
    transform: "rotate(-45deg)",
    WebkitTransform: "rotate(-45deg)",
    boxShadow: "0 0 3px rgba(0,0,0,.2)"
  },
  bigButtonContainer: {
    marginTop: 30,
    marginBottom: 20
  },
  smallButtonContainer: {
    marginBottom: 18
  },

  hostedBy: {
    paddingTop: 0,
    display: "flex",
    alignItems: "center",
    marginTop: -16,
    alignSelf: "center"
  },
  containerContent: {
    flex: 1,
    padding: "18px 40px",
    alignSelf: "flex-start",
    width: "100%"
  },
  propertyNameContainer: {
    backgroundColor: "#fff",

    display: "flex",
    flexDirection: "column",
    alignSelf: "flex-start"
  },
  propertyPreviewContainer: {
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    overflow: "hidden"
  },
  placeholderWrapper: {
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  placeholderIcon: {
    color: grey[400],
    fontSize: "50px"
  },
  avatar: {
    boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: 30,
    height: 30,
    marginRight: theme.spacing(2)
  },
  title: {
    maxWidth: 200,
    color: theme.palette.text.main,
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "28px",
    height: 60,
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  location: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "20px",
    color: theme.palette.text.main
  },
  locationIcon: {
    height: 16,
    verticalAlign: "middle",
    marginRight: 8
  },
  link: {
    color: theme.palette.primary.link,
    fontSize: 14,
    lineHeight: "24px"
  },
  propertyImage: {
    width: "100%",
    paddingTop: "70%",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  noImagePropertyContainer: {
    width: "100%",
    paddingTop: "70%",
    position: "relative"
  },
  noImageProperty: {
    fontSize: 100,
    borderRadius: 50,
    border: "2px solid",
    paddingLeft: 5,
    paddingRight: 5,
    position: "absolute",
    top: "36%",
    left: "35%",
    color: "#00000044"
  }
});

class PropertyCardPrivacy extends Component {
  render() {
    const {
      property,
      followProperty,
      privacy,
      classes,
      isBig,
      deleteProperty
    } = this.props;
    const privacyValue = privacy || property.privacy;
    const isHost = property && property.isHost;
    const hasAccess = property && property.hasAccess;
    const isActive = property && property.active;
    const image = property && property.image && property.image.url;
    const buttonClassName = isBig
      ? classes.bigButtonContainer
      : classes.smallButtonContainer;
    return (
      <Paper elevation={4} className={classes.propertyPreviewContainer}>
        {isHost && property.active === false && (
          <div className={classes.badge}>Not Complete</div>
        )}
        {(!image || (privacyValue === "Private" && !hasAccess && !isHost)) && (
          <div className={classes.noImagePropertyContainer}>
            <ImageFilterHdr
              size={100}
              className={classes.noImageProperty}
              style={{}}
            ></ImageFilterHdr>
          </div>
        )}
        {(privacyValue !== "Private" || hasAccess || isHost) && image && (
          <div
            className={classes.propertyImage}
            style={{
              backgroundImage: "url('" + image + "')"
            }}
          ></div>
        )}
        {/* {privacyValue !== "Private" && !image && (
          <div className={classes.placeholderWrapper}>
            <HomeIcon className={classes.placeholderIcon} />
          </div>
        )} */}
        <div className={classes.hostedBy}>
          <HostsNew isSmall hosts={property.hosts} />
        </div>
        <div className={classes.containerContent}>
          <div className={classes.propertyNameContainer}>
            <Typography variant="h6" className={classes.title}>
              {property.name}
            </Typography>

            <Typography variant="subtitle1" className={classes.location}>
              &nbsp;
              {(hasAccess || privacyValue !== "Private") && property.address && (
                <>
                  <img
                    alt="location icon"
                    src={locationIcon}
                    className={classes.locationIcon}
                  />
                  {`${property.address.city}, ${property.address.region}`}
                </>
              )}
            </Typography>
          </div>
          {isHost && !isActive && (
            <div className={buttonClassName}>
              <MainActionButton
                style={{ width: "100%", marginTop: 16 }}
                to={`/add-property/${property.id}`}
              >
                Continue Wizard
              </MainActionButton>
            </div>
          )}
          {isHost && isActive && (
            <div className={buttonClassName}>
              {/* <Link
                className={classes.link}
                to={"/manage/property/basics/" + property.id}
              >
                Edit My Property
              </Link> */}
              <MainActionButton
                style={{ width: "100%", marginTop: 16 }}
                to={"/manage/property/basics/" + property.id}
              >
                Edit Property
              </MainActionButton>
            </div>
          )}

          {hasAccess && !isHost && isActive && (
            <div className={buttonClassName}>
              <MainActionButton
                to={"/p/" + property.slug}
                style={{ width: "100%", marginTop: 16 }}
              >
                Request Stay
              </MainActionButton>
            </div>
          )}
          {false && isHost && isActive && (
            <div className={buttonClassName}>
              <MainActionButton
                style={{ width: "100%" }}
                to={"/p/" + property.slug}
              >
                Guest View
              </MainActionButton>
            </div>
          )}
          {!hasAccess && !isHost && (
            <div className={buttonClassName}>
              <MainActionButton
                style={{ width: "100%", marginTop: 16 }}
                to={"/p/" + property.slug}
              >
                View property
              </MainActionButton>
            </div>
          )}
          {false && followProperty && (
            <div className={buttonClassName}>
              <ProgressButton
                isSmall
                disabled={property.hasRequested}
                label={
                  property.hasRequested ? "Follow requested" : "Follow Property"
                }
                progress
                onClick={followProperty}
              />
            </div>
          )}

          {false && deleteProperty && (
            <div className={buttonClassName}>
              <ProgressButton
                style={{ width: "100%" }}
                onClick={deleteProperty}
                progress
              >
                Delete property
              </ProgressButton>
            </div>
          )}
        </div>
      </Paper>
    );
  }
}
export default withStyles(styles)(PropertyCardPrivacy);
