import React, { Component } from "react";
import WizardStepContainer from "components/wizard/WizardStepContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fields, reduxForm, formValueSelector } from "redux-form";
import * as propertiesActionCreators from "../../redux/modules/properties";
import * as imagesActionCreators from "../../redux/modules/images";
import * as appActionCreators from "../../redux/modules/app";
import validate from "../../components/Properties/validateProperty";
import dasherize from "dasherize";
import AddressField from "components/Fields/AddressField";
import GoogleMap from "google-map-react";
import { checkUpdateAddress } from "helpers/checkUpdateAddress";
import { updateAddressLocation } from "helpers/updateAddressLocation";
import WizardFieldName from "components/wizard/WizardFieldName";

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

class AddressStep extends Component {
  async continueCheck() {
    return new Promise(async resolve => {
      const submitter = this.props.handleSubmit(async values => {
        try {
          const property = dasherize(values);
          if (values.address) {
            let addressResource;
            let updatedAddress = checkUpdateAddress(values, values.address);
            if (updatedAddress) {
              addressResource = await updateAddressLocation(values);
            }
            property.address = addressResource;
          } else {
            this.props.appSnackbarMessage({
              message: "You have to set an address",
              type: "warning"
            });
            return resolve(false);
          }

          await this.props.updateProperty(property);
          return resolve(true);
        } catch (error) {
          console.error("error", error);
        }
      });
      const a = await submitter();
      if (a) return resolve(false);
    });
  }

  getMap() {
    if (!this.props.location) return;
    let lat = this.props.location.latitude;
    let lng = this.props.location.longitude;
    const randomLat =
      getRandomArbitrary(0.0005, 0.002) * (Math.random() >= 0.5 ? 1 : -1);
    const randomLng =
      getRandomArbitrary(0.0005, 0.002) * (Math.random() >= 0.5 ? 1 : -1);
    const center = { lat: lat + randomLat, lng: lng + randomLng };

    const Map = ({ latlng, zoom }) => (
      <div style={{ height: 400 }}>
        <GoogleMap
          yesIWantToUseGoogleMapApiInternals={true}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_CLOUD_API }}
          defaultZoom={14}
          defaultCenter={center}
          onGoogleApiLoaded={({ map, maps }) =>
            /*global google*/
            new google.maps.Circle({
              strokeColor: "#40A5CB",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: "#40A5CB",
              fillOpacity: 0.3,
              map,
              center: center,
              radius: 500
            })
          }
        />
      </div>
    );

    return (
      <div style={{ marginTop: 24 }}>
        <Map />
      </div>
    );
  }

  render() {
    return (
      <WizardStepContainer
        Component={
          <div>
            <WizardFieldName
              title={"Property Address"}
              secondary={
                "Enter your address - it will only be revealed to guests you approve to stay at your property."
              }
            />
            <Fields
              names={[
                "address.id",
                "address.address1",
                "address.address2",
                "address.city",
                "address.region",
                "address.postalCode",
                "address.postalCodeSuffix",
                "address.country",
                "address.location"
              ]}
              type="text"
              props={Object.assign({}, this.props, {
                classes: {}
              })}
              allowPOBoxes={false}
              component={AddressField}
              required={true}
            />
          </div>
        }
        SecondComponent={
          <div>
            <WizardFieldName
              title={"Map Preview"}
              secondary={
                "Once you enter your address, you will see a preview of how it will appear to guests you invite to your property."
              }
            />

            {this.getMap()}
          </div>
        }
      ></WizardStepContainer>
    );
  }
}

function mapStateToProps(state, props) {
  const selector = formValueSelector("AddressStepForm" + props.propertyId); // <-- same as form name

  let property;
  if (props.property) {
    property = props.property;
  } else if (props.propertyId) {
    property = state.properties[props.propertyId] || null;
  }

  return {
    hasFetched: state.properties.hasFetched,
    isDeleting: state.properties.isDeleting,
    isFetching: state.properties.isFetching,
    isUploading: state.images.isUploading,
    initialValues: {
      ...property
    },
    form: "AddressStepForm" + props.propertyId,
    property,
    images: state.images,
    location: selector(state, "address.location")
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators,
      ...imagesActionCreators,
      ...appActionCreators
    },
    dispatch
  );
}

AddressStep = reduxForm({
  // form: "AddressStepForm", // a unique identifier for this form
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate
})(AddressStep);

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(AddressStep);
