import React, { Component } from "react";
import CopyToClipboard from "components/General/CopyToClipboard";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "components/General/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "../Icons/CopyIcon";
import { getPropertyUrl } from "../../helpers/utils";
import Message from "../Message/Message";
import SnackbarMessage from "../Message/SnackbarMessage";
import { TextField, InputAdornment } from "@material-ui/core";
import MainActionButton from "components/Buttons/MainActionButton";
import FacebookFriendsList from "containers/Components/FacebookFriendsList";
import copy from "copy-to-clipboard";
import SelectGroup from "components/Fields/SelectGroup";

const styles = theme => ({
  button: {
    marginRight: 10
  },
  headline: {
    marginBottom: 16
  },
  icon: {
    maxHeight: 16
  },
  iconButton: {},
  sharing: { textAlign: "center", margin: 10, marginBottom: 24 },
  title: {
    marginTop: 8,
    marginBottom: 16
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto",
    padding: 32
  },
  marginTop12: {
    marginTop: 12
  },
  listItem: {
    display: "flex"
  },
  secondaryActions: { display: "flex", position: "unset", transform: "unset" }
});

class PropertyGuests extends Component {
  state = { friends: [], changedGroups: {} };
  async componentDidMount() {
    this.props.getPropertyGuests();
  }
  clickedOnFacebookFriend = () => {
    this.props.getPropertyGuests();
  };
  onChangeGroup = (groupId, userId, propertyId) => {
    this.props.setGroupToUserForProperty(groupId, userId, propertyId);
    this.setState({
      changedGroups: { ...this.state.changedGroups, [userId]: groupId }
    });
  };
  render() {
    const {
      classes,
      closeSnackbarMessage,
      errorMessage,
      handleDeleteGuest,
      isDeleting,
      property,
      snackbarMessage,
      users,
      groups
    } = this.props;

    if (!property) {
      return null;
    }
    const link = getPropertyUrl(property.slug);

    const clickToCopyLink = (
      <TextField
        id="outlined-adornment-password"
        variant="outlined"
        value={link}
        disabled
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard>
                {({ copy }) => (
                  <IconButton
                    edge="end"
                    onClick={() => {
                      copy(link);
                      this.props.appSnackbarMessage({
                        message: "Copied to clipboard"
                      });
                    }}
                    onMouseDown={event => {
                      event.preventDefault();
                    }}
                  >
                    <CopyIcon />
                  </IconButton>
                )}
              </CopyToClipboard>
            </InputAdornment>
          )
        }}
      />
    );

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {errorMessage && (
                <div className={classes.headline}>
                  <Message type="danger" message={errorMessage} />
                </div>
              )}
              <Typography variant="subtitle1" color="inherit">
                {"Invite Guests"}
              </Typography>
              <div className={classes.sharing}>
                <Typography variant="subtitle1">{"Direct link"}</Typography>
                {clickToCopyLink}
                <br />
                <MainActionButton
                  className={classes.marginTop12}
                  isSmall
                  onClick={() => {
                    copy(link);
                    this.props.appSnackbarMessage({
                      message: "Copied to clipboard"
                    });
                  }}
                >
                  Copy to clipboard
                </MainActionButton>
                <Typography
                  variant="caption"
                  style={{ textAlign: "center", marginTop: 8 }}
                  component="div"
                >
                  {
                    "Property links are private. Copy the link to share with guests. Guests will request to follow your property, which you need to approve. Once approved, guests can view your property."
                  }
                </Typography>
                <br />
                <br />
                <Typography variant="subtitle1">
                  {"Invite friends directly via email"}
                </Typography>
                {/* <Typography
                  variant="caption"
                  style={{ textAlign: "center" }}
                  component="div"
                >
                  {"Coming soon!"}
                </Typography> */}
                <MainActionButton
                  className={classes.marginTop12}
                  isSmall
                  to={"/invite-guests?propertyId=" + property.id}
                >
                  Invite friends to Vrbff
                </MainActionButton>
                <br />
                <br />
                <Typography variant="subtitle1">
                  {"Facebook friends who use Vrbff"}
                </Typography>

                <div>
                  <FacebookFriendsList
                    propertyId={property.id}
                    onClick={this.clickedOnFacebookFriend}
                  />
                </div>
              </div>
              <Typography variant="subtitle1" color="inherit">
                {"Guest List"}
              </Typography>
              <Grid container>
                <Grid item xs={12}>
                  {users && users.ids.length > 0 && (
                    <div className={classes.guests}>
                      <List>
                        {users.ids.map(id => (
                          <ListItem
                            key={`user${id}`}
                            ContainerProps={{
                              className: classes.listItem
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                firstName={users[id].firstName}
                                lastName={users[id].lastName}
                                src={users[id].picture}
                              />
                            </ListItemAvatar>
                            <ListItemText primary={users[id].name} />
                            <ListItemSecondaryAction
                              className={classes.secondaryActions}
                            >
                              <SelectGroup
                                groups={groups}
                                input={{
                                  value: this.state.changedGroups[id]
                                    ? this.state.changedGroups[id]
                                    : users[id].groupId,
                                  onChange: groupId =>
                                    this.onChangeGroup(groupId, id, property.id)
                                }}
                                meta={{ touched: false }}
                              />
                              <IconButton
                                disabled={isDeleting}
                                edge="end"
                                aria-label="Delete"
                                onClick={() => handleDeleteGuest(id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  )}
                  {users && users.ids.length === 0 && (
                    <div className={classes.guests}>{"No guests"}</div>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <SnackbarMessage
          allowUpdates={true}
          message={snackbarMessage}
          onClose={closeSnackbarMessage}
        />
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { name: "PropertyGuests" })(PropertyGuests);
