import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";

import Loading from "components/Progress/Loading";
import FollowRequestItem from "./FollowRequestItem";

export default class FollowRequests extends Component {
  render() {
    const { isManage, follows, title } = this.props;
    if (this.props.isFetching && this.props.follows.ids.length === 0)
      return (
        <main>
          <Loading />
        </main>
      );

    return (
      <main>
        <Typography variant="h6">{title}</Typography>
        {(!follows || !follows.ids || follows.ids.length === 0) && (
          <Typography variant="h6">No pending follow requests.</Typography>
        )}
        {follows && follows.ids && follows.ids.length > 0 && (
          <Card>
            <List>
              {this.props.follows.ids
                .sort((a, b) => b - a)
                .map((id, index) => {
                  const follow = this.props.follows[id];
                  // if (!trip.lastMessage) return <></>;
                  return (
                    <FollowRequestItem
                      key={id}
                      isManage={isManage}
                      follow={follow}
                      index={index}
                      length={this.props.follows.ids.length}
                      picture={
                        follow.property.image ? follow.property.image.url : null
                      }
                    />
                  );
                })}
            </List>
          </Card>
        )}
      </main>
    );
  }
}
