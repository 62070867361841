import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import withRoot from "../../withRoot";
import SelectGuests from "../Properties/RequestStay/SelectGuests";
import moment from "moment";
import HomeIcon from "../Icons/HomeHeartLight";
import DatePicker from "containers/Components/DatePicker";
import ProgressButton from "components/Progress/ProgressButton";
import { FormHelperText, Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const ResponsiveDialog = withMobileDialog({ breakpoint: "xs" })(Dialog);

const styles = theme => ({
  root: {
    width: "100%"
  },
  requestTitle: {
    marginTop: 10
  },
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  selectProperty: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  requestButton: {
    marginTop: 20
  },
  errorText: {
    color: "red"
  }
});

const CustomItem = ({ item }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row"
  };

  const imageStyle = {
    display: "flex",
    width: "32px",
    height: "32px",
    flexGrow: 0,
    marginRight: "8px",
    backgroundColor: "transparent"
  };

  const contentStyle = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2
  };

  const dropdownImage = item.img ? (
    <img src={item.img} alt={item.name} style={imageStyle} />
  ) : (
    <HomeIcon style={imageStyle} />
  );

  return (
    <div style={containerStyle}>
      {dropdownImage}
      <div style={contentStyle}>
        <strong>{item.name}</strong>
        <small>{item.address}</small>
      </div>
    </div>
  );
};
class BookingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      property: props.trip ? props.trip.property.id : props.selectedProperty,
      adults: 1,
      children: 0,
      startDate: props.start ? moment(props.start) : null,
      endDate: props.end ? moment(props.end) : null,
      focusedInput: null,
      message: null,
      lastTime: props.start ? props.start.getTime() : null
    };
  }

  handleChangeMessage = event => {
    const message = event.target.value;
    this.setState({ message });
  };

  requestStay = async () => {
    const {
      adults,
      children,
      startDate,
      endDate,
      message,
      property
    } = this.state;
    let error = {};
    // const { property } = this.props;

    if (!property || property === "all") {
      error.property = "You have to select a Property";
    }

    // if (!message || message.length === 0) {
    //   error.message = "The message is mandatory";
    // }

    if (!startDate) {
      error.startDate = true;
      this.datePickerRef.openCalendar();
    }

    if (Object.keys(error).length > 0) {
      return this.setState({ error: error });
    }

    const values = {
      adults,
      children,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      message,
      property: { id: property }
    };
    if (this.props.trip) {
      values.id = this.props.trip.id;
    }
    try {
      await this.props.onRequestStay(values);
      this.props.cancelEvent();
    } catch (e) {
      //handle error
    }
  };

  deleteStay = async () => {
    try {
      await this.props.onDeleteStay(this.props.trip.id);
      this.props.cancelEvent();
    } catch (e) {
      //handle error
    }
  };

  handleChange = event => {
    this.setState({ property: event.target.value });
  };

  handleGuestChanges = (name, value) => () => {
    this.setState({ ...this.state, [name]: this.state[name] + value });
  };

  render() {
    const {
      classes,
      selectedProperty,
      dropDownProperties,
      setNewEventDate,
      cancelEvent,
      open,
      trip
    } = this.props;
    if (
      this.datePickerRef &&
      this.datePickerRef.reset &&
      this.props.start &&
      this.props.start.valueOf &&
      this.props.valueOf() !== this.state.lastTime
    ) {
      setTimeout(() => {
        this.setState({
          lastTime: this.props.valueOf(),
          startDate: moment(this.props.start),
          endDate: moment(this.props.end)
        });
        this.datePickerRef &&
          this.datePickerRef.reset(
            moment.range(
              moment(this.state.startDate),
              moment(this.state.endDate)
            )
          );
      }, 1);
    }

    const { adults, children, property, error } = this.state;
    return (
      <ResponsiveDialog open={open} onClose={cancelEvent}>
        <DialogTitle>Block a date</DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <FormControl className={classes.selectProperty}>
              <InputLabel htmlFor="age-customized-select">Property</InputLabel>
              <Select
                value={this.state.property || "all"}
                onChange={this.handleChange}
                disabled={selectedProperty !== "all"}
              >
                <MenuItem value="all">
                  <em>Select a property</em>
                </MenuItem>
                {dropDownProperties.map(p => (
                  <MenuItem key={p.value} value={p.value}>
                    <CustomItem item={p} />
                  </MenuItem>
                ))}
              </Select>
              {error && error.property && (
                <FormHelperText className={classes.errorText}>
                  {error.property}
                </FormHelperText>
              )}
            </FormControl>

            <Typography variant="body2" color="inherit">
              {"Dates"}
            </Typography>
            <DatePicker
              key={"pickerproperty" + property}
              ref={r => (this.datePickerRef = r)}
              blockId={(trip && trip.id) || false}
              propertyId={property}
              initialDate={moment.range(
                moment(this.state.startDate),
                moment(this.state.endDate)
              )}
              onSelect={value => {
                setNewEventDate(value);
                this.setState({ startDate: value.start, endDate: value.end });
              }}
            />
            <Typography
              className={classes.requestTitle}
              variant="body2"
              color="inherit"
            >
              {"Guests"}
            </Typography>
            <SelectGuests
              adults={adults}
              children={children}
              fullTitle
              handleAddAdults={this.handleGuestChanges("adults", 1)}
              handleRemoveAdults={this.handleGuestChanges("adults", -1)}
              handleAddChildren={this.handleGuestChanges("children", 1)}
              handleRemoveChildren={this.handleGuestChanges("children", -1)}
            />
            {/* <Typography
              className={classes.requestTitle}
              variant="body2"
              color="inherit"
            >
              {"Message"}
            </Typography>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows="5"
              className={classes.textField}
              margin="none"
              variant="outlined"
              onChange={this.handleChangeMessage}
              value={this.state.message || ""}
              fullWidth
            />
            {error && error.message && (
              <FormHelperText className={classes.errorText}>
                {error.message}
              </FormHelperText>
            )} */}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEvent} color="primary">
            {"Cancel"}
          </Button>
          {this.props.trip && (
            <ProgressButton
              isDanger
              variant="outlined"
              color="primary"
              label="Delete"
              onClick={this.deleteStay}
            />
          )}
          <ProgressButton
            variant="outlined"
            color="primary"
            label="Block Dates"
            onClick={this.requestStay}
          />
        </DialogActions>
      </ResponsiveDialog>
    );
  }
}

export default withRoot(
  withStyles(styles, { name: "BookingForm" })(BookingForm)
);
