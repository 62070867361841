import React from "react";
import { withStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

const styles = theme => ({});

function FacebookIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Facebook Icon</title>
        <desc>Created with sketchtool.</desc>
        <g
          id="landing-page"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="01-vrbff-lp@2x"
            transform="translate(-1440.000000, -3716.000000)"
          >
            <g id="footer" transform="translate(0.000000, 3532.000000)">
              <g id="Facebook" transform="translate(1441.000000, 185.000000)">
                <path
                  d="M0,20 C0,8.95430446 8.95430446,0 20,0 C31.0456955,0 40,8.95430446 40,20 C40,31.0456955 31.0456955,40 20,40 C8.95430446,40 0,31.0456955 0,20 Z"
                  id="Combined-Shape"
                  stroke="var(--color-2, currentColor)"
                  fill="var(--color-2, currentColor)"
                ></path>
                <path
                  d="M21.2324433,28.3114754 L21.2324433,19.6547242 L23.560354,19.6547242 L23.8688525,16.6715598 L21.2324433,16.6715598 L21.2363984,15.1784572 C21.2363984,14.400402 21.3084143,13.9835023 22.3970578,13.9835023 L23.8523728,13.9835023 L23.8523728,11 L21.5241325,11 C18.7275415,11 17.7432139,12.4471557 17.7432139,14.8808165 L17.7432139,16.6718976 L16,16.6718976 L16,19.655062 L17.7432139,19.655062 L17.7432139,28.3114754 L21.2324433,28.3114754 Z"
                  id="Shape"
                  fill="var(--color-1, none)"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default withStyles(styles)(FacebookIcon);
