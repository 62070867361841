import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";

import BillOfRightsHero from "./BillOfRightsHero";
import BillOfRightsText from "./BillOfRightsText";

const styles = theme => ({});

class BillOfRights extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <BillOfRightsHero {...this.props} />
        <BillOfRightsText />
      </React.Fragment>
    );
  }
}

BillOfRights.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(BillOfRights));
