import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";
import { FormControl } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import dasherize from "dasherize";

const styles = theme => ({
  root: {
    flexGrow: 1,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 8,
    paddingTop: 0
  },
  form: {
    flexGrow: 1,
    marginTop: 16,
    marginLeft: 8,
    margin: theme.spacing(1)
  },
  icon: {
    height: 20
  },
  iconLeft: {
    marginTop: 24,
    marginRight: 8,
    paddingLeft: 4,
    textAlign: "center"
  },
  iconRemove: {
    color: theme.palette.textColor
  },
  iconRight: {
    marginTop: 12,
    textAlign: "right"
  },
  input: {
    marginTop: 0
  },
  inputLabel: {
    marginTop: 0
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  title: {
    marginTop: 8,
    flex: 1,
    fontWeight: theme.typography.fontWeightMedium
  }
});

class Search extends Component {
  typingTimer = null;

  state = {
    showSearch: false
  };

  handleReset = event => {
    // clear search interval
    const search = this.props.search;
    this.props.setSearch(null);

    let params = {
      page: {
        number: 1,
        size: this.props.pageSize
      },
      ...this.props.params
    };

    // check for orderBy and persist on reset
    if (this.props.orderBy) {
      params["sort"] =
        (this.props.order === "asc" ? "" : "-") + dasherize(this.props.orderBy);
    }

    // run search
    if (search) {
      this.props.handleSearch(params);
    }
  };

  keyDownSearch = event => {
    clearTimeout(this.typingTimer);
  };

  keyUpSearch = event => {
    const search = event.target.value;
    clearTimeout(this.typingTimer);

    this.typingTimer = setTimeout(() => {
      this.runSearch(search);
    }, 1000);
  };

  handleRequestShowSearch = (event, show) => {
    if (!show) {
      this.handleReset();
    }
    this.setState({ showSearch: show });
  };

  runSearch = search => {
    this.props.setSearch(search);

    let params = {
      filter: {
        search: search
      },
      page: {
        number: 1,
        size: this.props.pageSize
      },
      ...this.props.params
    };
    if (this.props.orderBy) {
      params["sort"] =
        (this.props.order === "asc" ? "" : "-") + dasherize(this.props.orderBy);
    }

    // run search
    this.props.handleSearch(params);
  };

  render() {
    const { classes, title } = this.props;
    return (
      <div>
        <Grid container direction="row" spacing={0} className={classes.root}>
          <Grid item xs={12}>
            <div className={classes.row}>
              {this.state.showSearch && (
                <div className={classes.iconLeft}>
                  <SearchIcon className={classes.icon} />
                </div>
              )}
              <div className={classes.form}>
                {!this.state.showSearch && (
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    color="inherit"
                    className={classes.title}
                  >
                    {title}
                  </Typography>
                )}
                {this.state.showSearch && (
                  <Grow in={this.state.showSearch}>
                    <FormControl fullWidth>
                      <Input
                        autoFocus
                        className={classes.input}
                        onKeyDown={this.keyDownSearch}
                        onKeyUp={this.keyUpSearch}
                        placeholder="Search"
                        type="text"
                        disabled={false}
                        fullWidth
                      />
                    </FormControl>
                  </Grow>
                )}
              </div>
              <div className={classes.iconRight}>
                <IconButton
                  className={classes.iconRemove}
                  onClick={event =>
                    this.handleRequestShowSearch(event, !this.state.showSearch)
                  }
                >
                  {!this.state.showSearch && (
                    <SearchIcon className={classes.icon} />
                  )}
                  {this.state.showSearch && <CloseIcon />}
                </IconButton>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { name: "Search" })(Search);
