import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

import ProgressButton from "../../Progress/ProgressButton";
import withRoot from "../../../withRoot";
import SelectGuests from "./SelectGuests";
import dasherize from "dasherize";
import DatePicker from "../../../containers/Components/DatePicker";
import { FormHelperText, Paper } from "@material-ui/core";
import { apiAxiosRequest, serializeResource } from "apis/jsonApi";
import PaymentSummary from "components/General/PaymentSummary";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: "#fff",
    paddingTop: 32,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 24
  },
  requestButton: {
    width: "100%",
    marginBottom: 8
  },
  requestTitle: {
    marginTop: 10
  },
  fieldText: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: "24px",
    color: theme.palette.text.main,
    marginBottom: 9
  },
  containerField: {
    marginBottom: 24
  },
  pricing: {
    marginBottom: 24
  }
});

class RequestStay extends Component {
  handleAddAdults = () => {
    const adults = this.state.adults + 1;
    this.setState({ adults }, this.getPricing);
  };

  handleRemoveAdults = () => {
    const adults = this.state.adults - 1;
    this.setState({ adults }, this.getPricing);
  };

  handleAddChildren = () => {
    const children = this.state.children + 1;
    this.setState({ children }, this.getPricing);
  };

  handleRemoveChildren = () => {
    const children = this.state.children - 1;
    this.setState({ children }, this.getPricing);
  };

  handleChangeMessage = event => {
    const message = event.target.value;
    this.setState({ message }, this.getPricing);
  };

  getPricing = async () => {
    const { adults, children, startDate, endDate, message } = this.state;
    const { property } = this.props;

    if (!startDate) {
      return;
    }

    const values = {
      adults,
      children,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      message,
      property
    };

    let data = await apiAxiosRequest(
      `trips/pricing`,
      "POST",
      { data: serializeResource("trip", dasherize(values)) },
      {},
      true,
      true
    );

    this.setState({ pricing: data });
  };

  getTripValues = () => {
    const { adults, children, startDate, endDate, message } = this.state;
    const { property } = this.props;

    return {
      adults,
      children,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      message,
      property
    };
  };
  requestStay = async () => {
    const { adults, children, startDate, endDate, message } = this.state;

    const { property } = this.props;

    if (!startDate) {
      return this.datePickerRef.openCalendar();
    }

    // if (!message || message.length < 1) {
    //   return this.setState({
    //     errors: { message: "You need to attach a message to your request" }
    //   });
    // }

    const values = {
      adults,
      children,
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
      message,
      property
    };

    await this.props.handleRequestStay(values);
  };

  state = {
    adults: 1,
    children: 0,
    startDate: null,
    endDate: null,
    focusedInput: null,
    message: null,
    errors: {}
  };

  render() {
    const { classes, isAdding, property, Map } = this.props;
    const { adults, children, errors, pricing } = this.state;

    return (
      <Paper elevation={4} className={classes.root}>
        <div className={classes.containerField}>
          <div className={classes.fieldText}>{"Dates"}</div>
          <DatePicker
            ref={r => (this.datePickerRef = r)}
            propertyId={property.id}
            onSelect={value =>
              this.setState(
                { startDate: value.start, endDate: value.end },
                this.getPricing
              )
            }
          />
        </div>
        <div className={classes.containerField}>
          <div className={classes.fieldText}>{"Guests"}</div>
          <SelectGuests
            adults={adults}
            children={children}
            handleAddAdults={this.handleAddAdults}
            handleRemoveAdults={this.handleRemoveAdults}
            handleAddChildren={this.handleAddChildren}
            handleRemoveChildren={this.handleRemoveChildren}
          />
        </div>
        <div className={classes.containerField}>
          <div className={classes.fieldText}>{"Mesage"}</div>

          <TextField
            id="outlined-multiline-static"
            multiline
            rows="5"
            fullWidth
            margin="none"
            variant="outlined"
            onChange={this.handleChangeMessage}
            value={this.state.message || ""}
            style={{
              borderColor: "#D5D5D5"
            }}
            InputProps={{
              style: {
                fontWeight: 400,
                fontSize: "16px",
                textAlign: "left",
                paddingLeft: 32
              }
            }}
          />
        </div>
        {errors.message && <FormHelperText>{errors.message}</FormHelperText>}
        {pricing && pricing.total && pricing.total > 0 && (
          <div className={classes.pricing}>
            <div className={classes.fieldText}>{"Price"}</div>

            <PaymentSummary
              trip={{ ...this.getTripValues(), pricing: pricing }}
            />
          </div>
        )}
        <ProgressButton
          className={classes.requestButton}
          label="Request Stay"
          loading={isAdding}
          onClick={this.requestStay}
        />
        {Map && Map}
      </Paper>
    );
  }
}

export default withRoot(
  withStyles(styles, { name: "RequestStay" })(RequestStay)
);
