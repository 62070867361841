import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import MainActionButton from "../Buttons/MainActionButton";

const styles = theme => ({
  mainContainer: {
    marginBottom: 40
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 24,
    marginBottom: 14
  },
  containerBottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    marginTop: 32
  },
  layout: {
    display: "flex",
    width: "auto"
  },
  title: {
    fontWeigth: 700,
    fontSize: 32,
    lineHeigth: "48px",
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0
    }
  },
  actionButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "flex-end",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: "rgba(255,255,255,0.94)",
      boxShadow: theme.shadow.medium,
      zIndex: "999"
    }
  },
  mainActionButton: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "auto"
    }
  }
});

class PropertyManageLayout extends Component {
  onClick = async () => {
    if (this.props.onClick) {
      this.props.onClick(this.ref);
    }
  };

  render() {
    const {
      classes,
      Comp,
      stepProps,
      title,
      buttonName,
      to,
      buttons,
      buttonBottom
    } = this.props;

    return (
      <div className={classes.mainContainer}>
        <div className={classes.container}>
          <h2 className={classes.title}>{title}</h2>
          {buttonName && (
            <div className={classes.actionButtonContainer}>
              <MainActionButton
                className={classes.mainActionButton}
                onClick={this.onClick}
                to={to}
              >
                {buttonName}
              </MainActionButton>
            </div>
          )}
          {buttons && (
            <div className={classes.actionButtonContainer}>{buttons}</div>
          )}
        </div>
        {Comp ? (
          <Comp ref={r => (this.ref = r)} {...stepProps}></Comp>
        ) : (
          this.props.children
        )}
        {buttonBottom && (
          <div className={classes.containerBottom}>
            {buttonName && (
              <div className={classes.actionButtonContainer}>
                <MainActionButton
                  className={classes.mainActionButton}
                  onClick={this.onClick}
                  to={to}
                >
                  {buttonName}
                </MainActionButton>
              </div>
            )}
            {buttons && (
              <div className={classes.actionButtonContainer}>{buttons}</div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(PropertyManageLayout);
