import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../withRoot";

const styles = theme => ({
  content: {
    fontSize: 16,
    lineHeight: 1.8,
    paddingBottom: 100,
    paddingTop: 60,
    paddingLeft: "10%",
    paddingRight: "10%"
  },
  signature: {
    textAlign: "right",
    fontWeight: "bold"
  },
  [theme.breakpoints.down("sm")]: {
    content: {
      paddingBottom: 60,
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20
    }
  }
});

class BillOfRightsText extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.content}>
        <p>
          <strong>Article the First</strong> … The right to privacy and control
          of who is allowed to stay in our home.
        </p>
        <p>
          <strong>Article the Second</strong> … The right to reasonable,
          transparent pricing without hidden fees.
        </p>
        <p>
          <strong>Article the Third</strong> … The right to communicate directly
          with guests.
        </p>
        <p>
          <strong>Article the Fourth</strong> … The right to own our
          relationship with our guests without the threat of re-marketing.
        </p>
        <p>
          <strong>Article the Fifth</strong> … The right to receive our money on
          time, without unnecessary holds.
        </p>
        <p>
          <strong>Article the Sixth</strong> … The right to expect screened,
          qualified guests.
        </p>
        <p>
          <strong>Article the Seventh</strong> … The right to an owner-centric
          platform that respects us and our homes.
        </p>
        <div>
          <div>
            <strong>ATTEST,</strong>
          </div>
          We commit to the fulfillment of this Bill of Rights and pledge our
          support and commitment to provide fair terms in a listing platform
          that respects owners and puts them first.
        </div>
        <p className={classes.signature}>
          The "Vacation Rentals by Friends &amp; Family" Team
        </p>
      </div>
    );
  }
}

BillOfRightsText.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(BillOfRightsText));
