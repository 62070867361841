import React, { Component } from "react";
import { Field } from "redux-form";
import InputField from "./InputField";
import SelectCountry from "./SelectCountry";
import { buildLocation, buildStreetAddress } from "helpers/utils";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LocationSearchField from "./LocationSearchField";

const styles = theme => ({
  root: {
    marginTop: 24
  },
  caption: {},
  field: {
    paddingRight: 8
  },
  formControl: {
    marginLeft: 0,
    marginRight: 0
  },
  error: {
    color: theme.palette.error.main
  }
});

class AddressField extends Component {
  render() {
    const {
      meta: { touched, error },
      input: { value, onChange }
    } = this.props.address.address1;

    const change = this.props.change;
    const classes = this.props.classes;
    const { allowPOBoxes } = this.props;

    const onSuggestSelect = suggest => {
      // build the location object
      console.log("suggest", suggest);

      const location = buildLocation(suggest);
      console.log("location", location);
      if (location !== undefined) {
        const streetAddress = buildStreetAddress(location);

        // set address1 to the street address
        onChange(""); // must first clear the old value
        onChange(streetAddress);

        // brooklyn issue no locality then sublocality
        if (location.sublocality && !location.locality) {
          location.locality = location.sublocality;
          location.sublocality = null;
        }

        // change address2 sublocality
        change("address.address2", location.sublocality || "");

        // set city to locality
        change("address.city", location.locality);

        // set region to administrativeAreaLevel1
        change("address.region", location.administrativeAreaLevel1);

        // set postalCode to postalCode + postalCodeSuffix
        change("address.postalCode", location.postalCode);
        change("address.postalCodeSuffix", location.postalCodeSuffix);

        // set country field to country
        change("address.country", location.country);

        // set the addressLocation
        change("address.location", location);
      }
    };

    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LocationSearchField
              autoFocus={false}
              error={touched && error ? true : false}
              inputClass={classes.formControl}
              initialValue={value}
              label={"Street Address 1"}
              onSelect={onSuggestSelect}
              onChange={onChange}
            />
            {!(touched && error) && !allowPOBoxes && (
              <Typography
                className={classes.caption}
                variant="caption"
                component="div"
              >
                {"No P.O. boxes"}
              </Typography>
            )}
            {touched && error && (
              <Typography
                className={classes.error}
                variant="caption"
                component="div"
              >
                {error}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Field
              name="address.address2"
              type="text"
              formControlClass={classes.formControl}
              component={InputField}
              variant="outlined"
              label="Address 2 (Optional)"
              labelSmall="(Suite or Unit)"
              placeholder="Street Address 2"
              required={false}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.field}>
              <Field
                name="address.city"
                type="text"
                variant="outlined"
                formControlClass={classes.formControl}
                component={InputField}
                label="City"
                placeholder="City"
                required={true}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              name="address.region"
              type="text"
              variant="outlined"
              formControlClass={classes.formControl}
              component={InputField}
              label="State/Region"
              placeholder="State/Region"
              required={true}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.field}>
              <Field
                variant="outlined"
                name="address.postalCode"
                type="text"
                formControlClass={classes.formControl}
                component={InputField}
                label="Postal Code"
                placeholder="Zip/Postal code"
                required={true}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              variant="outlined"
              name="address.country"
              type="text"
              margin="dense"
              formControlClass={classes.formControl}
              component={SelectCountry}
              label="Country"
              placeholder="Country"
              required={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { name: "AddressField" })(AddressField);
