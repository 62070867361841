import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../../withRoot";

const styles = theme => ({
  topMessage: {
    marginBottom: 8
  }
});

class StayCanceledMessage extends Component {
  render() {
    // const { message, classes } = this.props;
    return <>Request was canceled</>;
  }
}

export default withRoot(withStyles(styles)(StayCanceledMessage));
