import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../../../withRoot";

const styles = theme => ({
  topMessage: {
    marginBottom: 8
  }
});

class StayUpdatedMessage extends Component {
  render() {
    const { message, classes } = this.props;
    return (
      <>
        <div className={classes.topMessage}>The request got updated</div>
        <div>
          Checkin is <b>{message.start_date}</b> and Checkout is{" "}
          <b>{message.end_date}</b>
        </div>
      </>
    );
  }
}

export default withRoot(withStyles(styles)(StayUpdatedMessage));
