import React, { Component } from "react";
import PropTypes from "prop-types";
import EditorButton from "./EditorButton";

const BLOCK_TYPES = [
  { label: "unordered-list-item", style: "unordered-list-item" },
  { label: "ordered-list-item", style: "ordered-list-item" }
];

class BlockStyleControls extends Component {
  render() {
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();

    return (
      <span>
        {BLOCK_TYPES.map(type => (
          <EditorButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={this.props.onToggle}
            style={type.style}
          />
        ))}
      </span>
    );
  }
}

BlockStyleControls.propTypes = {
  editorState: PropTypes.object,
  onToggle: PropTypes.func
};

export default BlockStyleControls;
