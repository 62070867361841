import React, { Fragment } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "components/General/Avatar";
import HomeIcon from "../Icons/HomeHeartLight";

import { Link } from "react-router-dom";
import moment from "moment";

const styles = theme => ({
  addressText: {
    fontSize: "0.75rem",
    marginTop: 5,
    color: "rgba(0, 0, 0, 0.74)"
  },
  listLeftText: {
    marginLeft: 16,
    maxWidth: 200,
    minWidth: 200
  },
  itemPending: {
    borderLeft: "2px solid yellow"
  },
  noMargin: {
    margin: 0
  },
  bold: { margin: 0, fontWeight: "bold" },
  image: {
    display: "flex",
    width: "40px",
    height: "40px",
    flexGrow: 0,
    marginRight: "8px",
    backgroundColor: "transparent"
  }
});

const FollowRequestItem = ({
  follow,
  message,
  name,
  picture,
  index,
  length,
  classes,
  isManage
}) => {
  return (
    <Fragment key={index}>
      <ListItem
        alignItems="flex-start"
        button
        component={Link}
        to={"/p/" + follow.property.slug}
      >
        <ListItemAvatar>
          {picture !== null ? (
            <Avatar isBig firstName={name} src={picture} />
          ) : (
            <HomeIcon className={classes.image} />
          )}
        </ListItemAvatar>
        <ListItemText
          className={classes.listLeftText}
          primary={
            <Typography component="span" variant="body2" color="textPrimary">
              {follow.property.name}
            </Typography>
          }
          secondaryTypographyProps={{ component: "div" }}
          secondary={
            <Fragment>
              <p className={classes.bold}>{"Pending"}</p>
              <p className={classes.noMargin}>
                Requested at{" "}
                {moment(follow.createdAt).format("MMM DD, YYYY at HH:mm")}
              </p>
            </Fragment>
          }
        />
      </ListItem>
      {index < length - 1 && false && <Divider component="li" />}
    </Fragment>
  );
};

export default React.memo(withStyles(styles)(FollowRequestItem));
