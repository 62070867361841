import { apiRequest } from "apis/jsonApi";
import Resource from "./resource";

let resource = new Resource({
  singularName: "personalProperty",
  pluralName: "personalProperties"
});

export function fetchPersonalProperties() {
  return function(dispatch) {
    dispatch(resource.fetchingPlural);
    apiRequest(`properties`, "GET")
      .then(response => {
        dispatch(resource.addingPlural(response));
        //filter out the properties that the user is not an host of
        let ids = response.data
          .map(p => (p.isHost && p.active ? p.id : false))
          .filter(Boolean);
        return ids;
      })
      .then(ids => {
        dispatch(resource.successPlural(ids));
      })
      .catch(error => dispatch(resource.errorPlural(error)));
  };
}

export default resource.reducer;
