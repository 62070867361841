// @flow weak

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import MaterialLink from "@material-ui/core/Link";

export const styles = theme => ({
  navLink: {
    fontWeight: "500",
    color: "#555",
    display: "block",
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    textDecoration: "none",
    borderRight: "2px solid #ededed",
    textAlign: "right",
    transition: theme.transitions.create("all"),
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      borderColor: theme.palette.secondary.main
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      borderLeft: "2px solid #ededed",
      borderRight: "none",
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(2.5)
    }
  },
  activeItem: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main
  },
  danger: {
    color: "#e43",

    "&:hover": {
      color: "#b93426",
      borderColor: "#b93426"
    }
  }
});

class AppSidebarNavItem extends Component {
  static propTypes = {
    children: PropTypes.node,
    currentPath: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    to: PropTypes.string
  };
  render() {
    const { classes, currentPath, title, to, type } = this.props;
    if (to) {
      let linkClass = classNames(classes.navLink, classes[type]);
      if (to.length === 1) {
        if (currentPath && to === currentPath) {
          linkClass = classNames(linkClass, classes.activeItem);
        }
      } else if (currentPath && to === currentPath.substring(0, to.length)) {
        linkClass = classNames(linkClass, classes.activeItem);
      }
      return (
        <MaterialLink
          component={Link}
          color="textPrimary"
          to={to}
          className={linkClass}
        >
          {title}
        </MaterialLink>
      );
    }
  }
}

export default withStyles(styles, { name: "AppSidebarNavItem" })(
  AppSidebarNavItem
);
