import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loading from "../Progress/Loading";
import FollowRequestsContainer from "../../containers/Properties/FollowRequestsContainer";
import PropertyEditContainer from "../../containers/Properties/PropertyEditContainer";
import PropertyGuestsContainer from "../../containers/Properties/PropertyGuestsContainer";
import PropertyImagesContainer from "../../containers/Properties/PropertyImagesContainer";

export default class ManageProperty extends Component {
  state = {
    tab: 0
  };

  handleTabChange = (event, tab) => {
    this.setState({ tab });
  };

  render() {
    const { property,isFetching } = this.props;

    const { tab } = this.state;

    return (
      <main>
        {isFetching && <Loading />}
        {!isFetching && (
          <div>
            {property && (
              <React.Fragment>
                <AppBar color="default" position="static">
                  <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Edit" />
                    <Tab label="Images" />
                    <Tab label="Guest List" />
                    <Tab label="Follow Requests" />
                  </Tabs>
                </AppBar>
              </React.Fragment>
            )}
            {tab === 0 && (
              <PropertyEditContainer isCreate property={property} />
            )}
            {tab === 1 && <PropertyImagesContainer property={property} />}
            {tab === 2 && <PropertyGuestsContainer property={property} />}
            {tab === 3 && <FollowRequestsContainer property={property} />}
          </div>
        )}
      </main>
    );
  }
}