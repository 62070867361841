import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as imagesActionCreators from "../../redux/modules/images";
import PropertyImages from "../../components/Properties/PropertyImages";
import { getErrorMessage } from "../../helpers/utils";

class PropertyImagesContainer extends Component {
  state = {
    characterCount: null,
    deleteFollowError: null,
    deleteImage: false,
    deleteImageError: null,
    deleteProperty: false,
    deletePropertyError: null,
    descriptionRaw: null,
    descriptionHtml: null,
    image: null,
    snackbarMessage: null
  };

  closeDeleteImage = event => {
    this.setState({
      deleteImage: false
    });
  };

  closeSnackbarMessage = () => {
    this.setState({ snackbarMessage: null });
  };

  openDeleteImage = (event, id) => {
    let image = this.props.images[id];
    this.setState({ deleteImage: true, image });
  };

  getPropertyImages = async () => {
    const id =
      (this.props.property && this.props.property.id) ||
      this.props.match.params.id;
    await this.props.fetchImagesByProperty(id);
  };

  onDeleteImage = async id => {
    try {
      let image = this.props.images[id];
      await this.props.deleteImage(id);
      const propertyId =
        (this.props.property && this.props.property.id) ||
        this.props.match.params.id;
      await this.props.fetchImagesByProperty(propertyId);
      this.closeDeleteImage();
      this.setState({ snackbarMessage: `${image.filename} deleted` });
    } catch (error) {
      console.error("error:", error);
      let deleteImageError = getErrorMessage(
        error,
        "There was a problem deleting the image."
      );
      this.setState({ deleteImageError });
      throw error;
    }
  };

  savePropertyImages = async iamges => {
    await this.props.sortImages(iamges);
  };

  handleUpload = async files => {
    const id =
      (this.props.property && this.props.property.id) ||
      this.props.match.params.id;
    await this.props.uploadImages(files, {
      property_id: id
    });
    // load images after upload
    await this.props.fetchImagesByProperty(id);
  };

  render() {
    return (
      <PropertyImages
        closeDeleteDialog={this.closeDeleteImage}
        closeSnackbarMessage={this.closeSnackbarMessage}
        deleteDialog={this.state.deleteImage}
        deleteError={this.state.deleteImageError}
        getPropertyImages={this.getPropertyImages}
        handleDelete={values => this.onDeleteImage(values)}
        handleUpload={this.handleUpload}
        isDeleting={this.props.isDeleting}
        image={this.state.image}
        images={this.props.images}
        isUploading={this.props.isUploading}
        openDeleteDialog={this.openDeleteImage}
        property={this.props.property}
        snackbarMessage={this.state.snackbarMessage}
        savePropertyImages={this.savePropertyImages}
      />
    );
  }
}

function mapStateToProps({ images }) {
  return {
    isDeleting: images.isDeleting,
    isUploading: images.isUploading,
    images
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...imagesActionCreators
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PropertyImagesContainer);
