import React, { Component, Fragment } from "react";
import { generatePath } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as propertiesActionCreators from "../../redux/modules/properties";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import HomeIcon from "../../components/Icons/HomeIcon";
import { buildAddress } from "../../helpers/utils";
import InputLabel from "@material-ui/core/InputLabel";
import AppSidebarNavItem from "../../components/App/AppSidebarNavItem";
import { ListItem, Collapse } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Helmet } from "react-helmet";

const CustomItem = ({ item }) => {
  const containerStyle = {
    display: "flex",
    flexDirection: "row"
  };

  const imageStyle = {
    display: "flex",
    width: "32px",
    height: "32px",
    flexGrow: 0,
    marginRight: "8px",
    backgroundColor: "transparent"
  };

  const dropdownImage = item.img ? (
    <img src={item.img} alt={item.name} style={imageStyle} />
  ) : (
    <HomeIcon style={imageStyle} />
  );

  return (
    <div style={containerStyle}>
      {dropdownImage}
      <div>
        <strong>{item.name}</strong>
      </div>
    </div>
  );
};

const styles = theme => ({
  layout: {
    display: "flex",
    width: "auto",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      marginRight: 0
    }
  },
  sidebar: {
    width: theme.spacing(24),
    marginRight: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "100%",
      textAlign: "left"
    }
  },
  sidebarOptionsMenu: {
    [theme.breakpoints.down("xs")]: {
      display: "block",
      paddingBottom: theme.spacing(2),
      width: "100%",
      overflowX: "scroll",
      textAlign: "left"
    }
  },
  manageContent: {
    flex: 1
  },
  selectPropertyWrapper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  selectPropertyDropdown: {
    width: "100%",
    borderBottom: "none",
    whiteSpace: "unset"
  },
  dropdownNav: {
    fontWeight: "500",
    color: "#555",
    display: "flex",
    paddingRight: theme.spacing(2.5),
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    textDecoration: "none",
    borderRight: "2px solid #ddd",
    textAlign: "right",
    justifyContent: "flex-end",
    transition: theme.transitions.create("all"),
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      borderRightColor: theme.palette.secondary.main
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      borderLeft: "2px solid #ededed",
      borderRight: "none",
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2.5)
    }
  }
});

class ManageContainer extends Component {
  constructor(props) {
    super(props);

    const selectedProperty =
      (this.props.match.params && this.props.match.params.id) || "all";
    this.state = {
      openSettingsMenu: selectedProperty === "all" ? false : true,
      selectedProperty: selectedProperty
    };
  }

  componentDidMount() {
    this.props.fetchProperties();
  }

  static getDerivedStateFromProps(props, state) {
    const currentPropertyId =
      (props.match.params && props.match.params.id) || "all";
    if (currentPropertyId !== state.selectedProperty)
      return {
        selectedProperty: currentPropertyId
      };

    // Return null to indicate no change to state.
    return null;
  }

  handlePropertyChange = event => {
    const name = this.props.match.params.name;
    const value = event.target.value;

    if (
      this.props.match.params.id &&
      (this.props.acceptsAll || value !== "all")
    ) {
      return this.props.history.push({
        pathname: generatePath(this.props.match.path, {
          name: name,
          id: value
        })
      });
    } else if (this.props.location.pathname === "/manage" && value !== "all") {
      return this.props.history.push("/manage/calendar/" + value);
    }
    this.props.history.push({
      pathname: "/manage"
    });
  };

  handleNavClick = () => {
    this.setState({ openSettingsMenu: !this.state.openSettingsMenu });
  };

  render() {
    const { classes, properties } = this.props;
    const { openSettingsMenu } = this.state;
    const currentPath = this.props.location.pathname;
    let selectedSlug = false;
    const dropDownProperties = (properties.ownPropertiesIds || []).map(i => {
      const p = properties[i];
      if (i === this.state.selectedProperty) selectedSlug = p.slug;
      const image = p.image ? p.image.url : null;
      return {
        value: p.id,
        name: p.name,
        address: buildAddress(p.address),
        img: image
      };
    });

    return (
      <Fragment>
        <Helmet>
          <title>Manage&nbsp;&bull;&nbsp;Vrbff</title>
        </Helmet>
        <div className={this.props.classes.layout}>
          <div className={classes.sidebar}>
            <FormControl className={classes.selectPropertyWrapper}>
              <InputLabel htmlFor="age-customized-select">Property</InputLabel>
              <Select
                classes={{ selectMenu: classes.selectPropertyDropdown }}
                value={
                  dropDownProperties.length > 0
                    ? this.state.selectedProperty || "all"
                    : "all"
                }
                onChange={this.handlePropertyChange}
              >
                <MenuItem value="all">
                  <strong>All Properties</strong>
                </MenuItem>
                {dropDownProperties.map(p => (
                  <MenuItem key={p.value} value={p.value}>
                    <CustomItem item={p} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.sidebarOptionsMenu}>
              <AppSidebarNavItem
                title="Calendar"
                to={"/manage/calendar/" + this.state.selectedProperty}
                currentPath={currentPath}
              />

              <AppSidebarNavItem
                title="Stay Requests"
                to={"/manage/stays/" + this.state.selectedProperty}
                currentPath={currentPath}
              />

              {this.state.selectedProperty !== "all" && (
                <Fragment>
                  <AppSidebarNavItem
                    title="Sharing &amp; Guest List"
                    to={"/manage/guests/" + this.state.selectedProperty}
                    currentPath={currentPath}
                  />

                  <AppSidebarNavItem
                    title="Follow Requests"
                    to={"/manage/follows/" + this.state.selectedProperty}
                    currentPath={currentPath}
                  />

                  <ListItem
                    className={classes.dropdownNav}
                    button
                    onClick={this.handleNavClick}
                  >
                    <span>Settings</span>
                    {openSettingsMenu ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  <Collapse in={openSettingsMenu} timeout="auto" unmountOnExit>
                    <AppSidebarNavItem
                      title="Basics"
                      to={
                        "/manage/property/basics/" + this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Description"
                      to={
                        "/manage/property/description/" +
                        this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Address"
                      to={
                        "/manage/property/address/" +
                        this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Amenities"
                      to={
                        "/manage/property/amenities/" +
                        this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Privacy"
                      to={
                        "/manage/property/privacy/" +
                        this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Groups & Pricing"
                      to={
                        "/manage/property/groupspricing/" +
                        this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Payment Handling"
                      to={
                        "/manage/property/paymenthandling/" +
                        this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Manage Hosts"
                      to={
                        "/manage/property/hosts/" + this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    />
                    <AppSidebarNavItem
                      title="Guest View"
                      to={"/p/" + selectedSlug}
                      currentPath={currentPath}
                    />

                    {/* <AppSidebarNavItem
                      type={"danger"}
                      title="Danger Zone"
                      to={
                        "/manage/property/danger/" + this.state.selectedProperty
                      }
                      currentPath={currentPath}
                    /> */}
                  </Collapse>
                </Fragment>
              )}
            </div>
          </div>
          <div className={classes.manageContent}>{this.props.children}</div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps({ properties }, props) {
  return {
    properties: properties
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...propertiesActionCreators
    },
    dispatch
  );
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ManageContainer)
);
