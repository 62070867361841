import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import grey from "@material-ui/core/colors/grey";
import AddIcon from "@material-ui/icons/Add";
import withRoot from "../../withRoot";
import HomeIcon from "../Icons/HomeIcon";
import Loading from "../Progress/Loading";
import ProgressButton from "../Progress/ProgressButton";
import SnackbarMessage from "../Message/SnackbarMessage";
import PropertySBB from "components/General/PropertySBB";
import locationIcon from "../../static/assets/icons/location.svg";
import PropertyExtensionPanel from "./PropertyExtensionPanel";
import { cleanHtml } from "helpers/utils";
import HostsNew from "components/General/HostsNew";

const styles = theme => ({
  title: {
    marginTop: 8,
    marginBottom: 16
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  actions: {
    marginTop: 16,
    position: "relative",
    display: "block",
    textAlign: "right",
    width: "100%"
  },
  buttonDelete: {
    marginRight: 8
  },
  buttonBack: {
    marginBottom: 8
  },
  container: {
    padding: 24
  },
  headline: {
    marginBottom: 16
  },
  icon: {
    color: grey[200],
    maxWidth: 440,
    [theme.breakpoints.up("xs")]: {
      width: 360
    },
    [theme.breakpoints.up("sm")]: {
      width: 440
    }
  },
  image: {
    height: 512,
    width: "auto"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    flex: "1 1 auto"
  },
  textField: {
    width: 286
  },
  containerMainContent: {
    padding: 32,
    paddingTop: 8
  },
  infoContainer: {
    borderBottom: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  iconInfo: {
    color: "#40A5CB",
    marginLeft: 4,
    fontSize: 20,
    fontWeight: "bold"
  },
  infoTitle: {
    fontWeight: "bold"
  },
  amenitiesTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2)
  },
  descriptionContainer: {
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  requestTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1)
  },
  avatar: {
    boxShadow: "#fff 0 0 0 2px, #40A5CB 0 0 0 4px",
    width: 30,
    height: 30,
    marginRight: theme.spacing(2)
  },
  hostedText: {
    marginBottom: 0
  },
  hostedBy: {
    display: "flex",
    alignItems: "center"
  },
  propertyLocation: {
    marginTop: theme.spacing(2)
  },
  containerSide: {
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 32,
    paddingBottom: 32
  },
  containerExpandning: {
    marginTop: 40
  },
  houseRules: {
    padding: "0 " + theme.spacing(2) + "px"
  },
  containerTitle: {
    padding: 32,
    paddingBottom: 8
  },
  subtitleContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    color: "#00000066",
    marginBottom: 0,
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(3),
    borderRight: "1px solid #00000066"
  },
  SBB: {
    paddingTop: theme.spacing(2),
    borderTop: "1px solid #ddd",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  amenitiesIcon: {
    marginRight: theme.spacing(1),
    fontSize: 22
  },
  amenitiesText: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2)
  },
  containerImage: {
    textAlign: "center"
  },
  mainInfoContainer: {
    marginTop: 40,
    border: "1px solid " + theme.palette.secondary.border,
    borderRadius: 4
  },
  mainInfoSubContainer: {
    paddingRight: 40,
    paddingLeft: 40,
    paddingTop: 32,
    paddingBottom: 16
  },
  location: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main
  },
  locationIcon: {
    height: 24,
    verticalAlign: "middle",
    marginRight: 8,
    color: theme.palette.primary.main
  },
  titleText: {
    color: "#0F0F10",
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: "48px",
    marginBottom: 8
  },
  mainInfoSubElements: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mainInfoHostsContainer: { display: "flex", alignItems: "center" },
  hosts: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "28px",
    color: theme.palette.text.main,
    marginBottom: 0,
    marginRight: 16
  },
  mainGeneralInfo: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    paddingTop: 16,
    borderTop: "1px solid #d5d5d5",
    paddingRight: 40,
    paddingLeft: 40,
    marginBottom: 8
  },
  generalInfoText: {
    fontWeight: 700,
    marginLeft: 8
  },
  mainImage: {
    width: "100%",
    paddingTop: "70%",
    backgroundSize: "cover",
    backgroundPosition: "center"
  }
});

class RequestProperty extends Component {
  render() {
    const {
      property,
      classes,
      closeSnackbarMessage,
      handleFollow,
      isAdding,
      isFetching,
      snackbarMessage
    } = this.props;

    let isPrivate = property.privacy === "Private";
    const rules =
      property && property.rulesHtml ? cleanHtml(property.rulesHtml) : false;
    return (
      <main>
        {isFetching && <Loading />}
        {!isFetching && property && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={7}>
              <div className={classes.containerImage}>
                {property.images.length === 0 ||
                  (isPrivate && (
                    <HomeIcon
                      style={{ fontSize: 216 }}
                      className={classes.icon}
                    />
                  ))}
                {property.image && !isPrivate && (
                  <div
                    className={classes.mainImage}
                    style={{
                      backgroundImage: "url('" + property.image.url + "')"
                    }}
                  />
                )}
              </div>
              {/* {property.images.length > 0 && (
                  <PropertyCarousel
                    images={(property.images || []).sort(
                      (a, b) => a.sortOrder - b.sortOrder
                    )}
                  />
                )} */}

              <div className={classes.mainInfoContainer}>
                <div className={classes.mainInfoSubContainer}>
                  <div className={classes.titleText}>{property.name}</div>
                  <div className={classes.mainInfoSubElements}>
                    <Typography
                      variant="subtitle1"
                      className={classes.location}
                    >
                      <img
                        alt="location icon"
                        src={locationIcon}
                        className={classes.locationIcon}
                      />
                      {`${property.address.city}, ${property.address.region}`}
                    </Typography>
                    <div className={classes.mainInfoHostsContainer}>
                      <Typography variant="subtitle1" className={classes.hosts}>
                        Hosts:{" "}
                      </Typography>{" "}
                      <HostsNew isSmall hosts={property.hosts} />
                    </div>
                  </div>
                </div>
                <div className={classes.mainGeneralInfo}>
                  <PropertySBB
                    sleeps={property.sleeps}
                    bathrooms={property.bathrooms}
                    bedrooms={property.bedrooms}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={5}>
              <Paper elevation={4} className={classes.containerSide}>
                <Typography color="inherit" className={classes.infoTitle}>
                  You don't have access to this property
                </Typography>

                <Typography
                  variant="body1"
                  component="div"
                  style={{ marginTop: 8, marginBottom: 24 }}
                >
                  Click the button below to request to follow this property. The
                  owner will have to approve your request before you can see the
                  property.
                </Typography>

                <ProgressButton
                  disabled={property.hasRequested}
                  label={property.hasRequested ? "Follow Requested" : "Follow"}
                  icon={<AddIcon />}
                  loading={isAdding}
                  onClick={handleFollow}
                />

                {/* <div className={classes.propertyLocation}>
                  <Typography
                    variant="button"
                    color="inherit"
                    className={classes.infoTitle}
                  >
                    Property Location
                  </Typography>
                  {this.getMap()}
                </div> */}
                {rules && (
                  <div className={classes.containerExpandning}>
                    {property.rulesHtml && (
                      <PropertyExtensionPanel expanded name="House Rules">
                        <div
                          className={classes.houseRules}
                          dangerouslySetInnerHTML={{
                            __html: property.rulesHtml
                          }}
                        />
                      </PropertyExtensionPanel>
                    )}
                  </div>
                )}
              </Paper>
            </Grid>
          </Grid>
        )}
        <SnackbarMessage
          allowUpdates={true}
          message={snackbarMessage}
          onClose={closeSnackbarMessage}
        />
      </main>
    );
  }
}

export default withRoot(
  withStyles(styles, { name: "RequestProperty" })(RequestProperty)
);
