import { createResource, updateResource, apiRequest } from "apis/jsonApi";
import Resource from "./resource";
import { getErrorMessage } from "helpers/utils";

let resource = new Resource({
  singularName: "rate",
  pluralName: "rates"
});

export function addRate(values) {
  return async function(dispatch, getState) {
    dispatch(resource.addingSingular());
    try {
      const message = await createResource("rate", values);

      return message;
    } catch (error) {
      dispatch(resource.errorAddingSingular(error));
      throw error;
    }
  };
}

export function deleteRate(id) {
  return async function(dispatch, getState) {
    dispatch(resource.deletingSingular());
    try {
      await apiRequest(`rates/${id}`, "DELETE").then(response => {
        if (response.errors) {
          let error = getErrorMessage(response);
          dispatch(resource.errorDeletingSingular(error));
          throw error;
        }
      });
    } catch (error) {
      dispatch(resource.errorDeletingSingular(error));
      throw error;
    }
  };
}

export function updateRate(values) {
  return function(dispatch, getState) {
    dispatch(resource.updatingSingular());
    const trip = updateResource("rate", values)
      .then(response => {
        dispatch(resource.successUpdatingSingular(response));
        return response;
      })
      .catch(error => {
        dispatch(resource.errorUpdatingSingular(error));
        throw error;
      });
    return trip;
  };
}

export default resource.reducer;
