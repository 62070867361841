import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

const SecondaryButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 2,
    fontSize: 16,
    padding: "6px 18px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#333",
    backgroundColor: "#f9f9f9",
    borderColor: "#d2d2d2",
    "&:hover": {
      backgroundColor: "#d9d9d9"
      // borderColor: '#0062cc',
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
})(Button);

const SecondaryButtonSmall = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 2,
    fontSize: 12,
    padding: "4px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    color: "#333",
    backgroundColor: "#f9f9f9",
    borderColor: "#d2d2d2",
    "&:hover": {
      backgroundColor: "#d9d9d9"
      // borderColor: '#0062cc',
    },
    "&:active": {
      // boxShadow: 'none',
      // backgroundColor: '#0062cc',
      // borderColor: '#005cbf',
    },
    "&:focus": {
      // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    }
  }
})(Button);

const AdapterLink = React.forwardRef((props, ref) => (
  <Link innerRef={ref} {...props} />
));

const SecondaryActionButton = props => {
  let { isSmall, ...rest } = props;
  let Btn = props.isSmall ? SecondaryButtonSmall : SecondaryButton;
  return (
    <Btn
      variant="contained"
      component={props.to ? AdapterLink : undefined}
      color="primary"
      {...rest}
    >
      {props.children}
    </Btn>
  );
};

export default SecondaryActionButton;
